import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/CotisationAnnuelle/cotisationannuelle";

export default function TableCotisationAnnuelle(props) {
  const onRowClick = async (rowIdx) => {
    props.onSelectCotisation(rowIdx.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des cotisations annuelles</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Grid
          enableCellSelect={true}
          idProperty="idrefcotisation"
          emptyText="Aucune cotisation renseignée"
          columns={columns}
          dataSource={props.cotisations}
          style={gridStyle}
          onRowClick={onRowClick}
          pagination
          defaultLimit={50}
        />
      </CardBody>
    </Card>
  );
}
