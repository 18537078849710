import { Table } from "reactstrap";

export default function TableAffectation(props) {
  const renderHeader = () => {
    return props.columns.map((column, key) => (
      <th scope="col" key={key}>
        {column.header}
      </th>
    ));
  };

  const renderBody = () => {
    return props.dataSource.map((data, key) => {
      return (
        <tr key={key}>
          {props.columns.map((column, index) => {
            return (
              <td
                key={index}
                onClick={column.onClick ? () => column.onClick(data) : null}
                style={column.style ?? null}
              >
                {column.render ? column.render() : data[column.name]}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Table striped className="table-affectation" style={props.style}>
      <thead>
        <tr>{renderHeader()}</tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
}
