import { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import ZoneProductionController from "../../config/apiUtils/ZoneProductionController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import CommuneInput from "../../components/CommuneInput";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import FormInputGroup from "../../components/Form/FormInputGroup";

export default function FormUpdateExploitation(props) {
  const store = useContext(StoreContext);
  const [hasAdresseEnvoiCourrier, setHasAdresseEnvoiCourrier] = useState(
    props.dernierDossier.envoicourrier,
  );
  const [idstatutjuridique, setIdstatutjuridique] = useState(
    props.dernierDossier.idstatutjuridique,
  );
  const [idrefzoneproduction, setIdrefzoneproduction] = useState(
    props.dernierDossier.idrefzoneproduction,
  );

  useConstructor(async () => {
    ReferentielController.getStatutsJuridique().then((res) => {
      store.referentiels.setStatutsJuridique(res);
    });
    const zonesProdutionAPI =
      await ZoneProductionController.getZonesProduction();
    store.setZonesProduction(zonesProdutionAPI);
  });

  const onSubmitUpdate = (event) => {
    event.preventDefault();
    const exploitationForm = {
      raisonsociale: event.target.raisonsociale.value,
      adresse: event.target.adresse.value,
      idcommune: event.target.idcommune.value,
      idstatutjuridique: idstatutjuridique,
      siret: event.target.siret.value,
      codedc:
        event.target.codedc.value !== "" ? event.target.codedc.value : null,
      codeoctagri:
        event.target.codeoctagri.value !== ""
          ? event.target.codeoctagri.value
          : null,
      numerounilever: event.target.numerounilever.value,
      abandon: event.target.abandon.value,
      listerouge: event.target.listerouge.value,
      idrefzoneproduction: idrefzoneproduction,
      actif: event.target.actif.value,
      commentaireactif: event.target.commentaireactif.value,
      millesimeadhesion:
        event.target.millesimeadhesion.value == ""
          ? null
          : event.target.millesimeadhesion.value,
      envoicourrier: hasAdresseEnvoiCourrier,
      adresseenvoicourrier: hasAdresseEnvoiCourrier
        ? event.target.adresseenvoicourrier.value
        : null,
      idcommuneenvoicourrier: hasAdresseEnvoiCourrier
        ? parseInt(event.target.idcommuneenvoicourrier.value)
        : null,
      commentaireapgmb: event.target.commentaireapgmb.value,
    };
    props.onClickUpdateExploitation(
      props.dernierDossier.idexploitation,
      exploitationForm,
    );
    event.target.reset();
    props.hideFormUpdateExploitation();
  };

  const renderZonesProductionOptions = () => {
    return store.zonesProduction.map((zone, key) => {
      return (
        <option key={key} value={zone.idrefzoneproduction}>
          {zone.libellelong ?? zone.libelle}
        </option>
      );
    });
  };

  const renderStatutsJuridiqueOptions = () => {
    return store.referentiels.statutsJuridique.map((statut, key) => {
      return (
        <option value={statut.idstatutjuridique} key={key}>
          {statut.libellestatut}
        </option>
      );
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Modification de l'exploitation -{" "}
                {props.dernierDossier.raisonsociale}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.hideFormUpdateExploitation} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={onSubmitUpdate}>
            <Row>
              <h5>Administratif</h5>
              <Col md="3">
                <FormInputGroup
                  id="idstatutjuridique"
                  label="Statut juridique *"
                  type="select"
                  className="select-input"
                  placeholder="Statut juridique *"
                  defaultValue={idstatutjuridique}
                  onChange={(e) => setIdstatutjuridique(e.target.value)}
                >
                  {renderStatutsJuridiqueOptions()}
                </FormInputGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="raisonsociale">Raison sociale *</Label>
                  <Input
                    type="text"
                    name="raisonsociale"
                    placeholder="Raison sociale"
                    defaultValue={props?.dernierDossier?.raisonsociale}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="siret">Numéro SIRET *</Label>
                  <Input
                    type="text"
                    name="siret"
                    placeholder="Numero SIRET"
                    defaultValue={props.dernierDossier.siret}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="millesimeadhesion">Année d'adhésion *</Label>
                  <Input
                    type="text"
                    name="millesimeadhesion"
                    placeholder="Année d'adhésion"
                    defaultValue={props?.dernierDossier?.millesimeadhesion}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label for="codedc">Code DC *</Label>
                  <Input
                    type="text"
                    name="codedc"
                    placeholder="Code DC"
                    defaultValue={props.dernierDossier.codedc}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="codeoctagri">Code Octagri *</Label>
                  <Input
                    type="text"
                    name="codeoctagri"
                    placeholder="Code Octagri"
                    defaultValue={props.dernierDossier.codeoctagri}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="numerounilever">Numéro UNILEVER *</Label>
                  <Input
                    type="text"
                    name="numerounilever"
                    placeholder="Numéro UNILEVER"
                    defaultValue={props.dernierDossier.numerounilever}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <h5>Coordonnées</h5>
              <Col md="6">
                <FormGroup>
                  <Label for="adresse">Adresse *</Label>
                  <Input
                    type="text"
                    name="adresse"
                    placeholder="Adresse"
                    defaultValue={props.dernierDossier.adresse}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <CommuneInput
                  codepostal={props?.dernierDossier?.codepostal}
                  idcommune={props?.dernierDossier?.idcommune}
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col>
                <Label check style={{ display: "flex" }}>
                  <Input
                    type={"checkbox"}
                    defaultChecked={hasAdresseEnvoiCourrier}
                    onClick={(evt) =>
                      setHasAdresseEnvoiCourrier(evt.target.checked)
                    }
                  />
                  &nbsp;
                  <h5>
                    Adresse d'envoi du courrier différente du siège sociale
                  </h5>
                </Label>
              </Col>
            </Row>
            <Collapse isOpen={hasAdresseEnvoiCourrier}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="adresseenvoicourrier">
                      Adresse d'envoi du courrier
                    </Label>
                    <Input
                      type="text"
                      name="adresseenvoicourrier"
                      placeholder="Adresse d'envoi du courrier"
                      defaultValue={
                        props.dernierDossier.adresseenvoicourrier ??
                        props.dernierDossier.adresse
                      }
                      idcommune={props?.dernierDossier?.idcommuneenvoicourrier}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <CommuneInput
                    name={"idcommuneenvoicourrier"}
                    codepostal={
                      props?.dernierDossier?.codepostalenvoicourrier ??
                      props?.dernierDossier?.codepostal
                    }
                    idcommune={
                      props.dernierDossier.idcommuneenvoicourrier ??
                      props.dernierDossier.idcommune
                    }
                    required
                  />
                </Col>
              </Row>
            </Collapse>
            <Row style={{ marginTop: 30 }}>
              <Col md="5">
                <h5>Zones de production</h5>
                <FormGroup>
                  <Input
                    type="select"
                    className="select-input"
                    name="idrefzoneproduction"
                    value={idrefzoneproduction}
                    onChange={(e) => setIdrefzoneproduction(e.target.value)}
                  >
                    {renderZonesProductionOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="7">
                <h5>Statut</h5>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="abandon">Abandon de la culture *</Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="abandon"
                        defaultValue={props.dernierDossier.abandon}
                      >
                        <option value={false}>NON</option>
                        <option value={true}>OUI</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="listerouge">Liste rouge *</Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="listerouge"
                        defaultValue={props.dernierDossier.listerouge}
                      >
                        <option value={false}>NON</option>
                        <option value={true}>OUI</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="actif">
                        Actif en {store.millesime.idmillesime}
                      </Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="actif"
                        defaultValue={props.dernierDossier.actif}
                      >
                        <option value={false}>NON</option>
                        <option value={true}>OUI</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="commentaireactif">Commentaire</Label>
                      <Input
                        type="textarea"
                        name="commentaireactif"
                        placeholder={`Commentaire sur l'activité en ${store.millesime.idmillesime}`}
                        defaultValue={props.dernierDossier.commentaireactif}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col>
                <FormGroup>
                  <Label for={"commentaireapgmb"}>Commentaire APGMB</Label>
                  <Input
                    type="textarea"
                    name="commentaireapgmb"
                    placeholder={"Commentaire de l'APGMB"}
                    defaultValue={props.dernierDossier.commentaireapgmb}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
                <CancelButton
                  style={{ marginTop: 15 }}
                  onClick={(event) => {
                    event.preventDefault();
                    props.hideFormUpdateExploitation();
                  }}
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
