import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../context/StoreContext";
import Section from "../Section";
import { Alert, Row, Table } from "reactstrap";
import {
  createNotification,
  downloadFile,
  RenderIf,
} from "../../../../config/utils";
import PdfButton from "../../../../components/Buttons/PdfButton";
import EditionController from "../../../../config/apiUtils/EditionController";

export default function ConventionSection() {
  const context = useContext(StoreContext);
  const [convention, setConvention] = useState(null);

  useEffect(() => {
    if (context.conventions.length > 0) {
      setConvention(context.conventions[0]);
    } else {
      setConvention(null);
    }
  }, [context.conventions]);

  return (
    <>
      <Section id={"convention"} title={"Convention"}>
        <Row>
          <RenderIf isTrue={convention != null}>
            <Table style={{ color: "#FFFFFF" }}>
              <tbody>
                <tr>
                  <th scope="row">Date d'envoi</th>
                  <td>{convention?.dateenvoi}</td>
                </tr>
                <tr>
                  <th scope="row">Date de retour</th>
                  <td>{convention?.dateretour ?? "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Statut</th>
                  <td>
                    <b>
                      {convention?.signee ? (
                        <Alert
                          color="success"
                          style={styles.alertStyle}
                          fade={false}
                        >
                          Signée
                        </Alert>
                      ) : (
                        <Alert
                          color="danger"
                          style={styles.alertStyle}
                          fade={false}
                        >
                          Non signée
                        </Alert>
                      )}
                    </b>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Télécharger</th>
                  <td>
                    <PdfButton
                      color="primary"
                      style={{ marginRight: 30 }}
                      onClick={async () => {
                        createNotification(
                          "info",
                          "",
                          "Préparation du document en cours...",
                        );
                        const resDocument =
                          await EditionController.getConvention(
                            convention?.idconvention,
                          );
                        downloadFile(
                          resDocument,
                          `convention_${convention?.raisonsociale}_${convention?.millesime}.pdf`,
                          "application/octet-stream",
                        );
                        createNotification(
                          "success",
                          "",
                          "Convention générée avec succès.",
                        );
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </RenderIf>
          <RenderIf isTrue={convention == null}>
            <Alert color="warning" fade={false}>
              Aucune convention renseignée pour {context.millesime.idmillesime}
            </Alert>
          </RenderIf>
        </Row>
      </Section>
    </>
  );
}

const styles = {
  alertStyle: {
    height: 12,
    padding: "0rem 1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: 0,
  },
};
