import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeProduit from "./TypeProduit/ListeTypeProduit";
import FormNewTypeProduit from "./TypeProduit/FormNewTypeProduit";
import FormUpdateTypeProduit from "./TypeProduit/FormUpdateTypeProduit";
import { createNotification } from "../../config/utils";

export default function TypeProduit() {
  const store = useContext(StoreContext);
  const [showFormNewTypeProduit, setShowFormNewTypeProduit] = useState(false);
  const [showFormUpdateTypeProduit, setShowFormUpdateTypeProduit] =
    useState(false);
  const [idreftypeproduitToUpdate, setIdreftypeproduitToUpdate] = useState();

  const handleAddNewTypeProduit = async (typeProduit) => {
    const res = await ReferentielController.insertTypeProduit(typeProduit);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de produit créé avec succès",
      );
      store.referentiels.setTypesProduits([
        ...store.referentiels.typesProduits,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de produit",
      );
    }
  };

  const handleUpdateTypeProduit = async (typeProduit) => {
    const res = await ReferentielController.updateTypeProduit(
      idreftypeproduitToUpdate,
      typeProduit,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de produit modifié avec succès",
      );
      store.referentiels.setTypesProduits(
        await ReferentielController.getTypesProduit(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de produit",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeProduit}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeProduit
          onClickAdd={handleAddNewTypeProduit}
          onClickClose={() => setShowFormNewTypeProduit(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeProduit}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeProduit
          onClickUpdate={handleUpdateTypeProduit}
          onClickClose={() => setShowFormUpdateTypeProduit(false)}
          idreftypeproduit={idreftypeproduitToUpdate}
        />
      </Collapse>
      <ListeTypeProduit
        showFormUpdateTypeProduit={(idreftypeproduit) => {
          setIdreftypeproduitToUpdate(idreftypeproduit);
          setShowFormUpdateTypeProduit(true);
        }}
        onClickNewType={() => setShowFormNewTypeProduit(true)}
      />
    </>
  );
}
