import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/Exploitation/rendementref";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { createNotification, RenderIf } from "../../config/utils";
import FormNewRendementRef from "./FormNewRendementRef";
import FormUpdateRendementRef from "./FormUpdateRendementRef";
import ExploitationController from "../../config/apiUtils/ExploitationController";

export default function RendementsExploitation() {
  const store = useContext(StoreContext);
  const [showFormUpdateRendementRef, setShowFormUpdateRendementRef] =
    useState(false);
  const [showFormNewRendementRef, setShowFormNewRendementRef] = useState(false);
  const [selectedRendement, setSelectedRendement] = useState();

  const handleUpdateRendementRefExploitation = async (rendement) => {
    const res = await ExploitationController.updateRendementRefExploitation(
      rendement,
      store.exploitation.informations.idexploitation,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Rendement modifié avec succès");
      await store.exploitation.loadDernierDossier();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification du rendement...",
      );
    }
  };

  const handleNewRendementRef = async (rendement) => {
    const resRendements =
      await ExploitationController.insertRendementRefExploitation(
        rendement,
        store.exploitation.informations.idexploitation,
      );
    if (resRendements.status === 200) {
      createNotification("success", "Succès", "Rendement créé avec succès");
      await store.exploitation.loadDernierDossier();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création du rendement...",
      );
    }
  };

  const onRowClick = async (rowIdx) => {
    setSelectedRendement(rowIdx.data);
    setShowFormUpdateRendementRef(true);
  };

  const renderRendementsRef = () => {
    return (
      <Grid
        onRowClick={
          store.utilisateur.hasDroits(
            droits.typesdroits.GESTION_EXPLOITATION,
            droits.modalite.modification,
          )
            ? onRowClick
            : null
        }
        idProperty="idrendementrefexploitation"
        columns={columns}
        emptyText="Aucune rendement n'est renseigné"
        dataSource={store.exploitation.informations.rendementref}
        style={gridStyle}
      />
    );
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Rendements de référence</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf
              isTrue={store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_EXPLOITATION,
                droits.modalite.modification,
              )}
            >
              <Button color="primary">
                <i
                  className="fa-solid fa-plus"
                  onClick={() => setShowFormNewRendementRef(true)}
                />
              </Button>
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Collapse
              isOpen={showFormNewRendementRef}
              mountOnEnter
              unmountOnExit
            >
              <FormNewRendementRef
                onClickNew={handleNewRendementRef}
                onClickClose={() => {
                  setShowFormNewRendementRef(false);
                }}
              />
            </Collapse>
            <Collapse
              isOpen={!showFormUpdateRendementRef && !showFormNewRendementRef}
              mountOnEnter
              unmountOnExit
            >
              {renderRendementsRef()}
            </Collapse>
            <Collapse
              isOpen={showFormUpdateRendementRef}
              mountOnEnter
              unmountOnExit
            >
              <FormUpdateRendementRef
                onClickUpdate={handleUpdateRendementRefExploitation}
                rendement={selectedRendement}
                onClickClose={() => {
                  setShowFormUpdateRendementRef(false);
                }}
              />
            </Collapse>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
