import APIController from "../APIController";

class CotisationController extends APIController {
  constructor() {
    super("/cotisations");
  }

  async payerCotisation(idcontrat) {
    return await this.putRequest(`/paye?idcontrat=${idcontrat}`);
  }

  async getCotisationByMillesime(millesime) {
    return await this.getRequest("/", {
      params: {
        millesime,
      },
    });
  }
}

export default new CotisationController();
