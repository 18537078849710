import { Button } from "reactstrap";

export default function CloseButton(props) {
  return (
    <Button
      className="close"
      style={styles.closeButton}
      onClick={props.onClick}
    >
      <i className="fa fa-times" aria-hidden="true"></i>
    </Button>
  );
}

const styles = {
  closeButton: {
    borderRadius: 20,
    backgroundColor: "transparent",
    border: 0,
    padding: "0.75rem 1.25rem",
    position: "absolute",
    float: "right",
    color: "inherit",
    lineHeight: 1,
    opacity: 0.5,
    fontSize: "1.5rem",
    fontWeight: 700,
  },
};
