import APIController from "../APIController";

class UtilisateurController extends APIController {
  constructor() {
    super("");
  }

  async getUserInfo() {
    return await this.getRequest("/users/me");
  }

  async getUtilisateurs() {
    return await this.getRequest("/utilisateur");
  }

  async getDroitsUtilisateur() {
    return await this.getRequest("/profil/droits/utilisateur");
  }

  async getAccesUtilisateur(idutilisateur) {
    return await this.getRequest(`/utilisateur/${idutilisateur}/acces`);
  }

  async getProfilsUtilisateur() {
    return await this.getRequest("/profil/utilisateur/");
  }

  async isRespoOSDijonCereales() {
    const res = (await this.getProfilsUtilisateur())[0];
    return res.codeprofil === "RESPO_OS" && res.idorganismestockeur === 3;
  }

  async createUser(utilisateur) {
    return await this.postRequest("/utilisateur", utilisateur);
  }

  async createFormulaireUtilisateur(formulaire) {
    return await this.postRequest("/utilisateur/formulaire", formulaire);
  }

  async getFormulairesUtilisateur() {
    return await this.getRequest("/utilisateur/formulaire");
  }

  async updateFormulairesUtilisateur(idformulaireutilisateur, formulaire) {
    return await this.putRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}`,
      formulaire,
    );
  }

  async deleteFormulaireUtilisateur(idformulaireutilisateur) {
    return await this.deleteRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}`,
    );
  }

  async validerFormulaireUtilisateur(
    idformulaireutilisateur,
    idprofil,
    idorganismestockeur,
  ) {
    if (idorganismestockeur) {
      return await this.putRequest(
        `/utilisateur/formulaire/${idformulaireutilisateur}/validation?idprofil=${idprofil}&idorganismestockeur=${idorganismestockeur}`,
      );
    }
    return await this.putRequest(
      `/utilisateur/formulaire/${idformulaireutilisateur}/validation?idprofil=${idprofil}`,
    );
  }

  async createAccesUtilisateur(idutilisateur, acces) {
    return await this.postRequest(`/utilisateur/${idutilisateur}/acces`, acces);
  }

  async changePasswordUtilisateur(idutilisateur, utilisateur) {
    return await this.putRequest(
      `/utilisateur/${idutilisateur}/changepassword`,
      utilisateur,
    );
  }

  async reinitPasswordUtilisateur(idutilisateur) {
    return await this.postRequest(
      `/utilisateur/${idutilisateur}/reinitpassword`,
    );
  }

  async activerUtilisateur(idutilisateur) {
    return await this.putRequest(`/utilisateur/${idutilisateur}/activation`);
  }

  async modifierUtilisateur(idutilisateur, utilisateur) {
    return await this.putRequest(`/utilisateur/${idutilisateur}`, utilisateur);
  }

  async updateInfosUtilisateur(idutilisateur, utilisateur) {
    return await this.putRequest(
      `/utilisateur/${idutilisateur}/infos`,
      utilisateur,
    );
  }

  async desactiverUtilisateur(idutilisateur) {
    return await this.putRequest(`/utilisateur/${idutilisateur}/desactivation`);
  }

  async deleteUtilisateur(idutilisateur) {
    return await this.deleteRequest(`/utilisateur/${idutilisateur}`);
  }
}

export default new UtilisateurController();
