import APIController from "../APIController";

class ProducteurEditionController extends APIController {
  constructor() {
    super("/producteur/edition");
  }

  async updateCivilite(idproducteur, idcivilite) {
    return await this.putRequest(
      `/idcivilite?idproducteur=${idproducteur}${
        idcivilite ? "&idcivilite=" + idcivilite : ""
      }`,
    );
  }

  async updateNom(idproducteur, nom) {
    return await this.putRequest(
      `/nom?idproducteur=${idproducteur}${nom ? "&nom=" + nom : ""}`,
    );
  }

  async updatePrenom(idproducteur, prenom) {
    return await this.putRequest(
      `/prenom?idproducteur=${idproducteur}${
        prenom ? "&prenom=" + prenom : ""
      }`,
    );
  }

  async updateMail(idproducteur, mail) {
    return await this.putRequest(
      `/mail?idproducteur=${idproducteur}${mail ? "&mail=" + mail : ""}`,
    );
  }

  async updateTelephoneFixe(idproducteur, telephonefixe) {
    return await this.putRequest(
      `/telephonefixe?idproducteur=${idproducteur}${
        telephonefixe ? "&telephonefixe=" + telephonefixe : ""
      }`,
    );
  }

  async updateTelephonePortable(idproducteur, telephoneportable) {
    return await this.putRequest(
      `/telephoneportable?idproducteur=${idproducteur}${
        telephoneportable ? "&telephoneportable=" + telephoneportable : ""
      }`,
    );
  }

  async updateField(idproducteur, field, value) {
    return await this.putRequest(
      `/${field}?idproducteur=${idproducteur}${
        value !== undefined && value !== null && value !== ""
          ? "&" + field + "=" + value
          : ""
      }`,
    );
  }
}

export default new ProducteurEditionController();
