import Login from "../../views/Login";
import Logout from "../../views/Logout";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import Layouts from "../layoutRoutes";

const AuthRoutes = [
  {
    path: "/login",
    name: "Connexion",
    element: <Login />,
    layout: Layouts.loginLayout,
    show: false,
  },
  {
    path: "/logout",
    name: "Deconnexion",
    element: <Logout />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/privacy-policy",
    name: "Politique de confidentialité",
    element: <PrivacyPolicy />,
    layout: Layouts.loginLayout,
    show: true,
    pageGenerique: true,
  },
];
export default AuthRoutes;
