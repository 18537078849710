import APIController from "../APIController";

class ConventionController extends APIController {
  constructor() {
    super("/convention");
  }

  async insertConvention(convention) {
    return await this.postRequest("/", convention);
  }

  async getConventionMillesime(millesime) {
    return await this.getRequest(`/?millesime=${millesime}`);
  }

  async updateConvention(idconvention, convention) {
    return await this.putRequest(`/${idconvention}`, convention);
  }
}

export default new ConventionController();
