import { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormNewVariete(props) {
  const store = useContext(StoreContext);
  const onSubmit = (event) => {
    const produit = {
      libelle: event.target.libelle.value,
      codevariete: event.target.codevariete.value,
      idreftypevariete: event.target.idreftypevariete.value,
      estapteigp: event.target.estapteigp.value,
      estutilisee: true,
    };
    props.onClickAdd(produit);
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  const renderTypesVarietesOption = () => {
    return store.referentiels.typesVarietes.map((type, key) => {
      return (
        <option key={key} value={type.idreftypevariete}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle variété</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label for="idreftypevariete">Type de variété</Label>
                <Input
                  name="idreftypevariete"
                  type="select"
                  className="select-input"
                >
                  {renderTypesVarietesOption()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="codevariete">Code variété</Label>
                <Input
                  name="codevariete"
                  placeholder={"Code variété"}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="libelle">Variété</Label>
                <Input name="libelle" placeholder={"Variété"} type="text" />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="estapteigp">Apte IGP</Label>
                <Input name="estapteigp" type="select" className="select-input">
                  <option value={1}>OUI</option>
                  <option value={0}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
