import { useState } from "react";
import { Col, Row } from "reactstrap";
import { RenderIf } from "../config/utils";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import CartoAppariement from "./appariementparcelles/CartoAppariement";

export default function AppariementParcelles() {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col md="12">
          <CartoAppariement />
        </Col>
      </Row>
    </>
  );
}
