import { Fragment, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
  defaultFilterValue,
} from "../../components/Grids/planproduction/listeparparcelle";
import StoreContext from "../../context/StoreContext";
import {
  RenderIf,
  convertToCSV,
  createNotification,
  downloadCsv,
  unflatten,
} from "../../config/utils";
import moment from "moment";
import CsvButton from "../../components/Buttons/CsvButton";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import useConstructor from "../../config/hooks/useConstructor";
import PlanProductionEditionController from "../../config/apiUtils/PlanProductionEditionController";
import SurfacePotentielleEditionController from "../../config/apiUtils/SurfacePotentielleEditionController";

export default function ListeParParcelle(props) {
  const store = useContext(StoreContext);

  const [dataSource, setDataSource] = useState(
    store.planProduction.planProductionParcelle,
  );
  const [totalAutomne, setTotalAutomne] = useState(0);
  const [totalPrintemps, setTotalPrintemps] = useState(0);
  const [varietes, setVarietes] = useState({});
  const [totalBio, setTotalBio] = useState(0);
  const [totalNonBio, setTotalNonBio] = useState(0);

  const mapRoutes = {
    "idetatvalidationadministrative": "idetatvalidationadministrative",
    "idsilo": "idsilo",
    "idsilodefinitif": "idsilodefinitif",
    "idrefvariete": "idrefvariete",
    "estconforme": "estconforme",
    "surveillancecolzasanve": "surveillancecolzasanve",
    "exploitation.rendementrefmoyen": "rendementrefmoyen",
    "exploitation.rendementrefmillesime": "rendementrefmillesime",
    "quantitesemence": "quantitesemence",
    "exploitation.codedc": "codedc",
    "exploitation.codeos": "codeos",
  };

  const mapKeysNames = {
    "millesime": "Année de récolte",
    "libelletypeproduction": "Type de production",
    "numerocontrat": "Contrat",
    "idrefvariete": "Code variété",
    "libellevariete": "Variété",
    "quantitesemence": "Quantité de semence",
    "quantitelivree": "Quantité livrée",
    "tonnage": "Tonnage de référence moyen",
    "tonnageestime": "Tonnage estimé de l'année",
    "nomsilo": "Silo de livraison",
    "nomsilodefinitif": "Silo définitif",
    "nomorganismestockeur": "Organisme stockeur",
    "nomparcelle": "Parcelle",
    "parcelle.codepostal": "Code postal parcelle",
    "parcelle.nomcommune": "Commmune parcelle",
    "surfaceengageeprintemps": "Surface engagée au printemps",
    "surfaceengageeautomne": "Surface engagée en automne",
    "surfaceprintemps": "Surface réelle printemps",
    "surfaceautomne": "Surface réelle automne",
    "surfacetotale": "Surface totale",
    "rendementbrut": "Rendement brut",
    "rendementnet": "Rendement net",
    "estconforme": "Conformité",
    "surveillancecolzasanve": "Surveillance (Colza/Sanve)",
    "libellestatutjuridique": "Statut juridique",
    "raisonsociale": "Exploitation",
    "siret": "Numéro SIRET",
    "adresse": "Adresse",
    "codeinsee": "Code INSEE",
    "codepostal": "Code postal",
    "ville": "Commune",
    "codedc": "Code DC",
    "codeos": "Code OS",
    "codeoctagri": "Code OCTAGRI",
    "numerounilever": "Numéro UNILEVER",
    "rendementrefmoyen": "Rendement de référence moyen",
    "rendementrefmillesime": "Rendement estimé de l'année",
    "libellezoneproduction": "Zone de production",
  };

  useEffect(() => {
    const loadDataSource = () => {
      let totPrintemps = 0;
      let totAutomne = 0;
      let totBio = 0;
      let totNonBio = 0;
      let surfacesVarietes = {};
      dataSource.forEach((element) => {
        totPrintemps += element.surfaceprintemps;
        totAutomne += element.surfaceautomne;
        if (element.idreftypeproduction === 4) {
          totBio +=
            parseFloat(element.surfaceprintemps ?? 0) +
            parseFloat(element.surfaceautomne ?? 0);
        } else {
          totNonBio +=
            parseFloat(element.surfaceprintemps ?? 0) +
            parseFloat(element.surfaceautomne ?? 0);
        }
        if (surfacesVarietes.hasOwnProperty(element.libellevariete)) {
          surfacesVarietes[element.libellevariete] +=
            parseFloat(element.surfaceprintemps ?? 0) +
            parseFloat(element.surfaceautomne ?? 0);
        } else {
          surfacesVarietes[element.libellevariete] =
            parseFloat(element.surfaceprintemps ?? 0) +
            parseFloat(element.surfaceautomne ?? 0);
        }
      });
      setTotalPrintemps(totPrintemps.toFixed(2));
      setTotalAutomne(totAutomne.toFixed(2));
      setTotalBio(totBio.toFixed(2));
      setTotalNonBio(totNonBio.toFixed(2));
      props.setSurfaceTotale((totPrintemps + totAutomne).toFixed(2));
      setVarietes(surfacesVarietes);
    };

    setDataSource(store.planProduction.planProductionPrevisionnel);

    loadDataSource();
  }, [store.planProduction.planProductionPrevisionnel, props]);

  const getDataForCsv = () => {
    var temp = [...dataSource];
    const exploitationKeysToRemove = [
      "idexploitation",
      "listerouge",
      "abandon",
      "rendementref",
      "idstatutjuridique",
      "millesimeadhesion",
      "idcommune",
    ];
    const parcelleKeysToRemove = [
      "idparcelle",
      "idcommune",
      "idsilo",
      "idreftypesol",
      "libelletypesol",
      "surface",
    ];
    const planProductionKeyToRemove = [
      "exploitation",
      "parcelle",
      "ilot",
      "idcommune",
      "idilot",
      "idorganismestockeur",
      "idparcelle",
      "idplanproduction",
      "idreftypeproduction",
      "idsilo",
      "idsilodefinitif",
      "idcontrat",
    ];

    temp.forEach((data) => {
      if (data.exploitation != null) {
        exploitationKeysToRemove.forEach(
          (key) => delete data.exploitation[key],
        );
        Object.keys(data.exploitation).forEach((key) => {
          data[key] = data.exploitation[key];
        });
        parcelleKeysToRemove.forEach((key) => {
          if (data.parcelle && data.parcelle[key]) delete data.parcelle[key];
        });
        if (data.parcelle) {
          Object.keys(data.parcelle).forEach((key) => {
            switch (key) {
              case "codepostal":
                data["parcelle.codepostal"] = data.parcelle[key];
                break;
              case "nomcommune":
                data["parcelle.nomcommune"] = data.parcelle[key];
                break;
              default:
                data[key] = data.parcelle[key];
                break;
            }
          });
        }

        planProductionKeyToRemove.forEach((key) => delete data[key]);
      }
    });

    return temp;
  };

  const onCellClick = (event, cellProps) => {
    const includedColumns = [
      "numerocontrat",
      "exploitation.codedc",
      "exploitation.numerounilever",
      "exploitation.raisonsociale",
      "parcelle.nomparcelle",
    ];
    if (includedColumns.includes(cellProps.name)) {
      navigator.clipboard.writeText(cellProps.value);
      createNotification(
        "info",
        "Information",
        cellProps.header + " copié dans le presse-papier",
        1000,
      );
    }
  };

  const renderSurfacesVarietes = () => {
    const libelles = Object.keys(varietes);
    return libelles.map((libellevariete, i) => {
      return (
        <Fragment key={i}>
          Total {libellevariete != "null" ? libellevariete : "sans variété"} :{" "}
          {varietes[libellevariete].toFixed(2)} Ha{" "}
          {i + 1 == libelles.length ? "" : "| "}
        </Fragment>
      );
    });
  };

  const renderFooter = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total printemps : {totalPrintemps} Ha | Total automne : {totalAutomne}{" "}
          Ha | Total :{" "}
          {(parseFloat(totalAutomne) + parseFloat(totalPrintemps)).toFixed(2)}{" "}
          Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
          }}
        >
          Total bio : {totalBio} Ha | Total non bio : {totalNonBio} Ha
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          {renderSurfacesVarietes()}
        </div>
      </>
    );
  };

  const onEditComplete = async ({ value, columnId, data }) => {
    // On récupère le dataSource de la colonne (s'il y en a un)
    var dataSourceColumn = [];
    const column = columns.find((column) => column.name === columnId);

    if (column?.editorProps?.dataSource) {
      if (typeof column.editorProps.dataSource?.then === "function") {
        await column.editorProps.dataSource.then(
          (res) => (dataSourceColumn = res),
        );
      } else {
        dataSourceColumn = column.editorProps.dataSource;
      }
    }

    const res = data.isintentionsemis
      ? await SurfacePotentielleEditionController.updateField(
          data.idplanproduction,
          mapRoutes[
            dataSourceColumn.length > 0
              ? dataSourceColumn.find((e) => e.id === value).nomId
              : column.name
          ],
          value,
        )
      : await PlanProductionEditionController.updateField(
          data.idplanproduction,
          mapRoutes[
            dataSourceColumn.length > 0
              ? dataSourceColumn.find((e) => e.id === value).nomId
              : column.name
          ],
          value,
        );

    const nomColonne =
      dataSourceColumn.length > 0
        ? dataSourceColumn.find((e) => e.id === value).nomId
        : column.name;

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      // On récupère la ligne de manière temporaire pour une modification en live de la grid
      const tmp = [...dataSource];

      tmp.find((e) => e.idplanproduction === data.idplanproduction)[columnId] =
        dataSourceColumn.length > 0
          ? dataSourceColumn.find((e) => e.id === value).label
          : value;
      tmp.find((e) => e.idplanproduction === data.idplanproduction)[
        nomColonne
      ] = value;

      // On reset le dataSource pour rechargement de la grid
      setDataSource(tmp);
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const getColumnsByName = (name) => {
    return columns.find((c) => c.name === name);
  };

  useConstructor(async () => {
    //On load les datasources de la grid
    getColumnsByName("libellevariete").editorProps.dataSource = (
      await ReferentielController.getVarietes(true)
    ).map((variete) => {
      return {
        id: variete.idrefvariete,
        label: variete.libelle,
        nomId: "idrefvariete",
      };
    });
  });

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Plan de production par parcelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={dataSource.length > 0}>
              <CsvButton
                color="primary"
                style={{ marginRight: 30 }}
                onClick={() => {
                  downloadCsv(
                    convertToCSV(getDataForCsv(), ";", mapKeysNames),
                    "plan_prod_parcelles_" +
                      store.millesime.idmillesime +
                      "_" +
                      moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <CloseButton onClick={props.close} />
          </Col>
        </Row>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <Grid
          enableCellSelect={true}
          idProperty="idplanproduction"
          emptyText="Le plan de production est vide"
          columns={columns}
          groups={groups}
          dataSource={dataSource}
          style={gridStyle}
          onCellClick={onCellClick}
          onEditComplete={onEditComplete}
          defaultFilterValue={defaultFilterValue}
          pagination
          defaultLimit={100}
        />
        {renderFooter()}
      </CardBody>
    </Card>
  );
}
