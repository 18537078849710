import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import { convertToCSV, downloadCsv, RenderIf } from "../../../config/utils";
import ActionGroupeeController from "../../../config/apiUtils/ActionGroupeeController";
import Grid from "../../../components/Grid";
import CsvButton from "../../../components/Buttons/CsvButton";
import moment from "moment";
import {
  columns,
  gridStyle,
} from "../../../components/Grids/ActionsGroupees/envoiguidescarnetsuivi";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";

export default function TableEnvoiGuideCarnetSuivi() {
  const store = useContext(StoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    setData(
      await ActionGroupeeController.getEnvoiGuidesCarnetSuivi(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>
              Envoi des guides et carnet de suivi (contrats)
            </CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={data.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(data, ";", getColumns()),
                    "export_eg_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="id"
            emptyText="Aucune information renseignée"
            columns={columns}
            dataSource={data}
            onRowClick={() => {}}
            style={gridStyle}
            pagination
            defaultLimit={50}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
