import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import colors from "../../../config/colors";

const columns = [
  {
    header: "Type de variété",
    name: "idreftypevariete",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ data }) => {
      return data.libelletypevariete;
    },
  },
  {
    header: "Code variété",
    name: "codevariete",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 140,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libelle",
    cellStyle: { textAlign: "center" },
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Apte IGP",
    name: "estapteigp",
    cellStyle: { textAlign: "center" },
    minWidth: 120,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      idProperty: "id",
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
  {
    header: "Utilisée",
    name: "estutilisee",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

const defaultFilterValue = [
  { name: "idreftypevariete", type: "select", operator: "inlist", value: [] },
  { name: "codevariete", type: "float", operator: "contains", value: "" },
  { name: "libelle", type: "float", operator: "contains", value: "" },
  { name: "estapteigp", type: "select", operator: "inlist", value: [] },
  { name: "estutilisee", type: "select", operator: "inlist", value: [] },
];

export default function ListeVariete(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setVarietes(await ReferentielController.getVarietes());
    const types = await ReferentielController.getTypesVariete();
    store.referentiels.setTypesVarietes(types);
    columns.find(
      (c) => c.name === "idreftypevariete",
    ).filterEditorProps.dataSource = types.map((type) => {
      return { id: type.idreftypevariete, label: type.libelle };
    });
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateVariete(rowIdx.data.idrefvariete);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Variétés</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <AddButton onClick={props.onClickNewVariete} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {store.referentiels.varietes !== null &&
                store.referentiels.varietes !== [] &&
                store.referentiels.varietes !== undefined &&
                !loading && (
                  <Grid
                    enableCellSelect={true}
                    idProperty="idreftypepvariete"
                    emptyText="Aucune variété renseignée"
                    columns={columns}
                    dataSource={store.referentiels.varietes}
                    defaultFilterValue={defaultFilterValue}
                    style={gridStyle}
                    onRowClick={onRowClick}
                    pagination
                    defaultLimit={20}
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
