import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";
import colors from "../../../config/colors";

const columns = [
  {
    header: "Type de production",
    name: "libelle",
    minWidth: 150,
    defaultFlex: 3,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Actif",
    name: "actif",
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeTypeProduction(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateTypeProduction(rowIdx.data.idreftypeproduction);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Types de production</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewType} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.referentiels.typesProduction !== null &&
              store.referentiels.typesProduction !== [] &&
              store.referentiels.typesProduction !== undefined &&
              !loading && (
                <Grid
                  enableCellSelect={true}
                  idProperty="idreftypeproduction"
                  emptyText="Aucun type de production renseigné"
                  columns={columns}
                  dataSource={store.referentiels.typesProduction}
                  style={gridStyle}
                  onRowClick={onRowClick}
                  pagination
                  defaultLimit={20}
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
