import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/CommandeIndustriel/commandes";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import droits from "../../config/CodesDroits";
import StoreContext from "../../context/StoreContext";

export default function TableCommandeIndustrielle(props) {
  const store = useContext(StoreContext);

  const onRowClick = async (rowIdx) => {
    props.onSelectCommande(rowIdx.data);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Récapitulatif des commandes industrielles
        </CardTitle>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf
          isTrue={
            !props.loading &&
            props.commandes !== null &&
            props.commandes !== [] &&
            props.commandes !== undefined
          }
        >
          <Grid
            enableCellSelect={true}
            idProperty="idcommande"
            emptyText="Aucune commande renseignée"
            columns={columns}
            dataSource={props.commandes}
            onRowClick={
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_COMMANDE,
                droits.modalite.modification,
              )
                ? onRowClick
                : null
            }
            style={gridStyle}
            pagination
            defaultLimit={50}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
