import Hashes from "jshashes";
import security from "../assets/img/icons/security.svg";
import { NotificationManager } from "react-notifications";

var bcrypt = require("bcryptjs");

export const createNotification = (type, title, message, timeout = 6000) => {
  switch (type) {
    case "info":
      NotificationManager.info(message, title, timeout);
      break;
    case "success":
      NotificationManager.success(message, title, timeout);
      break;
    case "warning":
      NotificationManager.warning(message, title, timeout);
      break;
    case "error":
      NotificationManager.error(message, title, timeout);
      break;
  }
};

export const dateFilterEditorProps = (props, { index }) => {
  // for range and notinrange operators, the index is 1 for the after field
  var placeholder = "";
  switch (props.filterValue.operator) {
    case "after":
      placeholder = "Date après...";
      break;
    case "afterOrOn":
      placeholder = "Date après ou le...";
      break;
    case "before":
      placeholder = "Date avant...";
      break;
    case "beforeOrOn":
      placeholder = "Date avant ou le...";
      break;
    case "inrange":
    case "notinrange":
      placeholder = index == 1 ? "Date après..." : "Date avant...";
      break;
    default:
      break;
  }

  return {
    dateFormat: "YYYY-MM-DD",
    placeholder: placeholder,
  };
};

export const checkTelephone = (telephone) => {
  const regex =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return regex.test(telephone);
};

export const checkMail = (mail) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(mail);
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const getSha256 = (str) => {
  return new Hashes.SHA256().hex(str);
};

export const hash = (str) => {
  return bcrypt.hashSync(str);
};

export const checkPassword = (password, hash) => {
  return bcrypt.compareSync(password, hash);
};

export const GoToTopPage = () => {
  window.scrollTo(0, 0);
};

export const focus = (id) => {
  const el = document.getElementById(id);
  if (el !== null) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

const traverseAndFlatten = (currentNode, target, flattenedKey) => {
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + "." + key;
      }

      var value = currentNode[key];
      if (typeof value === "object") {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
};

export const flatten = (obj) => {
  var flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
};

export const flattenList = (arr) => {
  if (arr === null || arr === undefined) {
    return [];
  }
  return arr.map((d) => flatten(d));
};

export const unflatten = (obj) =>
  Object.keys(obj).reduce((res, k) => {
    k.split(".").reduce(
      (acc, e, i, keys) =>
        acc[e] ||
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? obj[k]
            : {}
          : []),
      res,
    );
    return res;
  }, {});

export const unflattenList = (arr) => {
  if (arr === null || arr === undefined) {
    return [];
  }
  return arr.map((d) => unflatten(d));
};

export const Mailto = ({ email, subject, body, style, ...props }) => {
  return (
    <a
      style={style}
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
    >
      <img alt="security" src={security} width={14} height={14} />{" "}
      {props.children}
    </a>
  );
};

export const replaceKeys = (data, mapKeysNames) => {
  data.forEach((row) => {
    Object.keys(mapKeysNames).forEach((key) => {
      data[mapKeysNames[key]] = data[key];
      delete data[key];
    });
  });
  return data;
};

export const convertToCSV = (
  objArray,
  delimiter = ",",
  mapKeysNames = null,
) => {
  if (objArray == null || objArray.length == 0) {
    return null;
  }

  var header = [];
  if (mapKeysNames === null) {
    for (var obj of objArray) {
      header = header.concat(Object.keys(obj));
    }
  } else {
    header = header.concat(Object.values(mapKeysNames));
  }

  header = [...new Set(header)];
  const headerString = header.join(delimiter);

  const replacer = (key, value) => {
    if (value === true || value === false) {
      return value ? "Oui" : "Non";
    }
    return value ?? "";
  };

  const rowItems =
    mapKeysNames === null
      ? objArray.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(delimiter),
        )
      : replaceKeys(objArray, mapKeysNames).map((row) =>
          Object.keys(mapKeysNames)
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(delimiter),
        );

  const csv = [headerString, ...rowItems].join("\r\n");
  return csv;
};

export const downloadCsv = (csv, name) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.csv`);
  a.click();
};

export const downloadFile = (content, filename, contentType) => {
  if (!contentType) contentType = "application/octet-stream";
  var a = document.createElement("a");
  var blob = new Blob([content], { type: contentType });
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  a.remove();
};

export const getQueryParam = (item) => {
  return new URLSearchParams(window.location.search).get(item);
};

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search).toString();
};

export const setQueryParam = (item, value) => {
  const url = new URLSearchParams(window.location.search);
  url.set(item, value);
  window.history.replaceState(null, null, `?${url.toString()}`);
};

export const deleteAllQueryParams = () => {
  window.history.replaceState(null, null, window.location.pathname);
};

export const deleteQueryParam = (item) => {
  const url = new URLSearchParams(window.location.search);
  url.delete(item);
  window.history.replaceState(null, null, `?${url.toString()}`);
};
