import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/ListeProducteur/tableproducteur";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ProducteurEditionController from "../../config/apiUtils/ProducteurEditionController";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";

export default function TableProducteurs(props) {
  const mapRoutes = {
    idcivilite: "idcivilite",
    nom: "nom",
    prenom: "prenom",
    mail: "mail",
    telephonefixe: "telephonefixe",
    telephoneportable: "telephoneportable",
  };
  const store = useContext(StoreContext);

  useConstructor(async () => {
    await props.loadProducteurs();
  });

  const onEditComplete = async ({ value, columnId, data }) => {
    // On récupère le dataSource de la colonne (s'il y en a un)
    var dataSourceColumn = [];
    const column = columns.find((column) => column.name === columnId);

    if (column?.editorProps?.dataSource) {
      if (typeof column.editorProps.dataSource?.then === "function") {
        await column.editorProps.dataSource.then(
          (res) => (dataSourceColumn = res),
        );
      } else {
        dataSourceColumn = column.editorProps.dataSource;
      }
    }

    const res = await ProducteurEditionController.updateField(
      data.idproducteur,
      mapRoutes[
        dataSourceColumn.length > 0
          ? dataSourceColumn.find((e) => e.id === value).nomId
          : column.name
      ],
      value,
    );

    const nomColonne =
      dataSourceColumn.length > 0
        ? dataSourceColumn.find((e) => e.id === value).nomId
        : column.name;

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      // On récupère la ligne de manière temporaire pour une modification en live de la grid
      const tmp = [...props.producteurs];
      tmp.find((e) => e.idproducteur === data.idproducteur)[columnId] =
        dataSourceColumn.length > 0
          ? dataSourceColumn.find((e) => e.id === value).label
          : value;
      tmp.find((e) => e.idproducteur === data.idproducteur)[nomColonne] = value;

      // On reset le dataSource pour rechargement de la grid
      props.setProducteurs(tmp);
      store.setProducteursMillesime(tmp);
      store.exploitation.setProducteurs(
        tmp.filter(
          (p) =>
            p.idexploitation === store.exploitation.informations.idexploitation,
        ),
      );
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const onCellClick = (event, cellProps) => {
    navigator.clipboard.writeText(cellProps.value);
    createNotification(
      "info",
      "Information",
      cellProps.header + " copié dans le presse-papier",
      3000,
    );
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Pool producteurs</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={props.producteurs.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(props.producteurs, ";", getColumns()),
                    "export_producteurs_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idproducteur"
            emptyText="Aucun producteur renseignée"
            columns={columns}
            dataSource={props.producteurs}
            defaultFilterValue={defaultFilterValue}
            onEditComplete={onEditComplete}
            onCellClick={onCellClick}
            style={gridStyle}
            pagination
            defaultLimit={100}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
