import { useRef } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import useComponentDidMount from "../../config/hooks/useComponentDidMount";

export default function FormNewRendementRef(props) {
  const inputRef = useRef(null);

  useComponentDidMount(() => {
    inputRef.current.focus();
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const rendement = {
      millesime: event.target.millesime.value,
      valeur: event.target.valeur.value,
    };
    await props.onClickNew(rendement);
    await props.onClickClose();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="millesime">Millésime</Label>
            <Input
              type="text"
              name="millesime"
              placeholder="Millésime"
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <Label for="valeur">Rendement</Label>
          <Input
            type="text"
            name="valeur"
            placeholder="Rendement"
            innerRef={inputRef}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <ValidateButton color="primary" style={{ marginTop: 10 }} />
          <CancelButton
            style={{ marginTop: 10, marginLeft: 10 }}
            onClick={props.onClickClose}
          />
          <DeleteButton
            style={{ marginTop: 10, marginLeft: 10 }}
            onClick={props.onClickClose}
          />
        </Col>
      </Row>
    </Form>
  );
}
