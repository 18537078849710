import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import CommandeController from "../../config/apiUtils/CommandeController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";

export default function RecapCommandeIndustrielle() {
  const store = useContext(StoreContext);
  const [commande, setCommande] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const loadCommandes = async () => {
    const resCommandes = await CommandeController.getCommandes(
      store.millesime.idmillesime,
    );
    setCommande(resCommandes[0]);
    setIsLoading(false);
  };

  const renderLoading = () => {
    return <SpinLoadingAnimation />;
  };

  useConstructor(async () => {
    setIsLoading(true);
    await loadCommandes();
    setIsLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Commande des industriels</CardTitle>
      </CardHeader>
      <CardBody
        style={isLoading ? { display: "flex", justifyContent: "center" } : {}}
      >
        {isLoading && renderLoading()}
        {!isLoading && (
          <>
            <h5>Pour la récolte {commande.millesime} : </h5>
            <Table style={{ color: "#FFFFFF" }}>
              <tbody>
                <tr>
                  <th scope="row">Total commandé</th>
                  <td>{commande.tonnage} t</td>
                </tr>
                <tr>
                  <th scope="row">Total rendement estimé</th>
                  <td>{commande.estimationrendement} t/ha</td>
                </tr>
                <tr>
                  <th scope="row">Surface à implanter</th>
                  <td>{commande.surface} ha</td>
                </tr>
                <tr>
                  <th scope="row">
                    {commande.surfacerestante < 0
                      ? "Surface contractualisée en surplus"
                      : "Surface restante à contractualiser"}
                  </th>
                  <td>
                    {Math.abs(commande.surfacerestante) ?? commande.surface} ha
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </CardBody>
    </Card>
  );
}
