import { useContext, useEffect, useState } from "react";
import StoreContext from "../../context/StoreContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import useConstructor from "../../config/hooks/useConstructor";

export default function FormUpdateConvention(props) {
  const store = useContext(StoreContext);
  const [dateenvoi, setDateenvoi] = useState(props.convention.dateenvoi);
  const [dateretour, setDateretour] = useState(props.convention.dateretour);
  const [signee, setSignee] = useState(props.convention.signee);

  useConstructor(async () => {});

  useEffect(() => {
    setDateenvoi(
      props.convention.dateenvoi === "" ? null : props.convention.dateenvoi,
    );
    setDateretour(
      props.convention.dateretour === "" ? null : props.convention.dateretour,
    );
    setSignee(props.convention.signee);
  }, [props.convention]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const conventionForm = {
      idconvention: props.convention.idconvention,
      idexploitation: props.convention.idexploitation,
      millesime: store.millesime.idmillesime,
      dateenvoi: dateenvoi === "" ? null : dateenvoi,
      dateretour: dateretour === "" ? null : dateretour,
      signee: signee,
    };
    await props.onClickUpdateConvention(conventionForm);
    event.target.reset();
    props.hideFormUpdateConvention();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Modifier la convention</CardTitle>
        <CloseButton onClick={props.hideFormUpdateConvention} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginTop: 20 }}>
            <Col md="3">
              <FormGroup>
                <Label for="raisonsociale">Exploitation</Label>
                <Input
                  type="text"
                  name="raisonsociale"
                  disabled={true}
                  value={props.convention.raisonsociale}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="dateenvoi">Date d'envoi</Label>
                <Input
                  type="date"
                  name="dateenvoi"
                  value={dateenvoi}
                  onChange={(event) => setDateenvoi(event.target.value)}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="dateenvoi">Date de retour</Label>
                <Input
                  type="date"
                  name="dateretour"
                  value={dateretour}
                  onChange={(event) => setDateretour(event.target.value)}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="signee">Convention signée</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="signee"
                  value={signee}
                  onChange={(event) => setSignee(event.target.value)}
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <ValidateButton style={{ marginTop: 15 }} />
          <CancelButton
            style={{ marginTop: 15, marginLeft: 10 }}
            onClick={(event) => {
              event.preventDefault();
              props.hideFormUpdateConvention();
            }}
          />
        </Form>
      </CardBody>
    </Card>
  );
}
