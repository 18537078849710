import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import AddButton from "../../components/Buttons/AddButton";
import colors from "../../config/colors";
import { unflatten } from "../../config/utils";
import layoutRoutes from "../../config/layoutRoutes";
import { NavLink, useNavigate } from "react-router-dom";

const columns = [
  {
    header: "Parcelle",
    name: "nomparcelle",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de sol",
    name: "libelletypesol",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface",
    name: "surface",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Est cartographié",
    name: "estcarto",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data, cellProps }) => {
      cellProps.style.border = "1px solid";
      if (unflatten(data).geometrie) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

const defaultFilterValue = [
  { name: "nomparcelle", type: "string", operator: "contains", value: "" },
  { name: "libelletypesol", type: "string", operator: "contains", value: "" },
  { name: "surface", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
];

export default function ListeParcelles() {
  const store = useContext(StoreContext);
  const [parcelles, setParcelles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useConstructor(async () => {
    setLoading(true);
    setParcelles(
      await ExploitationController.getParcellesByIdexploitation(
        store.exploitation.informations.idexploitation,
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  const onRowClick = (rowIdx) => {
    if (unflatten(rowIdx.data).geometrie) {
      navigate(
        `/app/carto/parcellaire-exploitation?parcelle=${rowIdx.data.idparcelle}`,
      );
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des parcelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <NavLink
              to={`${layoutRoutes.userLayout}/carto/parcellaire-exploitation?newparcelle=true`}
            >
              <AddButton />
            </NavLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {!loading && (
              <Grid
                enableCellSelect={true}
                idProperty="idparcelle"
                emptyText="Aucune parcelle renseigné"
                columns={columns}
                dataSource={parcelles}
                defaultFilterValue={defaultFilterValue}
                style={gridStyle}
                onRowClick={onRowClick}
                pagination
                defaultLimit={20}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
