import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  columns,
  defaultFilterValue,
  gridStyle,
  groups,
} from "../../components/Grids/FormulaireUtilisateur/formulaires";
import Grid from "../../components/Grid";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function ListeFormulaireUtilisateur(props) {
  const onRowClick = async (rowIdx) => {
    props.onClickFormulaire(rowIdx.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Formulaires de demande</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idformulaireutilisateur"
            emptyText="Aucun formulaire renseigné"
            columns={columns}
            dataSource={props.formulaires}
            style={gridStyle}
            groups={groups}
            onRowClick={onRowClick}
            defaultFilterValue={defaultFilterValue}
            pagination
            defaultLimit={50}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
