import APIController from "../APIController";

class SurfacePotentielleEditionController extends APIController {
  constructor() {
    super("/surfacepotentielle/edition");
  }

  async updateField(idsurfacepotentielle, field, value) {
    return await this.putRequest(
      `/${field}?idsurfacepotentielle=${idsurfacepotentielle}${
        value !== undefined && value !== null && value !== ""
          ? "&" + field + "=" + value
          : ""
      }`,
    );
  }
}

export default new SurfacePotentielleEditionController();
