import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import AddButton from "../../components/Buttons/AddButton";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/Exploitation/surfacespotentielle";
import StoreContext from "../../context/StoreContext";
import { useNavigate } from "react-router-dom";

export default function TableSurfacesPotentielles(props) {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Intentions de semis</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton
              onClick={() => {
                navigate(
                  "/app/carto/parcellaire-exploitation?section=intentionsemis&newintention=true",
                );
              }}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Grid
          enableCellSelect={true}
          idProperty="idsurfacepotentielle"
          emptyText={
            "Aucune surface renseignée pour " + store.millesime.idmillesime
          }
          columns={columns}
          dataSource={store.surfacesPotentielles}
          style={gridStyle}
          pagination
          defaultLimit={50}
        />
      </CardBody>
    </Card>
  );
}
