import { useContext, useState } from "react";
import file from "../../assets/img/icons/file.svg";
import { Button } from "reactstrap";
import download from "../../assets/img/icons/download.svg";
import StoreContext from "../../context/StoreContext";
import useWindowDimensions from "../../config/hooks/useWindowDimensions";
import { RenderIf } from "../../config/utils";

export default function Fichier(props) {
  const store = useContext(StoreContext);
  const { height, width } = useWindowDimensions();
  const [ratio] = useState(1.2);

  return (
    <>
      <tr className="fichier">
        <td style={{ verticalAlign: "middle", width: 20 }}>
          <img
            src={file}
            alt=""
            style={{
              width: 20,
              height: 20,
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          />
        </td>
        <td
          style={{
            verticalAlign: "middle",
          }}
        >
          {props.libelletypedocument}
        </td>
        <td
          style={{
            verticalAlign: "middle",
          }}
        >
          {props.filename}
        </td>
        <RenderIf isTrue={props.filesize != null}>
          <td
            style={{
              verticalAlign: "middle",
            }}
          >
            {parseFloat(props.filesize / 1024).toFixed(2)} Ko
          </td>
        </RenderIf>
        <td style={{ width: 30 }}>
          <Button
            color="primary"
            style={{
              display: "flex",
              border: "transparent",
            }}
            onClick={props.onClickDownload}
          >
            <img
              src={download}
              alt=""
              style={{
                width: 20,
                height: 20,
                display: "flex",
                alignItems: "center",
                color: "white",
                marginRight:
                  height / width <= ratio && !store.userDevice.isMobile ? 5 : 0,
              }}
            />
            {height / width <= ratio &&
              !store.userDevice.isMobile &&
              "Télécharger"}
          </Button>
        </td>
      </tr>
    </>
  );
}
