export const groups = [{ name: "contact", header: "Contact" }];

export const columns = [
  {
    header: "Civilité",
    name: "civilite",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nom",
    name: "nom",
    cellStyle: { textAlign: "center" },
    defaultFlex: 1,
    minWidth: 100,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Prénom",
    name: "prenom",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse mail",
    name: "mail",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone fixe",
    name: "telephonefixe",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone portable",
    name: "telephoneportable",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 300, color: "#ffffff" };
