import ContratController from "../../../config/apiUtils/ContratController";
import { createNotification } from "../../../config/utils";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import { useContext } from "react";
import StoreContext from "../../../context/StoreContext";

export default function ModalSignatureContrat(props) {
  const context = useContext(StoreContext);
  const onClickSignerContrat = async () => {
    if (!document.getElementById("validationpriseconnaissance").checked) {
      createNotification(
        "error",
        "Erreur",
        "Merci de prendre connaissance des engagements vis-à-vis de la filière.",
      );
      return;
    }
    const res = await ContratController.signerContrat(props.contrat.idcontrat);
    if (res.status === 200) {
      context.setContratsExploitation(
        await ExploitationController.getContratExploitation(
          context.millesime.idmillesime,
          context.exploitation.informations.idexploitation,
        ),
      );
      createNotification(
        "success",
        "Contrat complété et signé",
        "Votre contrat a bien été signé, il est maintenant en attente d'être validé par votre Organisme Stockeur",
        15000,
      );
      props.toggle();
    } else {
      createNotification(
        "error",
        "Une erreur est survenue lors de la signature du contrat...",
      );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size={"lg"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className="modal-header-form">
        Signature du contrat {props.contrat.numerocontrat}
      </ModalHeader>
      <ModalBody className="modal-body-form">
        En signant le contrat, vous certifiez avoir pris connaissance des
        engagements vis-à-vis de la filière, et vous engagez à les respecter.
        <br />
        <br />
        Confirmez-vous la signature du contrat {props.contrat.numerocontrat} ?
        <br />
        <br />
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <Label check>
              <Input
                type="checkbox"
                name="validationpriseconnaissance"
                id={"validationpriseconnaissance"}
              />{" "}
              J'ai pris connaissance de mes engagements vis-à-vis de la filière,
              et m'engager à les respecter
            </Label>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter
        className="modal-footer-form"
        style={{ justifyContent: "left" }}
      >
        <Button color="primary" autoFocus={true} onClick={onClickSignerContrat}>
          Signer le contrat
        </Button>{" "}
        <Button color="danger" onClick={() => props.toggle()}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
}
