import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import { useContext, useRef } from "react";
import StoreContext from "../../context/StoreContext";
import useComponentDidMount from "../../config/hooks/useComponentDidMount";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import { createNotification } from "../../config/utils";

export default function FormUpdateRendementRef(props) {
  const store = useContext(StoreContext);
  const inputRef = useRef(null);

  useComponentDidMount(() => {
    inputRef.current.focus();
  });

  const onSubmitUpdate = async (event) => {
    event.preventDefault();
    const valeur =
      parseFloat(event.target.valeur.value) != null
        ? parseFloat(event.target.valeur.value)
        : 0;
    const rendement = {
      millesime: props.rendement.millesime,
      valeur: valeur,
    };
    await props.onClickUpdate(rendement);
    await props.onClickClose();
  };

  const onClickDelete = async () => {
    const res = await ExploitationController.deleteRendementRefExploitation(
      store.exploitation.informations.idexploitation,
      props.rendement.idrendementrefexploitation,
    );

    if (res.status === 200) {
      await store.exploitation.loadDernierDossier();
      createNotification(
        "success",
        "Succès",
        "Rendement supprimé avec succès !",
      );
      props.onClickClose();
    }
  };

  return (
    <Form onSubmit={onSubmitUpdate}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="millesime">Millésime *</Label>
            <Input
              type="text"
              name="millesime"
              defaultValue={props.rendement.millesime}
              readOnly
              disabled
            />
          </FormGroup>
        </Col>
        <Col>
          <Label for="valeur">Rendement *</Label>
          <Input
            type="text"
            name="valeur"
            placeholder="Rendement"
            defaultValue={props.rendement.valeur}
            innerRef={inputRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <ValidateButton color="primary" style={{ marginTop: 10 }} />
          <CancelButton
            style={{ marginTop: 10, marginLeft: 10 }}
            onClick={props.onClickClose}
          />
          <DeleteButton
            style={{ marginTop: 10, marginLeft: 10 }}
            onClick={onClickDelete}
          />
        </Col>
      </Row>
    </Form>
  );
}
