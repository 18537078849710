import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Alert } from "reactstrap";
import { raisonsociale } from "../columns";
import { dateFilterEditorProps } from "../../../config/utils";

export const columns = [
  raisonsociale,
  {
    header: "Numéro de contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date du contrat",
    name: "datecontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Date d'envoi",
    name: "dateenvoi",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Date de retour",
    name: "dateretour",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Convention signée",
    name: "signee",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ value }) => {
      if (value === true) {
        return (
          <div style={{ display: "flex" }}>
            <Alert
              fade={false}
              color="success"
              style={{
                height: 12,
                padding: "0rem 1.5rem 1.5rem 1.5rem",
                marginTop: "1rem",
              }}
            >
              OUI
            </Alert>
          </div>
        );
      }
      return (
        <div style={{ display: "flex" }}>
          <Alert
            fade={false}
            color="danger"
            style={{
              height: 12,
              padding: "0rem 1.5rem 1.5rem 1.5rem",
              marginTop: "1rem",
            }}
          >
            NON
          </Alert>
        </div>
      );
    },
  },
  {
    header: "Mail",
    name: "mail",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  { name: "datecontrat", type: "date", operator: "after", value: "" },
  { name: "dateenvoi", type: "date", operator: "after", value: "" },
  { name: "dateretour", type: "date", operator: "after", value: "" },
  { name: "signee", type: "select", value: [], operator: "inlist" },
  { name: "mail", type: "string", operator: "contains", value: "" },
];
