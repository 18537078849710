import { raisonsociale } from "../columns";

export const columns = [
  raisonsociale,
  {
    header: "Code DC",
    name: "codedc",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse",
    name: "adresse",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "codedc", type: "float", operator: "contains", value: "" },
  { name: "adresse", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
];
