import { useRef, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import useComponentDidMount from "../../../config/hooks/useComponentDidMount";

function LoginForm(props) {
  const loginRef = useRef(null);
  const [service, setService] = useState("https://gem.apgmb.fr");
  const [showPassword, setShowPassword] = useState(false);

  useComponentDidMount(() => {
    loginRef.current.focus();
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setService(urlParams.get("service") ?? service);
    props.connect({
      username: event.target.mail.value,
      password: event.target.password.value,
    });
  };

  return (
    <Form
      style={styles.form}
      onSubmit={async (event) => await handleSubmit(event)}
    >
      <Input
        style={styles.textField}
        name="mail"
        placeholder="Identifiant"
        type="text"
        innerRef={loginRef}
        invalid={props.error}
      />

      <Input
        style={styles.textField}
        name="password"
        placeholder="Mot de passe"
        type={showPassword ? "text" : "password"}
        invalid={props.error}
      />
      <Label check style={{ display: "flex", fontSize: 12, marginBottom: 10 }}>
        <Input
          type="checkbox"
          style={{ marginBottom: 10 }}
          onClick={(event) => {
            setShowPassword(event.target.checked);
          }}
        />
        &nbsp;Afficher le mot de passe
      </Label>
      <Row>
        <Col>
          <Button
            style={{ width: "10em", borderRadius: "0.5rem" }}
            color="primary"
            block
            type="submit"
          >
            Se connecter
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const styles = {
  card: {
    width: 500,
    backgroundColor: "rgba(255, 255, 255, 1)",
    textAlign: "center",
    justifyContent: "center",
    padding: 10,
    position: "relative",
    flexGrow: 1,
    marginTop: "4em",
    borderRadius: 50,
    flexDirection: "column",
    boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
  },
  cardBody: {
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    lineHeight: 1.1,
  },
  alertGreen: {
    color: "rgba(0,213,84,1)",
  },
  textField: {
    height: "3em",
    padding: "0.75rem",
    borderRadius: 15,
    marginBottom: 10,
    fontSize: "1rem",
  },
  form: {
    display: "grid",
    alignItems: "center",
    marginBottom: 20,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  logo: {
    paddingTop: 20,
  },
  button: {
    borderStyle: "none",
    fontSize: "1.25rem",
    lineHeight: 1.5,
    padding: "0.5rem 1rem",
    display: "block",
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    borderRadius: "0.5rem",
    marginTop: 20,
  },
  footer: {
    display: "grid",
    color: "rgb(130, 130, 130)",
  },
};

export default LoginForm;
