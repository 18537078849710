import { useContext, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import { hash } from "../../config/utils";

export default function FormUpdatePassword(props) {
  const store = useContext(StoreContext);
  const [
    showRepeatedNewPasswordErrorAlert,
    setShowRepeatedNewPasswordErrorAlert,
  ] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (
      event.target.newpassword.value !== event.target.repeatednewpassword.value
    ) {
      setShowRepeatedNewPasswordErrorAlert(true);
    } else {
      const form = {
        username: store.utilisateur.informations.username,
        oldpassword: event.target.password.value,
        newpassword: hash(event.target.newpassword.value),
      };
      await props.onClickUpdatePassword(form);
      props.hideUpdatePasswordForm();
    }
  };

  const renderRepeatedNewPasswordErrorAlert = () => {
    return (
      <Alert
        color="danger"
        style={{ fontSize: 12 }}
        isOpen={showRepeatedNewPasswordErrorAlert}
        toggle={() =>
          setShowRepeatedNewPasswordErrorAlert(
            !showRepeatedNewPasswordErrorAlert,
          )
        }
      >
        Merci de saisir à nouveau votre nouveau mot de passe.
      </Alert>
    );
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag="h5">Modifier le mot de passe</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.hideUpdatePasswordForm} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="password">Mot de passe actuel *</Label>
            <Input
              type="password"
              name="password"
              placeholder="Mot de passe actuel"
            />
          </FormGroup>
          <FormGroup>
            <Label for="newpassword">Nouveau mot de passe *</Label>
            <Input
              type="password"
              name="newpassword"
              placeholder="Nouveau mot de passe"
            />
          </FormGroup>
          <FormGroup>
            <Label for="repeatednewpassword">
              Répétez le nouveau mot de passe *
            </Label>
            <Input
              type="password"
              name="repeatednewpassword"
              placeholder="Répétez le nouveau mot de passe"
            />
          </FormGroup>
          {renderRepeatedNewPasswordErrorAlert()}
          <Row>
            <Col md="6">
              <Button
                style={{ marginTop: 15, marginRight: 15 }}
                type="submit"
                color="primary"
              >
                Valider
              </Button>
              <Button
                style={{ marginTop: 15 }}
                color="danger"
                onClick={(event) => {
                  event.preventDefault();
                  props.hideUpdatePasswordForm();
                }}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
