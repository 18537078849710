import APIController from "../APIController";

class ActionGroupeeController extends APIController {
  constructor() {
    super("/actionsgroupees");
  }

  async getAppelIntentionsSemis() {
    return await this.getRequest("/appelintentionsemis");
  }

  async getAvertissement(millesime) {
    return await this.getRequest("/avertissements", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getPreAvertissements(millesime) {
    return await this.getRequest("/preavertissements", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getConfirmationSurfaces(millesime) {
    return await this.getRequest("/confirmationsurfaces", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getAppelCotisations(millesime) {
    return await this.getRequest("/appelcotisation", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getHistoriqueFormation(millesime) {
    return await this.getRequest("/historiqueformation", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsActivesProduction(millesime) {
    return await this.getRequest("/exploitationsactives", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsActivesIntentionsContrats(millesime) {
    return await this.getRequest("/exploitationsactivesintentionscontrats", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getEnvoiGuidesCarnetSuivi(millesime) {
    return await this.getRequest("/envoiguide", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getAttestationLivraisonSemences(millesime) {
    return await this.getRequest("/attestationlivraisonsemences", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getConsignesRecolte(millesime) {
    return await this.getRequest("/consignesrecolte", {
      params: {
        millesime: millesime,
      },
    });
  }
}

export default new ActionGroupeeController();
