import { Col, Row } from "reactstrap";

export default function ActionsGroupees(props) {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Actions groupées</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      {props.children}
    </>
  );
}
