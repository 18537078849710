import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import CloseButton from "../../components/Buttons/CloseButton";
import CommuneInput from "../../components/CommuneInput";
import ZoneProductionController from "../../config/apiUtils/ZoneProductionController";
import ReferentielController from "../../config/apiUtils/ReferentielController";

export default function FormNewExploitation(props) {
  const store = useContext(StoreContext);
  const [idcommune, setIdCommune] = useState(0);
  const [zones, setZones] = useState([]);

  useConstructor(async () => {
    const resStatuts = await ReferentielController.getStatutsJuridique();
    store.referentiels.setStatutsJuridique(resStatuts);
    const resZonesProduction =
      await ZoneProductionController.getZonesProduction();
    store.setZonesProduction(resZonesProduction);
  });

  const renderZonesProductionOptions = () => {
    return store.zonesProduction.map((zone, key) => {
      return (
        <option key={key} value={zone.idrefzoneproduction}>
          {zone.libellelong || zone.libelle}
        </option>
      );
    });
  };

  const renderStatutsJuridiqueOptions = () => {
    return store.referentiels.statutsJuridique.map((statut, key) => {
      return (
        <option value={statut.idstatutjuridique} key={key}>
          {statut.libellestatut}
        </option>
      );
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const exploitation = {
      idcommune: event.target.idcommune.value,
      codedc:
        event.target.codedc.value === "" ? null : event.target.codedc.value,
      codeoctagri:
        event.target.codeoctagri.value === ""
          ? null
          : event.target.codeoctagri.value,
      millesimeadhesion:
        event.target.millesimeadhesion.value === ""
          ? null
          : event.target.millesimeadhesion.value,
      idstatutjuridique: event.target.idstatutjuridique.value,
      raisonsociale: event.target.raisonsociale.value,
      adresse: event.target.adresse.value,
      abandon: event.target.abandon.value,
      listerouge: event.target.listerouge.value,
      siret: event.target.siret.value,
      numerounilever:
        event.target.numerounilever.value === ""
          ? null
          : event.target.numerounilever.value,
      idrefzoneproduction: event.target.idrefzoneproduction.value,
    };
    props.onClickNewExploitation(exploitation);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle exploitation</CardTitle>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <h5>Administratif</h5>
            <Col md="3">
              <FormGroup>
                <Label for="idstatutjuridique">Statut juridique *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idstatutjuridique"
                >
                  {renderStatutsJuridiqueOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="raisonsociale">Raison sociale *</Label>
                <Input
                  type="text"
                  name="raisonsociale"
                  placeholder="EARL test"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="siret">Numéro SIRET *</Label>
                <Input
                  type="text"
                  name="siret"
                  placeholder="012345678901234"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="millesimeadhesion">Année d'adhésion *</Label>
                <Input
                  type="text"
                  name="millesimeadhesion"
                  placeholder={store.millesime.idmillesime}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="codedc">Code DC *</Label>
                <Input type="text" name="codedc" placeholder="Code DC"></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="codeoctagri">Code Octagri *</Label>
                <Input
                  type="text"
                  name="codeoctagri"
                  placeholder="Code Octagri"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="numerounilever">Numéro UNILEVER *</Label>
                <Input
                  type="text"
                  name="numerounilever"
                  placeholder="Numéro UNILEVER"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Coordonnées</h5>
            <Col md="6">
              <FormGroup>
                <Label for="adresse">Adresse *</Label>
                <Input type="text" name="adresse" placeholder="Adresse"></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <CommuneInput
                required
                codepostal={""}
                idcommune={idcommune}
                setIdCommune={() => {}}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Zones de production</h5>
            <Col md="12">
              <FormGroup>
                <Label for="idrefzoneproduction">Zones de production *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idrefzoneproduction"
                >
                  {renderZonesProductionOptions()}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Statut</h5>
            <Col md="6">
              <FormGroup>
                <Label for="abandon">Abandon de la culture *</Label>
                <Input type="select" className="select-input" name="abandon">
                  <option value={false}>NON</option>
                  <option value={true}>OUI</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="listerouge">Liste rouge *</Label>
                <Input type="select" className="select-input" name="listerouge">
                  <option value={false}>NON</option>
                  <option value={true}>OUI</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button style={{ marginTop: 15 }} color="primary">
            Valider
          </Button>
          <Button
            style={{ marginTop: 15, marginLeft: 15 }}
            color="danger"
            onClick={props.close}
          >
            Annuler
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}
