import { useRef, useCallback } from "react";

// Custom Hook
export const useDebouncedFunction = (fn, delay) => {
  const timeoutRef = useRef(null);

  return useCallback(
    function (arg) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => fn(arg), delay);
    },
    [fn, delay],
  );
};
