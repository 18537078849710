import ActionsGroupeesRoutes from "./routes/ActionsGroupeesRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import AnalysesRoutes from "./routes/AnalysesRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import CartoRoutes from "./routes/CartoRoutes";
import ContratConventionRoutes from "./routes/ContratConventionRoutes";
import ExploitationRoutes from "./routes/ExploitationRoutes";
import MaintenanceRoutes from "./routes/MaintenanceRoutes";
import ProductionRoutes from "./routes/ProductionRoutes";
import UserRoutes from "./routes/UserRoutes";

var routes = [
  ...ActionsGroupeesRoutes,
  ...AdminRoutes,
  ...AnalysesRoutes,
  ...AuthRoutes,
  ...CartoRoutes,
  ...ContratConventionRoutes,
  ...ExploitationRoutes,
  ...MaintenanceRoutes,
  ...ProductionRoutes,
  ...UserRoutes,
];
export default routes;
