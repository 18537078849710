import { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import Grid from "../../components/Grid";
import moment from "moment";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Analyses/exploitationszoneproduction";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Analyses from "../Analyses";
import CsvButton from "../../components/Buttons/CsvButton";

export default function ExploitationsZoneProduction() {
  const mapKeysNames = {
    raisonsociale: "Exploitation",
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useConstructor(async () => {
    setLoading(true);
    const res = await AnalysesController.getExploitationsZoneProduction();
    setData(res);
    setLoading(false);
  });

  const renderCardBody = () => {
    return data.map((item, key) => {
      return renderZone(item, key);
    });
  };

  const renderZone = (item, key) => {
    return (
      <Row key={key} style={{ marginBottom: 30 }}>
        <Col>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <CardTitle tag={"h5"}>
                {item.zone.libellelong ?? item.zone.libelle}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={data.length > 0}>
                <CsvButton
                  color="primary"
                  onClick={() => {
                    const name =
                      "analyses_exploitations_zone_production_" +
                      (item.zone.libellelong ?? item.zone.libelle) +
                      "_" +
                      moment().format("YYYY-MM-DD");
                    downloadCsv(
                      convertToCSV(item.exploitations, ";", mapKeysNames),
                      name,
                    );
                    createNotification(
                      "info",
                      "Information",
                      "Export téléchargé dans le fichier : " + name,
                    );
                  }}
                />
              </RenderIf>
            </Col>
          </Row>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Grid
              enableCellSelect={true}
              idProperty="id"
              emptyText="Aucune exploitation..."
              columns={columns}
              dataSource={item.exploitations}
              defaultFilterValue={defaultFilterValue}
              style={gridStyle}
              pagination
              defaultLimit={50}
            />
          </RenderIf>
        </Col>
      </Row>
    );
  };

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Exploitations par zone de production
                  </CardTitle>
                </Col>
                <Col
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                ></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>{renderCardBody()}</RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
