import APIController from "../APIController";

class OrganismeStockeurController extends APIController {
  constructor() {
    super("/organismestockeur");
  }

  async getOrganismesStockeur() {
    return await this.getRequest("/");
  }

  async getOrganismesStockeurById(idorganismestockeur) {
    return await this.getRequest(`/${idorganismestockeur}`);
  }

  async getSiloByIdOrganismeStockeur(idorganismestockeur, millesime) {
    return await this.getRequest(
      `/${idorganismestockeur}/silo?millesime=${millesime}`,
    );
  }

  async updateOrganismeStockeur(idorganismestockeur, organisme) {
    return await this.putRequest(`/${idorganismestockeur}`, organisme);
  }
}

export default new OrganismeStockeurController();
