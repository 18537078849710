import { Col, Row } from "reactstrap";
import useConstructor from "../config/hooks/useConstructor";
import MentionsLegalesCard from "./mentionslegales/MentionsLegalesCard";
import { GoToTopPage } from "../config/utils";

export default function MentionsLegales() {
  useConstructor(() => {
    GoToTopPage();
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Mentions légales</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30, minHeight: 500 }}>
        <Col md="12">
          <MentionsLegalesCard />
        </Col>
      </Row>
    </>
  );
}
