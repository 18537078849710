import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/Producteur/formation";
import StoreContext from "../../context/StoreContext";
import { RenderIf } from "../../config/utils";

export default function FormationProducteur(props) {
  const store = useContext(StoreContext);

  const onRowClick = async (rowIdx) => {
    props.onSelectFormation(rowIdx.data);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Formations</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits("GESTION_FORMATION", 40)}
              >
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={props.showFormNewFormation}
                >
                  Nouvelle formation
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Grid
                enableCellSelect={true}
                idProperty="idformationproducteur"
                emptyText="Aucune formation renseignée"
                columns={columns}
                dataSource={store.formations.formations}
                onRowClick={onRowClick}
                style={gridStyle}
                pagination
                defaultLimit={20}
                showSearchBar={false}
                searchBarPlaceholder={"Rechercher une formation"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
