import APIController from "../APIController";

class CertificationExploitationController extends APIController {
  constructor() {
    super("/certification");
  }

  async getCertificationsExploitation(
    millesime,
    showHabilite = false,
    showActif = false,
  ) {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
        showHabilite: showHabilite,
        showActif: showActif,
      },
    });
  }
}

export default new CertificationExploitationController();
