import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useContext, useState } from "react";
import StoreContext from "../../../context/StoreContext";
import CancelButton from "../../../components/Buttons/CancelButton";
import TableRecapParcelles from "../sections/importparcellesprecedentes/TableRecapParcelles";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import {
  createNotification,
  deleteAllQueryParams,
} from "../../../config/utils";

export default function ModalImportParcellesPrecedentes({
  isOpen,
  toggle,
  parcelles,
  forceReloadMap,
}) {
  const context = useContext(StoreContext);
  const [idparcellesSelected, setIdparcellesSelected] = useState([]);

  const onClickImporter = async () => {
    const form = {
      idparcelles: idparcellesSelected,
    };
    const res = await ExploitationController.importParcellesToMillesime(
      context.exploitation.informations.idexploitation,
      context.millesime.idmillesime,
      form,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Parcelles importées en " +
          context.millesime.idmillesime +
          " avec succès",
      );
      deleteAllQueryParams();
      await forceReloadMap();
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size={"xl"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className={"modal-header-form"}>
        Import des parcelles de {context.millesime.idmillesime - 1}
      </ModalHeader>
      <ModalBody className={"modal-body-form"}>
        <h3>Parcelles à importer :</h3>
        <TableRecapParcelles
          parcelles={parcelles}
          isModal
          setIdparcelles={setIdparcellesSelected}
        />
        <Row>
          <Col>
            Le clic sur le bouton <b>Importer</b> aura pour effet d'importer les
            parcelles ci-dessus de {context.millesime.idmillesime - 1} en{" "}
            {context.millesime.idmillesime}.
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter
        className="modal-footer-form"
        style={{ justifyContent: "left" }}
      >
        <Button
          style={{
            marginTop: 15,
            marginRight: 10,
          }}
          color="primary"
          onClick={onClickImporter}
        >
          Importer
        </Button>
        <CancelButton
          style={{ marginTop: 15 }}
          onClick={() => {
            toggle();
          }}
        />
      </ModalFooter>
    </Modal>
  );
}
