import { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Table } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";

export default function FicheProducteur(props) {
  const store = useContext(StoreContext);
  const [producteur, setProducteur] = useState({});

  const getProducteur = () => {
    setProducteur(
      store.producteursMillesime.find(
        (p) => p.idproducteur === props.idproducteur,
      ),
    );
  };

  useEffect(() => {
    getProducteur();
  }, [props.idproducteur]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Producteur : {producteur.nom}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Col md={6}>
          <h5>Informations administratives</h5>
          <Table style={{ color: "#FFFFFF" }}>
            <tbody>
              <tr>
                <th scope="row">Nom</th>
                <td>{producteur.nom}</td>
              </tr>
              <tr>
                <th scope="row">Prénom</th>
                <td>{producteur.prenom}</td>
              </tr>
              <tr>
                <th scope="row">Exploitation</th>
                <td>{producteur.raisonsociale}</td>
              </tr>
              <tr>
                <th scope="row">Adresse e-mail</th>
                <td>
                  {producteur.mail && producteur.mail !== ""
                    ? producteur.mail
                    : "Non renseigné"}
                </td>
              </tr>
              <tr>
                <th scope="row">Téléphone fixe</th>
                <td>
                  {producteur.telephonefixe && producteur.telephonefixe !== ""
                    ? producteur.telephonefixe
                    : "Non renseigné"}
                </td>
              </tr>
              <tr>
                <th scope="row">Téléphone mobile</th>
                <td>
                  {producteur.telephoneportable &&
                  producteur.telephoneportable !== ""
                    ? producteur.telephoneportable
                    : "Non renseigné"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </CardBody>
    </Card>
  );
}
