import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CertificationExploitationController from "../../config/apiUtils/CertificationExploitationController";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/CertificationExploitation/certificationexploitation";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function TableCertificationsExploitation() {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    const resCertifications =
      await CertificationExploitationController.getCertificationsExploitation(
        store.millesime.idmillesime,
      );
    store.certifications.setCertificationsExploitation(resCertifications);
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Certifications exploitation</CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <SpinLoadingAnimation />}
        {!loading && (
          <Grid
            enableCellSelect={true}
            idProperty="idcertification"
            emptyText="Aucune certification renseignée"
            columns={columns}
            dataSource={store.certifications.certificationsExploitation}
            onRowClick={() => {}}
            style={gridStyle}
            pagination
            defaultLimit={50}
            defaultFilterValue={defaultFilterValue}
          />
        )}
      </CardBody>
    </Card>
  );
}
