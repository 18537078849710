import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";
import StoreContext from "../../context/StoreContext";
import { useContext, useState } from "react";
import useConstructor from "../../config/hooks/useConstructor";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf, createNotification } from "../../config/utils";

export default function FormNewIntention(props) {
  const store = useContext(StoreContext);
  const [idExploitation, setIdexploitation] = useState(
    store.exploitation.informations.idexploitation,
  );
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    if (store.utilisateur.informations.isProducteur) {
      createNotification(
        "info",
        "Information",
        "En remplissant ce formulaire, vous vous engagez à respectez les éléments de la convention accessible dans la page : Exploitations / Producteurs > Mes documents",
        20000,
      );
      createNotification(
        "info",
        "Information",
        "Dans les champs des surfaces printemps et automne, merci de ne saisir que des chiffres.",
        20000,
      );
    }
    setLoading(true);
    store.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(true),
    );
    const resExploitation = await ExploitationController.getExploitations();
    store.setExploitations(resExploitation);
    setLoading(false);
  });

  const renderExploitationOptions = () => {
    return store.exploitations.map((exploitation, key) => {
      return (
        <option key={key} value={exploitation.idexploitation}>
          {exploitation.raisonsociale}
        </option>
      );
    });
  };

  const renderOrganismesStockeursOptions = () => {
    let organismes = [...store.organismesStockeur];
    organismes.unshift({ idorganismestockeur: -1, nomorganismestockeur: "-" });
    return organismes.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderTypesProductionOptions = () => {
    return store.referentiels.typesProduction.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduction}>
          {type.libelle}
        </option>
      );
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (event.target?.idorganismestockeur?.value == -1) {
      createNotification(
        "error",
        "Erreur - Formulaire incomplet",
        "Merci de sélectionner un Organisme Stockeur dans la liste déroulante.",
      );
      setLoading(false);
      return;
    }
    if (!event.target.validationpriseconnaissance.checked) {
      createNotification(
        "error",
        "Erreur",
        "Merci de prendre connaissance des engagements vis-à-vis de la filière.",
      );
      setLoading(false);
      return;
    }
    const intention = {
      surfacepotentielleprintemps:
        event.target.surfacepotentielleprintemps.value,
      surfacepotentielleautomne: event.target.surfacepotentielleautomne.value,
      commentaire: event.target.commentaire.value,
      idorganismestockeur: event.target.idorganismestockeur.value,
      idreftypeproduction: event.target.idreftypeproduction.value,
      idexploitation: event.target.idexploitation.value,
      datesurfacepotentielle: event.target.datesurfacepotentielle.value,
      millesime: store.millesime.idmillesime,
    };
    await props.onClickAdd(intention);
    setLoading(false);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle intention</CardTitle>
        <CloseButton onClick={props.hideFormNewIntention} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="datesurfacepotentielle">Date</Label>
                  <Input
                    type="date"
                    name="datesurfacepotentielle"
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="idexploitation">Exploitation</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idexploitation"
                    value={idExploitation}
                    onChange={(event) => setIdexploitation(event.target.value)}
                  >
                    {renderExploitationOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="4">
                <FormGroup>
                  <Label for="surfacepotentielleprintemps">
                    Surface potentielle printemps (Ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfacepotentielleprintemps"
                    placeholder="Surface potentielle printemps (Ha)"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    title="Merci de ne saisir que des chiffres"
                    required
                    defaultValue={0}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="surfacepotentielleautomne">
                    Surface potentielle automne (Ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfacepotentielleautomne"
                    placeholder="Surface potentielle automne (Ha)"
                    pattern="^\d{1,4}(?:\.\d{1,2})?$"
                    title="Merci de ne saisir que des chiffres"
                    required
                    defaultValue={0}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="idreftypeproduction">Type de production</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idreftypeproduction"
                    placeholder="Type de production"
                  >
                    {renderTypesProductionOptions()}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="6">
                <FormGroup>
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idorganismestockeur"
                    placeholder="Organisme stockeur"
                  >
                    {renderOrganismesStockeursOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="commentaire">Commentaire</Label>
                  <Input
                    type="textarea"
                    name="commentaire"
                    placeholder="Commentaire"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                Mes engagements :{" "}
                <a
                  style={{
                    textDecoration: "none",
                    color: "#f9aa33",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  href="/app/exploitation-document"
                  title="Page des documents"
                  id="mesdocuments"
                >
                  Cliquez ici
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label check>
                  <Input type="checkbox" name="validationpriseconnaissance" />{" "}
                  J'ai pris connaissance de mes engagements vis-à-vis de la
                  filière, et m'engager à les respecter
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
                <CancelButton
                  style={{ marginTop: 15 }}
                  onClick={(event) => {
                    event.preventDefault();
                    props.hideFormNewIntention();
                  }}
                />
              </Col>
            </Row>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
