import { Button } from "reactstrap";

export default function InfoButton(props) {
  return (
    <Button
      color="warning"
      style={{
        borderRadius: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 30,
        width: 30,
      }}
      onClick={props.onClick}
    >
      <i className="fa-solid fa-info" />
    </Button>
  );
}
