import { Button, Col, Row } from "reactstrap";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

export default function Information() {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3">Bienvenue</CardTitle>
      </CardHeader>
      <CardBody>
        <p>
          Bienvenue sur la gestion de la filière Moutarde de Bourgogne. Vous
          devez être connecté pour profiter des fonctionnalités offertes par ce
          logiciel.
          <br />
        </p>
        <p>
          Ce site a pour vocation de gérer l'ensemble de la production de la
          filière Moutarde de Bourgogne. Intensions de semis, plan de production
          automatisé, bilan de la récolte, gestion des producteurs et des
          exploitations moutardières, tout est en un seul logiciel : Ecodim.
        </p>
        <Row style={{ marginBottom: 30 }}>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" href="https://www.apgmb.fr">
              Site institutionnel de l'APGMB
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 200 }}
              src={require("../../assets/img/logoBFC.png")}
            ></img>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Outil développé avec le concours financier du Conseil Régional de
            Bourgogne Franche-Comté
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
