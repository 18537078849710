import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeVariete from "./TypeVariete/ListeTypeVariete";
import FormNewTypeVariete from "./TypeVariete/FormNewTypeVariete";
import FormUpdateTypeVariete from "./TypeVariete/FormUpdateTypeVariete";
import { createNotification } from "../../config/utils";

export default function TypeVariete() {
  const store = useContext(StoreContext);
  const [showFormNewTypeVariete, setShowFormNewTypeVariete] = useState(false);
  const [showFormUpdateTypeVariete, setShowFormUpdateTypeVariete] =
    useState(false);
  const [idreftypevarieteToUpdate, setIdreftypevarieteToUpdate] = useState();

  const handleAddNewTypeVariete = async (typeVariete) => {
    const res = await ReferentielController.insertTypeVariete(typeVariete);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de variété créé avec succès",
      );
      store.referentiels.setTypesVarietes([
        ...store.referentiels.typesVarietes,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de variété",
      );
    }
  };

  const handleUpdateTypeVariete = async (typeVariete) => {
    const res = await ReferentielController.updateTypeVariete(
      idreftypevarieteToUpdate,
      typeVariete,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de variété modifié avec succès",
      );
      store.referentiels.setTypesVarietes(
        await ReferentielController.getTypesVariete(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de variété",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeVariete}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeVariete
          onClickAdd={handleAddNewTypeVariete}
          onClickClose={() => setShowFormNewTypeVariete(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeVariete}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeVariete
          onClickUpdate={handleUpdateTypeVariete}
          onClickClose={() => setShowFormUpdateTypeVariete(false)}
          idreftypevariete={idreftypevarieteToUpdate}
        />
      </Collapse>
      <ListeTypeVariete
        showFormUpdateTypeVariete={(idreftypevariete) => {
          setIdreftypevarieteToUpdate(idreftypevariete);
          setShowFormUpdateTypeVariete(true);
        }}
        onClickNewType={() => setShowFormNewTypeVariete(true)}
      />
    </>
  );
}
