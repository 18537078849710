import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeMateriel from "./TypeMateriel/ListeTypeMateriel";
import FormNewTypeMateriel from "./TypeMateriel/FormNewTypeMateriel";
import FormUpdateTypeMateriel from "./TypeMateriel/FormUpdateTypeMateriel";
import { createNotification } from "../../config/utils";

export default function TypeMateriel() {
  const store = useContext(StoreContext);
  const [showFormNewTypeMateriel, setShowFormNewTypeMateriel] = useState(false);
  const [showFormUpdateTypeMateriel, setShowFormUpdateTypeMateriel] =
    useState(false);
  const [idreftypematerielToUpdate, setIdreftypematerielToUpdate] = useState();

  const handleAddNewTypeMateriel = async (typeMateriel) => {
    const res = await ReferentielController.insertTypeMateriel(typeMateriel);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de matériel créé avec succès",
      );
      store.referentiels.setTypesMateriels([
        ...store.referentiels.typesMateriels,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de matériel",
      );
    }
  };

  const handleUpdateTypeMateriel = async (typeMateriel) => {
    const res = await ReferentielController.updateTypeMateriel(
      idreftypematerielToUpdate,
      typeMateriel,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de matériel modifié avec succès",
      );
      store.referentiels.setTypesMateriels(
        await ReferentielController.getTypesMateriel(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de matériel",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeMateriel
          onClickAdd={handleAddNewTypeMateriel}
          onClickClose={() => setShowFormNewTypeMateriel(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeMateriel
          onClickUpdate={handleUpdateTypeMateriel}
          onClickClose={() => setShowFormUpdateTypeMateriel(false)}
          idreftypemateriel={idreftypematerielToUpdate}
        />
      </Collapse>
      <ListeTypeMateriel
        showFormUpdateTypeMateriel={(idreftypemateriel) => {
          setIdreftypematerielToUpdate(idreftypemateriel);
          setShowFormUpdateTypeMateriel(true);
        }}
        onClickNewType={() => setShowFormNewTypeMateriel(true)}
      />
    </>
  );
}
