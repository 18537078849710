import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeProduit from "./Produit/ListeProduit";
import FormNewProduit from "./Produit/FormNewProduit";
import FormUpdateProduit from "./Produit/FormUpdateProduit";
import { createNotification } from "../../config/utils";

export default function Produit() {
  const store = useContext(StoreContext);
  const [showFormNewProduit, setShowFormNewProduit] = useState(false);
  const [showFormUpdateProduit, setShowFormUpdateProduit] = useState(false);
  const [idrefproduitToUpdate, setIdrefproduitToUpdate] = useState();

  const handleAddNewProduit = async (produit) => {
    const res = await ReferentielController.insertProduit(produit);
    if (res.status === 200) {
      createNotification("success", "Succès", "Produit créé avec succès");
      store.referentiels.setProduits([
        ...store.referentiels.produits,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du produit",
      );
    }
  };

  const handleUpdateProduit = async (produit) => {
    const res = await ReferentielController.updateProduit(
      idrefproduitToUpdate,
      produit,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Produit modifié avec succès");
      store.referentiels.setProduits(await ReferentielController.getProduits());
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du produit",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewProduit}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewProduit
          onClickAdd={handleAddNewProduit}
          onClickClose={() => setShowFormNewProduit(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateProduit}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateProduit
          onClickUpdate={handleUpdateProduit}
          onClickClose={() => setShowFormUpdateProduit(false)}
          idrefproduit={idrefproduitToUpdate}
        />
      </Collapse>
      <ListeProduit
        showFormUpdateProduit={(idrefproduit) => {
          setIdrefproduitToUpdate(idrefproduit);
          setShowFormUpdateProduit(true);
        }}
        onClickNewProduit={() => setShowFormNewProduit(true)}
      />
    </>
  );
}
