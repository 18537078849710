import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import DeleteButton from "../../components/Buttons/DeleteButton";
import useConstructor from "../../config/hooks/useConstructor";
import ReferentielController from "../../config/apiUtils/ReferentielController";

export default function FormUpdateFormation(props) {
  const store = useContext(StoreContext);
  const [typeFormation, setTypeFormation] = useState(-1);
  const [suivi, setSuivi] = useState(false);

  useEffect(() => {
    setTypeFormation(props.formation.idreftypeformation);
    setSuivi(props.formation.suivi);
  }, [props.formation]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formation = {
      idreftypeformation: event.target.idreftypeformation.value,
      commentaire: event.target.commentaire.value,
      dateformation: event.target.dateformation.value,
      suivi: event.target.suivi.value,
    };
    await props.onClickUpdateFormation(
      props.formation.idformationproducteur,
      formation,
    );
    props.hideFormUpdateFormation();
    event.target.reset();
  };

  useConstructor(async () => {
    store.referentiels.setTypesFormations(
      await ReferentielController.getTypesFormations(),
    );
  });

  const renderTypesFormationOptions = () => {
    if (!store.referentiels.typesFormations) {
      return null;
    }

    return store.referentiels.typesFormations.map((typeFormation, key) => {
      return (
        <option key={key} value={typeFormation.idreftypeformation}>
          {typeFormation.libelle}
        </option>
      );
    });
  };

  const renderProducteurOptions = () => {
    return (
      <option value={props.formation.idproducteur}>
        {props.formation.nomproducteur}
      </option>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Modifier la formation</CardTitle>
        <CloseButton onClick={props.hideFormUpdateFormation} />
        <CardSubtitle tag={"h6"}>
          Pour modifier la formation, remplissez les informations suivantes.
          Tous les champs étoilés sont requis.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginTop: 20 }}>
            <Col md="6">
              <FormGroup>
                <Label for="idproducteur">Producteur *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idproducteur"
                  disabled={true}
                  value={props?.formation?.idproducteur}
                >
                  {renderProducteurOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="idreftypeformation">Type de formation *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idreftypeformation"
                  value={typeFormation}
                  onChange={(event) => {
                    setTypeFormation(event.target.value);
                  }}
                >
                  {renderTypesFormationOptions()}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="suivi">Formation suivie*</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="suivi"
                  placeholder="OUI/NON"
                  value={suivi}
                  onChange={(event) => {
                    setSuivi(event.target.value);
                  }}
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="dateformation">Date de la formation *</Label>
                <Input
                  type="date"
                  name="dateformation"
                  defaultValue={props?.formation?.dateformation}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label for="commentaire">Commentaires</Label>
                <Input
                  type="textarea"
                  name="commentaire"
                  placeholder="Commentaires..."
                  defaultValue={props?.formation?.commentaire}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15, marginRight: 10 }} />
              <CancelButton
                style={{ marginTop: 15, marginRight: 10 }}
                onClick={props.hideFormUpdateFormation}
              />
              <DeleteButton
                style={{ marginTop: 15 }}
                onClick={(event) => {
                  props.onClickDeleteFormation(
                    props?.formation?.idformationproducteur,
                  );
                  event.preventDefault();
                  props.hideFormUpdateFormation();
                }}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
