import APIController from "../APIController";

class FichierController extends APIController {
  constructor() {
    super("/fichier");
  }

  async getListDocuments() {
    return await this.getRequest("/liste/");
  }

  async getListDocumentsExploitation(idexploitation) {
    return await this.getRequest(`/exploitation/${idexploitation}/liste/`);
  }

  async uploadDocument(idreftypedocument, fichier) {
    return await this.postRequest("/upload/", fichier, {
      params: {
        idreftypedocument: idreftypedocument,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async uploadDocumentExploitation(idreftypedocument, idexploitation, fichier) {
    return await this.postRequest(
      `/upload/exploitation/${idexploitation}`,
      fichier,
      {
        params: {
          idreftypedocument: idreftypedocument,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  async downloadDocument(iddocument) {
    return await this.getRequest(`/download/${iddocument}`, {
      responseType: "blob",
    });
  }

  async removeDocuments(iddocument) {
    return await this.deleteRequest(`/${iddocument}`);
  }
}

export default new FichierController();
