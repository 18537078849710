import { useContext, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import CiviliteController from "../../config/apiUtils/CiviliteController";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import useConstructor from "../../config/hooks/useConstructor";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";

export default function FormNewProducteur(props) {
  const store = useContext(StoreContext);

  const [civiliteProducteur, setCiviliteProducteur] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const producteurForm = {
      idcivilite: event.target.idcivilite.value,
      idexploitation: event.target.idexploitation.value,
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      mail: event.target.mail.value,
      telephonefixe: event.target.telephonefixe.value,
      telephoneportable: event.target.telephoneportable.value,
    };
    await props.onClickNewProducteur(producteurForm);
    props.onClickClose();
    event.target.reset();
  };

  const renderCivilitesOptions = () => {
    return store.civilites.map((civilite, key) => {
      return (
        <option key={key} value={civilite.idcivilite}>
          {civilite.libelle}
        </option>
      );
    });
  };

  const renderExploitationsOptions = () => {
    return store.exploitations.map((exploitation, key) => {
      return (
        <option key={key} value={exploitation.idexploitation}>
          {exploitation.raisonsociale}
        </option>
      );
    });
  };

  useConstructor(async () => {
    setLoading(true);
    store.setCivilites(await CiviliteController.getCivilites());
    store.setExploitations(await ExploitationController.getAllExploitations());
    setLoading(false);
  });

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Nouveau producteur</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row>
              <Col md="12">
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md="3">
                      <Label for="idcivilite">Civilité *</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          className="select-input"
                          name="idcivilite"
                          value={civiliteProducteur}
                          onChange={(event) =>
                            setCiviliteProducteur(event.target.value)
                          }
                        >
                          {renderCivilitesOptions()}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <Label for="nom">NOM *</Label>
                      <Input
                        type="text"
                        name="nom"
                        placeholder="Nom"
                        required
                      ></Input>
                    </Col>
                    <Col md="3">
                      <Label for="prenom">Prénom *</Label>
                      <Input
                        type="text"
                        name="prenom"
                        placeholder="Prénom"
                        required
                      ></Input>
                    </Col>
                    <Col md="3">
                      <Label for="idexploitation">Exploitation *</Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="idexploitation"
                      >
                        {renderExploitationsOptions()}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Label for="mail">Adresse e-mail</Label>
                      <Input
                        type="email"
                        name="mail"
                        placeholder="Adresse e-mail"
                        required
                      ></Input>
                    </Col>
                    <Col md="4">
                      <Label for="telephonefixe">Téléphone fixe</Label>
                      <Input
                        type="text"
                        name="telephonefixe"
                        placeholder="Téléphone fixe"
                      ></Input>
                    </Col>
                    <Col md="4">
                      <Label for="telephoneportable">Téléphone mobile</Label>
                      <Input
                        type="text"
                        name="telephoneportable"
                        placeholder="Téléphone mobile"
                      ></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <ValidateButton
                        style={{ marginTop: 15, marginRight: 15 }}
                        type="submit"
                      />
                      <CancelButton
                        style={{ marginTop: 15 }}
                        onClick={(event) => {
                          event.preventDefault();
                          props.onClickClose();
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
