export const raisonsociale = {
  header: "Exploitation",
  name: "raisonsociale",
  cellStyle: { textAlign: "center" },
  minWidth: 200,
  defaultFlex: 1,
  suppressSizeToFit: true,
  sortable: true,
  filter: true,
  resizable: true,
};

export const raisonsocialeFilter = {
  name: "raisonsociale",
  type: "string",
  operator: "contains",
  value: "",
};
