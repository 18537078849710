import { Card, CardBody, Col, Row } from "reactstrap";
import { createNotification, RenderIf } from "../../config/utils";
import {
  columns,
  gridStyle,
  groups,
  defaultFilterValue,
} from "../../components/Grids/IntentionSemis/intentionsemis";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { useCallback, useRef, useState } from "react";
import Select from "react-select";
import { colourStyles } from "../../config/carto/utils";
import ValidateButton from "../../components/Buttons/ValidateButton";
import Grid from "../../components/Grid";
import AuthService from "../../config/AuthService";

export default function CardValidationIntentions({
  intentions,
  loading,
  handleSubmitValidation,
}) {
  const [selected, setSelected] = useState({});
  const [idetatvalidationadministrative, setIdetatvalidationadministrative] =
    useState(null);

  const actionSelectRef = useRef();

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const submitValidation = async () => {
    if (
      Object.values(selected).map((intention) => intention.idsurfacepotentielle)
        .length <= 0
    ) {
      createNotification(
        "error",
        "Merci de sélectionner au moins une intention de semis",
      );
      return;
    }

    if (actionSelectRef.current.getValue().length <= 0) {
      createNotification(
        "error",
        "Merci de sélectionner une action à effectuer",
      );
      return;
    }

    let valide = false;
    let idetatvalidationadministrative =
      actionSelectRef.current.getValue()[0].value;

    if (AuthService.isOrganismeStockeur()) {
      valide = actionSelectRef.current.getValue()[0].value;
    }

    await handleSubmitValidation(
      idetatvalidationadministrative,
      Object.values(selected).map(
        (intention) => intention.idsurfacepotentielle,
      ),
      valide,
    );
  };

  const getActionsOptions = () => {
    if (AuthService.isOrganismeStockeur()) {
      return [
        {
          value: false,
          label: "Non validé",
        },
        {
          value: true,
          label: "Validé",
        },
      ];
    }

    return [
      {
        value: 1,
        label: "Non validé administrativement",
      },
      {
        value: 2,
        label:
          "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
      },
      {
        value: 3,
        label:
          "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
      },
      {
        value: 4,
        label: "Validé administrativement",
      },
      {
        value: 5,
        label: "Pré-validé, en attente paiement cotisation",
      },
    ];
  };

  return (
    <Card>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>

        <RenderIf isTrue={!loading}>
          <Row style={{ marginBottom: 10 }}>
            <Col md={"6"}>
              <Select
                isClearable
                classNamePrefix="react-select"
                placeholder="Action..."
                ref={actionSelectRef}
                menuPortalTarget={document.body}
                autosize={false}
                menuPlacement={"auto"}
                menuPosition={"fixed"}
                defaultValue={getActionsOptions().find(
                  (etat) => etat.value == idetatvalidationadministrative,
                )}
                styles={colourStyles}
                options={getActionsOptions()}
                onChange={(selection) => {
                  setIdetatvalidationadministrative(selection?.value ?? null);
                }}
              />
            </Col>
            <Col md={"2"}>
              <ValidateButton onClick={submitValidation} />
            </Col>
            <Col md={"4"} style={{ display: "flex", justifyContent: "end" }}>
              {`${Object.values(selected).length} intentions sélectionnées`}
            </Col>
          </Row>
          <Row>
            <Col>
              <Grid
                enableCellSelect
                idProperty="idsurfacepotentielle"
                emptyText="Aucune intention à valider"
                columns={columns}
                checkboxColumn
                onSelectionChange={onSelectionChange}
                dataSource={intentions}
                style={gridStyle}
                multiSort
                defaultFilterValue={defaultFilterValue}
                groups={groups}
                selected={selected}
              />
            </Col>
          </Row>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
