import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import { convertToCSV, downloadCsv, RenderIf } from "../../../config/utils";
import ActionGroupeeController from "../../../config/apiUtils/ActionGroupeeController";
import Grid from "../../../components/Grid";
import CsvButton from "../../../components/Buttons/CsvButton";
import moment from "moment";
import {
  groups,
  columns,
  gridStyle,
} from "../../../components/Grids/ActionsGroupees/attestationlivraisonsemences";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";

export default function TableAttestationLivraisonSemences() {
  const store = useContext(StoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVarieteColumnName = (libellevariete, idrefvariete) => {
    return libellevariete + "." + idrefvariete;
  };

  useConstructor(async () => {
    setLoading(true);
    const res = await ActionGroupeeController.getAttestationLivraisonSemences(
      store.millesime.idmillesime,
    );
    res.forEach((element) => {
      //Pour chaque variété, on vérifie si la variété est présente dans les colonnes du tableau
      element.varietes.forEach((variete) => {
        //Pas présente par défaut
        let varieteInColumns = false;
        columns.forEach((column) => {
          //Si on trouve la variété dans le tableau
          if (
            column.name ===
            getVarieteColumnName(variete.libellevariete, variete.idrefvariete)
          ) {
            varieteInColumns = true;
          }
        });

        if (!varieteInColumns) {
          columns.push({
            header: variete.libellevariete ?? "PAS DE VARIETE",
            name: getVarieteColumnName(
              variete.libellevariete,
              variete.idrefvariete,
            ),
            group: "varietes",
            type: "number",
            cellStyle: { textAlign: "center" },
            minWidth: 170,
            defaultFlex: 1,
            suppressSizeToFit: true,
            sortable: true,
            filter: true,
            resizable: true,
            render: ({ value }) => (value ? value + " Kg" : ""),
          });
        }
        // On met à plat les données pour l'affichage dans le tableau
        element[
          getVarieteColumnName(variete.libellevariete, variete.idrefvariete)
        ] = variete.quantitesemencetotale;
      });
    });

    setData(res);
    setLoading(false);
  });

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>
              Attestation de livraison des semences (contrats)
            </CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={data.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  // On récupère une version non modifiée des data pour traitement tableau croisé dynamique
                  var temp = data;
                  temp.forEach((item) => {
                    item.varietes.forEach((variete) => {
                      item[variete.libellevariete] =
                        variete.quantitesemencetotale;
                    });
                    delete item.varietes;
                  });
                  downloadCsv(
                    convertToCSV(temp, ";", getColumns()),
                    "export_als_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="id"
            emptyText="Aucune information renseignée"
            columns={columns}
            dataSource={data}
            onRowClick={() => {}}
            style={gridStyle}
            pagination
            defaultLimit={50}
            groups={groups}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
