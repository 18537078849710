import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";

const columns = [
  {
    header: "Type de produit",
    name: "libelletypeproduit",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Produit",
    name: "libelleproduit",
    cellStyle: { textAlign: "center" },
    minWidth: 600,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Unité",
    name: "unite",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Utilisée",
    name: "estutilise",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value === true) {
        return "OUI";
      }
      return "NON";
    },
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeProduit(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setProduits(await ReferentielController.getProduits());
    store.referentiels.setTypesProduits(
      await ReferentielController.getTypesProduit(),
    );
    store.referentiels.setMatieresActives(
      await ReferentielController.getTypesMatieresActives(),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateProduit(rowIdx.data.idrefproduit);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Produits</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <AddButton onClick={props.onClickNewProduit} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {store.referentiels.produits !== null &&
                store.referentiels.produits !== [] &&
                store.referentiels.produits !== undefined &&
                !loading && (
                  <Grid
                    enableCellSelect={true}
                    idProperty="idreftypeproduit"
                    emptyText="Aucun produit renseigné"
                    columns={columns}
                    dataSource={store.referentiels.produits}
                    style={gridStyle}
                    onRowClick={onRowClick}
                    pagination
                    defaultLimit={20}
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
