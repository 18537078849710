import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeProduction from "./TypeProduction/ListeTypeProduction";
import FormNewTypeProduction from "./TypeProduction/FormNewTypeProduction";
import FormUpdateTypeProduction from "./TypeProduction/FormUpdateTypeProduction";
import { createNotification } from "../../config/utils";

export default function TypeProduction() {
  const store = useContext(StoreContext);
  const [showFormNewTypeProduction, setShowFormNewTypeProduction] =
    useState(false);
  const [showFormUpdateTypeProduction, setShowFormUpdateTypeProduction] =
    useState(false);
  const [idreftypeproductionToUpdate, setIdreftypeproductionToUpdate] =
    useState();

  const handleAddNewTypeProduction = async (typeProduction) => {
    const res = await ReferentielController.insertTypeProduction(
      typeProduction,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de production créé avec succès",
      );
      store.referentiels.setTypesProduction([
        ...store.referentiels.typesProduction,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de production",
      );
    }
  };

  const handleUpdateTypeProduction = async (typeProduction) => {
    const res = await ReferentielController.updateTypeProduction(
      idreftypeproductionToUpdate,
      typeProduction,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de production modifié avec succès",
      );
      store.referentiels.setTypesProduction(
        await ReferentielController.getTypesProduction(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de production",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeProduction}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeProduction
          onClickAdd={handleAddNewTypeProduction}
          onClickClose={() => setShowFormNewTypeProduction(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeProduction}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeProduction
          onClickUpdate={handleUpdateTypeProduction}
          onClickClose={() => setShowFormUpdateTypeProduction(false)}
          idreftypeproduction={idreftypeproductionToUpdate}
        />
      </Collapse>
      <ListeTypeProduction
        showFormUpdateTypeProduction={(idreftypeproduction) => {
          setIdreftypeproductionToUpdate(idreftypeproduction);
          setShowFormUpdateTypeProduction(true);
        }}
        onClickNewType={() => setShowFormNewTypeProduction(true)}
      />
    </>
  );
}
