import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import useConstructor from "../config/hooks/useConstructor";
import AG_GRID_LOCALE_FR from "../config/i18n/locale.fr";
import { flattenList } from "../config/utils";

function Grid(props) {
  const [gridRef, setGridRef] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState(props.columns);

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    {
      float: {
        type: "float",
        name: "float",
        emptyValue: "",
        operators: [
          {
            name: "contains",
            fn: ({ value, filterValue }) => {
              return !filterValue
                ? true
                : String(value).indexOf(String(filterValue)) != -1;
            },
          },
          {
            name: "eq",
            fn: ({ value, filterValue }) => {
              return !filterValue
                ? true
                : String(value) === String(filterValue);
            },
          },
          {
            name: "neq",
            fn: ({ value, filterValue }) => {
              return !filterValue
                ? true
                : String(value) !== String(filterValue);
            },
          },
        ],
      },
    },
  );

  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  const render = useCallback(({ value }) => {
    const lowerSearchText = searchTextRef.current.toLowerCase();
    if (!lowerSearchText) {
      return value;
    }

    const str = value + ""; // get string value
    const v = str.toLowerCase(); // our search is case insesitive
    const index = v.indexOf(lowerSearchText);

    if (index === -1) {
      return value;
    }

    return [
      <span key="before">{str.slice(0, index)}</span>,
      <span
        key="match"
        style={{ background: "orange", fontWeight: "bold", color: "black" }}
      >
        {str.slice(index, index + lowerSearchText.length)}
      </span>,
      <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
    ];
  }, []);
  const shouldComponentUpdate = () => true;

  const onSearchChange = ({ target: { value } }) => {
    const visibleColumns = gridRef.current.visibleColumns;

    setSearchText(value);

    const newDataSource = props.dataSource.filter((p) => {
      return visibleColumns.reduce((acc, col) => {
        const v = (p[col.id] + "").toLowerCase(); // get string value
        return acc || v.indexOf(value.toLowerCase()) !== -1; // make the search case insensitive
      }, false);
    });

    setDataSource(newDataSource);
  };

  useConstructor(async () => {
    let columnsList = [];
    columns.forEach((column) => {
      if (column.render === undefined) {
        columnsList.push({
          ...column,
          render,
          shouldComponentUpdate,
        });
      } else {
        columnsList.push({
          ...column,
        });
      }
    });
    setColumns(columnsList);
  });

  useEffect(() => {
    setDataSource(props.dataSource);
  }, [props.dataSource]);

  const renderActionsButton = () => {
    if (!props.buttons) {
      return;
    }
    return props.buttons.map((button, key) => {
      return (
        <Button
          style={{ borderRadius: "0.5rem" }}
          color={button.color}
          size="lg"
          block
          type="submit"
          onClick={button.onClick}
          key={key}
        >
          {button.title}
        </Button>
      );
    });
  };

  return (
    <>
      {props.showSearchBar && (
        <Row style={{ marginBottom: 20 }}>
          <Col md="3">
            Recherche :{" "}
            <Input
              type="text"
              value={searchText}
              placeholder={props.searchBarPlaceholder}
              onChange={onSearchChange}
            />
          </Col>
          <Col md="9">{renderActionsButton()}</Col>
        </Row>
      )}
      <ReactDataGrid
        onReady={setGridRef}
        enableCellSelect={true}
        defaultFilterValue={props.defaultFilterValue}
        i18n={AG_GRID_LOCALE_FR}
        onRowClick={props.onRowClick}
        onCellClick={props.onCellClick}
        idProperty={props.idProperty}
        defaultActiveCell={props.defaultActiveCell}
        cellSelection={props.cellSelection}
        onCellSelectionChange={props.setCellSelection}
        onFilterValueChange={props.onFilterValueChange}
        filterValue={props.filterValue ?? undefined}
        columns={columns}
        groups={props.groups}
        checkboxColumn={props.checkboxColumn}
        onSelectionChange={props.onSelectionChange}
        selected={props.selected}
        defaultSortInfo={props.multiSort ? [] : null}
        emptyText={props.emptyText}
        onEditComplete={props.onEditComplete}
        dataSource={flattenList(dataSource)}
        filterTypes={filterTypes}
        style={{ ...props.style, borderRadius: 10 }}
        pagination={props.pagination}
        defaultLimit={props.defaultLimit ? props.defaultLimit : 20}
        rowStyle={props.rowStyle}
        onRenderRow={props.onRenderRow}
      />
    </>
  );
}

export default Grid;
