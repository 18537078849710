import { atom } from "recoil";

export const culturesState = atom({
  key: "cultures-state",
  default: [],
});

export const typesSolState = atom({
  key: "types-sol-state",
  default: [],
});
