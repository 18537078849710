import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Contrat/tableparcelle";
import ContratController from "../../config/apiUtils/ContratController";
import useConstructor from "../../config/hooks/useConstructor";
import { createNotification, GoToTopPage } from "../../config/utils";

export default function FormDeleteParcelle(props) {
  useConstructor(async () => {
    GoToTopPage();
  });

  const onRowClick = async (rowIdx) => {
    const res = await ContratController.deleteParcelleContrat(
      props.contrat.idcontrat,
      rowIdx.data.idparcelle,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle supprimée du contrat avec succès",
      );
      props.onClickClose();
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col md="10">
              <CardTitle tag={"h5"}>Supprimer une parcelle</CardTitle>
              <CardSubtitle tag={"h6"}>
                Cliquez sur une parcelle du tableau pour demander sa suppression
                du contrat
              </CardSubtitle>
            </Col>
            <Col md="2">
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Grid
                enableCellSelect={true}
                idProperty="idparcelle"
                emptyText="Aucune parcelle contractualisée"
                columns={columns}
                dataSource={props.contrat.parcelles}
                defaultFilterValue={defaultFilterValue}
                style={gridStyle}
                onRowClick={onRowClick}
                pagination
                defaultLimit={100}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
