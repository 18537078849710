import { Col, Row } from "reactstrap";
import Information from "./dashboard/Information";
import InformationLogiciel from "./dashboard/InformationLogiciel";
import Contact from "./dashboard/Contact";

export default function APropos() {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>À propos</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      <Row>
        <Col md="6" style={{ marginBottom: 30 }}>
          <Information />
        </Col>
        <Col md="6" style={{ marginBottom: 30 }}>
          <InformationLogiciel />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 30 }}>
          <Contact />
        </Col>
      </Row>
    </>
  );
}
