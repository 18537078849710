export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "exploitation", header: "Exploitation" },
  { name: "quantite", header: "Quantité" },
];

export const columns = [
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Statut juridique",
    name: "libellestatutjuridique",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Raison sociale",
    name: "raisonsociale",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "codedc",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "SIRET",
    name: "siret",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libellevariete",
    group: "production",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle printemps (ha)",
    name: "surfacereelleprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle automne (ha)",
    name: "surfacereelleautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle totale (ha)",
    name: "surfacereelletotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Silo définitif",
    name: "nomsilodefinitif",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value === null) {
        return "PAS DE SILO";
      }
      return value;
    },
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff", marginBottom: 20 };

export const defaultFilterValue = [
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  {
    name: "libellestatutjuridique",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "raisonsociale",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "codedc",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "siret",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "codepostal",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "nomcommune",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "nomorganismestockeur",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  {
    name: "surfacereelleprintemps",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "surfacereelleautomne",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "surfacereelletotale",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "nomsilodefinitif", type: "string", operator: "contains", value: "" },
  { name: "quantitelivree", type: "string", operator: "contains", value: "" },
];
