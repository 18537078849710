import React, { Component } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import {
  createNotification,
  deleteQueryParam,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../config/utils";
import AddButton from "../../components/Buttons/AddButton";
import CancelButton from "../../components/Buttons/CancelButton";
import StoreContext from "../../context/StoreContext";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ButtonCartoAction from "./buttons/ButtonCartoAction";
import MesParcelles from "./MesParcelles";
import IntentionSemisSection from "./sections/intentionsemis/IntentionSemisSection";
import ContratSection from "./sections/contrat/ContratSection";
import ModalNewParcelle from "./modal/ModalNewParcelle";
import ConventionSection from "./sections/convention/ConventionSection";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import ImportParcellesPrecedentesSection from "./sections/importparcellesprecedentes/ImportParcellesPrecedentesSection";

export default class PanelRight extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      showModalNewParcelle: false,

      parcelleAssocieeSelected: null,
      featureNewParcelle: null,

      isDrawing: false,
      newParcelle: false,
      newParcelleFromRpg: false,

      parcellesToImport: [],
    };

    this.startDrawing = this.startDrawing.bind(this);
    this.loadParcellesToImport = this.loadParcellesToImport.bind(this);
    this.forceReloadMap = this.forceReloadMap.bind(this);
  }

  async componentDidMount() {
    await this.loadParcellesToImport();
  }

  async loadParcellesToImport() {
    const res =
      await ExploitationController.getParcelleToImportFromLastMillesime(
        this.context.exploitation.informations.idexploitation,
        this.context.millesime.idmillesime,
      );
    this.setState({
      parcellesToImport: res,
    });
  }

  startDrawing() {
    createNotification(
      "info",
      "Création de parcelle",
      "Pour définir une nouvelle parcelle, merci de la dessiner sur la carte. Une fois le dessin terminé, une popup s'ouvrira pour définir les informations de la parcelle.",
    );
    setQueryParam("newparcelle", "true");
    this.context.carto.getParcellaireLayer().initDrawer(
      (evt) => {},
      (evt) => {
        deleteQueryParam("newparcelle");
        this.context.carto.getParcellaireLayer().stopDrawing();
        this.setState({
          isDrawing: false,
          showModalNewParcelle: true,
          featureNewParcelle: evt.feature,
        });
      },
    );
    this.context.carto.getParcellaireLayer().showLayer();
    this.context.carto.getParcellaireLayer().draw();
    this.setState({ isDrawing: true, newParcelle: true });
  }

  async forceReloadMap() {
    await this.loadParcellesToImport();
    await this.context.carto.loadDataParcellaireCarto(this.props.forceUpdate);
  }

  render() {
    return (
      <>
        <Collapse
          isOpen={this.state.showModalNewParcelle}
          mountOnEnter
          unmountOnExit
        >
          <ModalNewParcelle
            isOpen={this.state.showModalNewParcelle}
            feature={this.state.featureNewParcelle}
            addFeature={(feature) => {
              this.context.carto.getParcellaireLayer().addFeature(feature);
              this.setState({
                parcelleAssocieeSelected: feature,
              });
            }}
            toggle={async () => {
              this.setState({
                showModalNewParcelle: !this.state.showModalNewParcelle,
              });
              await this.forceReloadMap();
            }}
          />
        </Collapse>
        <Card className="card-carto" style={{ height: this.props.height }}>
          <RenderIf isTrue={!this.props.panelRightOpened}>
            <CardBody>
              <Button
                color={"primary"}
                style={{ width: "100%" }}
                onClick={this.props.togglePanelRight}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </Button>
            </CardBody>
          </RenderIf>
          <RenderIf isTrue={this.props.panelRightOpened}>
            <CardHeader className="card-header-carto">
              <Row style={{ textAlign: "left" }}>
                <Col>
                  <CardTitle tag={"h4"}>Mon parcellaire</CardTitle>
                </Col>
                <Col
                  md={1}
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <Button
                    color={"primary"}
                    style={{ marginLeft: 10 }}
                    onClick={this.props.togglePanelRight}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </Button>
                  <RenderIf
                    isTrue={
                      !this.state.newParcelleFromRpg &&
                      !this.state.isDrawing &&
                      this.context.millesime.idmillesime > 2023
                    }
                  >
                    <AddButton id="button-add" onClick={this.startDrawing} />
                  </RenderIf>
                  <RenderIf isTrue={this.state.isDrawing}>
                    <CancelButton
                      onClick={() => {
                        deleteQueryParam("newparcelle");
                        this.context.carto.getParcellaireLayer().showLayer();
                        this.context.carto.getParcellaireLayer().stopDrawing();
                        this.setState({
                          isDrawing: false,
                          newParcelle: false,
                          newParcelleFromRpg: false,
                        });
                      }}
                    >
                      Annuler
                    </CancelButton>
                    <Button
                      color="warning"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        this.context.carto
                          .getParcellaireLayer()
                          .drawer.removeLastPoint();
                      }}
                    >
                      <i className="fa-solid fa-arrow-rotate-left" />
                    </Button>
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                overflowX: "clip",
                overflowY: "auto",
                paddingBottom: 100,
              }}
            >
              <RenderIf isTrue={this.props.loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!this.props.loading}>
                <RenderIf isTrue={this.state.newParcelle}>
                  <Row>
                    <ButtonCartoAction
                      libelle={
                        !this.state.newParcelleFromRpg
                          ? "Sélectionner depuis le RPG (2022)"
                          : "Annuler"
                      }
                      onClick={() => {
                        if (this.state.newParcelleFromRpg) {
                          this.context.carto.getParcellaireLayer().hideLayer();
                          this.context.carto.getRpgLayer().hideLayer();
                          this.startDrawing();
                          this.context.carto
                            .getRpgLayer()
                            .setSelectable(false, (evt) => {});
                        } else {
                          this.context.carto.getParcellaireLayer().showLayer();
                          this.context.carto.getRpgLayer().showLayer();
                          this.context.carto
                            .getParcellaireLayer()
                            .stopDrawing();
                          const me = this;
                          this.context.carto
                            .getRpgLayer()
                            .setSelectable(true, (evt) => {
                              me.context.carto.mapRef.current.forEachFeatureAtPixel(
                                evt.pixel,
                                (feature) => {
                                  if (
                                    me.context.carto
                                      .getRpgLayer()
                                      .isFeatureInLayer(feature)
                                  ) {
                                    this.setState({
                                      isDrawing: false,
                                      newParcelleFromRpg: false,
                                      newParcelle: false,
                                      showModalNewParcelle: true,
                                      featureNewParcelle: feature,
                                    });
                                  }
                                },
                              );
                            });
                        }

                        this.setState({
                          isDrawing: !this.state.isDrawing,
                          newParcelleFromRpg: !this.state.newParcelleFromRpg,
                        });
                      }}
                    />
                  </Row>
                </RenderIf>
                <RenderIf isTrue={!this.state.newParcelle}>
                  <RenderIf
                    isTrue={
                      this.context.carto.parcellaire.filter(
                        (p) => !!p.geometrie,
                      ).length === 0
                    }
                  >
                    <Alert fade={false} color="warning">
                      Pas de parcelle carto enregistrée pour{" "}
                      {this.context.exploitation.informations.raisonsociale} en{" "}
                      {this.context.millesime.idmillesime}
                    </Alert>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      this.context.carto.parcellaire.filter(
                        (p) => p.geometrie != null,
                      ).length > 0
                    }
                  >
                    <MesParcelles
                      loading={this.props.loading}
                      forceReloadMap={this.forceReloadMap}
                    />
                  </RenderIf>
                </RenderIf>
                <Row style={{ marginTop: 20 }}>
                  <Accordion
                    className={"section-accordion-carto"}
                    open={this.props.openedSection}
                    toggle={this.props.toggleSection}
                  >
                    <RenderIf isTrue={this.state.parcellesToImport.length > 0}>
                      <ImportParcellesPrecedentesSection
                        parcellesToImport={this.state.parcellesToImport}
                        forceReloadMap={this.forceReloadMap}
                      />
                    </RenderIf>
                    <IntentionSemisSection />
                    <ConventionSection />
                    <ContratSection />
                  </Accordion>
                </Row>
              </RenderIf>
            </CardBody>
          </RenderIf>
        </Card>
      </>
    );
  }
}
