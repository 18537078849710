import APIController from "../APIController";

class PlanProductionEditionController extends APIController {
  constructor() {
    super("/planproduction/edition");
  }

  async updateIdsilo(idplanproduction, idsilo) {
    return await this.putRequest(
      `/idsilo?idplanproduction=${idplanproduction}${
        idsilo ? "&idsilo=" + idsilo : ""
      }`,
    );
  }

  async updateSurveillancecolzasanve(idplanproduction, surveillancecolzasanve) {
    return await this.putRequest(
      `/idsilo?idplanproduction=${idplanproduction}${
        surveillancecolzasanve
          ? "&surveillancecolzasanve=" + surveillancecolzasanve
          : ""
      }`,
    );
  }

  async updateEstconforme(idplanproduction, estconforme) {
    return await this.putRequest(
      `/idsilo?idplanproduction=${idplanproduction}${
        estconforme ? "&estconforme=" + estconforme : ""
      }`,
    );
  }

  async updateField(idplanproduction, field, value) {
    return await this.putRequest(
      `/${field}?idplanproduction=${idplanproduction}${
        value !== undefined && value !== null && value !== ""
          ? "&" + field + "=" + value
          : ""
      }`,
    );
  }
}

export default new PlanProductionEditionController();
