import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { dateFilterEditorProps } from "../../../config/utils";
import AuthService from "../../../config/AuthService";
import colors from "../../../config/colors";
import droits from "../../../config/CodesDroits";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "exploitation", header: "Exploitation" },
  { name: "parcelle", header: "Parcelle" },
  { name: "quantite", header: "Quantité" },
];

const getListValidationAdministrative = () => {
  if (AuthService.hasProfil(droits.profils.ADMINISTRATEUR)) {
    return [
      {
        id: 1,
        label: "Non validé administrativement",
        nomId: "idetatvalidationadministrative",
      },
      {
        id: 2,
        label:
          "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        nomId: "idetatvalidationadministrative",
      },
      {
        id: 3,
        label:
          "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        nomId: "idetatvalidationadministrative",
      },
      {
        id: 4,
        label: "Validé administrativement",
        nomId: "idetatvalidationadministrative",
      },
    ];
  }
  return [
    {
      id: 16,
      label: "Non validé administrativement",
      nomId: "idetatvalidationadministrative",
    },
    {
      id: 17,
      label: "Validé administrativement",
      nomId: "idetatvalidationadministrative",
    },
  ];
};

export const columns = [
  {
    header: "Validé administrativement",
    name: "idetatvalidationadministrative",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25) &&
      !AuthService.hasProfil(droits.profils.OS_COLLECTE_FINALE),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: getListValidationAdministrative(),
      collapseOnSelect: true,
    },
    filter: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 1, label: "Non validé administrativement" },
        {
          id: 2,
          label:
            "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        },
        {
          id: 3,
          label:
            "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        },
        { id: 4, label: "Validé administrativement" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    resizable: true,
    render: ({ cellProps, data, value }) => {
      cellProps.style.border = "1px solid";
      if (data.valide === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
      } else {
        if (value === 1) {
          cellProps.style.background = "#f9d7da"; // DANGER
          cellProps.style.color = "#842029"; // DANGER
        } else {
          cellProps.style.background = colors.warning.background;
          cellProps.style.color = colors.warning.color;
        }
      }
      return data.libellevalidationadministrative;
    },
  },
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de dernière modification",
    name: "datedernieremodification",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Statut juridique",
    name: "exploitation.libellestatutjuridique",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Raison sociale",
    name: "exploitation.raisonsociale",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "exploitation.codedc",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    editable:
      (AuthService.hasProfil(droits.profils.RESPO_OS) &&
        AuthService.hasOrganismeStockeur(3)) ||
      AuthService.hasProfil(droits.profils.OS_COLLECTE_FINALE) ||
      AuthService.hasProfil(droits.profils.ADMINISTRATEUR),
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Numéro UNILEVER",
    name: "exploitation.numerounilever",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "SIRET",
    name: "exploitation.siret",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code INSEE",
    name: "exploitation.codeinsee",
    cellStyle: { textAlign: "center" },
    group: "exploitation",
    minWidth: 200,
    defaultVisible: true,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Localisation",
    name: "exploitation.ville",
    group: "exploitation",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => {
      return data["exploitation.codepostal"] + " " + data["exploitation.ville"];
    },
  },
  {
    header: "Code OS",
    name: "exploitation.codeos",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nom de la parcelle",
    name: "parcelle.nomparcelle",
    group: "parcelle",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Localisation parcelle",
    name: "parcelle.nomcommune",
    group: "parcelle",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => {
      if (!data["parcelle.codepostal"]) {
        return "";
      }
      return data["parcelle.codepostal"] + " " + data["parcelle.nomcommune"];
    },
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libellevariete",
    group: "production",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [],
      collapseOnSelect: true,
    },
  },
  {
    header: "Surface réelle printemps (ha)",
    name: "surfaceprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle automne (ha)",
    name: "surfaceautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle totale (ha)",
    name: "surfacetotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Quantité de semences",
    name: "quantitesemence",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
  },
  {
    header: "Silo de livraison",
    name: "nomsilo",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25) &&
      !AuthService.hasProfil(droits.profils.TECH_OS),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [],
      collapseOnSelect: true,
    },
    render: ({ data, value, cellProps }) => {
      if (value === null) {
        return "PAS DE SILO";
      }
      if (data.siloreprismillesimeprecedent) {
        cellProps.style.border = "1px solid";
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
      }
      return value;
    },
  },
  {
    header: "Silo définitif",
    name: "nomsilodefinitif",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 26),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [],
      collapseOnSelect: true,
    },
    render: ({ data, value, cellProps }) => {
      if (value === null) {
        return "PAS DE SILO";
      }
      if (data.siloreprismillesimeprecedent) {
        cellProps.style.border = "1px solid";
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
      }
      return value;
    },
  },
  {
    header: "Rendement de référence moyen",
    name: "exploitation.rendementrefmoyen",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
    filter: true,
    resizable: true,
  },
  {
    header: "Tonnage de référence moyen",
    name: "tonnage",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Rendement estimé de l'année",
    name: "exploitation.rendementrefmillesime",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 26),
    filter: true,
    resizable: true,
  },
  {
    header: "Tonnage estimé de l'année",
    name: "tonnageestime",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Traçabilité conforme",
    name: "estconforme",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: null, label: "Non renseigné", nomId: "estconforme" },
        { id: true, label: "OUI", nomId: "estconforme" },
        { id: false, label: "NON", nomId: "estconforme" },
      ],
      collapseOnSelect: true,
    },
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      } else if (value === false) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "NON";
      } else {
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
        return "Non renseigné";
      }
    },
  },
  {
    header: "Surveillance (Colza/Sanve)",
    name: "surveillancecolzasanve",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.GESTION_PLAN_PRODUCTION, 25) &&
      !AuthService.hasProfil(droits.profils.TECH_OS) &&
      !AuthService.hasProfil(droits.profils.RESPO_OS),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: null, label: "Non renseigné", nomId: "surveillancecolzasanve" },
        { id: true, label: "OUI", nomId: "surveillancecolzasanve" },
        { id: false, label: "NON", nomId: "surveillancecolzasanve" },
      ],
      collapseOnSelect: true,
    },
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "OUI";
      } else if (value === false) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "NON";
      } else {
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
        return "Non renseigné";
      }
    },
  },
  {
    header: "Récoltée",
    name: "recoltee",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 25),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: null, label: "Non renseigné", nomId: "recoltee" },
        { id: true, label: "OUI", nomId: "recoltee" },
        { id: false, label: "NON", nomId: "recoltee" },
      ],
      collapseOnSelect: true,
    },
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      } else if (value === false) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "NON";
      } else {
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
        return "Non renseigné";
      }
    },
  },
  {
    header: "Autorisation stockage nettoyage",
    name: "autorisationstockagenettoyage",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.GESTION_PLAN_PRODUCTION, 25) &&
      !AuthService.hasProfil(droits.profils.TECH_OS) &&
      !AuthService.hasProfil(droits.profils.RESPO_OS),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: true, label: "OUI", nomId: "autorisationstockagenettoyage" },
        { id: false, label: "NON", nomId: "autorisationstockagenettoyage" },
      ],
      collapseOnSelect: true,
    },
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "OUI";
      } else if (value === false) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "NON";
      } else {
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
        return "Non renseigné";
      }
    },
  },
  {
    header: "Zones de production",
    name: "exploitation.libellezoneproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 700, color: "#ffffff", marginBottom: 20 };

export const defaultFilterValue = [
  {
    name: "idetatvalidationadministrative",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  {
    name: "datedernieremodification",
    type: "date",
    operator: "after",
    value: "",
  },
  {
    name: "exploitation.libellestatutjuridique",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.raisonsociale",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codedc",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codeos",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.numerounilever",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.siret",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codeinsee",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "parcelle.nomparcelle",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "libelletypeproduction",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "nomorganismestockeur",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  { name: "surfaceprintemps", type: "float", operator: "contains" },
  { name: "surfaceautomne", type: "float", operator: "contains" },
  { name: "surfacetotale", type: "float", operator: "contains" },
  { name: "nomsilo", type: "string", operator: "contains", value: "" },
  { name: "nomsilodefinitif", type: "string", operator: "contains", value: "" },
  {
    name: "exploitation.ville",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "parcelle.nomcommune",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  {
    name: "exploitation.rendementrefmoyen",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "tonnage", type: "float", operator: "contains", value: "" },
  {
    name: "exploitation.rendementrefmillesime",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "tonnageestime", type: "float", operator: "contains", value: "" },
  {
    name: "estconforme",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "surveillancecolzasanve",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "recoltee",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "autorisationstockagenettoyage",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "exploitation.libellezoneproduction",
    type: "string",
    operator: "contains",
    value: "",
  },
];
