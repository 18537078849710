import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeDocument from "./TypeDocument/ListeTypeDocument";
import FormNewTypeDocument from "./TypeDocument/FormNewTypeDocument";
import FormUpdateTypeDocument from "./TypeDocument/FormUpdateTypeDocument";
import { createNotification } from "../../config/utils";

export default function TypeDocument() {
  const store = useContext(StoreContext);
  const [showFormNewTypeDocument, setShowFormNewTypeDocument] = useState(false);
  const [showFormUpdateTypeDocument, setShowFormUpdateTypeDocument] =
    useState(false);
  const [idreftypedocumentToUpdate, setIdreftypedocumentToUpdate] = useState();

  const handleAddNewTypeDocument = async (typeDocument) => {
    const res = await ReferentielController.insertTypeDocument(typeDocument);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de document créé avec succès",
      );
      store.referentiels.setTypesDocuments([
        ...store.referentiels.typesDocuments,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de document",
      );
    }
  };

  const handleUpdateTypeDocument = async (typeDocument) => {
    const res = await ReferentielController.updateTypeDocument(
      idreftypedocumentToUpdate,
      typeDocument,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de document modifié avec succès",
      );
      store.referentiels.setTypesDocuments(
        await ReferentielController.getTypesDocument(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de document",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeDocument}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeDocument
          onClickAdd={handleAddNewTypeDocument}
          onClickClose={() => setShowFormNewTypeDocument(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeDocument}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeDocument
          onClickUpdate={handleUpdateTypeDocument}
          onClickClose={() => setShowFormUpdateTypeDocument(false)}
          idreftypedocument={idreftypedocumentToUpdate}
        />
      </Collapse>
      <ListeTypeDocument
        showFormUpdateTypeDocument={(idreftypedocument) => {
          setIdreftypedocumentToUpdate(idreftypedocument);
          setShowFormUpdateTypeDocument(true);
        }}
        onClickNewType={() => setShowFormNewTypeDocument(true)}
      />
    </>
  );
}
