import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { getArea } from "ol/sphere.js";
import { createEmpty, extend } from "ol/extent";

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#526b79",
    fill: "red",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      "cursor": isDisabled ? "not-allowed" : "default",
      "backgroundColor": isSelected
        ? "#415864"
        : isFocused
        ? "#486271"
        : "#526b7a",
      "borderColor": "#526b79",
      "zIndex": 10,
      ":active": {
        ...styles[":active"],
        zIndex: 10,
      },
      ":hover": {
        backgroundColor: "#415864",
        zIndex: 10,
      },
    };
  },
  clearIndicator: (styles) => ({
    ...styles,
    "color": "white",
    ":hover": {
      color: "white",
      backgroundColor: "#486271",
      borderRadius: 40,
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    "color": "white",
    ":hover": {
      color: "white",
      backgroundColor: "#486271",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: "#526b7a",
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
    borderColor: "#526b79",
    color: "white",
  }),
  input: (styles) => ({
    ...styles,
    color: "white",
  }),
  placeholder: (styles) => ({ ...styles, color: "rgba(255, 255, 255, 0.5)" }),
  singleValue: (styles) => ({
    ...styles,
    backgroundColor: "#526b79",
    color: "white",
    zIndex: 10,
  }),
};

export const getTileLayerXYZ = (url, attribution = null) => {
  return new TileLayer({
    source: new OSM({
      url: url,
      attribution: attribution,
    }),
  });
};

export const getRpgAnonymeUrl = (extent) => {
  return (
    "https://geoserver.billonp.fr/geoserver/apgmb/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=apgmb:parcelles_graphiques&outputFormat=application/json&srsname=EPSG:2154&" +
    "bbox=" +
    extent.join(",") +
    ",EPSG:2154"
  );
};

export const getVectorSourceRpgAnonyme = () => {
  return new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return getRpgAnonymeUrl(extent);
    },
    strategy: bboxStrategy,
  });
};

export const getLayerRpgAnonyme = () => {
  return new VectorLayer({
    source: getVectorSourceRpgAnonyme(),
    style: {
      "stroke-width": 0.75,
      "stroke-color": "white",
      "fill-color": "rgba(252, 185, 0, 0.4)",
    },
    id: "rpg",
  });
};

export const getCommunesUrl = (extent) => {
  return (
    "https://geoserver.billonp.fr/geoserver/apgmb/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=apgmb:communes&outputFormat=application/json&srsname=EPSG:2154&" +
    "bbox=" +
    extent.join(",") +
    ",EPSG:2154"
  );
};

export const getVectorSourceCommunes = () => {
  return new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return getCommunesUrl(extent);
    },
    strategy: bboxStrategy,
  });
};

export const getLayerCommunes = () => {
  return new VectorLayer({
    source: getVectorSourceCommunes(),
    style: {
      "stroke-width": 0.75,
      "stroke-color": "white",
      "fill-color": "rgba(154, 93, 93, 0.8)",
    },
    id: "communes",
  });
};

export const getDepartementsUrl = (extent) => {
  return (
    "https://geoserver.billonp.fr/geoserver/apgmb/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=apgmb:departements&outputFormat=application/json&srsname=EPSG:2154&" +
    "bbox=" +
    extent.join(",") +
    ",EPSG:2154"
  );
};

export const getVectorSourceDepartements = () => {
  return new VectorSource({
    format: new GeoJSON(),
    url: function (extent) {
      return getDepartementsUrl(extent);
    },
    strategy: bboxStrategy,
  });
};

export const getLayerDepartements = () => {
  return new VectorLayer({
    source: getVectorSourceDepartements(),
    style: {
      "stroke-width": 0.75,
      "stroke-color": "white",
      "fill-color": "rgba(41, 255, 57, 0.8)",
    },
    id: "communes",
  });
};

export const getRoadsUrl = () => {
  return "https://tiles.stadiamaps.com/tiles/stamen_toner_lines/{z}/{x}/{y}{r}.png";
};

export const getLayerRoads = () => {
  return getTileLayerXYZ(
    "https://tiles.stadiamaps.com/tiles/stamen_toner_lines/{z}/{x}/{y}{r}.png",
  );
};

export const getLayerById = (mapRef, id) => {
  var res = null;
  mapRef.current.getLayers().forEach((layer) => {
    if (layer.get("id") == id) {
      res = layer;
    }
  });
  return res;
};

export const getSurface = (geometrie) => {
  return parseFloat((geometrie.getArea() / 10000).toFixed(2));
};

export const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = (area / 10000).toFixed(2) + " Ha";
  } else {
    output = (area / 10000).toFixed(2) + " " + "m2 Ha";
  }
  return output;
};

export const getExtentFeatures = (features) => {
  let extent = new createEmpty();
  features.forEach((feature) => {
    extent = new extend(extent, feature.getGeometry().getExtent());
  });
  return extent;
};

export const getGeoJsonFeature = (feature) => {
  return {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:EPSG::2154",
      },
    },
    features: [JSON.parse(new GeoJSON().writeFeature(feature))],
  };
};
