import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import ReferentielController from "../config/apiUtils/ReferentielController";
import useConstructor from "../config/hooks/useConstructor";
import FormUpdateCotisation from "./cotisationannuelle/FormUpdateCotisation";
import TableCotisationAnnuelle from "./cotisationannuelle/TableCotisationAnnuelle";
import { createNotification, RenderIf } from "../config/utils";

export default function CotisationAnnuelle() {
  const [cotisations, setCotisations] = useState([]);
  const [selectedCotisation, setSelectedCotisation] = useState();
  const [isFormUpdateCotisationOpen, setIsFormUpdateCotisationOpen] =
    useState(false);

  const onSelectCotisation = (cotisation) => {
    setSelectedCotisation(cotisation);
    setIsFormUpdateCotisationOpen(true);
  };

  const onClickUpdateCotisation = async (cotisation) => {
    const resUpdateCotisation = await ReferentielController.updateRefCotisation(
      selectedCotisation.idrefcotisation,
      cotisation,
    );
    if (resUpdateCotisation.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Cotisation annuelle modifiée avec succès !",
      );
      await getCotisations();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification de la cotisation...",
      );
    }
  };

  const getCotisations = async () => {
    const resCotisations = await ReferentielController.getRefCotisation();
    setCotisations(resCotisations);
  };

  useConstructor(async () => {
    await getCotisations();
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des cotisations annuelles</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary">
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <TableCotisationAnnuelle
            cotisations={cotisations}
            onSelectCotisation={onSelectCotisation}
          />
        </Col>
        <Col md="6">
          <Collapse
            isOpen={isFormUpdateCotisationOpen}
            mountOnEnter
            unmountOnExit
          >
            <RenderIf isTrue={selectedCotisation}>
              <FormUpdateCotisation
                cotisation={selectedCotisation}
                hideFormUpdateCotisation={() => {
                  setIsFormUpdateCotisationOpen(false);
                }}
                onClickUpdateCotisation={onClickUpdateCotisation}
              />
            </RenderIf>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}
