import { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";

export default function FormCommandeIndustriel(props) {
  const store = useContext(StoreContext);

  const onSubmit = async (event) => {
    event.preventDefault();

    await props.onClickAddCommande({
      millesime: event.target.millesime.value,
      tonnage: event.target.tonnage.value,
      estimationrendement: event.target.estimationrendement.value,
      prixbio: event.target.prixbio.value || 0,
      prixbourgogne: event.target.prixbourgogne.value || 0,
      prixhorsbourgogne: event.target.prixhorsbourgogne.value || 0,
    });
    props.hideFormNewCommande();
    event.target.reset();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Nouvelle commande</CardTitle>
        <CloseButton onClick={props.hideFormNewCommande} />
        <CardSubtitle>
          Pour ajouter une nouvelle commande au système de gestion, remplissez
          les informations suivantes. Tous les champs étoilés sont requis.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="millesime">Millésime *</Label>
                <Input
                  type="text"
                  name="millesime"
                  defaultValue={store.millesime.idmillesime}
                  readOnly
                  disabled
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="tonnage">Tonnage commandé *</Label>
                <Input
                  type="text"
                  name="tonnage"
                  placeholder="Tonnage commandé"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="estimationrendement">Rendement estimé *</Label>
                <Input
                  type="text"
                  name="estimationrendement"
                  placeholder="Rendement estimé"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="prixbio">Prix Biologique</Label>
                <Input
                  type="text"
                  name="prixbio"
                  placeholder="Prix biologique"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="prixbourgogne">Prix Bourgogne</Label>
                <Input
                  type="text"
                  name="prixbourgogne"
                  placeholder="Prix Bourgogne"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="prixhorsbourgogne">Prix Hors Bourgogne</Label>
                <Input
                  type="text"
                  name="prixhorsbourgogne"
                  placeholder="Prix Hors Bourgogne"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <ValidateButton style={{ marginTop: 10 }} />
          <CancelButton
            style={{ marginLeft: 15 }}
            onClick={(event) => {
              event.preventDefault();
              props.hideFormNewCommande();
            }}
          />
        </Form>
      </CardBody>
    </Card>
  );
}
