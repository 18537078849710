import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/StoreContext";
import ContratController from "../config/apiUtils/ContratController";
import { Col, Row } from "reactstrap";
import CardValidationContrats from "./validationcontrats/CardValidationContrats";
import { createNotification } from "../config/utils";

export default function ValidationContrats() {
  const [contrats, setContrats] = useState([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(StoreContext);

  const loadContrats = async () => {
    setLoading(true);

    const resContrats = await ContratController.getContratMillesime(
      context.millesime.idmillesime,
    );
    setContrats(
      resContrats.filter(
        (contrat) =>
          !contrat.abandon && contrat.idetatvalidationadministrative < 4,
      ),
    );

    setLoading(false);
  };

  const handleSubmitValidation = async (
    idetatvalidationadministrative,
    idcontrats,
    valide,
  ) => {
    setLoading(true);

    const form = {
      idetatvalidationadministrative,
      idcontrats,
      valide,
    };
    const res = await ContratController.updateValidationContrats(form);

    if (res.status === 200) {
      await loadContrats();
      createNotification(
        "success",
        "Validation de " +
          idcontrats.length +
          " contrat" +
          (idcontrats.length > 1 ? "s" : "") +
          " modifiée avec succès",
      );
      return;
    }

    setLoading(false);
  };

  useEffect(() => {
    loadContrats();
  }, []);

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Validation des contrats de production</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <CardValidationContrats
            contrats={contrats}
            loading={loading}
            handleSubmitValidation={handleSubmitValidation}
          />
        </Col>
      </Row>
    </>
  );
}
