import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { useContext, useEffect } from "react";
import Grid from "../../components/Grid";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import {
  columns,
  gridStyle,
  groups,
  defaultFilterValue,
} from "../../components/Grids/IntentionSemis/intentionsemis";
import CsvButton from "../../components/Buttons/CsvButton";
import {
  RenderIf,
  convertToCSV,
  createNotification,
  downloadCsv,
} from "../../config/utils";
import moment from "moment";
import droits from "../../config/CodesDroits";
import StoreContext from "../../context/StoreContext";

export default function TableIntentionGeneral(props) {
  const store = useContext(StoreContext);

  useEffect(() => {
    const organismes = [];
    props.surfacesPotentielle.forEach((surface) => {
      if (
        !organismes.some(
          (organisme) => organisme === surface.nomorganismestockeur,
        )
      ) {
        organismes.push(surface.nomorganismestockeur);
      }
    });
    columns.forEach((column) => {
      if (column.name === "nomorganismestockeur") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: organismes.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
  }, [props.surfacesPotentielle]);

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateIntention(rowIdx.data);
  };

  const onCellClick = (event, cellProps) => {
    const includedColumns = ["codedc", "numerounilever", "exploitation"];
    if (includedColumns.includes(cellProps.name)) {
      navigator.clipboard.writeText(cellProps.value);
      createNotification(
        "info",
        "Information",
        cellProps.header + " copié dans le presse-papier",
        1000,
      );
    }
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    delete tmp["desactiver"];
    return tmp;
  };

  const getDataForCsv = () => {
    var tmp = [...props.surfacesPotentielle];
    tmp.forEach(
      (intention) =>
        (intention.idetatvalidationadministrative =
          intention.libellevalidationadministrative),
    );
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Intentions de semis</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={props.surfacesPotentielle.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(getDataForCsv(), ";", getColumns()),
                    "export_intentions_semis_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {props.loading && <SpinLoadingAnimation />}
        {!props.loading && (
          <Grid
            enableCellSelect={true}
            idProperty="idsurfacepotentielle"
            emptyText="Aucune intention renseignée"
            columns={columns}
            dataSource={props.surfacesPotentielle}
            style={gridStyle}
            onCellClick={onCellClick}
            defaultFilterValue={defaultFilterValue}
            onRowClick={
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_INTENTIONS_SEMIS,
                droits.modalite.modification,
              )
                ? onRowClick
                : null
            }
            groups={groups}
            pagination
            defaultLimit={50}
          />
        )}
      </CardBody>
    </Card>
  );
}
