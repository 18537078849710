import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { unflatten } from "../../config/utils";
import useConstructor from "../../config/hooks/useConstructor";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";

export default function FormUpdateCommande(props) {
  const [tonnage, setTonnage] = useState(0);
  const [estimationRendement, setEstimationRendement] = useState(0);
  const [prixos, setPrixos] = useState([]);
  const [organismes, setOrganismes] = useState([]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const commande = {
      tonnage: event.target.tonnage.value || 0,
      estimationrendement: event.target.estimationrendement.value || 0,
      prix: prixos,
    };
    await props.onClickUpdateCommande(commande);
    props.hideFormUpdateCommande();
  };

  useConstructor(async () => {
    const tmp = await OrganismeStockeurController.getOrganismesStockeur();
    setOrganismes(tmp);
  });

  useEffect(() => {
    setTonnage(props.commande.tonnage);
    setEstimationRendement(props.commande.estimationrendement);
    setPrixos(unflatten(props.commande).prix || []);
  }, [props.commande]);

  const renderInput = (name, placeholder, label, value, onChange) => {
    return (
      <Col>
        <FormGroup>
          <Label for={name}>{label}</Label>
          <Input
            type="text"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </FormGroup>
      </Col>
    );
  };

  const renderPrixBio = (organisme, prix) => {
    return renderInput(
      `${prix.idorganismestockeur}.prixbio`,
      "Prix Biologique",
      "Prix Biologique",
      prix.prixbio,
      (event) => {
        prixos.find(
          (p) => organisme.idorganismestockeur == p.idorganismestockeur,
        ).prixbio = event.target.value;
        setPrixos([...prixos]);
      },
    );
  };

  const renderPrixBourgogne = (organisme, prix) => {
    return renderInput(
      `${prix.idorganismestockeur}.prixbourgogne`,
      "Prix Bourgogne",
      "Prix Bourgogne",
      prix.prixbourgogne,
      (event) => {
        prixos.find(
          (p) => organisme.idorganismestockeur == p.idorganismestockeur,
        ).prixbourgogne = event.target.value;
        setPrixos([...prixos]);
      },
    );
  };

  const renderPrixHorsBourgogne = (organisme, prix) => {
    return renderInput(
      `${prix.idorganismestockeur}.prixhorsbourgogne`,
      "Prix Hors Bourgogne",
      "Prix Hors Bourgogne",
      prix.prixhorsbourgogne,
      (event) => {
        prixos.find(
          (p) => organisme.idorganismestockeur == p.idorganismestockeur,
        ).prixhorsbourgogne = event.target.value;
        setPrixos([...prixos]);
      },
    );
  };

  const renderInputsPrixOrganismesStockeur = () => {
    return prixos.map((prix, key) => {
      const organisme = organismes.find(
        (o) => o.idorganismestockeur == prix.idorganismestockeur,
      );

      return (
        <Row key={key} style={{ marginBottom: 10 }}>
          <h4>{organisme?.nomorganismestockeur}</h4>
          {renderPrixBio(organisme, prix)}
          {renderPrixBourgogne(organisme, prix)}
          {renderPrixHorsBourgogne(organisme, prix)}
        </Row>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Modification de la commande</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.hideFormUpdateCommande} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="millesime">Millésime</Label>
                <Input
                  type="text"
                  name="millesime"
                  placeholder="Millésime"
                  value={props?.commande.millesime}
                  disabled
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="tonnage">Tonnage</Label>
                <Input
                  type="text"
                  name="tonnage"
                  placeholder="Tonnage"
                  value={tonnage}
                  onChange={(event) => {
                    setTonnage(event.target.value);
                  }}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="estimationrendement">Rendement estimé</Label>
                <Input
                  type="text"
                  name="estimationrendement"
                  placeholder="Estimation du rendement"
                  value={estimationRendement}
                  onChange={(event) => {
                    setEstimationRendement(event.target.value);
                  }}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          {renderInputsPrixOrganismesStockeur()}
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
              <CancelButton
                style={{ marginTop: 15 }}
                onClick={(event) => {
                  event.preventDefault();
                  props.hideFormUpdateCommande();
                }}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
