import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/ag-grid-community/dist/styles/ag-grid.css";
import "./assets/css/ag-grid-community/dist/styles/ag-theme-balham.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/notifications.css";
import "./assets/css/carto.css";
import AuthService from "./config/AuthService";
import StoreProvider from "./context/StoreProvider";
import LoadingPage from "./views/LoadingPage";
import MaintenanceLayout from "./layouts/Maintenance/MaintenanceLayout";

const UserLayout = lazy(() => import("./layouts/User/UserLayout"));
const LoginLayout = lazy(() => import("./layouts/Login/LoginLayout"));

const showMessageMaintenance =
  window.env.SHOW_MESSAGE_MAINTENANCE &&
  !!window.env.MESSAGE_MAINTENANCE &&
  window.env.MESSAGE_MAINTENANCE != null &&
  window.env.MESSAGE_MAINTENANCE.length > 0;

export default function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <div className="App" style={styles.color}>
        <BrowserRouter>
          {!AuthService.isLoggedIn() && !showMessageMaintenance && (
            <LoginLayout />
          )}
          {AuthService.isLoggedIn() && !showMessageMaintenance && (
            <StoreProvider>
              <UserLayout />
            </StoreProvider>
          )}
          {showMessageMaintenance && <MaintenanceLayout />}
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

const styles = {
  color: {
    background:
      "linear-gradient(45deg, rgba(35,47,52,1) 0%, rgba(48,63,69,1) 22%, rgba(35,47,52,1) 100%)",
    backgroundColor: "#232F34",
    color: "#FFFFFF",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflowX: "auto",
  },
};
