import APIController from "../APIController";

class ParcelleController extends APIController {
  constructor() {
    super("/parcelle");
  }

  async getParcelles() {
    return await this.getRequest("/");
  }

  /*async getParcelleByIdParcelle(idparcelle) {
    return await this.getRequest(`/${idparcelle}`);
  }*/

  async getParcellesDisponiblesMillesime(millesime) {
    return await this.getRequest("/disponible", {
      params: {
        millesime: millesime,
      },
    });
  }

  async insertParcelle(parcelle) {
    return await this.postRequest("/", parcelle);
  }

  async updateParcelle(idparcelle, parcelle) {
    return await this.putRequest(`/${idparcelle}`, parcelle);
  }

  async associerParcelle(idparcelle, parcelle) {
    return await this.postRequest(`/${idparcelle}/associer`, parcelle);
  }

  async getCommuneParcelleByGeom(geojson) {
    return await this.getRequest(
      `/communebygeom?geojson=${JSON.stringify(geojson)}`,
    );
  }
}

export default new ParcelleController();
