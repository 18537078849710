import APIController from "../APIController";

class ContactController extends APIController {
  constructor() {
    super("/contact");
  }

  async getMotifsContact() {
    return await this.getRequest("/motifs");
  }

  async getMotifContactById(idmotifcontact) {
    return await this.getRequest(`/motifs/${idmotifcontact}`);
  }

  async sendDemandeContact(formulaire) {
    return await this.postRequest("/send", formulaire);
  }
}

export default new ContactController();
