import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import colors from "../../../config/colors";
import { dateFilterEditorProps, unflatten } from "../../../config/utils";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "production", header: "Production" },
  { name: "abandon", header: "Abandon" },
];

export const columns = [
  {
    header: "Validé",
    name: "idetatvalidationadministrative",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 0, label: "Abandon" },
        { id: 1, label: "Non validé administrativement" },
        {
          id: 2,
          label:
            "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        },
        {
          id: 3,
          label:
            "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        },
        { id: 4, label: "Validé administrativement" },
        { id: 5, label: "Pré-validé, en attente paiement cotisation" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    resizable: true,
    render: ({ cellProps, data, value }) => {
      cellProps.style.border = "1px solid";
      if (data.valide === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
      } else {
        if (value === 0) {
          cellProps.style.background = "#b4b4b4"; // ABANDON
          cellProps.style.color = "#000000"; // ABANDON
        } else if (value === 1) {
          cellProps.style.background = "#f9d7da"; // DANGER
          cellProps.style.color = "#842029"; // DANGER
        } else {
          cellProps.style.background = colors.warning.background;
          cellProps.style.color = colors.warning.color;
        }
      }
      return data.libellevalidationadministrative;
    },
  },
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value, data }) => {
      if (data.abandon === true) {
        cellProps.style.border = "1px solid";
        cellProps.style.background = "#b4b4b4"; // ABANDON
        cellProps.style.color = "#000000"; // ABANDON
      }
      return value;
    },
  },
  {
    header: "Raison sociale",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 370,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Cotisation payée",
    name: "cotisation.0.paye",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ data, cellProps }) => {
      cellProps.style.border = "1px solid";
      if (unflatten(data).cotisation[0].paye) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
  {
    header: "Date de dernière modification",
    name: "datedernieremodification",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Date d'envoi de la facture",
    name: "dateenvoifacturecotisation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Date du contrat",
    name: "datecontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    group: "production",
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [].map((c) => {
        return { id: c, label: c };
      }),
    },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    group: "production",
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [].map((c) => {
        return { id: c, label: c };
      }),
    },
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nombre de parcelles",
    name: "nbparcelles",
    type: "number",
    group: "production",
    cellStyle: { textAlign: "center" },
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((c) => {
        return { id: c, label: c };
      }),
    },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface printemps (ha)",
    name: "surfaceprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface automne (ha)",
    name: "surfaceautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface totale (ha)",
    name: "surfacetotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Abandonné",
    name: "abandon",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#f9d7da"; // DANGER
        cellProps.style.color = "#842029"; // DANGER
        return "OUI";
      }
      cellProps.style.background = "#d1e7dd"; // SUCCESS
      cellProps.style.color = "#0f5132"; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Commentaire abandon",
    name: "commentaireabandon",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 250,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commentaire",
    name: "commentaire",
    cellStyle: { textAlign: "center" },
    minWidth: 250,
    defaultFlex: 1,
    defaultVisible: false,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 700, color: "#ffffff" };

export const defaultFilterValue = [
  {
    name: "idetatvalidationadministrative",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "cotisation.0.paye", type: "select", value: [], operator: "inlist" },
  {
    name: "datedernieremodification",
    type: "date",
    operator: "after",
    value: "",
  },
  {
    name: "dateenvoifacturecotisation",
    type: "date",
    operator: "after",
    value: "",
  },
  { name: "datecontrat", type: "date", operator: "after", value: "" },
  {
    name: "nomorganismestockeur",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "libelletypeproduction",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "nbparcelles", type: "select", operator: "inlist", value: [] },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "surfacetotale", type: "float", operator: "contains", value: "" },
  { name: "abandon", type: "select", value: [], operator: "inlist" },
];
