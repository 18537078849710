import Layouts from "../layoutRoutes";
import MaintenancePage from "../../views/MaintenancePage";

const MaintenanceRoutes = [
  {
    path: "/",
    name: "Maintenance de la plateforme",
    element: <MaintenancePage />,
    layout: Layouts.maintenanceLayout,
    show: false,
  },
];

export default MaintenanceRoutes;
