import APIController from "../APIController";

class CertificationIgpController extends APIController {
  constructor() {
    super("/certificationigp");
  }

  async getCertificationsIgp(
    millesime,
    showHabilite = false,
    showActif = false,
  ) {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
        showActif: showActif,
        showHabilite: showHabilite,
      },
    });
  }

  async activerHabilitationIgp(idexploitation, millesime, actif) {
    return await this.putRequest(
      `/activation/${idexploitation}?millesime=${millesime}&actif=${actif}`,
    );
  }
}

export default new CertificationIgpController();
