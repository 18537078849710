import { useContext, useState } from "react";
import { Alert, Button, Col, Collapse, Row } from "reactstrap";
import useConstructor from "../config/hooks/useConstructor";
import Documents from "./moncompte/Documents";
import FormNewFichier from "./moncompte/documents/FormNewFichier";
import FicheMonCompte from "./moncompte/FicheMonCompte";
import FormUpdatePassword from "./moncompte/FormUpdatePassword";
import FormUpdateUtilisateur from "./moncompte/FormUpdateUtilisateur";
import StoreContext from "../context/StoreContext";
import {
  deleteQueryParam,
  getQueryParam,
  setQueryParam,
  RenderIf,
  createNotification,
} from "../config/utils";
import FichierController from "../config/apiUtils/FichierController";
import UtilisateurController from "../config/apiUtils/UtilisateurController";

export default function MonCompte() {
  const store = useContext(StoreContext);
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
  const [showUpdateUtilisateurForm, setShowUpdateUtilisateurForm] =
    useState(false);
  const [showNewFormFichier, setShowNewFormFichier] = useState(false);
  const [showSuccessUpdatePassword, setShowSuccessUpdatePassword] =
    useState(false);
  const [showErrorUpdatePassword, setShowErrorUpdatePassword] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [documents, setDocuments] = useState([]);

  const loadDocuments = async () => {
    const resDocuments = await FichierController.getListDocuments();
    setDocuments(resDocuments);
  };

  const handleUpdatePassword = async (utilisateur) => {
    const resUser = await UtilisateurController.changePasswordUtilisateur(
      store.utilisateur.informations.idutilisateur,
      utilisateur,
    );
    if (resUser.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Mot de passe modifié avec succès",
      );
      setShowSuccessUpdatePassword(true);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification du mot de passe...",
      );
      setShowErrorUpdatePassword(true);
      setErrorMessagePassword(
        "Une erreur s'est produite : " + resUser.data.details,
      );
    }
  };

  const handleUpdateInfos = async (utilisateur) => {
    const resUser = await UtilisateurController.updateInfosUtilisateur(
      store.utilisateur.informations.idutilisateur,
      utilisateur,
    );
    if (resUser.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Utilisateur modifié avec succès",
      );
      store.utilisateur.setUtilisateur(
        await UtilisateurController.getUserInfo(),
      );
    } else {
      createNotification(
        "error",
        "Une erreur s'est produite",
        resUser.data.details,
      );
    }
  };

  useConstructor(async () => {
    await loadDocuments();
    if (getQueryParam("updatepassword") == "true") {
      setShowUpdatePasswordForm(true);
    }
  });

  const renderSuccessUpdatePasswordAlert = () => {
    return (
      <Alert
        color="success"
        isOpen={showSuccessUpdatePassword}
        toggle={() => setShowSuccessUpdatePassword(!showSuccessUpdatePassword)}
      >
        Mot de passe modifié avec succès.
      </Alert>
    );
  };

  const renderErrorUpdatePasswordAlert = () => {
    return (
      <Alert
        color="danger"
        isOpen={showErrorUpdatePassword}
        toggle={() => setShowErrorUpdatePassword(!showErrorUpdatePassword)}
      >
        {errorMessagePassword}
      </Alert>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Mon compte</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <RenderIf
            isTrue={store.utilisateur.hasDroits("GESTION_DOCUMENT", 40)}
          >
            <Button
              color="primary"
              onClick={() => {
                setShowNewFormFichier(true);
              }}
              style={{ marginLeft: 10 }}
            >
              Nouveau document
            </Button>
          </RenderIf>
          <Button
            color="primary"
            onClick={() => {
              setShowUpdatePasswordForm(true);
              setQueryParam("updatepassword", "true");
            }}
          >
            Modifier le mot de passe
          </Button>
        </Col>
      </Row>

      <Row>
        <Col
          md={store.utilisateur.hasDroits("GESTION_DOCUMENT", 20) ? 4 : 12}
          style={{ marginBottom: 30 }}
        >
          {renderSuccessUpdatePasswordAlert()}
          {renderErrorUpdatePasswordAlert()}
          <Collapse
            isOpen={showUpdatePasswordForm && !showUpdateUtilisateurForm}
            mountOnEnter
            unmountOnExit
          >
            <FormUpdatePassword
              onClickUpdatePassword={handleUpdatePassword}
              hideUpdatePasswordForm={() => {
                deleteQueryParam("updatepassword");
                setShowUpdatePasswordForm(false);
              }}
            />
          </Collapse>
          <Collapse
            isOpen={showUpdateUtilisateurForm}
            mountOnEnter
            unmountOnExit
          >
            <FormUpdateUtilisateur
              onClickUpdateInfos={handleUpdateInfos}
              hideUpdateUtilisateurForm={() =>
                setShowUpdateUtilisateurForm(false)
              }
            />
          </Collapse>
          <Collapse
            isOpen={!showUpdatePasswordForm && !showUpdateUtilisateurForm}
            mountOnEnter
          >
            <FicheMonCompte
              showUpdateUtilisateurForm={() =>
                setShowUpdateUtilisateurForm(true)
              }
            />
          </Collapse>
        </Col>
        <RenderIf isTrue={store.utilisateur.hasDroits("GESTION_DOCUMENT", 20)}>
          <Col md="8">
            <Collapse isOpen={showNewFormFichier} mountOnEnter unmountOnExit>
              <FormNewFichier
                hideFormNewFichier={() => setShowNewFormFichier(false)}
                loadDocuments={loadDocuments}
              />
            </Collapse>
            <Collapse isOpen={!showNewFormFichier} mountOnEnter unmountOnExit>
              <Documents documents={documents} loadDocuments={loadDocuments} />
            </Collapse>
          </Col>
        </RenderIf>
      </Row>
    </>
  );
}
