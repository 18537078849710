import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import StoreContext from "../../../context/StoreContext";

export default function ProducteurWidget() {
  const context = useContext(StoreContext);

  const renderProducteurs = () => {
    if (context.exploitation.producteurs.length > 0) {
      return (
        <Table style={{ color: "#FFFFFF" }}>
          <tbody>
            {context.exploitation.producteurs.map((producteur, key) => {
              return (
                <tr key={key}>
                  <td>
                    {producteur.nom} {producteur.prenom}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return "Pas de producteur";
  };

  return (
    <Card style={{ height: 300, maxHeight: 300, marginTop: 10 }}>
      <CardHeader>
        <CardTitle style={{ fontWeight: "bold" }}>Producteurs</CardTitle>
      </CardHeader>
      <CardBody style={{ overflowY: "auto" }}>{renderProducteurs()}</CardBody>
    </Card>
  );
}
