import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ZoneProductionController from "../../../config/apiUtils/ZoneProductionController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";

const columns = [
  {
    header: "Zone de production",
    name: "libelle",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nom",
    name: "libellelong",
    minWidth: 350,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeZoneProduction(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.setZonesProduction(
      await ZoneProductionController.getZonesProduction(),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateZoneProduction(rowIdx.data.idrefzoneproduction);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Zones de production</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewZoneProduction} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.zonesProduction !== null &&
              store.zonesProduction !== [] &&
              store.zonesProduction !== undefined &&
              !loading && (
                <Grid
                  enableCellSelect={true}
                  idProperty="idrefzoneproduction"
                  emptyText="Aucune zone de production renseignée"
                  columns={columns}
                  dataSource={store.zonesProduction}
                  style={gridStyle}
                  onRowClick={onRowClick}
                  pagination
                  defaultLimit={20}
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
