import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormUpdateStatutJuridique(props) {
  const store = useContext(StoreContext);
  const [statutJuridique, setStatutJuridique] = useState(
    store.referentiels.statutsJuridique.find(
      (item) => item.idstatutjuridique === props.idstatutjuridique,
    ),
  );
  const [libellestatut, setLibellestatut] = useState(
    statutJuridique.libellestatut,
  );

  const onSubmit = (event) => {
    event.preventDefault();
    props.onClickUpdate({ libellestatut: libellestatut });
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const statut = store.referentiels.statutsJuridique.find(
      (item) => item.idstatutjuridique === props.idstatutjuridique,
    );
    setStatutJuridique(statut);
    setLibellestatut(statut.libellestatut);
  }, [props.idstatutjuridique, store.referentiels.statutsJuridique]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>{statutJuridique.libellestatut}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label for="libellestatut">Statut juridique</Label>
                <Input
                  name="libellestatut"
                  placeholder={"Statut juridique"}
                  type="text"
                  value={libellestatut}
                  onChange={(event) => setLibellestatut(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
