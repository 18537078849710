import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";

export default function FormUpdateCotisation(props) {
  const [montant, setMontant] = useState(props.cotisation.prixcotisation);
  const [diffcotisation, setDiffcotisation] = useState(
    props.cotisation.diffcotisation,
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    const cotisation = {
      prixcotisation: event.target.prixcotisation.value || 0,
      diffcotisation: event.target.diffcotisation.value || 0,
    };
    await props.onClickUpdateCotisation(cotisation);
    props.hideFormUpdateCotisation();
  };

  useEffect(() => {
    setMontant(props.cotisation.prixcotisation);
  }, [props.cotisation.prixcotisation]);

  useEffect(() => {
    setMontant(props.cotisation.diffcotisation);
  }, [props.cotisation.diffcotisation]);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Modification de la cotisation</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.hideFormUpdateCotisation} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="millesime">Millésime *</Label>
                <Input
                  type="text"
                  name="millesime"
                  placeholder="Millésime"
                  value={props?.cotisation.millesime}
                  disabled
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="prixcotisation">Montant HT *</Label>
                <Input
                  type="text"
                  name="prixcotisation"
                  placeholder="Montant"
                  defaultValue={montant}
                  onChange={(event) => {
                    setMontant(event.target.value);
                  }}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="prixcotisation">Différence de cotisation</Label>
                <Input
                  type="text"
                  name="diffcotisation"
                  placeholder="Différence de cotisation"
                  value={diffcotisation}
                  onChange={(event) => {
                    setDiffcotisation(event.target.value);
                  }}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
              <CancelButton
                style={{ marginTop: 15 }}
                onClick={(event) => {
                  event.preventDefault();
                  props.hideFormUpdateCotisation();
                }}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
