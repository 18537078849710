import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";

export default function FicheMonCompte(props) {
  const store = useContext(StoreContext);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Informations personnelles</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={props.showUpdateUtilisateurForm}
            >
              Modifier
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table style={{ color: "#FFFFFF" }}>
          <tbody>
            <tr>
              <th scope="row">NOM</th>
              <td>{store.utilisateur.informations.nom}</td>
            </tr>
            <tr>
              <th scope="row">Prénom</th>
              <td>{store.utilisateur.informations.prenom}</td>
            </tr>
            <tr>
              <th scope="row">Adresse</th>
              <td>{store.utilisateur.informations.adresse}</td>
            </tr>
            <tr>
              <th scope="row">Ville</th>
              <td>
                {store.utilisateur.informations.codepostal ?? "-"}{" "}
                {store.utilisateur.informations.nomcommune}
              </td>
            </tr>
            <tr>
              <th scope="row">Numéro de téléphone</th>
              <td>{store.utilisateur.informations.telephone}</td>
            </tr>
            <tr>
              <th scope="row">Adresse e-mail</th>
              <td>{store.utilisateur.informations.mail}</td>
            </tr>
            <tr>
              <th scope="row">Profil</th>
              <td>{store.utilisateur.profilsUtilisateur[0]?.libelleprofil}</td>
            </tr>
            <tr>
              <th scope="row">Structure</th>
              <td>{store.utilisateur.informations.nomorganismestockeur}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
