import { Component } from "react";
import { Button, Col } from "reactstrap";

export default class ButtonCartoAction extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Col
        className="btn-carto-parcelle-action-container"
        style={this.props.style}
      >
        <Button
          color={this.props.color ?? "warning"}
          className="btn-carto-parcelle-action"
          onClick={this.props.onClick}
        >
          {this.props.libelle}
        </Button>
      </Col>
    );
  }
}
