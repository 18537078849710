import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import Analyses from "../Analyses";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment/moment";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Analyses/codesos";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import Grid from "../../components/Grid";

export default function CodesOs() {
  const context = useContext(StoreContext);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMapKeysNames = () => {
    const mapKeysNames = {};
    columns.forEach((col) => (mapKeysNames[col.name] = col.header));
    return mapKeysNames;
  };

  useConstructor(async () => {
    setLoading(true);
    setRows(await AnalysesController.getCodesOs());
    setLoading(false);
  });

  const onEditComplete = async ({ value, columnId, data }) => {
    if (columnId === "codeos") {
      const res = await AnalysesController.updateCodeOs(
        data.idexploitation,
        data.idorganismestockeur,
        value,
      );

      if (res.status === 200) {
        createNotification("success", "Code OS modifié avec succès.");
        const tmp = [...rows];
        tmp.find(
          (r) =>
            r.idexploitation === data.idexploitation &&
            r.idorganismestockeur === data.idorganismestockeur,
        ).codeos = value;
        if (data.idorganismestockeur === 3) {
          tmp.forEach((r) => {
            if (r.idexploitation === data.idexploitation) {
              r.codedc = value;
            }
          });
        }
        setRows(tmp);
      }
    } else if (columnId === "codedc") {
      const res = await AnalysesController.updateCodeDc(
        data.idexploitation,
        value,
      );
      if (res.status === 200) {
        createNotification("success", "Code DC modifié avec succès.");
        const tmp = [...rows];
        tmp.forEach((r) => {
          if (r.idexploitation === data.idexploitation) {
            r.codedc = value;
            if (r.idorganismestockeur === 3) {
              r.codeos = value;
            }
          }
        });
        setRows(tmp);
      }
    }
  };

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>Codes Organisme Stockeur</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={rows.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        const name =
                          "analyses_codes_os_" + moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(rows, ";", getMapKeysNames()),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <Grid
                  enableCellSelect={true}
                  idProperty="id"
                  emptyText="Aucune donnée"
                  columns={columns}
                  dataSource={rows}
                  defaultFilterValue={defaultFilterValue}
                  style={gridStyle}
                  onEditComplete={onEditComplete}
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
