import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import Grid from "../../components/Grid";
import {
  columns,
  defaultFilterValue,
  gridStyle,
  groups,
} from "../../components/Grids/BilanAnnuel/tablebilanannuelexploitation";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";
import PlanProductionEditionController from "../../config/apiUtils/PlanProductionEditionController";
import SiloController from "../../config/apiUtils/SiloController";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import moment from "moment";
import CsvButton from "../../components/Buttons/CsvButton";

export default function TableBilanAnnuelExploitation(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  const getColumnsByName = (name) => {
    return columns.find((c) => c.name === name);
  };

  const mapRoutes = {
    "idsilo": "idsilo",
    "idsilodefinitif": "idsilodefinitif",
    "idrefvariete": "idrefvariete",
    "estconforme": "estconforme",
    "surveillancecolzasanve": "surveillancecolzasanve",
    "exploitation.rendementrefmoyen": "rendementrefmoyen",
    "quantitesemence": "quantitesemence",
    "quantitelivree": "quantitelivree",
  };

  const mapKeysNames = {
    numerocontrat: "Contrat",
    idrefvariete: "Code variété",
    libellevariete: "Variété",
    quantitelivree: "Quantité livrée",
    nomsilodefinitif: "Silo définitif",
    nomorganismestockeur: "Organisme stockeur",
    raisonsociale: "Exploitation",
    siret: "Numéro SIRET",
    codepostal: "Code postal",
    nomcommune: "Commune",
    codedc: "Code DC",
  };

  useConstructor(async () => {
    setLoading(true);
    store.planProduction.setBilanRecolteExploitation(
      await PlanProductionController.getBilanRecolte(
        store.millesime.idmillesime,
      ),
    );
    getColumnsByName("nomsilodefinitif").editorProps.dataSource = (
      await SiloController.getSilos(2, store.millesime.idmillesime, true)
    ).map((silo) => {
      return {
        id: silo.idsilo,
        label: silo.nomsilo,
        nomId: "idsilodefinitif",
      };
    });
    getColumnsByName("nomsilodefinitif").editorProps.dataSource.unshift({
      id: null,
      label: "PAS DE SILO",
      nomId: "idsilodefinitif",
    });
    setLoading(false);
  });

  const onEditComplete = async ({ value, columnId, rowIndex, data }) => {
    // On récupère le dataSource de la colonne (s'il y en a un)
    var dataSourceColumn = [];
    const column = columns.find((column) => column.name === columnId);

    if (column?.editorProps?.dataSource) {
      if (typeof column.editorProps.dataSource?.then === "function") {
        await column.editorProps.dataSource.then(
          (res) => (dataSourceColumn = res),
        );
      } else {
        dataSourceColumn = column.editorProps.dataSource;
      }
    }

    const res = await PlanProductionEditionController.updateField(
      data.idplanproduction,
      mapRoutes[
        dataSourceColumn.length > 0
          ? dataSourceColumn.find((e) => e.id === value).nomId
          : column.name
      ],
      value,
    );

    const nomColonne =
      dataSourceColumn.length > 0
        ? dataSourceColumn.find((e) => e.id === value).nomId
        : column.name;

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      // On récupère la ligne de manière temporaire pour une modification en live de la grid
      const tmp = [...store.planProduction.bilanRecolteExploitation];
      tmp.find((e) => e.idplanproduction === data.idplanproduction)[columnId] =
        dataSourceColumn.length > 0
          ? dataSourceColumn.find((e) => e.id === value).label
          : value;
      tmp.find((e) => e.idplanproduction === data.idplanproduction)[
        nomColonne
      ] = value;

      // On reset le dataSource pour rechargement de la grid
      store.planProduction.setBilanRecolteExploitation(tmp);
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Bilan de la récolte par exploitation
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={
                  store.planProduction.bilanRecolteExploitation.length > 0
                }
              >
                <CsvButton
                  color="primary"
                  style={{ marginRight: 30 }}
                  onClick={() => {
                    downloadCsv(
                      convertToCSV(
                        store.planProduction.bilanRecolteExploitation,
                        ";",
                        mapKeysNames,
                      ),
                      "bilan_recolte_exploitation_" +
                        store.millesime.idmillesime +
                        "_" +
                        moment().format("YYYY-MM-DD"),
                    );
                  }}
                />
              </RenderIf>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <Grid
                  enableCellSelect={true}
                  idProperty="idcontrat"
                  emptyText="Le bilan de récolte est vide"
                  columns={columns}
                  groups={groups}
                  dataSource={store.planProduction.bilanRecolteExploitation}
                  style={gridStyle}
                  onEditComplete={onEditComplete}
                  defaultFilterValue={defaultFilterValue}
                  pagination
                  defaultLimit={100}
                />
              </RenderIf>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
