import BilanAnnuel from "../../views/BilanAnnuel";
import CommandeIndustrielle from "../../views/CommandeIndustrielle";
import IntentionSemis from "../../views/IntentionSemis";
import OrganismeStockeur from "../../views/OrganismeStockeur";
import PlanProduction from "../../views/PlanProduction";
import PlanProductionPrevisionnel from "../../views/PlanProductionPrevisionnel";
import Silos from "../../views/Silos";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";
import ValidationIntentions from "../../views/ValidationIntentions";

const ProductionRoutes = [
  {
    path: "/commande-industrielle",
    name: "Commandes",
    element: <CommandeIndustrielle />,
    restriction: droits.typesdroits.ACCES_COMMANDE,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/plan-production",
    name: "Plan de production",
    element: <PlanProduction />,
    restriction: droits.typesdroits.ACCES_PLAN_PRODUCTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/plan-production-previsionnel",
    name: "Plan de production prévisionnel",
    element: <PlanProductionPrevisionnel />,
    restriction: droits.typesdroits.ACCES_PLAN_PRODUCTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/bilan-annuel",
    name: "Bilan annuel",
    element: <BilanAnnuel />,
    restriction: droits.typesdroits.ACCES_BILAN_RECOLTE,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/intentions-semis",
    name: "Intentions de semis",
    element: <IntentionSemis />,
    restriction: droits.typesdroits.ACCES_INTENTIONS_SEMIS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/validation-intentions-semis",
    name: "Validation des intentions de semis",
    element: <ValidationIntentions />,
    restriction: droits.typesdroits.ACCES_VALIDATION_INTENTION_SEMIS,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/organismes-stockeurs",
    name: "Organismes stockeurs",
    element: <OrganismeStockeur />,
    restriction: droits.typesdroits.ACCES_ORGANISME_STOCKEUR,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/silos",
    name: "Silos",
    element: <Silos />,
    restriction: droits.typesdroits.ACCES_SILO,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
];

export default ProductionRoutes;
