import { Col, Row } from "reactstrap";
import "../assets/css/SpinLoadingAnimation.css";

export default function SpinLoadingAnimation(props) {
  return (
    <Row>
      <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
        <div className="loader-wrapper" style={props.style}>
          <div className="loader">
            <div className="loader loader-inner"></div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
