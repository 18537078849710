import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
} from "../../components/Grids/Exploitation/cotisations";
import StoreContext from "../../context/StoreContext";
import { RenderIf } from "../../config/utils";
import useConstructor from "../../config/hooks/useConstructor";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function CotisationsExploitation() {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [cotisations, setCotisations] = useState([]);

  useConstructor(async () => {
    setLoading(true);
    const contrats = store.contratsExploitation;
    const cotisationsTemp = [];
    contrats.forEach((contrat) => {
      const cotisation = contrat.cotisation.at(-1);
      cotisation.numerocontrat = contrat.numerocontrat;
      cotisationsTemp.push(cotisation);
    });
    setCotisations(cotisationsTemp);
    setLoading(false);
  });

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Cotisations</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf
                isTrue={
                  !loading &&
                  cotisations !== null &&
                  cotisations !== [] &&
                  cotisations !== undefined
                }
              >
                <Grid
                  enableCellSelect={true}
                  idProperty="idcontrat"
                  emptyText={
                    "Aucun contrat renseigné pour " +
                    store.millesime.idmillesime
                  }
                  columns={columns}
                  dataSource={cotisations}
                  style={gridStyle}
                  groups={groups}
                  pagination
                  defaultLimit={50}
                />
              </RenderIf>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
