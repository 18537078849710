import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { dateFilterEditorProps } from "../../../config/utils";

export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 280,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Producteur",
    name: "nomproducteur",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de formation",
    name: "libelletypeformation",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de formation",
    name: "dateformation",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Formation suivie",
    name: "suivi",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ value }) => {
      if (value === true) {
        return "OUI";
      }
      return "NON";
    },
  },
  {
    header: "Commentaire",
    name: "commentaire",
    cellStyle: { textAlign: "center" },
    minWidth: 500,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 700, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "nomproducteur", type: "string", operator: "contains", value: "" },
  {
    name: "libelletypeformation",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "dateformation", type: "date", operator: "after", value: "" },
  { name: "suivi", type: "select", operator: "inlist", value: [] },
  { name: "commentaire", type: "string", operator: "contains", value: "" },
];
