import APIController from "../APIController";

class MillesimeController extends APIController {
  constructor() {
    super("/millesime");
  }

  async getMillesimes() {
    return await this.getRequest("/");
  }

  async getMillesimeCourant() {
    return await this.getRequest("/courant");
  }

  async getDernierMillesimeUtilisateur() {
    return await this.getRequest("/last");
  }

  async changeMillesime(millesime) {
    return await this.putRequest(`/change/${millesime}`);
  }
}

export default new MillesimeController();
