import { useContext, useState } from "react";
import StoreContext from "../../../../context/StoreContext";
import { getQueryParam, RenderIf } from "../../../../config/utils";
import Section from "../Section";
import ButtonCartoAction from "../../buttons/ButtonCartoAction";
import { Collapse, Row } from "reactstrap";
import ModalImportParcellesPrecedentes from "../../modal/ModalImportParcellesPrecedentes";
import TableRecapParcelles from "./TableRecapParcelles";

export default function ImportParcellesPrecedentesSection({
  forceReloadMap,
  parcellesToImport,
}) {
  const context = useContext(StoreContext);
  const [showModalImportParcelle, setShowModalImportParcelle] = useState(
    getQueryParam("importparcellesprecedentes") === "true",
  );

  return (
    <>
      <Collapse isOpen={showModalImportParcelle} mountOnEnter unmountOnExit>
        <ModalImportParcellesPrecedentes
          isOpen={showModalImportParcelle}
          toggle={() => setShowModalImportParcelle(false)}
          parcelles={parcellesToImport}
          forceReloadMap={forceReloadMap}
        />
      </Collapse>
      <Section
        id={"importparcellesprecedentes"}
        title={`Parcelles en ${context.millesime.idmillesime - 1}`}
      >
        <RenderIf isTrue={parcellesToImport.length > 0}>
          <Row>
            <ButtonCartoAction
              libelle={`Importer parcelles depuis ${
                context.millesime.idmillesime - 1
              }`}
              onClick={() => setShowModalImportParcelle(true)}
            />
          </Row>
          <Row>
            <TableRecapParcelles parcelles={parcellesToImport} />
          </Row>
        </RenderIf>
      </Section>
    </>
  );
}
