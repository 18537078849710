import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Formation/formations";

export default function TableFormation(props) {
  const onRowClick = async (rowIdx) => {
    props.onSelectFormation(rowIdx.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des formations</CardTitle>
            <CardSubtitle tag={"h6"}>
              Pour modifier une formation, cliquez sur la ligne associée.
            </CardSubtitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            {props.formations !== null &&
            props.formations !== [] &&
            props.formations !== undefined ? (
              <Grid
                enableCellSelect={true}
                idProperty="idformationproducteur"
                emptyText="Aucune formation renseignée"
                columns={columns}
                dataSource={props.formations}
                style={gridStyle}
                pagination
                defaultLimit={50}
                onRowClick={onRowClick}
                defaultFilterValue={defaultFilterValue}
              />
            ) : (
              <Alert fade={false} color="danger">
                Aucun formation renseignée
              </Alert>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
