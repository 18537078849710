export const columns = [
  {
    header: "Nom de la parcelle",
    name: "nomparcelle",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  },
  {
    header: "Commune",
    name: "nomcommune",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => data.codepostal + " " + data.nomcommune,
  },
  {
    header: "Surface engagée printemps",
    name: "surfaceengageeprintemps",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  },
  {
    header: "Surface engagée automne",
    name: "surfaceengageeautomne",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  },
  {
    header: "Surface engagée totale",
    name: "surfaceengagee",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle printemps",
    name: "surfaceprintemps",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    header: "Surface réelle automne",
    name: "surfaceautomne",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "nomparcelle", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  {
    name: "libelletypesol",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  {
    name: "surfaceengageeprintemps",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfaceengageeautomne",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "surfaceengagee", type: "float", operator: "contains", value: "" },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "quantitelivree", type: "float", operator: "contains", value: "" },
  { name: "idilot", type: "string", operator: "contains", value: "" },
  { name: "surface", type: "string", operator: "contains", value: "" },
];
