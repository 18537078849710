import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { dateFilterEditorProps } from "../../../config/utils";

export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    minWidth: 250,
    defaultFlex: 4,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date d'envoi",
    name: "dateenvoi",
    minWidth: 200,
    defaultFlex: 3,
    suppressSizeToFit: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Date de retour",
    name: "dateretour",
    minWidth: 200,
    defaultFlex: 3,
    suppressSizeToFit: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Convention signée",
    name: "signee",
    minWidth: 160,
    defaultFlex: 3,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "OUI";
      }
      cellProps.style.background = "#f9d7da"; // DANGER
      cellProps.style.color = "#842029"; // DANGER
      return "NON";
    },
  },
  {
    header: "",
    name: "telecharger",
    minWidth: 50,
    defaultFlex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    textAlign: "center",
    filterEditorProps: dateFilterEditorProps,
    render: ({ cellProps }) => {
      cellProps.style.border = "1px solid";
      cellProps.style.background = "#c1aa6c"; // SUCCESS
      cellProps.style.color = "#000000"; // SUCCESS
      return "PDF";
    },
  },
];

export const gridStyle = { minHeight: 700, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "dateenvoi", type: "date", operator: "after", value: "" },
  { name: "dateretour", type: "date", operator: "after", value: "" },
  { name: "signee", type: "select", value: [], operator: "inlist" },
];
