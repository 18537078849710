import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeFormation from "./TypeFormation/ListeTypeFormation";
import FormNewTypeFormation from "./TypeFormation/FormNewTypeFormation";
import FormUpdateTypeFormation from "./TypeFormation/FormUpdateTypeFormation";
import { createNotification } from "../../config/utils";

export default function TypeFormation() {
  const store = useContext(StoreContext);
  const [showFormNewTypeFormation, setShowFormNewTypeFormation] =
    useState(false);
  const [showFormUpdateTypeFormation, setShowFormUpdateTypeFormation] =
    useState(false);
  const [idreftypeformationToUpdate, setIdreftypeformationToUpdate] =
    useState();

  const handleAddNewTypeFormation = async (typeFormation) => {
    const res = await ReferentielController.insertTypeFormation(typeFormation);
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de formation créé avec succès",
      );
      store.referentiels.setTypesFormations([
        ...store.referentiels.typesFormations,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de formation",
      );
    }
  };

  const handleUpdateTypeFormation = async (typeFormation) => {
    const res = await ReferentielController.updateTypeFormation(
      idreftypeformationToUpdate,
      typeFormation,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de formation modifié avec succès",
      );
      store.referentiels.setTypesFormations(
        await ReferentielController.getTypesFormations(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de formation",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeFormation}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeFormation
          onClickAdd={handleAddNewTypeFormation}
          onClickClose={() => setShowFormNewTypeFormation(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeFormation}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeFormation
          onClickUpdate={handleUpdateTypeFormation}
          onClickClose={() => setShowFormUpdateTypeFormation(false)}
          idreftypeformation={idreftypeformationToUpdate}
        />
      </Collapse>
      <ListeTypeFormation
        showFormUpdateTypeFormation={(idreftypeformation) => {
          setIdreftypeformationToUpdate(idreftypeformation);
          setShowFormUpdateTypeFormation(true);
        }}
        onClickNewType={() => setShowFormNewTypeFormation(true)}
      />
    </>
  );
}
