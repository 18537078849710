export const columns = [
  {
    header: "Millésime",
    name: "millesime",
    type: "number",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Montant HT",
    name: "prixcotisation",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value) {
        return value + " € HT";
      }
    },
  },
  {
    header: "Montant TTC",
    name: "cotisationttc",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => {
      if (data.prixcotisation > 0) {
        return (data.prixcotisation * 1.2).toFixed(2) + " € TTC";
      }
    },
  },
  {
    header: "Différence de cotisation",
    name: "diffcotisation",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value) {
        return value + " € TTC";
      }
    },
  },
];

export const gridStyle = { minHeight: 400, color: "#ffffff" };
