import { Col, Row } from "reactstrap";
import FormulaireContact from "./contact/FormulaireContact";

export default function ContactPage() {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Contact</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <FormulaireContact />
        </Col>
      </Row>
    </>
  );
}
