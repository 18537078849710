import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CloseButton from "../../components/Buttons/CloseButton";

const columns = [
  {
    header: "Parcelle",
    name: "nomparcelle",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de sol",
    name: "libelletypesol",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface",
    name: "surface",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

const defaultFilterValue = [
  { name: "nomparcelle", type: "string", operator: "contains", value: "" },
  { name: "libelletypesol", type: "string", operator: "contains", value: "" },
  { name: "surface", type: "string", operator: "contains", value: "" },
];

export default function FormNewParcelle(props) {
  const store = useContext(StoreContext);
  const [parcellesDisponibles, setParcellesDisponibles] = useState([]);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    setParcellesDisponibles(
      await ParcelleController.getParcellesDisponiblesMillesime(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    await props.onClickAdd(rowIdx.data.idparcelle);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Nouvelle parcelle</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.onClickClose} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {!loading && (
              <Grid
                enableCellSelect={true}
                idProperty="idparcelle"
                emptyText="Aucune parcelle disponible"
                columns={columns}
                dataSource={parcellesDisponibles}
                defaultFilterValue={defaultFilterValue}
                style={gridStyle}
                onRowClick={onRowClick}
                pagination
                defaultLimit={50}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
