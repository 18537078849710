import APIController from "../APIController";

class VersionController extends APIController {
  constructor() {
    super("/version");
  }

  async getVersions() {
    return await this.getRequest("/");
  }
}

export default new VersionController();
