import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Collapse, Row } from "reactstrap";
import ListeParcelles from "./parcelles/ListeParcelles";
import FormNewParcelle from "./parcelles/FormNewParcelle";
import StoreContext from "../context/StoreContext";
import ExploitationController from "../config/apiUtils/ExploitationController";
import { createNotification } from "../config/utils";
import layoutRoutes from "../config/layoutRoutes";

export default function Parcelles() {
  const store = useContext(StoreContext);
  const [showFormNewParcelles, setShowFormNewParcelles] = useState(false);

  const onClickNewParcelleExploitation = async (idparcelle) => {
    const res = await ExploitationController.appropriateParcelleExploitation(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
      idparcelle,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle d'exploitation créée avec succès",
      );
      setShowFormNewParcelles(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de la parcelle d'exploitation",
      );
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Parcelles</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="warning" style={{ marginRight: 10 }}>
            <NavLink
              style={{ textDecoration: "none", color: "#000000" }}
              to={`${layoutRoutes.userLayout}/carto/parcellaire-exploitation`}
            >
              Carte
            </NavLink>
          </Button>
        </Col>
      </Row>
      <Collapse
        isOpen={showFormNewParcelles}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewParcelle
          onClickAdd={onClickNewParcelleExploitation}
          onClickClose={() => setShowFormNewParcelles(false)}
        />
      </Collapse>
      <Collapse
        isOpen={!showFormNewParcelles}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row>
          <Col md="12">
            <ListeParcelles
              onClickNewParcelle={() => setShowFormNewParcelles(true)}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
