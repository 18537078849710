import { FormGroup, Label, Input } from "reactstrap";

const FormInputGroup = ({
  id,
  label,
  type,
  placeholder,
  defaultValue,
  onChange,
  children,
  className,
}) => {
  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Input
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        className={className}
      >
        {children}
      </Input>
    </FormGroup>
  );
};

export default FormInputGroup;
