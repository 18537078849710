import AppariementParcelles from "../../views/AppariementParcelles";
import ParcellaireExploitation from "../../views/ParcellaireExploitation";
import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";

const CartoRoutes = [
  {
    path: "/carto/parcellaire-exploitation",
    name: "Cartographie",
    element: <ParcellaireExploitation />,
    restriction: droits.typesdroits.ACCES_PARCELLAIRE_CARTO,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: false,
    pageCarto: true,
    motsCles: ["Réglages", "Liste", "exploitations"],
  },
  {
    path: "/carto/appariement-parcelles",
    name: "Appariement des parcelles",
    element: <AppariementParcelles />,
    restriction: droits.typesdroits.ACCES_APPARIEMENT_PARCELLES_CARTO,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
    pageCarto: true,
    motsCles: ["Cartographie", "Appariement", "parcelles"],
  },
];

export default CartoRoutes;
