import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { RenderIf } from "../../config/utils";
import AuthService from "../../config/AuthService";
import SpinLoadingAnimation from "../SpinLoadingAnimation";

export default function PasswordForgottenCard(props) {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await AuthService.createUserCodeReinitPassword(
      event.target.mail.value,
    );
    await props.onClickValidate(res);
    props.onClickClose();
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="mail">Adresse mail</Label>
              <Input
                type="mail"
                name="mail"
                placeholder="Adresse mail"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" type="submit">
              Envoyer
            </Button>
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button color="danger" onClick={props.onClickClose}>
              Annuler
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <CardHeader
        className="card-header-login"
        style={styles.cardHeader}
        key="loginCardHeader"
      >
        <CardTitle tag="h1">Réinitialisez votre mot de passe</CardTitle>
        <p style={styles.alert}>
          Vous avez oublié votre mot de passe ?
          <br />
          Merci de remplir ce formulaire. Un mail de réinitialisation vous sera
          envoyé
        </p>
      </CardHeader>
      <CardBody style={{ overflowX: "hidden" }}>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>{renderForm()}</RenderIf>
      </CardBody>
    </Card>
  );
}

const styles = {
  card: {
    textAlign: "center",
    padding: 5,
    flexGrow: 1,
    height: "43em",
    maxWidth: "62em",
    flexDirection: "column",
  },
  cardBody: {
    marginTop: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    lineHeight: 1.2,
    padding: "0rem 1rem",
  },
  alert: {
    fontSize: 12,
  },
  textField: {
    height: "1.5rem",
    padding: "0.75rem",
    borderRadius: 15,
    marginBottom: 20,
    fontSize: "1rem",
  },
  form: {
    display: "grid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  logo: {
    paddingTop: 20,
  },
  button: {
    borderStyle: "none",
    fontSize: "1.25rem",
    lineHeight: 1.5,
    padding: "0.5rem 1rem",
    display: "block",
    width: "96%",
    color: "#ffffff",
    backgroundColor: "#495057",
    borderColor: "#007bff",
    borderRadius: 15,
    marginTop: 20,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "96%",
    bottom: 5,
    color: "#ffffff",
    fontSize: 9,
  },
};
