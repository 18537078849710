import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeVariete from "./Variete/ListeVariete";
import FormNewVariete from "./Variete/FormNewVariete";
import FormUpdateVariete from "./Variete/FormUpdateVariete";
import { createNotification } from "../../config/utils";

export default function Variete() {
  const store = useContext(StoreContext);
  const [showFormNewVariete, setShowFormNewVariete] = useState(false);
  const [showFormUpdateVariete, setShowFormUpdateVariete] = useState(false);
  const [idrefvarieteToUpdate, setIdrefvarieteToUpdate] = useState();

  const handleAddNewVariete = async (variete) => {
    const res = await ReferentielController.insertVariete(variete);
    if (res.status === 200) {
      createNotification("success", "Succès", "Variété créée avec succès");
      store.referentiels.setVarietes([
        ...store.referentiels.varietes,
        res.data,
      ]);
    }
  };

  const handleUpdateVariete = async (variete) => {
    const res = await ReferentielController.updateVariete(
      idrefvarieteToUpdate,
      variete,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Variété modifiée avec succès");
      store.referentiels.setVarietes(await ReferentielController.getVarietes());
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewVariete}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewVariete
          onClickAdd={handleAddNewVariete}
          onClickClose={() => setShowFormNewVariete(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateVariete}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateVariete
          onClickUpdate={handleUpdateVariete}
          onClickClose={() => setShowFormUpdateVariete(false)}
          idrefvariete={idrefvarieteToUpdate}
        />
      </Collapse>
      <ListeVariete
        showFormUpdateVariete={(idrefvariete) => {
          setIdrefvarieteToUpdate(idrefvariete);
          setShowFormUpdateVariete(true);
        }}
        onClickNewVariete={() => setShowFormNewVariete(true)}
      />
    </>
  );
}
