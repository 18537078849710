import { Input, Row, Table } from "reactstrap";
import { RenderIf } from "../../../../config/utils";
import { useState } from "react";
import ModalPreviewParcelle from "../../modal/ModalPreviewParcelle";
import ElementParcelle from "./ElementParcelle";

export default function TableRecapParcelles({
  parcelles,
  isModal = false,
  setIdparcelles,
}) {
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [parcelleToPreview, setParcelleToPreview] = useState({});
  const [idparcellesSelected, setIdparcellesSelected] = useState([]);

  const closeModalPreview = () => {
    setShowModalPreview(false);
    setParcelleToPreview({});
  };

  const onClickPreviewParcelle = (parcelle) => {
    setParcelleToPreview(parcelle);
    setShowModalPreview(true);
  };

  const selectAllParcelle = () => {
    setIdparcellesSelected(parcelles.map((parcelle) => parcelle.idparcelle));
    setIdparcelles(parcelles.map((parcelle) => parcelle.idparcelle));
  };

  const unselectAllParcelle = () => {
    setIdparcellesSelected([]);
    setIdparcelles([]);
  };

  const allParcelleSelected = () => {
    return parcelles.every((parcelle) =>
      idparcellesSelected.includes(parcelle.idparcelle),
    );
  };

  const toggleSelectParcelle = (parcelle) => {
    const set = new Set(idparcellesSelected);
    if (set.has(parcelle.idparcelle)) {
      set.delete(parcelle.idparcelle);
    } else {
      set.add(parcelle.idparcelle);
    }
    setIdparcellesSelected(Array.from(set));
    setIdparcelles(Array.from(set));
  };

  const isParcelleSelected = (parcelle) => {
    return idparcellesSelected.find(
      (idparcelle) => idparcelle === parcelle.idparcelle,
    );
  };

  const showDetailParcelles = () => {
    return parcelles.map((parcelle, index) => (
      <ElementParcelle
        parcelle={parcelle}
        key={index}
        index={index}
        toggleSelectParcelle={() => toggleSelectParcelle(parcelle)}
        isParcelleSelected={() => isParcelleSelected(parcelle)}
        onClickPreviewParcelle={() => onClickPreviewParcelle(parcelle)}
        isModal={isModal}
      />
    ));
  };

  const renderHeaderTable = () => {
    return (
      <thead>
        <tr>
          <RenderIf isTrue={isModal}>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              Carte
            </th>
          </RenderIf>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Numéro
          </th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>Nom</th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Type de sol
          </th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Culture
          </th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Commune
          </th>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>
            Surface
          </th>
          <RenderIf isTrue={isModal}>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Input
                type={"checkbox"}
                id={"parcelle-all"}
                name={"parcelle-all"}
                checked={allParcelleSelected()}
                onChange={(event) => {
                  if (event.target.checked) {
                    selectAllParcelle();
                  } else {
                    unselectAllParcelle();
                  }
                }}
              />
            </th>
          </RenderIf>
        </tr>
      </thead>
    );
  };

  const renderBodyTable = () => {
    return <tbody>{showDetailParcelles()}</tbody>;
  };

  return (
    <>
      <RenderIf isTrue={isModal && showModalPreview && parcelleToPreview != {}}>
        <ModalPreviewParcelle
          isOpen={showModalPreview}
          toggle={closeModalPreview}
          parcelle={parcelleToPreview}
        />
      </RenderIf>
      <Row style={{ overflowX: "auto" }}>
        <Table style={{ color: "#FFFFFF" }}>
          {renderHeaderTable()}
          {renderBodyTable()}
        </Table>
      </Row>
    </>
  );
}
