import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/Exploitation/certificationigp";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { createNotification, RenderIf } from "../../config/utils";
import CertificationIgpController from "../../config/apiUtils/CertificationIgpController";

export default function CertificationsIgpExploitations(props) {
  const store = useContext(StoreContext);

  const activerHabilitation = async () => {
    const actif = !store.exploitation.informations.certificationigp.find(
      (c) =>
        c.idexploitation === store.exploitation.informations.idexploitation &&
        c.millesime === store.millesime.idmillesime,
    ).actif;

    const res = await CertificationIgpController.activerHabilitationIgp(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
      actif,
    );

    if (res.status === 200) {
      await store.exploitation.loadDernierDossier();
      createNotification(
        "success",
        "Succès",
        "Habilitation IGP " +
          (actif ? "activée" : "désactivée") +
          " avec succès.",
      );
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const addHabilitation = async () => {
    const habilite = !store.exploitation.informations.certificationigp.find(
      (c) =>
        c.idexploitation === store.exploitation.informations.idexploitation &&
        c.millesime === store.millesime.idmillesime,
    ).habilite;

    /*const res = await CertificationIgpController.activerHabilitationIgp(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
      actif,
    );

    if (res.status === 200) {
      await store.exploitation.loadDernierDossier();
      createNotification(
        "success",
        "Succès",
        "Habilitation IGP " +
          (actif ? "activée" : "désactivée") +
          " avec succès.",
      );
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }*/
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Certifications IGP</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={
                  store.utilisateur.hasDroits(
                    droits.typesdroits.GESTION_CERTIFICATION_IGP,
                    droits.modalite.creation,
                  ) &&
                  !store.exploitation.informations.certificationigp.find(
                    (certification) =>
                      certification.millesime === store.millesime.idmillesime,
                  )
                }
              >
                <Button color="primary" onClick={props.onClickHabilitation}>
                  <i className="fa-solid fa-plus" />
                </Button>
              </RenderIf>
              <RenderIf
                isTrue={
                  store.utilisateur.hasDroits(
                    droits.typesdroits.GESTION_CERTIFICATION_IGP,
                    droits.modalite.modification,
                  ) &&
                  !!store.exploitation.informations.certificationigp.find(
                    (certification) =>
                      certification.millesime === store.millesime.idmillesime,
                  )
                }
              >
                <Button
                  color="primary"
                  onClick={async () => await activerHabilitation()}
                  style={{ marginLeft: 10, fontSize: 10, padding: "0 10px" }}
                >
                  {store.exploitation.informations.certificationigp.find(
                    (certification) =>
                      certification.millesime === store.millesime.idmillesime,
                  )?.actif
                    ? "Désactiver"
                    : "Activer"}{" "}
                  IGP
                </Button>
              </RenderIf>
              <RenderIf
                isTrue={
                  false &&
                  store.utilisateur.hasDroits(
                    droits.typesdroits.GESTION_CERTIFICATION_IGP,
                    droits.modalite.modification,
                  ) &&
                  store.exploitation.informations.certificationigp.find(
                    (certification) =>
                      certification.millesime === store.millesime.idmillesime,
                  )?.habilite
                }
              >
                <Button
                  color="primary"
                  onClick={async () => await addHabilitation()}
                  style={{ fontSize: 10, padding: "0 10px" }}
                >
                  {store.exploitation.informations.certificationigp.find(
                    (certification) =>
                      certification.millesime === store.millesime.idmillesime,
                  )?.habilite
                    ? "Déshabiliter"
                    : "Habiliter"}{" "}
                  IGP
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Grid
                enableCellSelect={true}
                idProperty="idcertificationigp"
                emptyText="Aucune certification IGP renseignée"
                columns={columns}
                dataSource={store.exploitation.informations.certificationigp}
                style={gridStyle}
                pagination
                defaultLimit={50}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
