import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

export const groups = [
  { name: "identite", header: "Identité" },
  { name: "localisation", header: "Localisation" },
  { name: "information", header: "Informations" },
];

export const columns = [
  {
    header: "Nom",
    name: "nom",
    group: "identite",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Prénom",
    name: "prenom",
    group: "identite",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse",
    name: "adresse",
    group: "localisation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    group: "localisation",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    group: "localisation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse mail",
    name: "mail",
    group: "information",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone",
    name: "telephone",
    group: "information",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "État",
    name: "idetatvalidation",
    cellStyle: { textAlign: "center" },
    group: "information",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 1, label: "Non validé" },
        { id: 2, label: "En cours de validation" },
        { id: 3, label: "En attente d'informations supplémentaires" },
        { id: 4, label: "Validé" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      if (value === 1) {
        cellProps.style.background = "#f9d7da"; // DANGER
        cellProps.style.color = "#842029"; // DANGER
        cellProps.style.border = "1px solid";
        return "Non validé";
      }
      if (value === 2) {
        return "En cours de validation";
      }
      if (value === 3) {
        return "En attente d'informations supplémentaires";
      }
      if (value === 4) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        cellProps.style.border = "1px solid";
        return "Validé";
      }
      return "Erreur";
    },
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "nom", type: "string", operator: "contains", value: "" },
  { name: "prenom", type: "string", operator: "contains", value: "" },
  { name: "adresse", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  { name: "mail", type: "string", operator: "contains", value: "" },
  { name: "telephone", type: "string", operator: "contains", value: "" },
  { name: "aboutme", type: "string", operator: "contains", value: "" },
  {
    name: "idetatvalidation",
    type: "select",
    operator: "inlist",
    value: [],
  },
];
