import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
} from "../../components/Grids/Exploitation/contrats";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { RenderIf } from "../../config/utils";
import { useNavigate } from "react-router-dom";

export default function ContratsExploitation(props) {
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const onRowClick = async (rowIdx) => {
    if (
      store.utilisateur.informations.isProducteur &&
      rowIdx.data.nbparcelles - rowIdx.data.nbparcellesrenseignee > 0
    ) {
      navigate(
        `/app/carto/parcellaire-exploitation?section=contrat&contrat=contrat-${rowIdx.data.idcontrat}&completer=true`,
      );
    } else {
      navigate(
        `/app/carto/parcellaire-exploitation?section=contrat&contrat=contrat-${rowIdx.data.idcontrat}`,
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Contrats de production</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits(
                  droits.typesdroits.GESTION_CONTRAT,
                  droits.modalite.creation,
                )}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    navigate(
                      "/app/carto/parcellaire-exploitation?newcontrat=true",
                    );
                  }}
                >
                  <i className="fa-solid fa-plus" />
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Grid
                enableCellSelect={true}
                idProperty="idcontrat"
                emptyText={
                  "Aucun contrat renseigné pour " + store.millesime.idmillesime
                }
                columns={columns}
                dataSource={store.contratsExploitation}
                onRowClick={onRowClick}
                style={gridStyle}
                groups={groups}
                pagination
                defaultLimit={50}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
