import { useContext, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import OrganismeStockeurController from "../config/apiUtils/OrganismeStockeurController";
import PlanProductionController from "../config/apiUtils/PlanProductionController";
import useConstructor from "../config/hooks/useConstructor";
import {
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  GoToTopPage,
  RenderIf,
} from "../config/utils";
import StoreContext from "../context/StoreContext";
import BilanParOganismeStockeur from "./planproductionprevisionnel/BilanParOganismeStockeur";
import ListeParOrganismeStockeur from "./planproductionprevisionnel/ListeParOrganismeStockeur";
import ListeParParcelle from "./planproductionprevisionnel/ListeParParcelle";
import RecapCommandeIndustrielle from "./planproductionprevisionnel/RecapCommandeIndustrielle";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import droits from "../config/CodesDroits";

export default function PlanProductionPrevisionnel() {
  const store = useContext(StoreContext);
  const [isListParParcelleOpen, setIsListParParcelleOpen] = useState(false);
  const [isListParOrganismeStockeurOpen, setIsListParOrganismeStockeurOpen] =
    useState(false);
  const [isBilanParOrganismeStockeurOpen, setIsBilanParOrganismeStockeurOpen] =
    useState(false);
  const [loadingPlanProduction, setLoadingPlanProduction] = useState(true);
  const [surfaceTotale, setSurfaceTotale] = useState(0);

  useConstructor(async () => {
    const resPlanProduction =
      await PlanProductionController.getPlanProductionPrevisionnelMillesime(
        store.millesime.idmillesime,
      );
    store.planProduction.setPlanProductionPrevisionnel(resPlanProduction);
    setLoadingPlanProduction(false);
    const resOrganismesStockeur =
      await OrganismeStockeurController.getOrganismesStockeur();
    store.setOrganismesStockeur(resOrganismesStockeur);

    if (getQueryParam("parcelle") === "true") {
      setIsListParParcelleOpen(true);
    }

    if (getQueryParam("paros") === "true") {
      setIsListParOrganismeStockeurOpen(true);
    }
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Plan de production prévisionnel</h2>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginLeft: 10,
          }}
        >
          {!loadingPlanProduction && (
            <>
              <Button
                style={{ marginLeft: 10 }}
                color="primary"
                onClick={() => {
                  setIsBilanParOrganismeStockeurOpen(true);
                }}
              >
                Bilan par organismes stockeurs
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                color="primary"
                onClick={() => {
                  setQueryParam("paros", "true");
                  setIsListParOrganismeStockeurOpen(true);
                }}
              >
                Liste par organismes stockeurs
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setQueryParam("parcelle", "true");
                  setIsListParParcelleOpen(true);
                }}
              >
                Liste par parcelles
              </Button>
            </>
          )}
        </Col>
      </Row>

      {loadingPlanProduction && <SpinLoadingAnimation />}

      {!loadingPlanProduction && (
        <RenderIf
          isTrue={store.utilisateur.hasDroits(
            droits.typesdroits.GESTION_COMMANDE,
            droits.modalite.creation,
          )}
        >
          <Row style={{ marginBottom: 30 }}>
            <Col md="4">
              <RecapCommandeIndustrielle surfaceTotale={surfaceTotale} />
            </Col>
          </Row>
        </RenderIf>
      )}

      <Collapse isOpen={isListParParcelleOpen} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            {!loadingPlanProduction && (
              <ListeParParcelle
                setSurfaceTotale={setSurfaceTotale}
                dataSource={store.planProduction.planProductionParcelle}
                close={() => {
                  deleteQueryParam("parcelle");
                  setIsListParParcelleOpen(false);
                  GoToTopPage();
                }}
              />
            )}
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={isListParOrganismeStockeurOpen}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <ListeParOrganismeStockeur
              close={() => {
                deleteQueryParam("paros");
                deleteQueryParam("idos");
                setIsListParOrganismeStockeurOpen(false);
                GoToTopPage();
              }}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={isBilanParOrganismeStockeurOpen}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <BilanParOganismeStockeur
              close={() => {
                setIsBilanParOrganismeStockeurOpen(false);
                GoToTopPage();
              }}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
