import APIController from "../APIController";

class ProducteurController extends APIController {
  constructor() {
    super("/producteur");
  }

  async getProducteurByIdexploitation(idexploitation) {
    return await this.getRequest(`/?idexploitation=${idexploitation}`);
  }

  async getProducteurs() {
    return await this.getRequest("/");
  }

  async getAllProducteurs() {
    return await this.getRequest("/all");
  }

  async updateProducteur(idproducteur, producteur) {
    return await this.putRequest(`/${idproducteur}`, producteur);
  }

  async insertProducteur(producteur) {
    return await this.postRequest("/", producteur);
  }
}

export default new ProducteurController();
