import { Col, Row } from "reactstrap";
import TableHistoriqueFormation from "./historiqueformation/TableHistoriqueFormation";
import ActionsGroupees from "../ActionsGroupees";

export default function HistoriqueFormation() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableHistoriqueFormation />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
