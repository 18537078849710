import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import StoreContext from "../../../context/StoreContext";
import { RenderIf } from "../../../config/utils";
import { useNavigate } from "react-router-dom";

export default function InformationMillesime(props) {
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  return (
    <Card style={{ height: 300, maxHeight: 300, marginTop: 10 }}>
      <CardHeader>
        <CardTitle style={{ fontWeight: "bold" }}>
          Information sur {store.millesime.idmillesime}
        </CardTitle>
      </CardHeader>
      <CardBody style={{ overflowY: "auto" }}>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <Table style={{ color: "#FFFFFF" }}>
            <tbody>
              <tr>
                <td>
                  <RenderIf isTrue={store.contratsExploitation.length > 0}>
                    <>
                      Contrat{" "}
                      <b>
                        {Array.isArray(store.contratsExploitation)
                          ? store.contratsExploitation
                              .map((contrat, key) => (
                                <a
                                  key={key}
                                  style={{
                                    cursor: "pointer",
                                    color: contrat.abandon
                                      ? "#ff284e"
                                      : "#95f8cb",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/app/contrat?id=${contrat.idcontrat}`,
                                    )
                                  }
                                >
                                  {contrat.numerocontrat}
                                </a>
                              ))
                              .reduce((prev, curr) => [prev, " | ", curr], null)
                          : ""}
                      </b>
                    </>
                  </RenderIf>
                  <RenderIf isTrue={store.contratsExploitation.length === 0}>
                    <>Pas de contrat en {store.millesime.idmillesime}</>
                  </RenderIf>
                </td>
              </tr>
              <RenderIf
                isTrue={
                  store.contratsExploitation.filter(
                    (contrat) => !contrat.abandon,
                  ).length > 0
                }
              >
                <tr>
                  <td>
                    <>
                      Cotisations :{" "}
                      {(
                        store.contratsExploitation.filter(
                          (contrat) => !contrat.abandon,
                        )[0]?.cotisation[
                          store.contratsExploitation.filter(
                            (contrat) => !contrat.abandon,
                          )[0]?.cotisation.length - 1
                        ]?.prixcotisation * 1.2
                      ).toFixed(2)}
                      € TTC{" "}
                      {store.contratsExploitation.filter(
                        (contrat) => !contrat.abandon,
                      )[0]?.cotisation[
                        store.contratsExploitation.filter(
                          (contrat) => !contrat.abandon,
                        )[0]?.cotisation.length - 1
                      ]?.paye
                        ? "(payée)"
                        : "(non payée)"}
                    </>
                  </td>
                </tr>
              </RenderIf>
              <tr>
                <td>
                  {store.detailContratsExploitation.nbParcelles}
                  {store.detailContratsExploitation.nbParcelles > 1
                    ? " parcelles engagées"
                    : " parcelle engagée"}
                </td>
              </tr>
            </tbody>
          </Table>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
