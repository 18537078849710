import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";

export default function FicheOrganismeStockeur(props) {
  const [organisme, setOrganisme] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrganismeStockeurController.getOrganismesStockeurById(
      props.idorganismestockeurSelected,
    ).then((res) => {
      setOrganisme(res);
      setLoading(false);
    });
  }, [props.idorganismestockeurSelected]);

  const renderInformationProduction = () => {
    if (organisme.informationstockage === undefined) {
      return;
    }
    return organisme.informationstockage.map((info, key) => {
      return (
        <tr key={key}>
          <th scope="row">{info.millesime}</th>
          <td>{info.surface} Ha</td>
        </tr>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Fiche organisme stockeur</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={props.onClickUpdate}
            >
              Modifier
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Row>
            <Col md="8">
              <h5>Informations administratives</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Nom de l'organisme</th>
                    <td>{organisme.nomorganismestockeur}</td>
                  </tr>
                  <tr>
                    <th scope="row">Adresse</th>
                    <td>
                      {organisme.adresse}
                      <br />
                      {organisme.codepostal + " " + organisme.nomcommune}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Adresse mail</th>
                    <td>{organisme.mail ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Téléphone</th>
                    <td>{organisme.telephone ?? "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md="4">
              <h5>Informations de production</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>{renderInformationProduction()}</tbody>
              </Table>
            </Col>
          </Row>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
