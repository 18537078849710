import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import droits from "../../config/CodesDroits";
import { RenderIf, createNotification, downloadFile } from "../../config/utils";
import PdfButton from "../../components/Buttons/PdfButton";
import EditionController from "../../config/apiUtils/EditionController";

export default function ConventionsExploitations(props) {
  const store = useContext(StoreContext);
  const [convention, setConvention] = useState({});

  useEffect(() => {
    if (props.conventions.length > 0) {
      setConvention(props.conventions[0]);
    } else {
      setConvention({});
    }
  }, [props.conventions]);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Conventions</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={
                  store.utilisateur.hasDroits(
                    droits.typesdroits.GESTION_CONVENTION,
                    droits.modalite.creation,
                  ) && props.conventions.length == 0
                }
              >
                <Button color="primary" onClick={props.onClickNewConvention}>
                  <i className="fa-solid fa-plus" />
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ minHeight: 370 }}>
          <Row>
            <Col>
              <RenderIf isTrue={props.conventions.length > 0}>
                <Table style={{ color: "#FFFFFF" }}>
                  <tbody>
                    <tr>
                      <th scope="row">Date d'envoi</th>
                      <td>{convention.dateenvoi}</td>
                    </tr>
                    <tr>
                      <th scope="row">Date de retour</th>
                      <td>{convention.dateretour}</td>
                    </tr>
                    <tr>
                      <th scope="row">Statut</th>
                      <td>
                        <b>
                          {convention.signee ? (
                            <Alert
                              color="success"
                              style={styles.alertStyle}
                              fade={false}
                            >
                              Signée
                            </Alert>
                          ) : (
                            <Alert
                              color="danger"
                              style={styles.alertStyle}
                              fade={false}
                            >
                              Non signée
                            </Alert>
                          )}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Télécharger</th>
                      <td>
                        <PdfButton
                          color="primary"
                          style={{ marginRight: 30 }}
                          onClick={async () => {
                            createNotification(
                              "info",
                              "",
                              "Préparation du document en cours...",
                            );
                            const resDocument =
                              await EditionController.getConvention(
                                convention.idconvention,
                              );
                            downloadFile(
                              resDocument,
                              `convention_${convention.raisonsociale}_${convention.millesime}.pdf`,
                              "application/octet-stream",
                            );
                            createNotification(
                              "success",
                              "",
                              "Convention générée avec succès.",
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </RenderIf>
              <RenderIf isTrue={props.conventions.length <= 0}>
                Aucune convention renseignée pour {store.millesime.idmillesime}
              </RenderIf>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

const styles = {
  alertStyle: {
    height: 12,
    padding: "0rem 1rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: 0,
  },
};
