import APIController from "../APIController";

class CommandeController extends APIController {
  constructor() {
    super("/commande");
  }

  async getCommandes(millesime) {
    return await this.getRequest("/", {
      params: {
        millesime: millesime,
      },
    });
  }

  async updateCommande(idcommande, commande) {
    return await this.putRequest(`/${idcommande}`, commande);
  }

  async addCommande(commande) {
    return await this.postRequest("/", commande);
  }
}

export default new CommandeController();
