import { Button } from "reactstrap";

export default function ValidateButton(props) {
  return (
    <Button
      color="primary"
      type="submit"
      style={props.style}
      onClick={props.onClick}
    >
      <i className="fa fa-check" aria-hidden="true" />
    </Button>
  );
}
