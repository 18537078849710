import { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormNewProduit(props) {
  const store = useContext(StoreContext);
  const onSubmit = (event) => {
    const produit = {
      libelle: event.target.libelle.value,
      unite: event.target.unite.value,
      idreftypeproduit: event.target.idreftypeproduit.value,
      estutilise: true,
    };
    props.onClickAdd(produit);
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  const renderTypesProduitsOption = () => {
    return store.referentiels.typesProduits.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduit}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouveau produit</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="idreftypeproduit">Type de produits</Label>
                <Input
                  name="idreftypeproduit"
                  type="select"
                  className="select-input"
                >
                  {renderTypesProduitsOption()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="libelle">Produit</Label>
                <Input name="libelle" placeholder={"Produit"} type="text" />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="unite">Unité</Label>
                <Input name="unite" placeholder={"Unité"} type="text" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
