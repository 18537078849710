import { useState } from "react";
import { Alert, Button, Col, Collapse, Row } from "reactstrap";
import ExploitationController from "../config/apiUtils/ExploitationController";
import useConstructor from "../config/hooks/useConstructor";
import TableExploitations from "./listeexploitation/TableExploitations";
import FormNewExploitation from "./listeexploitation/FormNewExploitation";
import { createNotification } from "../config/utils";

export default function ListeExploitation() {
  const [showNewExploitationForm, setShowNewExploitationForm] = useState(false);
  const [exploitations, setExploitations] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadExploitations = async () => {
    setLoading(true);
    const resExploitations = await ExploitationController.getAllExploitations(
      "",
      "",
      null,
    );
    setExploitations(resExploitations);
    setLoading(false);
  };

  useConstructor(loadExploitations);

  const handleNewExploitation = async (exploitation) => {
    const res = await ExploitationController.createExploitation(exploitation);
    if (res.status === 200) {
      createNotification("success", "Succès", "Exploitation créée !");
      setShowNewExploitationForm(false);
      await loadExploitations();
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des exploitations</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            color="primary"
            onClick={() => setShowNewExploitationForm(true)}
          >
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={showNewExploitationForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormNewExploitation
              onClickNewExploitation={handleNewExploitation}
              close={() => setShowNewExploitationForm(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={!showNewExploitationForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <TableExploitations
              loading={loading}
              exploitations={exploitations}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
