import { useEffect, useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import FicheFormulaire from "./formulaireutilisateur/FicheFormulaire";
import ListeFormulaireUtilisateur from "./formulaireutilisateur/ListeFormulaireUtilisateur";
import { GoToTopPage, unflatten } from "../config/utils";
import useConstructor from "../config/hooks/useConstructor";
import UtilisateurController from "../config/apiUtils/UtilisateurController";

export default function FormulaireUtilisateur() {
  const [formulaireToEdit, setFormulaireToEdit] = useState({});
  const [showFicheFormulaire, setShowFicheFormulaire] = useState(false);
  const [formulaires, setFormulaires] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadFormulaires = async () => {
    setLoading(true);
    setFormulaires(await UtilisateurController.getFormulairesUtilisateur());
    setLoading(false);
  };

  useEffect(() => {
    if (formulaireToEdit !== {} && showFicheFormulaire) {
      const tmp = formulaires.find(
        (f) =>
          f.idformulaireutilisateur ===
          formulaireToEdit.idformulaireutilisateur,
      );
      setFormulaireToEdit(unflatten(tmp));
    }
  }, [formulaires]);

  const onClickFormulaire = (formulaire) => {
    GoToTopPage();
    setFormulaireToEdit(unflatten(formulaire));
    setShowFicheFormulaire(true);
  };

  const closeFiche = async () => {
    setFormulaireToEdit({});
    setShowFicheFormulaire(false);
    await loadFormulaires();
  };

  useConstructor(async () => {
    await loadFormulaires();
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col md="12">
          <h2>Gestion des demandes de création de comptes</h2>
          <p>
            Cette page permet d'administrer les demandes de création de comptes
            Ecodim.
          </p>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      <Collapse isOpen={showFicheFormulaire} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col>
            <FicheFormulaire
              formulaire={formulaireToEdit}
              onClickClose={closeFiche}
              loadFormulaires={loadFormulaires}
            />
          </Col>
        </Row>
      </Collapse>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <ListeFormulaireUtilisateur
            closeFiche={closeFiche}
            onClickFormulaire={onClickFormulaire}
            formulaires={formulaires}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}
