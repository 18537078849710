import { Col, Row } from "reactstrap";
import TableAttestationLivraisonSemences from "./attestationlivraisonsemences/TableAttestationLivraisonSemences";
import ActionsGroupees from "../ActionsGroupees";

export default function AttestationLivraisonSemences() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableAttestationLivraisonSemences />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
