import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormUpdateTypeProduit(props) {
  const store = useContext(StoreContext);
  const [typeProduit, setTypeProduit] = useState(
    store.referentiels.typesProduits.find(
      (type) => type.idreftypeproduit === props.idreftypeproduit,
    ),
  );
  const [libelle, setLibelle] = useState(typeProduit.libelle);

  const onSubmit = (event) => {
    props.onClickUpdate({ libelle: libelle });
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const types = store.referentiels.typesProduits.find(
      (type) => type.idreftypeproduit === props.idreftypeproduit,
    );
    setTypeProduit(types);
    setLibelle(types.libelle);
  }, [props.idreftypeproduit, store.referentiels.typesProduits]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>{typeProduit.libelle}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label for="libelle">Type de produit</Label>
                <Input
                  name="libelle"
                  placeholder={"Type de produit"}
                  type="text"
                  value={libelle}
                  onChange={(event) => setLibelle(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
