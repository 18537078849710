import APIController from "../APIController";

class SiloController extends APIController {
  constructor() {
    super("/silo");
  }

  async getSilos(idreftypesilo, millesime, onlyActif) {
    return await this.getRequest("/", {
      params: {
        idreftypesilo: idreftypesilo,
        millesime: millesime,
        onlyActif: onlyActif,
      },
    });
  }

  async getSiloById(idsilo, millesime) {
    return await this.getRequest(`/${idsilo}?millesime=${millesime}`);
  }

  async insertSilo(silo) {
    return await this.postRequest("/", silo);
  }

  async updateSilo(idsilo, silo) {
    return await this.putRequest(`/${idsilo}`, silo);
  }

  async activateSilo(idsilo, millesime, actif) {
    return await this.putRequest(
      `/${idsilo}/activation?millesime=${millesime}&actif=${actif}`,
    );
  }
}

export default new SiloController();
