import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import AddButton from "../../../components/Buttons/AddButton";

const columns = [
  {
    header: "Statut",
    name: "libellestatut",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeStatutJuridique(props) {
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setStatutsJuridique(
      await ReferentielController.getStatutsJuridique(),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateStatutJuridique(rowIdx.data.idstatutjuridique);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Statuts juridiques</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={props.onClickNewType} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col
            style={loading ? { display: "flex", justifyContent: "center" } : {}}
          >
            {loading && <SpinLoadingAnimation />}
            {store.referentiels.statutsJuridique !== null &&
              store.referentiels.statutsJuridique !== [] &&
              store.referentiels.statutsJuridique !== undefined &&
              !loading && (
                <Grid
                  enableCellSelect={true}
                  idProperty="idstatutjuridique"
                  emptyText="Aucun statut juridique renseigné"
                  columns={columns}
                  dataSource={store.referentiels.statutsJuridique}
                  style={gridStyle}
                  onRowClick={onRowClick}
                  pagination
                  defaultLimit={20}
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
