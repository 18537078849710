import { Col, Row } from "reactstrap";
import ActionsGroupees from "../ActionsGroupees";
import TablePreAvertissement from "./preavertissements/TablePreAvertissement";

export default function PreAvertissements() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TablePreAvertissement />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
