import APIController from "../APIController";

class EditionController extends APIController {
  constructor() {
    super("/edition");
  }

  async getFicheIdentificationRecolte(idexploitation, millesime) {
    return await this.getRequest(
      `/ficheidentificationrecolte?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getAttestationLivraisonSemences(idexploitation, millesime) {
    return await this.getRequest(
      `/attestationlivraisonsemence?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getAttestationPrix(idexploitation, millesime) {
    return await this.getRequest(
      `/attestationprix?idexploitation=${idexploitation}&millesime=${millesime}`,
      {
        responseType: "blob",
      },
    );
  }

  async getContrat(idcontrat) {
    return await this.getRequest(`/contrat?idcontrat=${idcontrat}`, {
      responseType: "blob",
    });
  }

  async getAnnexe(idannexe) {
    return await this.getRequest(`/annexe?idannexe=${idannexe}`, {
      responseType: "blob",
    });
  }

  async getContrats(idcontrats) {
    let params = "";
    idcontrats.forEach((idcontrat) => (params += `idcontrats=${idcontrat}&`));
    params.slice(0, -1);
    return await this.getRequest(`/contrats?${params}`, {
      responseType: "blob",
    });
  }

  async getConvention(idconvention) {
    return await this.getRequest(`/convention?idconvention=${idconvention}`, {
      responseType: "blob",
    });
  }

  async getConventions(idconventions) {
    let params = "";
    idconventions.forEach(
      (idconvention) => (params += `idconventions=${idconvention}&`),
    );
    params.slice(0, -1);
    return await this.getRequest(`/conventions?${params}`, {
      responseType: "blob",
    });
  }
}

export default new EditionController();
