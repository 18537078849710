import APIController from "../APIController";

class ZoneProductionController extends APIController {
  constructor() {
    super("/zoneproduction");
  }

  async getZonesProduction() {
    return await this.getRequest("/");
  }

  async updateZoneProduction(iderefzoneproduction, zoneProduction) {
    return await this.putRequest(`/${iderefzoneproduction}`, zoneProduction);
  }

  async insertZoneProduction(zoneProduction) {
    return await this.postRequest("/", zoneProduction);
  }
}

export default new ZoneProductionController();
