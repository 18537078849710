import APIController from "../APIController";

class CiviliteController extends APIController {
  constructor() {
    super("/civilite");
  }

  async getCivilites() {
    return await this.getRequest("/");
  }
}

export default new CiviliteController();
