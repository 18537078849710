export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Année d'adhésion",
    name: "millesimeadhesion",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "codedc",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse",
    name: "adresse",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Mail",
    name: "mail",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone portable",
    name: "telephoneportable",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone fixe",
    name: "telephonefixe",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Zone de production",
    name: "libellezoneproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variete",
    name: "libellevariete",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Silo",
    name: "nomsilo",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };
