import { useState, useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import MentionsLegales from "./dashboard/MentionsLegales";
import ProducteurWidget from "./dashboard/widgets/ProducteursWidget";
import SurfaceEngageeWidget from "./dashboard/widgets/SurfaceEngageeWidget";
import InformationsExploitationWidget from "./dashboard/widgets/InformationsExploitationWidget";
import InformationMillesime from "./dashboard/widgets/InformationMillesime";
import StoreContext from "../context/StoreContext";
import droits from "../config/CodesDroits";
import { createNotification, RenderIf } from "../config/utils";
import MesDocuments from "./documentexploitation/MesDocuments";
import WidgetCarto from "./dashboard/widgets/WidgetCarto";
import ExploitationController from "../config/apiUtils/ExploitationController";
import PlanProductionController from "../config/apiUtils/PlanProductionController";

export default function Dashboard() {
  const context = useContext(StoreContext);
  const [showMentionsLegales, setShowMentionsLegales] = useState(true);
  const [hasShownMessage, setHasShownMessage] = useState(false);
  const [mdWidget, setMdWidget] = useState(3);
  const [loading, setLoading] = useState(false);

  const flex = "300px";

  useEffect(() => {
    var nbWidget = 4;
    setMdWidget(12 / nbWidget);
  }, [context.utilisateur]);

  const closeMentionsLegales = () => {
    setShowMentionsLegales(false);
    localStorage.setItem("hideMentionsLegales", true);
  };

  const loadData = async () => {
    setLoading(true);
    if (
      context.utilisateur.hasDroits(
        droits.typesdroits.GESTION_CONTRAT,
        droits.modalite.lecture,
      )
    ) {
      const resContratsExploitation =
        await ExploitationController.getContratExploitation(
          context.millesime.idmillesime,
          context.exploitation.informations.idexploitation,
        );

      context.setContratsExploitation(resContratsExploitation);

      if (resContratsExploitation.length === 0) {
        context.detailContratsExploitation.setNbParcelles(0);
        context.detailContratsExploitation.setDetails([]);
      } else {
        const details = await PlanProductionController.getDetailsContrats(
          resContratsExploitation
            .filter((c) => !c.abandon)
            .map((c) => c.idcontrat),
        );
        context.detailContratsExploitation.setNbParcelles(details.length);
        context.detailContratsExploitation.setDetails(details);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dynamicRenderOfWidgets = (
    WidgetComponent,
    hasDroitsType,
    hasDroitsModalite,
  ) => {
    return (
      <RenderIf
        isTrue={
          context.utilisateur.hasDroits(hasDroitsType, hasDroitsModalite) &&
          context.exploitation.informations.idexploitation != -1
        }
      >
        <Col style={{ marginBottom: 30, flex: "1 1 " + flex }}>
          <WidgetComponent loading={loading} />
        </Col>
      </RenderIf>
    );
  };

  useEffect(() => {
    if (context.utilisateur.informations?.isProducteur && !hasShownMessage) {
      setHasShownMessage(true);
      createNotification(
        "info",
        "Informations",
        "Pour créer vos intentions de semis et vos contrats, cliquez sur le bouton 'Ma carto' dans la carte en haut à gauche de l'écran.",
        60000,
      );
    }
  });

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <h2>Tableau de bord</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Col style={{ marginBottom: 30, flex: "1 1 800px" }}>
              <WidgetCarto />
            </Col>
            {dynamicRenderOfWidgets(
              InformationsExploitationWidget,
              droits.typesdroits.GESTION_EXPLOITATION,
              droits.modalite.lecture,
            )}
            {dynamicRenderOfWidgets(
              InformationMillesime,
              droits.typesdroits.GESTION_CONTRAT,
              droits.modalite.lecture,
            )}
            {dynamicRenderOfWidgets(
              ProducteurWidget,
              droits.typesdroits.GESTION_PRODUCTEUR,
              droits.modalite.lecture,
            )}
            {dynamicRenderOfWidgets(
              SurfaceEngageeWidget,
              droits.typesdroits.GESTION_CONTRAT,
              droits.modalite.lecture,
            )}
            <Col style={{ marginBottom: 30, flex: "1 1 800px" }}>
              <MesDocuments hideHeader={true} isDashboard={true} />
            </Col>
          </Row>
        </Col>
      </Row>
      <RenderIf isTrue={showMentionsLegales}>
        <Row>
          <Col>
            <RenderIf
              isTrue={localStorage.getItem("hideMentionsLegales") != "true"}
            >
              <MentionsLegales closeMentionsLegales={closeMentionsLegales} />
            </RenderIf>
          </Col>
        </Row>
      </RenderIf>
    </>
  );
}
