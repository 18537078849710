import { useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import Cultures from "./referentiels/Cultures";
import Materiel from "./referentiels/Materiel";
import Produit from "./referentiels/Produit";
import StatutJuridique from "./referentiels/StatutJuridique";
import TypeFormation from "./referentiels/TypeFormation";
import TypeIntervention from "./referentiels/TypeIntervention";
import TypeMateriel from "./referentiels/TypeMateriel";
import TypeMatiereActive from "./referentiels/TypeMatiereActive";
import TypeProduction from "./referentiels/TypeProduction";
import TypeProduit from "./referentiels/TypeProduit";
import TypeSilo from "./referentiels/TypeSilo";
import TypeSol from "./referentiels/TypeSol";
import TypeVariete from "./referentiels/TypeVariete";
import Variete from "./referentiels/Variete";
import ZoneProduction from "./referentiels/ZoneProduction";
import {
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
} from "../config/utils";
import useConstructor from "../config/hooks/useConstructor";
import TypeDocument from "./referentiels/TypeDocument";
import { colourStyles } from "../config/carto/utils";
import Select from "react-select";

export default function Referentiels() {
  const [referentielToShow, setReferentielToShow] = useState("-1");
  const [referentiels] = useState([
    {
      key: "-1",
      value: "--",
      component: (
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <CardBody>
              Sélectionnez le référentiel sur lequel vous souhaitez travailler.
            </CardBody>
          </Card>
        </Col>
      ),
    },
    {
      key: "1",
      value: "Cultures",
      component: <Cultures />,
    },
    {
      key: "2",
      value: "Matériels",
      component: <Materiel />,
    },
    {
      key: "3",
      value: "Produits",
      component: <Produit />,
    },
    {
      key: "4",
      value: "Statuts juridiques",
      component: <StatutJuridique />,
    },
    {
      key: "5",
      value: "Types d'intervention",
      component: <TypeIntervention />,
    },
    {
      key: "6",
      value: "Types de document",
      component: <TypeDocument />,
    },
    {
      key: "7",
      value: "Types de formation",
      component: <TypeFormation />,
    },
    {
      key: "8",
      value: "Types de matériel",
      component: <TypeMateriel />,
    },
    {
      key: "9",
      value: "Types de matières actives",
      component: <TypeMatiereActive />,
    },
    {
      key: "10",
      value: "Types de production",
      component: <TypeProduction />,
    },
    {
      key: "11",
      value: "Types de produit",
      component: <TypeProduit />,
    },
    {
      key: "12",
      value: "Types de silo",
      component: <TypeSilo />,
    },
    {
      key: "13",
      value: "Types de sol",
      component: <TypeSol />,
    },
    {
      key: "14",
      value: "Types de variété",
      component: <TypeVariete />,
    },
    {
      key: "15",
      value: "Variétés",
      component: <Variete />,
    },
    {
      key: "16",
      value: "Zones de production",
      component: <ZoneProduction />,
    },
  ]);

  useConstructor(() => {
    if (getQueryParam("referentiel")) {
      setReferentielToShow(getQueryParam("referentiel"));
    }
  });

  const renderReferentiel = () => {
    return referentiels.find(
      (referentiel) => referentiel.key === referentielToShow,
    ).component;
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Référentiels</h2>
        </Col>
        <Col md="3" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div style={{ minWidth: 300 }}>
            <Select
              isClearable
              classNamePrefix="react-select"
              placeholder="Référentiel..."
              menuPortalTarget={document.body}
              autosize={false}
              menuPlacement={"auto"}
              menuPosition={"fixed"}
              styles={colourStyles}
              options={[
                ...referentiels.map((r) => ({
                  value: r.key,
                  label: r.value,
                })),
              ]}
              defaultValue={{
                value: getQueryParam("referentiel") ?? "-1",
                label: getQueryParam("referentiel")
                  ? referentiels.find(
                      (referentiel) =>
                        referentiel.key === getQueryParam("referentiel"),
                    ).value
                  : "--",
              }}
              onChange={(selection) => {
                if (selection == null) {
                  deleteQueryParam("referentiel");
                  setReferentielToShow("-1");
                  return;
                }
                if (selection.value === "-1") {
                  deleteQueryParam("referentiel");
                } else {
                  setQueryParam("referentiel", selection.value);
                }
                setReferentielToShow(selection.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" style={{ marginBottom: 30 }}>
          {renderReferentiel()}
        </Col>
      </Row>
    </>
  );
}
