import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import ProducteurController from "../../config/apiUtils/ProducteurController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";

export default function FormFormationProducteur(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [exploitations, setExploitations] = useState([]);
  const [idexploitation, setIdexploitation] = useState();
  const [producteurs, setProducteurs] = useState([]);

  const onSubmit = (event) => {
    const formation = {
      idreftypeformation: event.target.idreftypeformation.value,
      idproducteur: event.target.idproducteur.value,
      commentaire: event.target.commentaire.value,
      dateformation: event.target.dateformation.value,
      suivi: event.target.suivi.value,
      millesime: props.millesime,
    };
    props.onClickSaveNewFormation(formation);
    event.preventDefault();
    props.hideFormNewFormation();

    event.target.reset();
  };

  const renderProducteurOptions = () => {
    if (producteurs.length > 0) {
      return producteurs.map((producteur, key) => {
        return (
          <option key={key} value={producteur.idproducteur}>
            {producteur.nom} {producteur.prenom}
          </option>
        );
      });
    }
  };

  const renderTypesFormationOptions = () => {
    if (store.referentiels.typesFormations != null) {
      return store.referentiels.typesFormations.map((typeFormation, key) => {
        return (
          <option key={key} value={typeFormation.idreftypeformation}>
            {typeFormation.libelle}
          </option>
        );
      });
    }
  };

  const renderExploitationsOptions = () => {
    if (exploitations.length > 0) {
      return exploitations.map((exploitation, key) => {
        return (
          <option key={key} value={exploitation.idexploitation}>
            {exploitation.raisonsociale}
          </option>
        );
      });
    }
  };

  useEffect(() => {
    if (idexploitation) {
      ProducteurController.getProducteurByIdexploitation(idexploitation).then(
        (res) => {
          setProducteurs(res);
        },
      );
    }
  }, [idexploitation]);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setTypesFormations(
      await ReferentielController.getTypesFormations(),
    );

    const resExploitations = await ExploitationController.getExploitations();
    setExploitations(resExploitations);
    setIdexploitation(resExploitations[0].idexploitation);
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Nouvelle formation sur {props.millesime}
        </CardTitle>
        <CloseButton onClick={props.hideFormNewFormation} />
        <CardSubtitle>
          Pour ajouter une nouvelle formation au système de gestion, remplissez
          les informations suivantes. Tous les champs étoilés sont requis.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="idreftypeformation">Type de formation *</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idreftypeformation"
                  >
                    {renderTypesFormationOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="dateformation">Date de la formation *</Label>
                  <Input
                    type="date"
                    name="dateformation"
                    defaultValue={moment().format("YYYY-MM-DD")}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="4">
                <FormGroup>
                  <Label for="idexploitation">Exploitation *</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idexploitation"
                    value={idexploitation}
                    onChange={(event) => setIdexploitation(event.target.value)}
                  >
                    {renderExploitationsOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="idproducteur">Producteur *</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idproducteur"
                    disabled={producteurs.length === 0}
                  >
                    {renderProducteurOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="suivi">Formation suivie *</Label>
                  <Input type="select" className="select-input" name="suivi">
                    <option value={true}>OUI</option>
                    <option value={false}>NON</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md="12">
                <FormGroup>
                  <Label for="commentaire">Commentaire sur la formation</Label>
                  <Input
                    type="textarea"
                    name="commentaire"
                    placeholder="Commentaires relatifs à la formation"
                    defaultValue=""
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Button style={{ marginTop: 15 }} color="primary">
              Valider
            </Button>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
