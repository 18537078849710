import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { RenderIf, createNotification } from "../../config/utils";
import { useContext, useState } from "react";
import ContratController from "../../config/apiUtils/ContratController";
import CancelButton from "../../components/Buttons/CancelButton";
import StoreContext from "../../context/StoreContext";

export default function FicheAnnexe(props) {
  const store = useContext(StoreContext);
  const [showModalConfirmValidation, setShowModalConfirmValidation] =
    useState(false);

  const onClickSubmitValider = async () => {
    const res = await ContratController.validerAnnexe(
      props.annexe?.idcontrat,
      props.annexe?.idannexe,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Annexe validée par l'APGMB avec succès.",
        "",
      );
      props.onClickClose();
    } else {
      createNotification(
        "error",
        "Une erreur est survenue",
        "Une erreur est survenue lors de la validation de l'annexe...",
      );
    }
    setShowModalConfirmValidation(false);
  };

  const renderModalConfirmValidation = () => {
    return (
      <Modal
        isOpen={showModalConfirmValidation}
        toggle={() =>
          setShowModalConfirmValidation(!showModalConfirmValidation)
        }
        autoFocus={false}
      >
        <ModalHeader className="modal-header-form">
          Validation d'annexe
        </ModalHeader>
        <ModalBody className="modal-body-form">
          Vous êtes sur le point de valider l'annexe n°
          {props.annexe?.numeroannexe}. Confirmez-vous cette validation ?
          <br />
          <br />
          <b>
            En confirmant la validation de cette annexe, toutes les
            modifications apportées par cette dernière seront automatiquement
            appliquées à la plateforme.
          </b>
        </ModalBody>
        <ModalFooter className="modal-footer-form">
          <Button
            autoFocus={true}
            color="primary"
            onClick={onClickSubmitValider}
          >
            Confirmer
          </Button>
          <CancelButton onClick={() => setShowModalConfirmValidation(false)} />
        </ModalFooter>
      </Modal>
    );
  };

  const renderParcellesAjoutees = () => {
    return props.annexe?.parcelles.map((parcelle, key) => {
      if (parcelle.ajoutparcelle) {
        return (
          <tr key={key}>
            <th scope="row">{parcelle.newnomparcelle}</th>
            <td>{parcelle.newsurfaceprintemps + " Ha au printemps"}</td>
            <td>{parcelle.newsurfaceautomne + " Ha à l'automne"}</td>
          </tr>
        );
      }
    });
  };

  const renderParcellesModifiees = () => {
    return props.annexe?.parcelles.map((parcelle, key) => {
      if (parcelle.modificationparcelle) {
        return (
          <tr key={key}>
            <th scope="row">{parcelle.newnomparcelle}</th>
            <td>
              {parcelle.oldsurfaceprintemps +
                " Ha → " +
                parcelle.newsurfaceprintemps +
                " Ha au printemps"}
            </td>
            <td>
              {parcelle.oldsurfaceautomne +
                " Ha → " +
                parcelle.newsurfaceautomne +
                " Ha à l'automne"}
            </td>
          </tr>
        );
      }
    });
  };

  const renderParcellesSupprimees = () => {
    return props.annexe?.parcelles.map((parcelle, key) => {
      if (parcelle.suppressionparcelle) {
        return (
          <tr key={key}>
            <th scope="row">{parcelle.newnomparcelle}</th>
            <td>{parcelle.oldsurfaceprintemps + " Ha → 0 Ha au printemps"}</td>
            <td>{parcelle.oldsurfaceautomne + " Ha → 0 Ha à l'automne"}</td>
          </tr>
        );
      }
    });
  };

  return (
    <>
      {renderModalConfirmValidation()}
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <h3>
            Annexe n°{props.annexe?.numeroannexe} - {props.annexe?.dateannexe}
          </h3>
        </Col>
      </Row>
      <RenderIf
        isTrue={
          props.annexe?.changementadresse || props.annexe?.changementstatut
        }
      >
        <Row style={{ marginBottom: 10 }}>
          <RenderIf isTrue={props.annexe?.changementadresse}>
            <Col>
              <h5>Changement d'adresse</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Ancienne adresse</th>
                    <td>
                      {props.annexe?.oldadresse +
                        " - " +
                        props.annexe?.oldcodepostal +
                        " " +
                        props.annexe?.oldnomcommune}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Nouvelle adresse</th>
                    <td>
                      {props.annexe?.newadresse +
                        " - " +
                        props.annexe?.newcodepostal +
                        " " +
                        props.annexe?.newnomcommune}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </RenderIf>
          <RenderIf isTrue={props.annexe?.changementstatut}>
            <Col>
              <h5>Changement de statut juridique</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Ancien statut</th>
                    <td>{props.annexe?.oldlibellestatut}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nouveau statut</th>
                    <td>{props.annexe?.newlibellestatut}</td>
                  </tr>
                  <tr>
                    <th scope="row">Ancien SIRET</th>
                    <td>{props.annexe?.oldsiret}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nouveau SIRET</th>
                    <td>{props.annexe?.newsiret}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </RenderIf>
        </Row>
      </RenderIf>
      <RenderIf isTrue={props.annexe?.pertelabel || props.annexe?.perterecolte}>
        <Row style={{ marginBottom: 10 }}>
          <RenderIf isTrue={props.annexe?.pertelabel}>
            <Col>
              <h5>Perte de Label</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Perte du label</th>
                    <td>{props.annexe?.pertelabel ? "OUI" : "NON"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Description de l'écart</th>
                    <td>{props.annexe?.descriptionpertelabel}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </RenderIf>
          <RenderIf isTrue={props.annexe?.perterecolte}>
            <Col>
              <h5>Perte de récolte</h5>
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Perte de récolte</th>
                    <td>{props.annexe?.perterecolte ? "OUI" : "NON"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Récoltes impactés</th>
                    <td>{props.annexe?.descriptionperterecolte}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </RenderIf>
        </Row>
      </RenderIf>
      <RenderIf
        isTrue={
          props.annexe?.ajoutparcelles ||
          props.annexe?.modificationparcelles ||
          props.annexe?.suppressionparcelles
        }
      >
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <RenderIf isTrue={props.annexe?.ajoutparcelles}>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <h5>Parcelles ajoutées</h5>
                  <Table
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid",
                    }}
                    striped
                  >
                    <tbody>{renderParcellesAjoutees()}</tbody>
                  </Table>
                </Col>
              </Row>
            </RenderIf>
            <RenderIf isTrue={props.annexe?.modificationparcelles}>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <h5>Parcelles modifiées</h5>
                  <Table
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid",
                    }}
                    striped
                  >
                    <tbody>{renderParcellesModifiees()}</tbody>
                  </Table>
                </Col>
              </Row>
            </RenderIf>
            <RenderIf isTrue={props.annexe?.suppressionparcelles}>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <h5>Parcelles supprimées</h5>
                  <Table
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid",
                    }}
                    striped
                  >
                    <tbody>{renderParcellesSupprimees()}</tbody>
                  </Table>
                </Col>
              </Row>
            </RenderIf>
          </Col>
        </Row>
      </RenderIf>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <h5>Etat de l'annexe</h5>
          <Table
            style={{
              color: "#FFFFFF",
            }}
          >
            <tbody>
              <tr>
                <th scope="row">Statut</th>
                <td>
                  {props.annexe?.validationapgmb
                    ? "Validée le " +
                      props.annexe?.datevalidation +
                      " par l'APGMB"
                    : "Non validée, en attente de validation par l'APGMB"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Button onClick={props.onClickClose}>Retour</Button>
          <RenderIf
            isTrue={
              !props.annexe?.validationapgmb &&
              !store.utilisateur.informations.isProducteur
            }
          >
            <Button
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setShowModalConfirmValidation(true);
              }}
            >
              Valider l'annexe
            </Button>
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
