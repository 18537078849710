import APIController from "../APIController";

class CartoController extends APIController {
  constructor() {
    super("/carto");
  }

  async getLayers() {
    return await this.getRequest("/layer");
  }

  async getLayersCatalogue(codecatalogue) {
    return await this.getRequest(`/catalogue/${codecatalogue}`);
  }
}

export default new CartoController();
