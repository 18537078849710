import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import useConstructor from "../../config/hooks/useConstructor";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import CommuneInput from "../../components/CommuneInput";

export default function FormNewSilo(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    store.referentiels.setTypesSilo(await ReferentielController.getTypesSilo());
    setLoading(false);
  });

  const onSubmit = (event) => {
    event.preventDefault();
    const form = {
      idcommune: event.target.idcommune.value,
      idorganismestockeur: event.target.idorganismestockeur.value,
      millesime: store.millesime.idmillesime,
      actif: event.target.actif.value,
      nomsilo: event.target.nomsilo.value,
      idreftypesilo: event.target.idreftypesilo.value,
    };
    props.onClickNewSilo(form);
    event.target.reset();
  };

  const renderTypesSiloOptions = () => {
    return store.referentiels.typesSilo.map((type, key) => {
      return (
        <option value={type.idreftypesilo} key={key}>
          {type.libelle}
        </option>
      );
    });
  };

  const renderOrganismesStockeurOptions = () => {
    return store.organismesStockeur.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Nouveau silo</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.onClickClose} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="4">
                <Label for="nomsilo">Nom du silo</Label>
                <FormGroup>
                  <Input type="text" name="nomsilo" required />
                </FormGroup>
              </Col>
              <Col md="8">
                <CommuneInput
                  codepostal={""}
                  setIdCommune={() => {}}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label for="idreftypesilo">Type de silo</Label>
                <Input
                  name="idreftypesilo"
                  type="select"
                  className="select-input"
                  required
                >
                  {renderTypesSiloOptions()}
                </Input>
              </Col>
              <Col md="4">
                <Label for="idorganismestockeur">Organisme stockeur</Label>
                <Input
                  name="idorganismestockeur"
                  type="select"
                  className="select-input"
                  required
                >
                  {renderOrganismesStockeurOptions()}
                </Input>
              </Col>
              <Col md="4">
                <Label for="actif">
                  Actif en {store.millesime.idmillesime}
                </Label>
                <Input
                  name="actif"
                  type="select"
                  className="select-input"
                  required
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <ValidateButton
                  style={{ marginTop: 15, marginRight: 15 }}
                  type="submit"
                />
                <CancelButton
                  style={{ marginTop: 15 }}
                  onClick={(event) => {
                    event.preventDefault();
                    props.onClickClose();
                  }}
                />
              </Col>
            </Row>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
