import { useState, useEffect, useContext } from "react";
import Map from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import { Zoom, ScaleLine } from "ol/control";
import Overlay from "ol/Overlay";
import { setProj4 } from "../../config/MapValueConverter";
import { getTileLayerXYZ } from "../../config/carto/utils";
import { Geolocation } from "ol";
import Feature from "ol/Feature";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import StoreContext from "../../context/StoreContext";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

export default function MapWrapper(props) {
  setProj4();
  const context = useContext(StoreContext);

  const [map, setMap] = useState();
  const [onMapClickLoaded, setOnMapClickLoaded] = useState(false);

  if (props.mapRef) {
    props.mapRef.current = map;
  } else {
    context.carto.mapRef.current = map;
  }

  const view = new View({
    projection: "EPSG:2154",
    center: transform([5.109111, 47.236013], "EPSG:4326", "EPSG:2154"),
    zoom: 13,
  });

  const accuracyFeature = new Feature();
  accuracyFeature.setId("accuracy");
  const positionFeature = new Feature();
  positionFeature.setId("position");

  const geolocationLayer = new VectorLayer({
    source: new VectorSource({
      features: [accuracyFeature, positionFeature],
    }),
    id: "GEOLOCATION",
  });

  const geolocation = new Geolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
      enableHighAccuracy: true,
    },
    projection: view.getProjection(),
  });
  geolocation.setTracking(context.carto.geolocationEnabled);

  geolocation.on("change:accuracyGeometry", function () {
    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
  });
  geolocation.on("change:position", function () {
    const coordinates = geolocation.getPosition();
    positionFeature.setGeometry(
      coordinates ? new Point(coordinates) : positionFeature.getGeometry(),
    );
  });

  // initialize map on first render - logic formerly put into componentDidMount
  useEffect(() => {
    // create map
    const initialMap = new Map({
      target: props.mapRef
        ? props.mapRef.current
        : context.carto.mapRef.current,
      layers: [
        ...(props.layers ?? [
          getTileLayerXYZ(
            "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&STYLE=normal&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&FORMAT=image/jpeg",
          ),
        ]),
        geolocationLayer,
      ],
      view: view,
      controls: [],
    });

    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: "#3399CC",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      }),
    );

    if (props.showScaleline) {
      initialMap.addControl(
        new ScaleLine({
          units: ["metric"],
          target: document.getElementById("scaleline-metric"),
        }),
      );
    }
    if (props.showZoomControl) {
      initialMap.addControl(
        new Zoom({
          className: "ol-zoom",
        }),
      );
    }

    const popupOverlay = new Overlay({
      element: document.getElementById("popup-carto"),
      id: "popup-carto",
    });

    // save map and vector layer references to state
    initialMap.addOverlay(popupOverlay);
    setMap(initialMap);

    return () => initialMap.setTarget(null);
  }, []);

  useEffect(() => {
    geolocation.setTracking(context.carto.geolocationEnabled);
  }, [context.carto.geolocationEnabled]);

  useEffect(() => {
    geolocationLayer
      .getSource()
      .getFeatures()
      .forEach((f) => geolocationLayer.getSource().removeFeature(f));
    geolocationLayer
      .getSource()
      .addFeatures([positionFeature, accuracyFeature]);

    if (map != null) {
      const layers = [...map.getLayers().getArray(), geolocationLayer];
      layers.forEach((layer) => map.removeLayer(layer));
      props.layers.forEach((layer) => map.addLayer(layer));
      map.addLayer(geolocationLayer);
    }
  }, [props.layers]);

  useEffect(() => {
    if (map != null) {
      if (!onMapClickLoaded) {
        map.un("click", props.onMapClick);
        map.on("click", props.onMapClick);
        setOnMapClickLoaded(true);
      }
    }
  });

  return (
    <>
      <div
        ref={props.mapRef ?? context.carto.mapRef}
        style={{ height: "100%" }}
        className={
          "map-container" + (props.pageCarto ? " map-container-page" : "")
        }
      >
        <div
          className="ol-scale-line ol-unselectable"
          style={{ pointerEvents: "auto" }}
        ></div>
        {props.children}
      </div>
      <div id="popup-carto"></div>
    </>
  );
}
