import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import Select from "react-select";
import { selectStyles } from "../../config/styles";
import StoreContext from "../../context/StoreContext";
import {
  deleteQueryParam,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../config/utils";
import ParcelleInfo from "./ParcelleInfo";
import ModalUpdateParcelle from "./modal/ModalUpdateParcelle";
import GeoJSON from "ol/format/GeoJSON";
import { useRecoilState } from "recoil";
import { parcelleAssocieeSelectedState } from "../../config/atoms/parcellaireexploitation";

export default function MesParcelles({ loading, forceReloadMap }) {
  const context = useContext(StoreContext);
  const [parcelleAssocieeSelected, setParcelleAssocieeSelected] =
    useRecoilState(parcelleAssocieeSelectedState);
  const [showInfosParcelle, setShowInfosParcelle] = useState(false);
  const [showModalUpdateParcelle, setShowModalUpdateParcelle] = useState(false);

  useEffect(() => {
    if (context.carto.parcellaire.length > 0 && getQueryParam("parcelle")) {
      const parcelle = context.carto.parcellaire.find(
        (p) => p.idparcelle == getQueryParam("parcelle"),
      );
      if (parcelle) {
        const feature = context.carto
          .getParcellaireLayer()
          .getFeatures()
          .find((feature) => feature.get("idparcelle") == parcelle.idparcelle);
        context.carto.zoomMap([new GeoJSON().readFeature(parcelle.geometrie)]);
        context.carto.setParcelleSelected(parcelle);
        setParcelleAssocieeSelected(parcelle);
        context.carto.getParcellaireLayer().toggleSelectionFeature(feature);
        context.carto.getParcellaireLayer().selectFeature(feature);
      } else {
        deleteQueryParam("parcelle");
        context.carto.getParcellaireLayer().clearSelection();
      }
    }
  }, []);

  useEffect(() => {
    if (
      context.carto.parcellaire.length > 0 &&
      getQueryParam("parcelle") &&
      context.carto.parcelleAssocieeSelecterRef.current != null
    ) {
      const parcelle = context.carto.parcellaire.find(
        (p) => p.idparcelle == getQueryParam("parcelle"),
      );
      context.carto.parcelleAssocieeSelecterRef.current.setValue({
        value: parcelle.idparcelle,
        label: parcelle.nomparcelle,
        fromCarto: true,
      });
    }
  }, [context.carto.parcelleAssocieeSelecterRef.current]); // Re-run the effect when the flag changes

  return (
    <>
      <Collapse isOpen={showModalUpdateParcelle} mountOnEnter unmountOnExit>
        <ModalUpdateParcelle
          isOpen={showModalUpdateParcelle}
          close={async () => {
            await forceReloadMap();
            setShowModalUpdateParcelle(!showModalUpdateParcelle);
          }}
          parcelle={parcelleAssocieeSelected}
        />
      </Collapse>
      <h5>Mes parcelles</h5>
      <Row>
        <Col>
          <Select
            isClearable
            ref={context.carto.parcelleAssocieeSelecterRef}
            closeMenuOnSelect
            className="select-single"
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            isLoading={loading}
            placeholder="Sélectionnez une parcelle..."
            styles={selectStyles}
            options={[
              ...context.carto.parcellaire
                .filter((p) => p.geometrie != null)
                .map((parcelle) => ({
                  value: parcelle.idparcelle,
                  label: parcelle.nomparcelle,
                })),
            ]}
            onChange={async (selection) => {
              if (selection) {
                setQueryParam("parcelle", selection.value);
                if (!context.carto.getParcellaireLayer().isVisible()) {
                  context.carto.getParcellaireLayer().showLayer();
                }
                context.carto.setParcelleSelected(
                  context.carto.parcellaire.find(
                    (p) => p.idparcelle === selection.value,
                  ),
                );
                setParcelleAssocieeSelected(
                  context.carto.parcellaire.find(
                    (p) => p.idparcelle === selection.value,
                  ),
                );
                context.carto.getParcellaireLayer().toggleSelectionFeature(
                  context.carto
                    .getParcellaireLayer()
                    .getFeatures()
                    .find(
                      (feature) => feature.get("idparcelle") == selection.value,
                    ),
                );
                context.carto.getParcellaireLayer().selectFeature(
                  context.carto
                    .getParcellaireLayer()
                    .getFeatures()
                    .find(
                      (feature) => feature.get("idparcelle") == selection.value,
                    ),
                );
              } else {
                deleteQueryParam("parcelle");
                setParcelleAssocieeSelected(null);
                context.carto.setParcelleSelected(null);
                context.carto.getParcellaireLayer().clearSelection();
              }
            }}
          />
        </Col>
      </Row>
      <RenderIf isTrue={parcelleAssocieeSelected != null}>
        <ParcelleInfo
          showInfosParcelle={showInfosParcelle}
          forceReloadMap={forceReloadMap}
          setShowInfosParcelle={() => setShowInfosParcelle(!showInfosParcelle)}
          setShowModalUpdateParcelle={() => setShowModalUpdateParcelle(true)}
        />
      </RenderIf>
    </>
  );
}
