import { Col, Row } from "reactstrap";
import TableConfirmationSurfaces from "./confirmationsurfaces/TableConfirmationSurfaces";
import ActionsGroupees from "../ActionsGroupees";

export default function ConfirmationSurfaces() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableConfirmationSurfaces />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
