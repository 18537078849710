import { useContext, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import ContratController from "../config/apiUtils/ContratController";
import {
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  GoToTopPage,
  createNotification,
  RenderIf,
  downloadFile,
} from "../config/utils";
import StoreContext from "../context/StoreContext";
import ContratsExploitation from "./contrat/ContratsExploitations";
import FicheContrat from "./contrat/FicheContrat";
import FormNewContrat from "./contrat/FormNewContrat";
import useConstructor from "../config/hooks/useConstructor";
import AddButton from "../components/Buttons/AddButton";
import FormUpdateContrat from "./contrat/FormUpdateContrat";
import FormCompleterContrat from "./contrat/FormCompleterContrat";
import FormDeleteParcelle from "./contrat/FormDeleteParcelle";
import { useNavigate } from "react-router-dom";
import FormNewParcelle from "./listeparcelle/FormNewParcelle";
import ParcelleController from "../config/apiUtils/ParcelleController";
import { defaultFilterValue } from "../components/Grids/Contrat/contrats";
import EditionController from "../config/apiUtils/EditionController";
import { useConfirmBrowserExit } from "../config/hooks/useConfirmBrowserExit";
import FormNewAnnexe from "./contrat/FormNewAnnexe";
import ListeAnnexes from "./contrat/ListeAnnexes";

export default function Contrat() {
  const navigate = useNavigate();
  const confirmBrowserExit = useConfirmBrowserExit(false);
  const store = useContext(StoreContext);
  const [showNewContratForm, setShowNewContratForm] = useState(false);
  const [showUpdateContratForm, setShowUpdateContratForm] = useState(false);
  const [showCompleterContratForm, setShowCompleterContratForm] =
    useState(false);
  const [showNewAnnexeForm, setShowNewAnnexeForm] = useState(false);
  const [showDeleteParcelleForm, setShowDeleteParcelleForm] = useState(false);
  const [contrat, setContrat] = useState({});
  const [showFicheContrat, setShowFicheContrat] = useState(false);
  const [showContratTable, setShowContratTable] = useState(true);
  const [idcontrat, setIdcontrat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFormNewParcelle, setShowFormNewParcelle] = useState(false);
  const [showAnnexes, setShowAnnexes] = useState(false);
  const [filterValueTableauContrat, setFilterValueTableauContrat] =
    useState(defaultFilterValue);
  const [reloadDetail, setReloadDetail] = useState(false);

  const hideFormNewContrat = () => setShowNewContratForm(false);

  const onSelectContrat = (idcontrat) => {
    setQueryParam("id", idcontrat);
    setIdcontrat(idcontrat);
    GoToTopPage();
    setShowFicheContrat(true);
    setShowContratTable(false);
  };

  const loadContrats = async () => {
    setLoading(true);
    const res = await ContratController.getContratMillesime(
      store.millesime.idmillesime,
    );
    if (getQueryParam("id")) {
      if (res.find((contrat) => contrat.idcontrat == getQueryParam("id"))) {
        setIdcontrat(getQueryParam("id"));
        onSelectContrat(getQueryParam("id"));

        if (getQueryParam("completer") === "true") {
          setShowCompleterContratForm(true);
        }
        if (getQueryParam("annexe") === "true") {
          setShowNewAnnexeForm(true);
          setShowAnnexes(false);
        }
      } else {
        deleteQueryParam("id");
      }
    } else {
      if (store.utilisateur.informations.isProducteur) {
        navigate("/app/exploitation");
      }
    }
    store.setContrats(res);
    setLoading(false);
  };

  const onClickSaveNewContrat = async (contrat) => {
    if (contrat.idcontrat !== "") {
      const resContrat = await ContratController.insertContrat(contrat);
      if (resContrat.status === 200) {
        createNotification(
          "success",
          "Succès",
          "Contrat " + resContrat.data.numerocontrat + " créé avec succès !",
        );
        setShowNewContratForm(false);

        window.location.href = `/app/contrat?id=${resContrat.data.idcontrat}&completer=true`;
      } else {
        createNotification("error", "Erreur", resContrat.data.detail);
      }
    }
  };

  const handleSubmitNewParcelle = async (parcelle) => {
    const res = await ParcelleController.insertParcelle(parcelle);

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle " + parcelle.nomparcelle + " créée avec succès",
      );
      store.setParcelles(await ParcelleController.getParcelles());
      setShowFormNewParcelle(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de la parcelle...",
      );
    }
  };

  useConstructor(async () => {
    await loadContrats();
  });

  const downloadAllContrats = async () => {
    setLoading(true);
    createNotification(
      "info",
      "Préparation des documents en cours...",
      "L'extraction de tous les contrats peut prendre quelques minutes. En attendant la fin de l'extraction, merci de ne pas quitter la page tant que le chargement est en cours",
      20000,
    );
    confirmBrowserExit.setMessage(
      "L'extraction de tous les contrats est en cours. Souhaitez-vous vraiment quitter la page ?",
    );
    confirmBrowserExit.enable();
    const chunkSize = 100;
    let numChunk = 1;
    console.time("Execution time");
    for (let i = 0; i < store.contrats.length; i += chunkSize) {
      downloadFile(
        await EditionController.getContrats(
          store.contrats.slice(i, i + chunkSize).map((c) => c.idcontrat),
        ),
        `contrats_${store.millesime.idmillesime}_${numChunk}.zip`,
        "application/octet-stream",
      );
      numChunk++;
    }
    console.timeEnd("Execution time");
    setLoading(false);
    createNotification("success", "", "Archive générée avec succès.");
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Consultation contrat d'exploitation</h2>
        </Col>
        <RenderIf isTrue={!store.utilisateur.informations.isProducteur}>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <AddButton onClick={() => setShowNewContratForm(true)} />
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={downloadAllContrats}
            >
              Télécharger tout
            </Button>
          </Col>
        </RenderIf>
      </Row>

      <RenderIf
        isTrue={
          store.utilisateur.informations.isProducteur && !getQueryParam("id")
        }
      >
        <Row
          style={{ marginTop: 30, display: "flex", justifyContent: "center" }}
        >
          <Col md={8}>
            <Card>
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                Veuillez sélectionner un contrat dans la fiche Exploitation afin
                de pouvoir visualiser la fiche du contrat.
              </CardBody>
            </Card>
          </Col>
        </Row>
      </RenderIf>
      <RenderIf isTrue={!store.utilisateur.informations.isProducteur}>
        <Collapse isOpen={showNewContratForm} mountOnEnter unmountOnExit>
          <Row style={{ marginTop: 30 }}>
            <Col md="12">
              <FormNewContrat
                exploitation={store.exploitation.informations}
                hideFormNewContrat={hideFormNewContrat}
                millesime={store.millesime.idmillesime}
                onClickSaveNewContrat={onClickSaveNewContrat}
              />
            </Col>
          </Row>
        </Collapse>
      </RenderIf>
      <Row style={{ marginTop: 30 }}>
        <Col md="12">
          <Collapse isOpen={showAnnexes} mountOnEnter unmountOnExit>
            <ListeAnnexes
              idcontrat={idcontrat}
              contrat={contrat}
              onClickClose={async () => {
                setReloadDetail(true);
                setShowAnnexes(false);
              }}
              loadContrats={() => {
                setReloadDetail(true);
              }}
            />
          </Collapse>
          <Collapse
            isOpen={
              showFicheContrat &&
              !showUpdateContratForm &&
              !showCompleterContratForm &&
              !showDeleteParcelleForm &&
              !showNewAnnexeForm
            }
            mountOnEnter
            unmountOnExit
          >
            <FicheContrat
              idcontrat={idcontrat}
              reloadDetail={reloadDetail}
              setReloadDetail={setReloadDetail}
              hideFicheContrat={() => {
                deleteQueryParam("id");
                if (store.utilisateur.informations.isProducteur) {
                  navigate(-1);
                }
                setShowFicheContrat(false);
                setShowAnnexes(false);
                setShowContratTable(true);
              }}
              onClickUpdateContrat={async () => {
                setContrat(await ContratController.getContratDetail(idcontrat));
                setShowUpdateContratForm(true);
              }}
              onClickCompleterContrat={async () => {
                setContrat(await ContratController.getContratDetail(idcontrat));
                setQueryParam("completer", "true");
                setShowCompleterContratForm(true);
              }}
              onClickNewAnnexe={async () => {
                setContrat(await ContratController.getContratDetail(idcontrat));
                setQueryParam("annexe", "true");
                setShowNewAnnexeForm(true);
                setShowAnnexes(false);
              }}
              onClickShowAnnexe={async () => {
                setContrat(await ContratController.getContratDetail(idcontrat));
                setShowAnnexes(true);
              }}
              onClickDeleteParcelle={async () => {
                setContrat(await ContratController.getContratDetail(idcontrat));
                setShowDeleteParcelleForm(true);
              }}
            />
          </Collapse>
          <Collapse isOpen={showDeleteParcelleForm} mountOnEnter unmountOnExit>
            <FormDeleteParcelle
              contrat={contrat}
              onClickClose={async () => {
                await loadContrats();
                setShowDeleteParcelleForm(false);
              }}
            />
          </Collapse>
          <Collapse
            isOpen={showCompleterContratForm}
            mountOnEnter
            unmountOnExit
          >
            <FormCompleterContrat
              contrat={contrat}
              loadContrats={async () => {
                await loadContrats();
              }}
              onClickNewParcelle={() =>
                navigate("/app/carto/parcellaire-exploitation")
              }
              onClickClose={async () => {
                deleteQueryParam("completer");
                setShowCompleterContratForm(false);
              }}
            />
          </Collapse>
          <Collapse isOpen={showNewAnnexeForm} mountOnEnter unmountOnExit>
            <FormNewAnnexe
              contrat={contrat}
              loadContrats={async () => {
                await loadContrats();
              }}
              onClickNewParcelle={() => setShowFormNewParcelle(true)}
              onClickClose={async () => {
                deleteQueryParam("annexe");
                setShowNewAnnexeForm(false);
              }}
            />
          </Collapse>
          <Collapse
            isOpen={showFormNewParcelle}
            style={{ marginTop: 30 }}
            mountOnEnter
            unmountOnExit
          >
            <Row style={{ marginTop: 30 }}>
              <Col md="12">
                <FormNewParcelle
                  handleSubmitNewParcelle={handleSubmitNewParcelle}
                  onClickClose={() => setShowFormNewParcelle(false)}
                />
              </Col>
            </Row>
          </Collapse>
          <RenderIf isTrue={!store.utilisateur.informations.isProducteur}>
            <Collapse isOpen={showUpdateContratForm} mountOnEnter unmountOnExit>
              <FormUpdateContrat
                contrat={contrat}
                onClickClose={() => setShowUpdateContratForm(false)}
              />
            </Collapse>
            <Collapse
              isOpen={
                showContratTable && !getQueryParam("id") && !showNewContratForm
              }
              mountOnEnter
              unmountOnExit
            >
              <ContratsExploitation
                loading={loading}
                contrats={store.contrats}
                filterValueTableauContrat={filterValueTableauContrat}
                setFilterValueTableauContrat={setFilterValueTableauContrat}
                onSelectContrat={onSelectContrat}
              />
            </Collapse>
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
