import { useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import StoreContext from "../../../context/StoreContext";
import { RenderIf } from "../../../config/utils";

export default function SurfaceEngageeWidget(props) {
  const context = useContext(StoreContext);

  const renderParcelle = (detail, key) => {
    return (
      <tr key={key}>
        <td>
          <b>{detail.parcelle.nomparcelle}</b> :{" "}
          {detail.surfaceengageeprintemps} Ha printemps |{" "}
          {detail.surfaceengageeautomne} Ha automne
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          {context.detailContratsExploitation.details.map((detail, key) => {
            return renderParcelle(detail, key);
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Card style={{ height: 300, maxHeight: 300, marginTop: 10 }}>
      <CardHeader>
        <CardTitle style={{ fontWeight: "bold" }}>
          Surfaces engagées en {context.millesime.idmillesime}
        </CardTitle>
      </CardHeader>
      <CardBody style={{ overflowY: "auto" }}>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!props.loading}>
          <RenderIf
            isTrue={context.detailContratsExploitation.nbParcelles === 0}
          >
            Pas de parcelle contractualisée en {context.millesime.idmillesime}
          </RenderIf>
          <RenderIf isTrue={context.detailContratsExploitation.nbParcelles > 0}>
            {renderTable()}
          </RenderIf>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
