import { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import { useRecoilState } from "recoil";
import { culturesState } from "../../../config/atoms/referentiels";

const columns = [
  {
    header: "Groupe de culture",
    name: "libellegroupeculture",
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Culture",
    name: "libelle",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code culture",
    name: "codeculture",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Année de début",
    name: "millesimedebut",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Année de fin",
    name: "millesimefin",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const defaultFilterValue = [
  {
    name: "libellegroupeculture",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "libelle", type: "string", operator: "contains", value: "" },
  { name: "codeculture", type: "string", operator: "contains", value: "" },
  { name: "millesimedebut", type: "float", operator: "contains", value: "" },
  { name: "millesimefin", type: "float", operator: "contains", value: "" },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeCultures() {
  const [loading, setLoading] = useState(false);
  const [cultures, setCultures] = useRecoilState(culturesState);

  useConstructor(async () => {
    setLoading(true);
    setCultures(await ReferentielController.getCultures());
    setLoading(false);
  });

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Cultures</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {cultures !== null &&
                cultures !== [] &&
                cultures !== undefined &&
                !loading && (
                  <Grid
                    enableCellSelect={true}
                    idProperty="idreftculture"
                    emptyText="Aucune culture renseignée"
                    columns={columns}
                    defaultFilterValue={defaultFilterValue}
                    dataSource={cultures}
                    style={gridStyle}
                    pagination
                    defaultLimit={100}
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
