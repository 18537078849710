import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import FichierController from "../../config/apiUtils/FichierController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import CloseButton from "../../components/Buttons/CloseButton";
import useConstructor from "../../config/hooks/useConstructor";

export default function FormNewFichier(props) {
  const [typesDocuments, setTypesDocuments] = useState([]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const file = event.target.file.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const res = await FichierController.uploadDocument(
      event.target.idreftypedocument.value,
      formData,
    );
    if (res.status === 200) {
      await props.loadDocuments();
      props.hideFormNewFichier();
    }
  };

  useConstructor(async () => {
    setTypesDocuments(await ReferentielController.getTypesDocument());
  });

  const renderTypesDocumentsOptions = () => {
    return typesDocuments.map((type, key) => {
      return (
        <option key={key} value={type.idreftypedocument}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Nouveau document</CardTitle>
        <CloseButton onClick={props.hideFormNewFichier} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <Label for="idreftypedocument">Type de document</Label>
              <Input
                type="select"
                name="idreftypedocument"
                className="select-input"
              >
                {renderTypesDocumentsOptions()}
              </Input>
            </Col>
            <Col md="8">
              <FormGroup style={{ position: "relative", display: "grid" }}>
                <Label for="file">Nouveau document</Label>
                <Input type="file" name="file" id="" />
                <FormText className={"formtext-input-file"}>
                  Sur cette espace, une limitation de 2 Go de stockage
                  s'applique.
                </FormText>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Button
                style={{ marginTop: 15, marginRight: 15 }}
                color="primary"
                type="submit"
              >
                Valider
              </Button>
              <Button
                style={{ marginTop: 15 }}
                color="danger"
                onClick={(event) => {
                  event.preventDefault();
                  props.hideFormNewFichier();
                }}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
