import { Col, Row } from "reactstrap";
import TableAvertissement from "./avertissements/TableAvertissement";
import ActionsGroupees from "../ActionsGroupees";

export default function Avertissements() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableAvertissement />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
