import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormUpdateZoneProduction(props) {
  const store = useContext(StoreContext);
  const [zoneProduction, setZoneProduction] = useState(
    store.zonesProduction.find(
      (zone) => zone.idrefzoneproduction === props.idrefzoneproduction,
    ),
  );
  const [libelle, setLibelle] = useState(zoneProduction.libelle);
  const [libellelong, setLibelleLong] = useState(zoneProduction.libellelong);

  const onSubmit = (event) => {
    props.onClickUpdate({
      libelle: libelle,
      libellelong: libellelong,
    });
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const data = store.zonesProduction.find(
      (zone) => zone.idrefzoneproduction === props.idrefzoneproduction,
    );
    setZoneProduction(data);
    setLibelle(data.libelle);
    setLibelleLong(data.libellelong);
  }, [props.idrefzoneproduction, store.zonesProduction]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Zone de production : {zoneProduction.libelle}
        </CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="libelle">Libellé</Label>
                <Input
                  name="libelle"
                  placeholder={"Libellé"}
                  type="text"
                  value={libelle}
                  onChange={(event) => setLibelle(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="libellelong">Libellé long</Label>
                <Input
                  name="libellelong"
                  placeholder={"Libellé long"}
                  type="text"
                  value={libellelong}
                  onChange={(event) => setLibelleLong(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
