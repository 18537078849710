import { createRoot } from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import "./assets/css/userLayout.css";
import "./assets/css/reactDataGrid.css";
import "react-toastify/dist/ReactToastify.min.css";
import "@inovua/reactdatagrid-community/index.css";
import { NotificationContainer } from "react-notifications";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

const root = createRoot(document.getElementById("root"));

root.render(
  <>
    <RecoilRoot>
      <NotificationContainer />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <App />
    </RecoilRoot>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*function sendToAnalytics(metric) {
  //const body = JSON.stringify(metric);
  //const url = "https://example.com/analytics";
  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  //console.log(metric);
}

reportWebVitals(sendToAnalytics);
*/
