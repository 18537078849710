import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import StoreContext from "../../context/StoreContext";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";

const columns = [
  {
    header: "Nom du silo",
    name: "nomsilo",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Actif",
    name: "actif",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value === true) {
        return (
          <div style={{ display: "flex" }}>
            <Alert
              fade={false}
              color="success"
              style={{
                height: 12,
                padding: "0rem 1.5rem 1.5rem 1.5rem",
                marginTop: "1rem",
              }}
            >
              OUI
            </Alert>
          </div>
        );
      }
      return (
        <div style={{ display: "flex" }}>
          <Alert
            fade={false}
            color="danger"
            style={{
              height: 12,
              padding: "0rem 1.5rem 1.5rem 1.5rem",
              marginTop: "1rem",
            }}
          >
            NON
          </Alert>
        </div>
      );
    },
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function TableSilo(props) {
  const store = useContext(StoreContext);
  const [silos, setSilos] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrganismeStockeurController.getSiloByIdOrganismeStockeur(
      props.idorganismestockeurSelected,
      store.millesime.idmillesime,
    ).then((res) => {
      setSilos(res);
      setLoading(false);
    });
  }, [props.idorganismestockeurSelected]);

  const onRowClick = (rowIdx) => {
    props.onClickSilo(rowIdx.data.idsilo);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Silos rattachés à l'organisme</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idsilo"
            emptyText="Aucun silo renseigné"
            columns={columns}
            dataSource={silos}
            style={gridStyle}
            onRowClick={onRowClick}
            pagination
            defaultLimit={20}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
