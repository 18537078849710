import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import CiviliteController from "../../config/apiUtils/CiviliteController";
import useConstructor from "../../config/hooks/useConstructor";
import { checkMail, checkTelephone } from "../../config/utils";

export default function FormUpdateProducteur(props) {
  const [civilites, setCivilites] = useState([]);
  const [civiliteProducteur, setCiviliteProducteur] = useState(0);

  useConstructor(async () => {
    const resCivilites = await CiviliteController.getCivilites();
    setCivilites(resCivilites);
  });

  useEffect(() => {
    setCiviliteProducteur(props.producteur.idcivilite);
  }, [props.producteur]);

  const onSubmitUpdate = (event) => {
    const producteurForm = {
      idcivilite: event.target.idcivilite.value,
      idexploitation: event.target.idexploitation.value,
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      mail: event.target.mail.value,
      telephonefixe: event.target.telephonefixe.value,
      telephoneportable: event.target.telephoneportable.value,
    };
    event.preventDefault();
    let estValide = true;
    if (
      (producteurForm.telephonefixe &&
        !checkTelephone(producteurForm.telephonefixe)) ||
      (producteurForm.telephoneportable &&
        !checkTelephone(producteurForm.telephoneportable)) ||
      (producteurForm.mail && !checkMail(producteurForm.mail))
    ) {
      estValide = false;
    }
    if (estValide) {
      props.onClickUpdateProducteur(
        props.producteur.idproducteur,
        producteurForm,
      );
      props.hideUpdateProducteurForm();
    }
    event.target.reset();
  };

  const renderCivilitesOptions = () => {
    return civilites.map((civilite, key) => {
      return (
        <option key={key} value={civilite.idcivilite}>
          {civilite.libelle}
        </option>
      );
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Modifier le producteur</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.hideUpdateProducteurForm} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Form onSubmit={onSubmitUpdate}>
                <Table style={{ color: "#FFFFFF" }}>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Label for="idcivilite">Civilité</Label>
                      </th>
                      <td>
                        <Input
                          type="select"
                          className="select-input"
                          name="idcivilite"
                          value={civiliteProducteur}
                          onChange={(event) =>
                            setCiviliteProducteur(event.target.value)
                          }
                          required
                        >
                          {renderCivilitesOptions()}
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="nom">NOM</Label>
                      </th>
                      <td>
                        <Input
                          type="text"
                          name="nom"
                          defaultValue={props.producteur.nom}
                          required
                        ></Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="prenom">Prénom</Label>
                      </th>
                      <td>
                        <Input
                          type="text"
                          name="prenom"
                          defaultValue={props.producteur.prenom}
                          required
                        ></Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="idexploitation">Exploitation</Label>
                      </th>
                      <td>
                        <Input
                          type="select"
                          className="select-input"
                          name="idexploitation"
                          value={props.producteur.idexploitation}
                          disabled
                          readOnly
                          required
                        >
                          <option value={props.producteur.idexploitation}>
                            {props.producteur.raisonsociale}
                          </option>
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="mail">Adresse e-mail</Label>
                      </th>
                      <td>
                        <Input
                          type="email"
                          name="mail"
                          placeholder="Adresse e-mail"
                          defaultValue={props.producteur.mail}
                        ></Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="telephonefixe">Téléphone fixe</Label>
                      </th>
                      <td>
                        <Input
                          type="text"
                          name="telephonefixe"
                          placeholder="Téléphone fixe"
                          defaultValue={props.producteur.telephonefixe}
                        ></Input>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Label for="telephoneportable">Téléphone mobile</Label>
                      </th>
                      <td>
                        <Input
                          type="text"
                          name="telephoneportable"
                          placeholder="Téléphone mobile"
                          defaultValue={props.producteur.telephoneportable}
                        ></Input>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col md="6">
                    <Button
                      style={{ marginTop: 15, marginRight: 15 }}
                      type="submit"
                      color="primary"
                    >
                      Valider
                    </Button>
                    <Button
                      style={{ marginTop: 15 }}
                      color="danger"
                      onClick={(event) => {
                        event.preventDefault();
                        props.hideUpdateProducteurForm();
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
