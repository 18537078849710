import { Col, Row } from "reactstrap";
import TableEnvoiGuideCarnetSuivi from "./envoiguidecarnetsuivi/TableEnvoiGuideCarnetSuivi";
import ActionsGroupees from "../ActionsGroupees";

export default function EnvoiGuideCarnetSuivi() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableEnvoiGuideCarnetSuivi />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
