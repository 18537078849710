export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse e-mail",
    name: "mail",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone fixe",
    name: "telephonefixe",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone portable",
    name: "telephoneportable",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 500, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "mail", type: "string", operator: "contains", value: "" },
  {
    name: "telephonefixe",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "telephoneportable",
    type: "string",
    operator: "contains",
    value: "",
  },
];
