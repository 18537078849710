import React, { Component } from "react";
import { Card } from "reactstrap";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import MapWrapper from "../../components/carto/MapWrapper";
import StoreContext from "../../context/StoreContext";

export default class MapParcellaire extends Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card
        className="card-carto"
        style={{
          height: this.props.height,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RenderIf isTrue={this.props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!this.props.loading}>
          <MapWrapper
            showZoomControl
            showScaleline
            pageCarto
            onMapClick={this.props.onMapClick}
            layers={this.context.carto.layers.map((layer) => layer.getLayer())}
          ></MapWrapper>
        </RenderIf>
      </Card>
    );
  }
}
