import { useContext, useState } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import StoreContext from "../../context/StoreContext";
import CertificationIgpController from "../../config/apiUtils/CertificationIgpController";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/CertificationIgp/certificationigp";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function TableCertificationsIgp() {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    const resCertifications =
      await CertificationIgpController.getCertificationsIgp(
        store.millesime.idmillesime,
      );
    store.certifications.setCertificationsIgp(resCertifications);
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Certifications IGP</CardTitle>
      </CardHeader>
      <CardBody>
        {loading && <SpinLoadingAnimation />}
        {!loading && (
          <>
            {store.certifications.certificationsIgp.length > 0 ? (
              <Grid
                enableCellSelect={true}
                idProperty="idcertificationigp"
                emptyText="Aucune certification IGP renseignée"
                columns={columns}
                dataSource={store.certifications.certificationsIgp}
                defaultFilterValue={defaultFilterValue}
                onRowClick={() => {}}
                style={gridStyle}
                pagination
                defaultLimit={50}
                searchBarPlaceholder={"Recherche certification"}
              />
            ) : (
              <Alert fade={false} color="danger">
                Aucune certification IGP trouvée pour le millésime...
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}
