import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
} from "../../components/Grids/Producteur/producteurs";

export default function TableProducteur(props) {
  const onRowClick = async (rowIdx) => {
    props.onSelectProducteur(rowIdx.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des producteurs</CardTitle>
            <CardSubtitle tag={"h6"}>
              Pour voir la fiche d'un producteur, cliquez sur la ligne associée.
            </CardSubtitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <Grid
              enableCellSelect={true}
              onRowClick={onRowClick}
              idProperty="idproducteur"
              emptyText="Aucun producteur renseigné"
              columns={columns}
              groups={groups}
              dataSource={props.producteurs}
              style={gridStyle}
              pagination
              defaultLimit={10}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
