import { useContext, useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import SiloController from "../config/apiUtils/SiloController";
import useConstructor from "../config/hooks/useConstructor";
import { RenderIf, createNotification } from "../config/utils";
import StoreContext from "../context/StoreContext";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import TableSilos from "./silos/TableSilos";
import FormNewSilo from "./silos/FormNewSilo";

export default function Silos() {
  const store = useContext(StoreContext);

  const [showFormNewSilo, setShowFormNewSilo] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadSilos = async () => {
    setLoading(true);
    store.setSilos(
      await SiloController.getSilos(null, store.millesime.idmillesime),
    );
    setLoading(false);
  };

  useConstructor(async () => {
    await loadSilos();
  });

  const handleNewSilo = async (form) => {
    const res = await SiloController.insertSilo(form);

    if (res.status === 200) {
      createNotification("success", "Succès", "Silo créé avec succès !");
      setShowFormNewSilo(false);
      await loadSilos();
    } else {
      createNotification("error", "Erreur", "Erreur à la création du silo");
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Silos</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <Collapse isOpen={!showFormNewSilo} mountOnEnter unmountOnExit>
            <RenderIf isTrue={loading}>
              <SpinLoadingAnimation />
            </RenderIf>
            <RenderIf isTrue={!loading}>
              <TableSilos onClickNewSilo={() => setShowFormNewSilo(true)} />
            </RenderIf>
          </Collapse>
          <Collapse isOpen={showFormNewSilo} mountOnEnter unmountOnExit>
            <FormNewSilo
              onClickNewSilo={handleNewSilo}
              onClickClose={() => setShowFormNewSilo(false)}
            />
          </Collapse>
        </Col>
      </Row>
    </>
  );
}
