export const columns = [
  {
    header: "Millesime",
    name: "millesime",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Rendement (t/Ha)",
    name: "valeur",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };
