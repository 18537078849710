import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
} from "../../components/Grids/planproduction/listeparparcelle";
import CloseButton from "../../components/Buttons/CloseButton";

export default function BilanParOganismeStockeur(props) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Bilan Par Oganisme Stockeur</CardTitle>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <Grid
          enableCellSelect={true}
          idProperty="idplanproduction"
          emptyText="Le plan de production est vide"
          columns={columns}
          groups={groups}
          dataSource={[]}
          style={gridStyle}
          pagination
          defaultLimit={20}
          showSearchBar={true}
          searchBarPlaceholder={"Recherche dans le plan de production"}
        />
      </CardBody>
    </Card>
  );
}
