import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Collapse, Row } from "reactstrap";
import FormNewParcelle from "./listeparcelle/FormNewParcelle";
import TableParcelles from "./listeparcelle/TableParcelles";
import AddButton from "../components/Buttons/AddButton";
import ParcelleController from "../config/apiUtils/ParcelleController";
import StoreContext from "../context/StoreContext";
import { createNotification } from "../config/utils";
import layoutRoutes from "../config/layoutRoutes";
import FormUpdateParcelle from "./listeparcelle/FormUpdateParcelle";

export default function ListeParcelles() {
  const store = useContext(StoreContext);
  const [idparcelleToShow, setIdparcelleToShow] = useState();
  const [showFicheParcelle, setShowFicheParcelle] = useState(false);
  const [showFormNewParcelle, setShowFormNewParcelle] = useState(false);

  const onClickShowFicheParcelle = (idparcelle) => {
    setIdparcelleToShow(idparcelle);
    setShowFicheParcelle(true);
  };

  const handleSubmitNewParcelle = async (parcelle) => {
    const res = await ParcelleController.insertParcelle(parcelle);

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle " + parcelle.nomparcelle + " créée avec succès",
      );
      store.setParcelles(await ParcelleController.getParcelles());
      setShowFormNewParcelle(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de la parcelle...",
      );
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des parcelles</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <AddButton onClick={() => setShowFormNewParcelle(true)} />
          <Button color="warning" style={{ marginRight: 10 }}>
            <NavLink
              style={{ textDecoration: "none", color: "#000000" }}
              to={`${layoutRoutes.userLayout}/carto/appariement-parcelles`}
            >
              Carte
            </NavLink>
          </Button>
        </Col>
      </Row>
      <Collapse
        isOpen={showFormNewParcelle}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormNewParcelle
              handleSubmitNewParcelle={handleSubmitNewParcelle}
              onClickClose={() => setShowFormNewParcelle(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={showFicheParcelle && !showFormNewParcelle}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormUpdateParcelle
              idparcelle={idparcelleToShow}
              onClickClose={() => setShowFicheParcelle(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={!showFicheParcelle && !showFormNewParcelle}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row>
          <Col md="12">
            <TableParcelles
              onClickShowFicheParcelle={onClickShowFicheParcelle}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
