import { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormNewTypeProduction(props) {
  const store = useContext(StoreContext);
  const onSubmit = (event) => {
    const materiel = {
      idreftypemateriel: event.target.idreftypemateriel.value,
      libelle: event.target.libelle.value,
    };
    props.onClickAdd(materiel);
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  const renderTypesMaterielOptions = () => {
    return store.referentiels.typesMateriels.map((type, key) => {
      return (
        <option key={key} value={type.idreftypemateriel}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouveau matériel</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="idreftypemateriel">Type de matériel</Label>
                <Input
                  name="idreftypemateriel"
                  type="select"
                  className="select-input"
                >
                  {renderTypesMaterielOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="libelle">Matériel</Label>
                <Input name="libelle" placeholder={"Matériel"} type="text" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
