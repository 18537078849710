import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeSol from "./TypeSol/ListeTypeSol";
import FormNewTypeSol from "./TypeSol/FormNewTypeSol";
import FormUpdateTypeSol from "./TypeSol/FormUpdateTypeSol";
import { createNotification } from "../../config/utils";

export default function TypeSol() {
  const store = useContext(StoreContext);
  const [showFormNewTypeSol, setShowFormNewTypeSol] = useState(false);
  const [showFormUpdateTypeSol, setShowFormUpdateTypeSol] = useState(false);
  const [idreftypesolToUpdate, setIdreftypesolToUpdate] = useState();

  const handleAddNewTypeSol = async (typeSol) => {
    const res = await ReferentielController.insertTypeSol(typeSol);
    if (res.status === 200) {
      createNotification("success", "Succès", "Type de sol créé avec succès");
      store.referentiels.setTypesSol([
        ...store.referentiels.typesSol,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de sol",
      );
    }
  };

  const handleUpdateTypeSol = async (typeSol) => {
    const res = await ReferentielController.updateTypeSol(
      idreftypesolToUpdate,
      typeSol,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de sol modifié avec succès",
      );
      store.referentiels.setTypesSol(await ReferentielController.getTypesSol());
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de sol",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeSol}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeSol
          onClickAdd={handleAddNewTypeSol}
          onClickClose={() => setShowFormNewTypeSol(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeSol}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeSol
          onClickUpdate={handleUpdateTypeSol}
          onClickClose={() => setShowFormUpdateTypeSol(false)}
          idreftypesol={idreftypesolToUpdate}
        />
      </Collapse>
      <ListeTypeSol
        showFormUpdateTypeSol={(idreftypesol) => {
          setIdreftypesolToUpdate(idreftypesol);
          setShowFormUpdateTypeSol(true);
        }}
        onClickNewType={() => setShowFormNewTypeSol(true)}
      />
    </>
  );
}
