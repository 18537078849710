const AG_GRID_LOCALE_FR = {
  "pageText": " ",
  "ofText": "sur",
  "perPageText": "par page :",
  "showingText": "En cours :",
  "clearAll": "Tout vider",
  "clear": "Vider",
  "showFilteringRow": "",
  "hideFilteringRow": "",
  "enable": "Activer",
  "disable": "Désactiver",
  "sortAsc": "Trier dans l'ordre croissant",
  "sortDesc": "Trier dans l'ordre décroissant",
  "unsort": "Supprimer le tri",
  "group": "Grouper",
  "ungroup": "Dégrouper",
  "lockStart": " ",
  "lockEnd": " ",
  "unlock": "",
  "columns": "Colonnes",
  "autoresizeThisColumn": "",
  "autoresizeAllColumns": "",
  "autoSizeToFit": "",
  "contains": "contient",
  "startsWith": "commence par",
  "endsWith": "se termine par",
  "notContains": "Non trouvé",
  "inlist": "Egal",
  "notinlist": "Différent de",
  "neq": "différent",
  "inrange": "dans l'intervalle",
  "notinrange": "hors de l'intervalle",
  "eq": "égal",
  "notEmpty": "non vide",
  "empty": "vide",
  "lt": "inférieur",
  "lte": "inférieur ou égal",
  "gt": "supérieur",
  "gte": "supérieur ou égal",
  "before": "avant",
  "beforeOrOn": "avant ou le",
  "afterOrOn": "après ou le",
  "after": "après",
  "start": "Commencer",
  "end": "",
  "dragHeaderToGroup": "",
  "noRecords": "Non trouvé",
  "calendar.todayButtonText": "",
  "calendar.clearButtonText": "",
  "calendar.okButtonText": "",
  "calendar.cancelButtonText": "",
};

export default AG_GRID_LOCALE_FR;
