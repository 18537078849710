import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/ListeParcelle/tableparcelle";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ParcelleController from "../../config/apiUtils/ParcelleController";

export default function TableParcelles(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  useConstructor(async () => {
    setLoading(true);
    const typesSol = [];
    const resParcelles = await ParcelleController.getParcelles();
    store.setParcelles(resParcelles);
    resParcelles.forEach((parcelle) => {
      if (!typesSol.some((type) => type === parcelle.libelletypesol)) {
        typesSol.push(parcelle.libelletypesol);
      }
    });
    columns.forEach((column) => {
      if (column.name === "libelletypesol") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: typesSol.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.onClickShowFicheParcelle(rowIdx.data.idparcelle);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des parcelles</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idparcelle"
            emptyText="Aucune parcelle renseignée"
            columns={columns}
            dataSource={store.parcelles}
            defaultFilterValue={defaultFilterValue}
            onRowClick={onRowClick}
            style={gridStyle}
            pagination
            defaultLimit={100}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
