import APIController from "../APIController";

class ProfilController extends APIController {
  constructor() {
    super("/profil");
  }

  async getProfils() {
    return await this.getRequest("/");
  }

  async updateModaliteTypeDroitProfil(idprofil, idtypedroit, iddroitmodalite) {
    return await this.putRequest(
      `/${idprofil}/droit/${idtypedroit}?iddroitmodalite=${
        iddroitmodalite ?? "0"
      }`,
    );
  }
}

export default new ProfilController();
