import { Col, Row } from "reactstrap";

export default function Documentation() {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Documentation</h2>
        </Col>
      </Row>
    </>
  );
}
