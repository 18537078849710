import moment from "moment";
import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ReferentielController from "../../config/apiUtils/ReferentielController";

export default function FormFormationProducteur(props) {
  const store = useContext(StoreContext);

  const onSubmit = (event) => {
    const formation = {
      idreftypeformation: event.target.idreftypeformation.value,
      idproducteur: event.target.idproducteur.value,
      commentaire: event.target.commentaire.value,
      dateformation: event.target.dateformation.value,
      suivi: event.target.suivi.value,
      millesime: props.millesime,
    };
    props.onClickSaveNewFormation(formation);
    event.preventDefault();
  };

  const renderProducteurOptions = () => {
    return props.producteurs.map((producteur, key) => {
      return (
        <option key={key} value={producteur.idproducteur}>
          {producteur.nom} {producteur.prenom}
        </option>
      );
    });
  };

  const renderTypesFormationOptions = () => {
    if (store.referentiels.typesFormations != null) {
      return store.referentiels.typesFormations.map((typeFormation, key) => {
        return (
          <option key={key} value={typeFormation.idreftypeformation}>
            {typeFormation.libelle}
          </option>
        );
      });
    }
  };

  useConstructor(async () => {
    store.referentiels.setTypesFormations(
      await ReferentielController.getTypesFormations(),
    );
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Nouvelle formation sur {props.millesime}
        </CardTitle>
        <CloseButton onClick={props.hideFormNewFormation} />
        <CardSubtitle>
          Pour ajouter une nouvelle formation au système de gestion, remplissez
          les informations suivantes. Tous les champs étoilés sont requis.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="idreftypeformation">Type de formation *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idreftypeformation"
                >
                  {renderTypesFormationOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="dateformation">Date de la formation *</Label>
                <Input
                  type="date"
                  name="dateformation"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col md="6">
              <FormGroup>
                <Label for="idproducteur">Producteur *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idproducteur"
                  disabled={
                    props.idproducteur !== null &&
                    props.idproducteur !== undefined
                  }
                  value={props?.idproducteur}
                >
                  {renderProducteurOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="suivi">Formation suivie *</Label>
                <Input type="select" className="select-input" name="suivi">
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col md="12">
              <FormGroup>
                <Label for="commentaire">Commentaire sur la formation</Label>
                <Input
                  type="textarea"
                  name="commentaire"
                  placeholder="Commentaires relatifs à la formation"
                  defaultValue=""
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Button style={{ marginTop: 15 }} color="primary">
            Valider
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}
