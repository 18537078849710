import AuthService from "../../../config/AuthService";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

export const columns = [
  {
    header: "Nom de la parcelle",
    name: "nomparcelle",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => data.codepostal + " " + data.nomcommune,
  },
  {
    header: "Silo de livraison",
    name: "nomsilo",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libellevariete",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_PLAN_PRODUCTION", 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [],
      collapseOnSelect: true,
    },
  },
  {
    header: "Quantité de semences",
    name: "quantitesemence",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface engagée printemps",
    name: "surfaceengageeprintemps",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  },
  {
    header: "Surface engagée automne",
    name: "surfaceengageeautomne",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  },
  {
    header: "Surface engagée totale",
    name: "surfaceengagee",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle printemps",
    name: "surfaceprintemps",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    header: "Surface réelle automne",
    name: "surfaceautomne",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    header: "Quantité livrée",
    name: "quantitelivree",
    type: "number",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de sol",
    name: "libelletypesol",
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [].map((c) => {
        return { id: c, label: c };
      }),
    },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 300, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "nomparcelle", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  { name: "nomsilo", type: "string", operator: "contains", value: "" },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  {
    name: "libelletypesol",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  {
    name: "surfaceengageeprintemps",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfaceengageeautomne",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "surfaceengagee", type: "float", operator: "contains", value: "" },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "quantitelivree", type: "float", operator: "contains", value: "" },
  { name: "idilot", type: "string", operator: "contains", value: "" },
  { name: "surface", type: "string", operator: "contains", value: "" },
];
