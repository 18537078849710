import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { dateFilterEditorProps } from "../../../config/utils";
import colors from "../../../config/colors";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "exploitation", header: "Exploitation" },
  { name: "quantite", header: "Quantité" },
];

export const columns = [
  {
    header: "Validé administrativement",
    name: "idetatvalidationadministrative",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 1, label: "Non validé administrativement" },
        {
          id: 2,
          label:
            "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        },
        {
          id: 3,
          label:
            "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        },
        { id: 4, label: "Validé administrativement" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    resizable: true,
    render: ({ cellProps, data, value }) => {
      cellProps.style.border = "1px solid";
      if (data.valide === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
      } else {
        if (value === 1) {
          cellProps.style.background = "#f9d7da"; // DANGER
          cellProps.style.color = "#842029"; // DANGER
        } else {
          cellProps.style.background = colors.warning.background;
          cellProps.style.color = colors.warning.color;
        }
      }
      return data.libellevalidationadministrative;
    },
  },
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de dernière modification",
    name: "datedernieremodification",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Statut juridique",
    name: "exploitation.libellestatutjuridique",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Raison sociale",
    name: "exploitation.raisonsociale",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "exploitation.codedc",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Numéro UNILEVER",
    name: "exploitation.numerounilever",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "SIRET",
    name: "exploitation.siret",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Localisation",
    name: "exploitation.codeinsee",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code OS",
    name: "exploitation.codeos",
    group: "exploitation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nom de la parcelle",
    name: "parcelle.nomparcelle",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libellevariete",
    group: "production",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle printemps (ha)",
    name: "surfaceprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle automne (ha)",
    name: "surfaceautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface réelle totale (ha)",
    name: "surfacetotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Silo",
    name: "nomsilo",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value === null) {
        return "PAS DE SILO";
      }
      return value;
    },
  },
  {
    header: "Quantité de semences",
    name: "quantitesemence",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Rendement de référence moyen",
    type: "number",
    name: "exploitation.rendementrefmoyen",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Tonnage de référence moyen",
    name: "tonnageestime",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Traçabilité conforme",
    name: "estconforme",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value === true) {
        return "OUI";
      }
      return "NON";
    },
  },
  {
    header: "Zones de production",
    name: "exploitation.libellezoneproduction",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 700, color: "#ffffff", marginBottom: 20 };

export const defaultFilterValue = [
  {
    name: "idetatvalidationadministrative",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  {
    name: "datedernieremodification",
    type: "date",
    operator: "after",
    value: "",
  },
  {
    name: "exploitation.libellestatutjuridique",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.raisonsociale",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codedc",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codeos",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.numerounilever",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.siret",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "exploitation.codeinsee",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "parcelle.nomparcelle",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "libelletypeproduction",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "nomorganismestockeur",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "surfacetotale", type: "float", operator: "contains", value: "" },
  { name: "nomsilo", type: "string", operator: "contains", value: "" },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  {
    name: "exploitation.rendementrefmoyen",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "tonnage", type: "float", operator: "contains", value: "" },
  {
    name: "estconforme",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "exploitation.libellezoneproduction",
    type: "string",
    operator: "contains",
    value: "",
  },
];
