import { useContext, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import FicheProducteur from "./listeproducteurs/FicheProducteur";
import TableProducteurs from "./listeproducteurs/TableProducteurs";
import FormNewProducteur from "./listeproducteurs/FormNewProducteur";
import ProducteurController from "../config/apiUtils/ProducteurController";
import { createNotification } from "../config/utils";
import StoreContext from "../context/StoreContext";

export default function ListeProducteur() {
  const store = useContext(StoreContext);
  const [idproducteurToShow, setIdproducteurToShow] = useState();
  const [showFicheProducteur, setShowFicheProducteur] = useState(false);
  const [showNewProducteurForm, setShowNewProducteurForm] = useState(false);
  const [producteurs, setProducteurs] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadProducteurs = async () => {
    setLoading(true);
    const resProducteurs = await ProducteurController.getAllProducteurs();
    setProducteurs(resProducteurs);
    store.setProducteursMillesime(resProducteurs);
    setLoading(false);
  };

  const onClickShowFicheProducteur = (idproducteur) => {
    setIdproducteurToShow(idproducteur);
    setShowFicheProducteur(true);
  };

  const handleNewProducteur = async (producteur) => {
    const res = await ProducteurController.insertProducteur(producteur);
    if (res.status === 200) {
      createNotification("success", "Succès", "Producteur créé avec succès.");
      await loadProducteurs();
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des producteurs</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary">
            <i
              className="fa-solid fa-plus"
              onClick={() => setShowNewProducteurForm(true)}
            />
          </Button>
        </Col>
      </Row>
      <Collapse
        isOpen={showNewProducteurForm}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormNewProducteur
              onClickNewProducteur={handleNewProducteur}
              onClickClose={() => setShowNewProducteurForm(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={showFicheProducteur}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FicheProducteur
              idproducteur={idproducteurToShow}
              onClickClose={() => setShowFicheProducteur(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Row>
        <Col md="12">
          <TableProducteurs
            onClickShowFicheProducteur={onClickShowFicheProducteur}
            loadProducteurs={loadProducteurs}
            producteurs={producteurs}
            setProducteurs={setProducteurs}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}
