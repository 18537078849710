import { Col, Row } from "reactstrap";
import TableAppelCotisations from "./appelcotisations/TableAppelCotisations";
import ActionsGroupees from "../ActionsGroupees";

export default function AppelCotisations() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableAppelCotisations />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
