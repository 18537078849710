import { Col, Row } from "reactstrap";
import TableAppelIntentionsSemis from "./appelintentionssemis/TableAppelIntentionsSemis";
import ActionsGroupees from "../ActionsGroupees";

export default function AppelIntentionsSemis() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableAppelIntentionsSemis />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
