import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeTypeSilo from "./TypeSilo/ListeTypeSilo";
import { createNotification } from "../../config/utils";
import FormUpdateTypeSilo from "./TypeSilo/FormUpdateTypeSilo";
import FormNewTypeSilo from "./TypeSilo/FormNewTypeSilo";

export default function TypeSilo() {
  const store = useContext(StoreContext);
  const [showFormNewTypeSilo, setShowFormNewTypeSilo] = useState(false);
  const [showFormUpdateTypeSilo, setShowFormUpdateTypeSilo] = useState(false);
  const [idreftypesiloToUpdate, setIdreftypesiloToUpdate] = useState();

  const handleAddNewTypeSilo = async (typeSilo) => {
    const res = await ReferentielController.insertTypeSilo(typeSilo);
    if (res.status === 200) {
      createNotification("success", "Succès", "Type de silo créé avec succès");
      store.referentiels.setTypesSilo([
        ...store.referentiels.typesSilo,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du type de silo",
      );
    }
  };

  const handleUpdateTypeSilo = async (typeSilo) => {
    const res = await ReferentielController.updateTypeSilo(
      idreftypesiloToUpdate,
      typeSilo,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Type de silo modifié avec succès",
      );
      store.referentiels.setTypesSilo(
        await ReferentielController.getTypesSilo(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du type de silo",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewTypeSilo}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewTypeSilo
          onClickAdd={handleAddNewTypeSilo}
          onClickClose={() => setShowFormNewTypeSilo(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateTypeSilo}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateTypeSilo
          onClickUpdate={handleUpdateTypeSilo}
          onClickClose={() => setShowFormUpdateTypeSilo(false)}
          idreftypesilo={idreftypesiloToUpdate}
        />
      </Collapse>
      <ListeTypeSilo
        showFormUpdateTypeSilo={(idreftypesilo) => {
          setIdreftypesiloToUpdate(idreftypesilo);
          setShowFormUpdateTypeSilo(true);
        }}
        onClickNewType={() => setShowFormNewTypeSilo(true)}
      />
    </>
  );
}
