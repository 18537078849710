import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import CloseButton from "../../components/Buttons/CloseButton";
import CommuneInput from "../../components/CommuneInput";
import { RenderIf } from "../../config/utils";

export default function FormUpdateOrganismeStockeur(props) {
  const [idcommune, setIdCommune] = useState();
  const [organisme, setOrganisme] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrganismeStockeurController.getOrganismesStockeurById(
      props.idorganismestockeurSelected,
    ).then((res) => {
      setOrganisme(res);
      setLoading(false);
    });
  }, [props.idorganismestockeurSelected]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = {
      nomorganismestockeur: event.target.nomorganismestockeur.value,
      mail: event.target.mail.value,
      telephone: event.target.telephone.value,
      adresse: event.target.adresse.value,
      idcommune: event.target.idcommune.value,
    };
    await props.onClickValidate(form);
    props.hideFormUpdateOrganismeStockeur();
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Modification de l'organisme stockeur -{" "}
                {organisme.nomorganismestockeur}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.hideFormUpdateOrganismeStockeur} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={!loading}>
            <Form onSubmit={onSubmit}>
              <Row>
                <h5>Administratif</h5>
                <Col md="12">
                  <FormGroup>
                    <Label for="nomorganismestockeur">
                      Nom de l'organisme *
                    </Label>
                    <Input
                      type="text"
                      name="nomorganismestockeur"
                      placeholder="Nom de l'organisme"
                      defaultValue={organisme?.nomorganismestockeur}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="mail">Adresse e-mail de l'organisme *</Label>
                    <Input
                      type="text"
                      name="mail"
                      placeholder="Adresse e-mail de l'organisme"
                      defaultValue={organisme?.mail}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="telephone">
                      Numéro de téléphone de l'organisme *
                    </Label>
                    <Input
                      type="text"
                      name="telephone"
                      placeholder="Numéro de téléphone de l'organisme"
                      defaultValue={organisme?.telephone}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <h5>Localisation de l'organisme stockeur</h5>
                <Col md="6">
                  <FormGroup>
                    <Label for="adresse">Adresse *</Label>
                    <Input
                      type="text"
                      name="adresse"
                      placeholder="Adresse"
                      defaultValue={organisme.adresse}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <CommuneInput
                    codepostal={organisme?.codepostal}
                    idcommune={organisme?.idcommune}
                    setIdCommune={() => {}}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Button
                    style={{ marginTop: 15, marginRight: 15 }}
                    color="primary"
                    type="submit"
                  >
                    Valider
                  </Button>
                  <Button
                    style={{ marginTop: 15 }}
                    color="danger"
                    onClick={(event) => {
                      event.preventDefault();
                      props.hideFormUpdateOrganismeStockeur();
                    }}
                  >
                    Annuler
                  </Button>
                </Col>
              </Row>
            </Form>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
