import APIController from "../APIController";

class ExploitationController extends APIController {
  constructor() {
    super("/exploitation");
  }

  async getDernierDossierUtilisateur() {
    return await this.getRequest("/last");
  }

  async getExploitations() {
    return await this.getRequest("/");
  }

  async getExploitationByIdexploitation(idexploitation) {
    return await this.getRequest(`/${idexploitation}`);
  }

  async createExploitation(exploitation) {
    return await this.postRequest("/", exploitation);
  }

  async getFicheExploitation(millesime) {
    return await this.getRequest(`/fiche?millesime=${millesime}`);
  }

  async updateExploitation(idexploitation, exploitation, millesime) {
    return await this.putRequest(
      `/${idexploitation}?millesime=${millesime}`,
      exploitation,
    );
  }

  async changeExploitation(idexploitation) {
    const res = await this.putRequest(`/change/${idexploitation}`);
    //window.location.reload();

    return await res.data;
  }

  async desactivateExploitation(idexploitation) {
    return await this.deleteRequest(`/${idexploitation}`);
  }

  async getAllExploitations(raisonsociale, siret, idorganismestockeur) {
    return await this.getRequest("/all", {
      params: {
        raisonsociale: raisonsociale,
        siret: siret,
        idorganismestockeur: idorganismestockeur,
      },
    });
  }

  async getConventionExploitation(millesime, idexploitation) {
    return await this.getRequest(
      `/${idexploitation}/conventions?millesime=${millesime}`,
    );
  }

  async getContratExploitation(millesime, idexploitation) {
    return await this.getRequest(
      `/${idexploitation}/contrats?millesime=${millesime}`,
    );
  }

  async updateRendementRefExploitation(rendement, idexploitation) {
    return await this.putRequest(`/${idexploitation}/rendementref`, rendement);
  }

  async insertRendementRefExploitation(rendement, idexploitation) {
    return await this.postRequest(`/${idexploitation}/rendementref`, rendement);
  }

  async deleteRendementRefExploitation(
    idexploitation,
    idrendementrefexploitation,
  ) {
    return await this.deleteRequest(
      `/${idexploitation}/rendementref/${idrendementrefexploitation}`,
    );
  }

  async getZonesProductionByIdexploitation(idexploitation) {
    return await this.getRequest(`/${idexploitation}/zoneproduction`);
  }

  async getParcellesByIdexploitation(idexploitation, millesime) {
    return await this.getRequest(
      `/${idexploitation}/parcelles?millesime=${millesime}`,
    );
  }

  async getParcelleToImportFromLastMillesime(idexploitation, millesime) {
    return await this.getRequest(
      `/${idexploitation}/parcelles/importlastmillesime?millesime=${millesime}`,
    );
  }

  async importParcellesToMillesime(idexploitation, millesime, idparcelles) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/importlastmillesime?millesime=${millesime}`,
      idparcelles,
    );
  }

  async insertParcelleExploitation(idexploitation, millesime, parcelle) {
    return await this.postRequest(
      `/${idexploitation}/parcelles?millesime=${millesime}`,
      parcelle,
    );
  }

  async updateParcelleExploitation(idexploitation, idparcelle, parcelle) {
    return await this.putRequest(
      `/${idexploitation}/parcelles/${idparcelle}`,
      parcelle,
    );
  }

  async updateGeometrieParcelle(idexploitation, idparcelle, parcelle) {
    return await this.putRequest(
      `/${idexploitation}/parcelles/${idparcelle}/geometrie`,
      parcelle,
    );
  }

  async appropriateParcelleExploitation(idexploitation, millesime, idparcelle) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/${idparcelle}?millesime=${millesime}`,
    );
  }

  async associerParcelleExploitation(
    idexploitation,
    millesime,
    idparcelle,
    feature,
  ) {
    return await this.postRequest(
      `/${idexploitation}/parcelles/${idparcelle}/associer?millesime=${millesime}`,
      feature,
    );
  }

  async habilitationIgp(idexploitation, certification) {
    return await this.postRequest(
      `/${idexploitation}/certificationsigp/habilitation`,
      certification,
    );
  }
}

export default new ExploitationController();
