import { Alert, Col, Row } from "reactstrap";

export default function MaintenancePage() {
  return (
    <Row>
      <Col>
        <Alert color={"warning"}>
          <h4 className="alert-heading">
            {window.env.MESSAGE_MAINTENANCE_TITLE}
          </h4>
          {window.env.MESSAGE_MAINTENANCE.map((message, key) => (
            <p key={key}>{message}</p>
          ))}
        </Alert>
      </Col>
    </Row>
  );
}
