import APIController from "../APIController";

class ReferentielController extends APIController {
  constructor() {
    super("/referentiel");
  }

  async getEtatsValidationAdministrative() {
    return await this.getRequest("/etat/validationadministrative");
  }

  async getStatutsJuridique() {
    return await this.getRequest("/statutjuridique");
  }

  async updateStatutJuridique(idstatutjuridique, statutjuridique) {
    return await this.putRequest(
      `/statutjuridique/${idstatutjuridique}`,
      statutjuridique,
    );
  }

  async insertStatutJuridique(statutjuridique) {
    return await this.postRequest("/statutjuridique", statutjuridique);
  }

  async getTypesIntervention() {
    return await this.getRequest("/types/intervention");
  }

  async insertTypeIntervention(intervention) {
    return await this.postRequest("/types/intervention", intervention);
  }

  async updateTypeIntervention(idreftypeintervention, typeIntervention) {
    return await this.putRequest(
      `/types/intervention/${idreftypeintervention}`,
      typeIntervention,
    );
  }

  async getTypesMateriel() {
    return await this.getRequest("/types/materiel");
  }

  async insertTypeMateriel(materiel) {
    return await this.postRequest("/types/materiel", materiel);
  }

  async updateTypeMateriel(idreftypemateriel, typeMateriel) {
    return await this.putRequest(
      `/types/materiel/${idreftypemateriel}`,
      typeMateriel,
    );
  }

  async getMateriels() {
    return await this.getRequest("/materiel");
  }

  async insertMateriel(materiel) {
    return await this.postRequest("/materiel", materiel);
  }

  async updateMateriel(idrefmateriel, materiel) {
    return await this.putRequest(`/materiel/${idrefmateriel}`, materiel);
  }

  async getTypesProduction(onlyActif = false) {
    return await this.getRequest(`/types/production?onlyActif=${onlyActif}`);
  }

  async insertTypeProduction(typeProduction) {
    return await this.postRequest("/types/production", typeProduction);
  }

  async updateTypeProduction(idreftypeproduction, typeProduction) {
    return await this.putRequest(
      `/types/production/${idreftypeproduction}`,
      typeProduction,
    );
  }

  async getTypesProduit() {
    return await this.getRequest("/types/produit");
  }

  async insertTypeProduit(typeProduit) {
    return await this.postRequest("/types/produit", typeProduit);
  }

  async updateTypeProduit(idreftypeproduit, typeproduit) {
    return await this.putRequest(
      `/types/produit/${idreftypeproduit}`,
      typeproduit,
    );
  }

  async getProduits() {
    return await this.getRequest("/produit");
  }

  async updateProduit(idrefproduit, produit) {
    return await this.putRequest(`/produit/${idrefproduit}`, produit);
  }

  async insertProduit(produit) {
    return await this.postRequest("/produit", produit);
  }

  async getRefCotisation() {
    return await this.getRequest("/cotisation");
  }

  async getTypesVariete() {
    return await this.getRequest("/types/variete");
  }

  async insertTypeVariete(typeVariete) {
    return await this.postRequest("/types/variete", typeVariete);
  }

  async updateTypeVariete(idreftypevariete, typeVariete) {
    return await this.putRequest(
      `/types/variete/${idreftypevariete}`,
      typeVariete,
    );
  }

  async getVarietes(estutilisee = false) {
    return await this.getRequest(`/variete?estutilisee=${estutilisee}`);
  }

  async insertVariete(variete) {
    return await this.postRequest("/variete", variete);
  }

  async updateVariete(idrefvariete, variete) {
    return await this.putRequest(`/variete/${idrefvariete}`, variete);
  }

  async getTypesFormations() {
    return await this.getRequest("/types/formation");
  }

  async insertTypeFormation(typeformation) {
    return await this.postRequest("/types/formation", typeformation);
  }

  async updateTypeFormation(idreftypeformation, typeformation) {
    return await this.putRequest(
      `/types/formation/${idreftypeformation}`,
      typeformation,
    );
  }

  async updateRefCotisation(idrefcotisation, cotisation) {
    return await this.putRequest(`/cotisation/${idrefcotisation}`, cotisation);
  }

  async getTypesMatieresActives() {
    return await this.getRequest("/types/matiereactive");
  }

  async insertTypeMatiereActive(typematiereactive) {
    return await this.postRequest("/types/matiereactive", typematiereactive);
  }

  async updateTypeMatiereActive(idreftypematiereactive, typematiereactive) {
    return await this.putRequest(
      `/types/matiereactive/${idreftypematiereactive}`,
      typematiereactive,
    );
  }

  async getTypesSol() {
    return await this.getRequest("/types/sol");
  }

  async insertTypeSol(typesol) {
    return await this.postRequest("/types/sol", typesol);
  }

  async updateTypeSol(idreftypesol, typesol) {
    return await this.putRequest(`/types/sol/${idreftypesol}`, typesol);
  }

  async getTypesDocument() {
    return await this.getRequest("/types/document");
  }

  async insertTypeDocument(typedocument) {
    return await this.postRequest("/types/document", typedocument);
  }

  async updateTypeDocument(idreftypedocument, typedocument) {
    return await this.putRequest(
      `/types/document/${idreftypedocument}`,
      typedocument,
    );
  }

  async getTypesSilo() {
    return await this.getRequest("/types/silo");
  }

  async insertTypeSilo(typesilo) {
    return await this.postRequest("/types/silo", typesilo);
  }

  async updateTypeSilo(idreftypesilo, typesilo) {
    return await this.putRequest(`/types/silo/${idreftypesilo}`, typesilo);
  }

  async getCultures() {
    return await this.getRequest("/culture");
  }
}

export default new ReferentielController();
