import { Component } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";

export default class Section extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AccordionItem key={this.props.key}>
        <AccordionHeader
          className={this.props.className ?? "section-header"}
          targetId={this.props.id}
        >
          <h5>{this.props.title}</h5>
        </AccordionHeader>
        <AccordionBody
          style={this.props.styleBody ?? { padding: 0, marginBottom: 30 }}
          accordionId={this.props.id}
        >
          {this.props.children}
        </AccordionBody>
      </AccordionItem>
    );
  }
}
