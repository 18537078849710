import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CancelButton from "../../components/Buttons/CancelButton";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CommuneInput from "../../components/CommuneInput";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import useConstructor from "../../config/hooks/useConstructor";
import { createNotification, RenderIf } from "../../config/utils";
import StoreContext from "../../context/StoreContext";

export default function FormUpdateParcelle(props) {
  const store = useContext(StoreContext);
  const [parcelle, setParcelle] = useState({});
  const [loading, setLoading] = useState(false);

  const getParcelle = () => {
    const res = store.parcelles.find((p) => p.idparcelle === props.idparcelle);
    setParcelle(res);
  };

  const renderTypesSolOptions = () => {
    return store.referentiels.typesSol.map((type, key) => {
      return (
        <option key={key} value={type.idreftypesol}>
          {type.libelle}
        </option>
      );
    });
  };

  useEffect(() => {
    getParcelle();
  }, [props.idparcelle]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (
      event.target.idcommune.value === "" ||
      event.target.idcommune.value === null
    ) {
      createNotification(
        "error",
        "Commune non renseignée...",
        "Merci de renseigner la commune de la parcelle.",
      );
      return;
    }
    const form = {
      nomparcelle: event.target.nomparcelle.value,
      idreftypesol: event.target.idreftypesol.value,
      idcommune: event.target.idcommune.value,
      numeroparcelle: event.target.numeroparcelle.value,
      informationparcelle: event.target.informationparcelle.value,
    };
    const res = await ParcelleController.updateParcelle(props.idparcelle, form);

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        `Parcelle ${form.nomparcelle} modifiée avec succès !`,
      );
      props.onClickClose();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de la parcelle...",
      );
    }
  };

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setTypesSol(await ReferentielController.getTypesSol());
    setLoading(false);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Modification de la parcelle : {parcelle.nomparcelle}
        </CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Row>
            <h5>Informations administratives</h5>
          </Row>
          <Form onSubmit={onSubmit}>
            <Row style={{ marginBottom: 10 }}>
              <Col md={3}>
                <FormGroup>
                  <Label for="numeroparcelle">Numéro de la parcelle</Label>
                  <Input
                    type="text"
                    name="numeroparcelle"
                    value={parcelle.numeroparcelle}
                    onChange={(e) => {
                      const tmp = { ...parcelle };
                      tmp.numeroparcelle = e.target.value;
                      setParcelle(tmp);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label for="nomparcelle">Nom de la parcelle</Label>
                  <Input
                    type="text"
                    name="nomparcelle"
                    value={parcelle.nomparcelle}
                    onChange={(e) => {
                      const tmp = { ...parcelle };
                      tmp.nomparcelle = e.target.value;
                      setParcelle(tmp);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md={3}>
                <FormGroup>
                  <Label for="idreftypesol">Type de sol</Label>
                  <Input
                    name="idreftypesol"
                    className="select-input"
                    type="select"
                    value={parcelle.idreftypesol}
                    onChange={(e) => {
                      const tmp = { ...parcelle };
                      tmp.idreftypesol = e.target.value;
                      setParcelle(tmp);
                    }}
                    required
                  >
                    {renderTypesSolOptions()}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <CommuneInput
                  codepostal={parcelle?.codepostal}
                  idcommune={parcelle?.idcommune}
                  setIdCommune={() => {}}
                />
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for="informationparcelle">
                    Information sur la parcelle
                  </Label>
                  <Input
                    type="textarea"
                    name="informationparcelle"
                    value={parcelle.informationparcelle}
                    onChange={(e) => {
                      const tmp = { ...parcelle };
                      tmp.informationparcelle = e.target.value;
                      setParcelle(tmp);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <ValidateButton style={{ marginRight: 10 }} />
                <CancelButton
                  style={{ marginRight: 10 }}
                  onClick={props.onClickClose}
                />
              </Col>
            </Row>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
