import { useState, useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import useConstructor from "../../config/hooks/useConstructor";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import CloseButton from "../../components/Buttons/CloseButton";
import { RenderIf } from "../../config/utils";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import CommuneInput from "../../components/CommuneInput";

export default function FormNewParcelle(props) {
  const store = useContext(StoreContext);
  const [idcommune, setIdCommune] = useState(0);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setTypesSol(await ReferentielController.getTypesSol());
    setLoading(false);
  });

  const renderTypesSolOptions = () => {
    return store.referentiels.typesSol.map((type, key) => {
      return (
        <option key={key} value={type.idreftypesol}>
          {type.libelle}
        </option>
      );
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const parcelle = {
      nomparcelle: event.target.nomparcelle.value,
      idreftypesol: event.target.idreftypesol.value,
      idcommune: event.target.idcommune.value,
      numeroparcelle: event.target.numeroparcelle.value,
      informationparcelle: event.target.informationparcelle.value,
    };

    await props.handleSubmitNewParcelle(parcelle);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle parcelle</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Form onSubmit={onSubmit}>
            <Row style={{ marginBottom: 30 }}>
              <Col md="3">
                <Label for="numeroparcelle">Numéro de la parcelle</Label>
                <Input
                  type="number"
                  min="0"
                  defaultValue={0}
                  name="numeroparcelle"
                  placeholder="Numéro de la parcelle"
                />
              </Col>
              <Col md="5">
                <Label for="nomparcelle">Nom de la parcelle</Label>
                <Input
                  name="nomparcelle"
                  type="text"
                  placeholder="Nom de la parcelle"
                  required
                />
              </Col>
              <Col md="4">
                <Label for="idreftypesol">Type de sol</Label>
                <Input
                  name="idreftypesol"
                  className="select-input"
                  type="select"
                  required
                >
                  {renderTypesSolOptions()}
                </Input>
              </Col>
            </Row>
            <Row style={{ marginBottom: 30 }}>
              <Col md="6">
                <CommuneInput
                  codepostal={""}
                  idcommune={idcommune}
                  setIdCommune={() => {}}
                />
              </Col>
              <Col md={6}>
                <Label for="informationparcelle">
                  Information sur la parcelle
                </Label>
                <Input
                  type="textarea"
                  name="informationparcelle"
                  placeholder="Information sur la parcelle"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <ValidateButton style={{ marginRight: 10 }} />
                <CancelButton
                  style={{ marginRight: 10 }}
                  onClick={props.onClickClose}
                />
              </Col>
            </Row>
          </Form>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
