import { Col, Row } from "reactstrap";
import TableCertificationsIgp from "./certificationigp/TableCertificationsIgp";

export default function CertificationIgp() {
  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Certifications IGP</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30, minHeight: 500 }}>
        <Col md="12">
          <TableCertificationsIgp />
        </Col>
      </Row>
    </>
  );
}
