import APIController from "../APIController";

class PlanProductionController extends APIController {
  constructor() {
    super("/planproduction");
  }

  async getPlanProductionMillesime(millesime) {
    return await this.getRequest(`?millesime=${millesime}`);
  }

  async getPlanProductionPrevisionnelMillesime(millesime) {
    return await this.getRequest(`/previsionnel?millesime=${millesime}`);
  }

  async getPlanProductionPrevisionnelOrganismeStockeur(
    idorganismestockeur,
    millesime,
  ) {
    return await this.getRequest(
      `/previsionnel/organismestockeur/${idorganismestockeur}?millesime=${millesime}`,
    );
  }

  async getPlanProductionOrganismeStockeur(idorganismestockeur, millesime) {
    return await this.getRequest(
      `/organismestockeur/${idorganismestockeur}?millesime=${millesime}`,
    );
  }

  async getDetailsContrats(idcontrats) {
    return await this.getRequest(
      `/detail?${idcontrats
        .map((idcontrat) => "idcontrats=" + idcontrat)
        .join("&")}`,
    );
  }

  async getBilanOrganismeStockeur(millesime) {
    return await this.getRequest("/bilan/organismestockeur", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getBilanRecolte(millesime) {
    return await this.getRequest("/bilanrecolte", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getBilanRecolteByIdorganismestockeur(millesime, idorganismestockeur) {
    return await this.getRequest(`/bilanrecolte/${idorganismestockeur}`, {
      params: {
        millesime: millesime,
      },
    });
  }
}

export default new PlanProductionController();
