import APIController from "../APIController";

class CommuneController extends APIController {
  constructor() {
    super("/commune");
  }

  async getCommunes() {
    return await this.getRequest("/");
  }

  async getCommuneByIdCommune(idcommune) {
    return await this.getRequest(`/${idcommune}`);
  }

  async getGeometrieCommune(idcommune) {
    return await this.getRequest(`/${idcommune}/geometrie`);
  }

  async getCommunesByCodePostal(codePostal) {
    return await this.getRequest(`/codepostal/${codePostal}`);
  }

  async getCommunesByNomcommune(nomcommune) {
    return await this.getRequest(`/nomcommune/${nomcommune}`);
  }

  async getCommuneByCodeinsee(codeinsee) {
    return await this.getRequest(`/codeinsee/${codeinsee}`);
  }
}

export default new CommuneController();
