import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";
import { convertToCSV, downloadCsv, RenderIf } from "../../../config/utils";
import ActionGroupeeController from "../../../config/apiUtils/ActionGroupeeController";
import Grid from "../../../components/Grid";
import CsvButton from "../../../components/Buttons/CsvButton";
import moment from "moment";
import {
  columns,
  defaultFilterValue,
  gridStyle,
} from "../../../components/Grids/ActionsGroupees/historiqueformation";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";

export default function TableHistoriqueFormation() {
  const store = useContext(StoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMillesime, setIsMillesime] = useState(true);

  const loadData = async () => {
    setData([]);
    setLoading(true);
    if (isMillesime) {
      setData(
        await ActionGroupeeController.getHistoriqueFormation(
          store.millesime.idmillesime,
        ),
      );
    } else {
      setData(await ActionGroupeeController.getHistoriqueFormation());
    }
    setLoading(false);
    setIsMillesime(!isMillesime);
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  useConstructor(async () => {
    await loadData();
  });

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Historique des formations</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={data.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(data, ";", getColumns()),
                    "export_formations_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => loadData()}
            >
              {isMillesime ? store.millesime.idmillesime : "Tous"}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idformationproducteur"
            emptyText="Aucune information renseignée"
            columns={columns}
            dataSource={data}
            onRowClick={() => {}}
            style={gridStyle}
            defaultFilterValue={defaultFilterValue}
            pagination
            defaultLimit={50}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
