import APIController from "../APIController";

class FormationController extends APIController {
  constructor() {
    super("/formation");
  }

  async getFormations(millesime) {
    if (millesime) {
      return await this.getRequest(`/millesime/${millesime}`);
    }
    return await this.getRequest("/");
  }

  async getFormationsByIdproducteur(idproducteur, millesime = null) {
    return await this.getRequest(
      `/producteur/${idproducteur}${
        millesime ? "?millesime=" + millesime : ""
      }`,
    );
  }

  async createFormation(formation) {
    return await this.postRequest("/", formation);
  }

  async updateFormation(idformationproducteur, formation) {
    return await this.putRequest(`/${idformationproducteur}`, formation);
  }

  async deleteFormation(idformationproducteur) {
    return await this.deleteRequest(`/${idformationproducteur}`);
  }
}

export default new FormationController();
