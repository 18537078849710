import { useEffect, useState } from "react";
import { Button, Col, Collapse, Input, Label, Row } from "reactstrap";
import InfoButton from "../Buttons/InfoButton";

export default function ButtonLayer(props) {
  const [opacity, setOpacity] = useState(props.opacity);
  const [showAttribution, setShowAttribution] = useState(false);

  useEffect(() => {
    setOpacity(props.opacity);
  }, [props.opacity]);

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <Button
        style={{
          width: "100%",
          backgroundImage: `url(${props.background}) `,
          opacity: opacity > 0.5 ? opacity : 0.5,
          minHeight: 100,
          borderRadius: 20,
          cursor: "pointer",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          WebkitTextStroke: "1px #F8F8F8",
          textShadow: "0px 1px 4px #23430C",
        }}
        ref={props.ref}
        title={props.tooltip}
        onClick={() => {
          props.toggleVisibility();
          props.onClick();
        }}
      >
        <h5>{props.name}</h5>
      </Button>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 5,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Col md="2" style={{ display: "flex" }}>
          <InfoButton onClick={() => setShowAttribution(!showAttribution)} />
        </Col>
        <Col md="8" style={{ display: "flex" }}>
          <Input
            style={{
              padding: 10,
            }}
            id="exampleRange"
            name="range"
            type="range"
            max={1000}
            min={0}
            value={opacity * 1000}
            onChange={(e) => {
              setOpacity(e.target.value / 1000);
              props.setOpacity(e.target.value / 1000);
            }}
          />
        </Col>
        <Col md={2} style={{ display: "flex" }}>
          <i
            style={{
              borderRadius: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 30,
              width: 30,
            }}
            className="fa-solid fa-bars"
          ></i>
        </Col>
      </Row>
      <Collapse isOpen={showAttribution}>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Label check>
              <Input
                type={"checkbox"}
                defaultChecked={props.textFeatureShown}
                onChange={(e) => props.toggleTextFeatureShown(e.target.checked)}
              />
              &nbsp;Afficher texte
            </Label>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <h5>{props.tooltip}</h5>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="carto-attribution"
              dangerouslySetInnerHTML={{ __html: props.attribution }}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
}
