import { Col, Row } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/StoreContext";
import SurfacePotentielleController from "../config/apiUtils/SurfacePotentielleController";
import CardValidationIntentions from "./validationintentions/CardValidationIntentions";
import { createNotification } from "../config/utils";

export default function ValidationIntentions() {
  const [surfacesPotentielle, setSurfacesPotentielle] = useState([]);
  const [loading, setLoading] = useState(false);
  const context = useContext(StoreContext);

  const loadSurfacesPotentielles = async () => {
    setLoading(true);
    const resSurfaces =
      await SurfacePotentielleController.getSurfacesPotentielles(
        context.millesime.idmillesime,
      );
    setSurfacesPotentielle(
      resSurfaces.filter(
        (intention) =>
          !intention.abandon && intention.idetatvalidationadministrative < 4,
      ),
    );
    setLoading(false);
  };

  useEffect(() => {
    loadSurfacesPotentielles();
  }, []);

  const handleSubmitValidation = async (
    idetatvalidationadministrative,
    idsurfacepotentielles,
    valide,
  ) => {
    setLoading(true);

    const form = {
      idetatvalidationadministrative,
      idsurfacepotentielles,
      valide,
    };
    const res =
      await SurfacePotentielleController.updateValidationSurfacesPotentielles(
        form,
      );

    if (res.status === 200) {
      await loadSurfacesPotentielles();
      createNotification(
        "success",
        "Validation de " +
          idsurfacepotentielles.length +
          " intention" +
          (idsurfacepotentielles.length > 1 ? "s" : "") +
          " de semis modifiée avec succès",
      );
      return;
    }

    setLoading(false);
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Validation des intentions de semis</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}></Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <CardValidationIntentions
            intentions={surfacesPotentielle}
            loading={loading}
            handleSubmitValidation={handleSubmitValidation}
          />
        </Col>
      </Row>
    </>
  );
}
