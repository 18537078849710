import AuthService from "./AuthService";
import { createNotification } from "./utils";
import API from "./API";

class APIController {
  constructor(prefixe) {
    this.prefixe = prefixe;
    this.headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    this.api = new API();
    this.loadAPI();
  }

  async loadAPI() {
    if (
      localStorage.getItem("user") !== null &&
      (await AuthService.checkToken())
    ) {
      this.api.addHeaderAuthentication();
    } else {
      this.api.deleteHeaderAuthentication();
    }

    this.api.createAPI();
    localStorage.setItem("needToReload", "false");
  }

  logoutIfNeeded(status) {
    if (this.api.isCodeNeedsLogout(status)) {
      AuthService.logout(true);
    }
  }

  async getRequest(url, header) {
    try {
      await this.loadAPI();
      const res = await this.api.get(this.prefixe + url, header);
      return res.data;
    } catch (e) {
      createNotification("error", "Erreur API", e.response.data.detail);
      this.logoutIfNeeded(e.response.status);
      return e.response;
    }
  }

  async postRequest(url, data, headers) {
    try {
      await this.loadAPI();
      const res = await this.api.post(this.prefixe + url, data, headers);
      return res;
    } catch (e) {
      createNotification("error", "Erreur API", e.response.data.detail);
      this.logoutIfNeeded(e.response.status);
      return e.response;
    }
  }

  async putRequest(url, data, headers) {
    try {
      await this.loadAPI();
      const res = await this.api.put(this.prefixe + url, data, headers);
      return res;
    } catch (e) {
      createNotification("error", "Erreur API", e.response.data.detail);
      this.logoutIfNeeded(e.response.status);
      return e.response;
    }
  }

  async deleteRequest(url) {
    try {
      await this.loadAPI();
      const res = await this.api.delete(this.prefixe + url);
      return res;
    } catch (e) {
      createNotification("error", "Erreur API", e.response.data.detail);
      this.logoutIfNeeded(e.response.status);
      return e.response;
    }
  }
}

export default APIController;
