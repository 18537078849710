import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../../components/Grid";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import useConstructor from "../../../config/hooks/useConstructor";
import StoreContext from "../../../context/StoreContext";

const columns = [
  {
    header: "Type de matériel",
    name: "libelletypemateriel",
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Matériel",
    name: "libellemateriel",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const gridStyle = { minHeight: 500, color: "#ffffff" };

export default function ListeMateriel(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    store.referentiels.setMateriels(await ReferentielController.getMateriels());
    store.referentiels.setTypesMateriels(
      await ReferentielController.getTypesMateriel(),
    );
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    props.showFormUpdateMateriel(rowIdx.data.idrefmateriel);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Matériels</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button color="primary" onClick={props.onClickNewMateriel}>
                <i className="fa-solid fa-plus" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              style={
                loading ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {loading && <SpinLoadingAnimation />}
              {store.referentiels.materiels !== null &&
                store.referentiels.materiels !== [] &&
                store.referentiels.materiels !== undefined &&
                !loading && (
                  <Grid
                    enableCellSelect={true}
                    idProperty="idreftypemateriel"
                    emptyText="Aucun matériel renseigné"
                    columns={columns}
                    dataSource={store.referentiels.materiels}
                    style={gridStyle}
                    onRowClick={onRowClick}
                    pagination
                    defaultLimit={20}
                  />
                )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
