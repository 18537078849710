import { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import OrganismeStockeurController from "../config/apiUtils/OrganismeStockeurController";
import UtilisateurController from "../config/apiUtils/UtilisateurController";
import useConstructor from "../config/hooks/useConstructor";
import FicheAjoutDossier from "./listeutilisateurs/FicheAjoutDossier";
import FormNewUtilisateur from "./listeutilisateurs/FormNewUtilisateur";
import FormUpdateUtilisateur from "./listeutilisateurs/FormUpdateUtilisateur";
import TableUtilisateurs from "./listeutilisateurs/TableUtilisateurs";
import { createNotification, RenderIf, unflatten } from "../config/utils";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";

export default function ListeUtilisateurs() {
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [showNewUtilisateurForm, setShowNewUtilisateurForm] = useState(false);
  const [showUpdateUtilisateurForm, setShowUpdateUtilisateurForm] =
    useState(false);
  const [selectedUtilisateur, setSelectedUtilisateur] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAjoutDossierForm, setShowAjoutDossierForm] = useState(false);

  const loadUtilisateurs = async () => {
    setLoading(true);
    const res = await UtilisateurController.getUtilisateurs();
    const resOrganismes =
      await OrganismeStockeurController.getOrganismesStockeur();
    res.forEach((utilisateur) => {
      if (utilisateur.idorganismestockeurs.length > 0) {
        utilisateur.nomorganismestockeur = resOrganismes.find(
          (o) => o.idorganismestockeur === utilisateur.idorganismestockeurs[0],
        ).nomorganismestockeur;
      }
    });
    setUtilisateurs(res);
    setLoading(false);
  };

  useConstructor(async () => {
    await loadUtilisateurs();
  });

  const onClickUtilisateur = async (rowIdx) => {
    /* TODO :
    - Masquer la liste des utilisateurs (optionnel ?)
    - Afficher une vue de modification de l'utilisateur
    - Afficher une vue d'affectation de dossier
    - Ces 2 vues ensembles ? Une seule Card ?
    */
    setSelectedUtilisateur(unflatten(rowIdx.data));
    setShowUpdateUtilisateurForm(true);
  };

  const handleUpdateUtilisateur = async (utilisateur) => {
    const resUser = await UtilisateurController.modifierUtilisateur(
      selectedUtilisateur.idutilisateur,
      utilisateur,
    );

    if (resUser.status === 200) {
      createNotification("success", "Succès", "Utilisateur modifié");
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de l'utilisateur",
      );
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des utilisateurs</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            color="primary"
            onClick={() => setShowNewUtilisateurForm(true)}
          >
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={showNewUtilisateurForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormNewUtilisateur
              onSuccessNewUtilisateur={async () => {
                createNotification(
                  "success",
                  "Succès",
                  "Utilisateur ajouté avec succès. Vous pouvez maintenant configurer ses accès.",
                );
                const resUtilisateurs =
                  await UtilisateurController.getUtilisateurs();
                setUtilisateurs(resUtilisateurs);
              }}
              onErrorNewUtilisateur={async () => {
                createNotification(
                  "error",
                  "Erreur",
                  "Une erreur s'est produite à la création de l'utilisateur...",
                );
              }}
              hideFormNewUtilisateur={() => setShowNewUtilisateurForm(false)}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={showUpdateUtilisateurForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <FormUpdateUtilisateur
              close={() => {
                setShowUpdateUtilisateurForm(false);
                setShowAjoutDossierForm(false);
              }}
              reload={async () => loadUtilisateurs()}
              utilisateur={selectedUtilisateur}
              onSuccessNewUtilisateur={async () => {
                const resUtilisateurs =
                  await UtilisateurController.getUtilisateurs();
                setUtilisateurs(resUtilisateurs);
              }}
              hideFormUpdateUtilisateur={() =>
                setShowUpdateUtilisateurForm(false)
              }
              onClickUpdateUtilisateur={handleUpdateUtilisateur}
              onClickAddDossier={() => setShowAjoutDossierForm(true)}
            />
          </Col>
        </Row>
        <Collapse isOpen={showAjoutDossierForm} mountOnEnter unmountOnExit>
          <Row>
            <Col md="12">
              <FicheAjoutDossier
                onClickClose={() => setShowAjoutDossierForm(false)}
                utilisateur={selectedUtilisateur}
              />
            </Col>
          </Row>
        </Collapse>
      </Collapse>
      <Collapse isOpen={!showUpdateUtilisateurForm} mountOnEnter unmountOnExit>
        <Row style={{ marginBottom: 30 }}>
          <Col md="12">
            <RenderIf isTrue={loading}>
              <SpinLoadingAnimation />
            </RenderIf>
            <RenderIf isTrue={!loading}>
              <TableUtilisateurs
                onClickUtilisateur={onClickUtilisateur}
                utilisateurs={utilisateurs}
                loading={loading}
              />
            </RenderIf>
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
