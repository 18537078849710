import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import CiviliteController from "../../config/apiUtils/CiviliteController";
import useConstructor from "../../config/hooks/useConstructor";

export default function FormNewProducteur(props) {
  const store = useContext(StoreContext);

  const [civiliteProducteur, setCiviliteProducteur] = useState(0);

  const onSubmit = (event) => {
    const producteurForm = {
      idcivilite: event.target.idcivilite.value,
      idexploitation: event.target.idexploitation.value,
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      mail: event.target.mail.value,
      telephonefixe: event.target.telephonefixe.value,
      telephoneportable: event.target.telephoneportable.value,
    };
    props.onClickNewProducteur(producteurForm);
    event.preventDefault();
    props.hideFormNewProducteur();
    event.target.reset();
  };

  const renderCivilitesOptions = () => {
    return store.civilites.map((civilite, key) => {
      return (
        <option key={key} value={civilite.idcivilite}>
          {civilite.libelle}
        </option>
      );
    });
  };

  useConstructor(async () => {
    store.setCivilites(await CiviliteController.getCivilites());
  });

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Nouveau producteur</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.hideFormNewProducteur} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md="3">
                    <Label for="idcivilite">Civilité *</Label>
                    <FormGroup>
                      <Input
                        type="select"
                        className="select-input"
                        name="idcivilite"
                        value={civiliteProducteur}
                        onChange={(event) =>
                          setCiviliteProducteur(event.target.value)
                        }
                      >
                        {renderCivilitesOptions()}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <Label for="nom">NOM *</Label>
                    <Input type="text" name="nom" placeholder="Nom"></Input>
                  </Col>
                  <Col md="3">
                    <Label for="prenom">Prénom *</Label>
                    <Input
                      type="text"
                      name="prenom"
                      placeholder="Prénom"
                    ></Input>
                  </Col>
                  <Col md="3">
                    <Label for="idexploitation">Exploitation *</Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="idexploitation"
                      disabled
                      readOnly
                    >
                      <option value={props.exploitation.idexploitation}>
                        {props.exploitation.raisonsociale}
                      </option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Label for="mail">Adresse e-mail</Label>
                    <Input
                      type="email"
                      name="mail"
                      placeholder="Adresse e-mail"
                    ></Input>
                  </Col>
                  <Col md="4">
                    <Label for="telephonefixe">Téléphone fixe</Label>
                    <Input
                      type="text"
                      name="telephonefixe"
                      placeholder="Téléphone fixe"
                    ></Input>
                  </Col>
                  <Col md="4">
                    <Label for="telephoneportable">Téléphone mobile</Label>
                    <Input
                      type="text"
                      name="telephoneportable"
                      placeholder="Téléphone mobile"
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Button
                      style={{ marginTop: 15, marginRight: 15 }}
                      type="submit"
                      color="primary"
                    >
                      Valider
                    </Button>
                    <Button
                      style={{ marginTop: 15 }}
                      color="danger"
                      onClick={(event) => {
                        event.preventDefault();
                        props.hideFormNewProducteur();
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
