import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ZoneProductionController from "../../config/apiUtils/ZoneProductionController";
import StoreContext from "../../context/StoreContext";
import ListeZoneProduction from "./ZoneProduction/ListeZoneProduction";
import FormNewZoneProduction from "./ZoneProduction/FormNewZoneProduction";
import FormUpdateZoneProduction from "./ZoneProduction/FormUpdateZoneProduction";
import { createNotification } from "../../config/utils";

export default function ZoneProduction() {
  const store = useContext(StoreContext);
  const [showFormNewZoneProduction, setShowFormNewZoneProduction] =
    useState(false);
  const [showFormUpdateZoneProduction, setShowFormUpdateZoneProduction] =
    useState(false);
  const [idrefzoneproductionToUpdate, setIdrefzoneproductionToUpdate] =
    useState();

  const handleAddNewZoneProduction = async (zoneProduction) => {
    const res = await ZoneProductionController.insertZoneProduction(
      zoneProduction,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Zone de production créée avec succès",
      );
      store.setZonesProduction([...store.zonesProduction, res.data]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création de la zone de production",
      );
    }
  };

  const handleUpdateZoneProduction = async (zoneProduction) => {
    const res = await ZoneProductionController.updateZoneProduction(
      idrefzoneproductionToUpdate,
      zoneProduction,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Zone de production modifiée avec succès",
      );
      store.setZonesProduction(
        await ZoneProductionController.getZonesProduction(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification de la zone de production",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewZoneProduction}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewZoneProduction
          onClickAdd={handleAddNewZoneProduction}
          onClickClose={() => setShowFormNewZoneProduction(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateZoneProduction}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateZoneProduction
          onClickUpdate={handleUpdateZoneProduction}
          onClickClose={() => setShowFormUpdateZoneProduction(false)}
          idrefzoneproduction={idrefzoneproductionToUpdate}
        />
      </Collapse>
      <ListeZoneProduction
        showFormUpdateZoneProduction={(idrefzoneproduction) => {
          setIdrefzoneproductionToUpdate(idrefzoneproduction);
          setShowFormUpdateZoneProduction(true);
        }}
        onClickNewZoneProduction={() => setShowFormNewZoneProduction(true)}
      />
    </>
  );
}
