import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Alert } from "reactstrap";
import { dateFilterEditorProps } from "../../../config/utils";

export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "codedc",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Formation",
    name: "libelleformation",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nom",
    name: "nom",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Prénom",
    name: "prenom",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Mail",
    name: "mail",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone portable",
    name: "telephoneportable",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Téléphone fixe",
    name: "telephonefixe",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de la formation",
    name: "dateformation",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Millésime",
    name: "millesime",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Formation suivie",
    name: "suivi",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ value }) => {
      if (value === true) {
        return (
          <div style={{ display: "flex" }}>
            <Alert
              fade={false}
              color="success"
              style={{
                height: 12,
                padding: "0rem 1.5rem 1.5rem 1.5rem",
                marginTop: "1rem",
              }}
            >
              OUI
            </Alert>
          </div>
        );
      }
      return (
        <div style={{ display: "flex" }}>
          <Alert
            fade={false}
            color="danger"
            style={{
              height: 12,
              padding: "0rem 1.5rem 1.5rem 1.5rem",
              marginTop: "1rem",
            }}
          >
            NON
          </Alert>
        </div>
      );
    },
  },
  {
    header: "Commentaire",
    name: "commentaire",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  {
    name: "libelleformation",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "nom", type: "string", operator: "contains", value: "" },
  { name: "prenom", type: "string", operator: "contains", value: "" },
  { name: "mail", type: "string", operator: "contains", value: "" },
  {
    name: "telephoneportable",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "telephonefixe", type: "string", operator: "contains", value: "" },
  { name: "dateformation", type: "date", operator: "after", value: "" },
  { name: "suivi", type: "select", value: [], operator: "inlist" },
  { name: "millesime", type: "string", operator: "contains", value: "" },
  { name: "commentaire", type: "string", operator: "contains", value: "" },
];
