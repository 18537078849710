import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormNewTypeIntervention(props) {
  const onSubmit = (event) => {
    const typeIntervention = {
      libelle: event.target.libelle.value,
    };
    props.onClickAdd(typeIntervention);
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouveau type d'intervention</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="12">
              <Label for="libelle">Type d'intervention</Label>
              <Input
                name="libelle"
                placeholder={"Type d'intervention"}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
