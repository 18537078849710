import { useContext, useEffect } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  groups,
} from "../../components/Grids/Contrat/contrats";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf, convertToCSV, downloadCsv } from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";

export default function ContratsExploitation(props) {
  const store = useContext(StoreContext);
  const onRowClick = async (rowIdx) => {
    props.onSelectContrat(rowIdx.data.idcontrat);
  };

  useEffect(() => {
    const organismes = [];
    const typesproduction = [];
    store.contrats.forEach((contrat) => {
      if (
        !organismes.some(
          (organisme) => organisme === contrat.nomorganismestockeur,
        )
      ) {
        organismes.push(contrat.nomorganismestockeur);
      }

      if (
        !typesproduction.some((type) => type === contrat.libelletypeproduction)
      ) {
        typesproduction.push(contrat.libelletypeproduction);
      }
    });

    columns.forEach((column) => {
      if (column.name === "nomorganismestockeur") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: organismes.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
      if (column.name === "libelletypeproduction") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: typesproduction.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
  }, [store.contrats]);

  const renderGrid = () => {
    return (
      <Grid
        enableCellSelect={true}
        idProperty="idcontrat"
        columns={columns}
        dataSource={store.contrats}
        style={gridStyle}
        defaultFilterValue={props.filterValueTableauContrat}
        onRowClick={onRowClick}
        onFilterValueChange={(value) =>
          props.setFilterValueTableauContrat(value)
        }
        groups={groups}
        pagination
        emptyText="Aucun contrat trouvé"
        defaultLimit={50}
      />
    );
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    delete tmp["desactiver"];
    return tmp;
  };

  const getDataForCsv = () => {
    var temp = [...store.contrats];
    temp.forEach((row) => {
      row.idetatvalidationadministrative = row.libellevalidationadministrative;
      row["cotisation.0.paye"] = row.cotisation[0].paye ? "OUI" : "NON";
    });
    return temp;
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Contrats de production</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf isTrue={store.contrats.length > 0}>
                <CsvButton
                  color="primary"
                  onClick={() => {
                    downloadCsv(
                      convertToCSV(getDataForCsv(), ";", getColumns()),
                      "export_contrats_" + moment().format("YYYY-MM-DD"),
                    );
                  }}
                />
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={props.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!props.loading}>{renderGrid()}</RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
