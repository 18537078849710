import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Grid from "../../../components/Grid";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

const columns = [
  {
    header: "Matière active",
    name: "libellematiereactive",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Quantité",
    name: "quantite",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Unité",
    name: "unite",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const gridStyle = { minHeight: 300, color: "#ffffff" };

export default function FormUpdateProduit(props) {
  const store = useContext(StoreContext);
  const [produit, setProduit] = useState(
    store.referentiels.produits.find(
      (produit) => produit.idrefproduit === props.idrefproduit,
    ),
  );
  const [libelle, setLibelle] = useState(produit.libelleproduit);
  const [idreftypeproduit, setIdreftypeproduit] = useState(
    produit.idreftypeproduit,
  );
  const [unite, setUnite] = useState(produit.unite);
  const [estutilise, setEstutilise] = useState(produit.estutilise);

  const onSubmit = (event) => {
    props.onClickUpdate({
      idreftypeproduit: idreftypeproduit,
      libelle: libelle,
      unite: unite,
      estutilise: estutilise,
    });
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const prod = store.referentiels.produits.find(
      (produit) => produit.idrefproduit === props.idrefproduit,
    );
    setProduit(prod);
    setLibelle(prod.libelleproduit);
    setIdreftypeproduit(prod.idreftypeproduit);
    setEstutilise(prod.estutilise);
  }, [props.idrefproduit, store.referentiels.produits]);

  const renderTypesProduitOptions = () => {
    return store.referentiels.typesProduits.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduit}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Produit : {produit.libelleproduit}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: 15 }}>
            <Col md="3">
              <FormGroup>
                <Label for="idreftypeproduit">Type de produit</Label>
                <Input
                  name="idreftypeproduit"
                  type="select"
                  className="select-input"
                  value={idreftypeproduit}
                  onChange={(event) => setIdreftypeproduit(event.target.value)}
                >
                  {renderTypesProduitOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="libelle">Produit</Label>
                <Input
                  name="libelle"
                  placeholder={"Produit"}
                  type="text"
                  value={libelle}
                  onChange={(event) => setLibelle(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="unite">Unité</Label>
                <Input
                  name="unite"
                  placeholder={"Unité"}
                  type="text"
                  value={unite}
                  onChange={(event) => setUnite(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="estutilise">Produit utilisé</Label>
                <Input
                  name="estutilie"
                  type="select"
                  className="select-input"
                  defaultValue={produit.estutilise}
                  onChange={(event) => setEstutilise(event.target.value)}
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Grid
                enableCellSelect={true}
                idProperty="idreftypematiereactive"
                emptyText="Aucune matière active renseignée"
                columns={columns}
                dataSource={produit.matieres}
                style={gridStyle}
                pagination
                defaultLimit={20}
              />
            </Col>
            <Col md="7"></Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
