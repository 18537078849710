import jwt_decode from "jwt-decode";
import { createNotification, getTimestampInSeconds } from "./utils";
import API from "./API";
import droits from "./CodesDroits";

class AuthService {
  constructor() {
    this.api = new API();
    this.refreshing = false; // flag indicating whether refreshing is in progress
    this.waiting = []; // array to store callbacks of requests waiting for refresh
  }

  handleWaiting(isLogged) {
    while (this.waiting.length) {
      this.waiting.pop()(isLogged); // resolve all waiting requests
    }
  }

  async setDroitsInStorage() {
    const resDroits = await this.getDroitsCurrentUserFromApi();
    if (resDroits.status === 200 && resDroits.data) {
      localStorage.setItem("droits", JSON.stringify(resDroits.data));
    }
  }

  async setProfilsInStorage() {
    const resProfils = await this.getProfilsUserFromApi();
    if (resProfils.status === 200 && resProfils.data) {
      localStorage.setItem("profils", JSON.stringify(resProfils.data));
    }
  }

  async login(user) {
    try {
      this.api.setHeaderContentType("application/x-www-form-urlencoded");
      const params = new URLSearchParams();
      params.append("username", user.username);
      params.append("password", user.password);
      const res = await this.api.post("/token", params);
      if (res.data.access_token) {
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.removeItem("hideMentionsLegales");

        await this.loadAPI();

        await this.setDroitsInStorage();
        await this.setProfilsInStorage();

        return true;
      }

      return {
        success: false,
      };
    } catch (e) {
      return {
        success: false,
        message: e.response.data.detail,
      };
    } finally {
      this.api.setHeaderContentType("application/json");
    }
  }

  isLoggedIn() {
    try {
      if (localStorage.getItem("user")) {
        return this.checkToken();
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async checkToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    const expiration_date = 1000 * user.expiration;
    const now = Date.now();

    if (jwt_decode(user.refresh_token).exp <= getTimestampInSeconds()) {
      this.logout(true);
      return false;
    }

    if (expiration_date <= now) {
      if (!this.refreshing) {
        this.refreshing = true;
        const isLogged = await this.refreshToken();
        this.refreshing = false;
        this.handleWaiting(isLogged);
        return isLogged;
      } else {
        return new Promise((resolve) => {
          this.waiting.push(resolve); // if refreshToken already in progress, queue the callback.
        });
      }
    }
    return true;
  }

  async refreshToken() {
    try {
      const refresh_token = JSON.parse(
        localStorage.getItem("user"),
      ).refresh_token;

      if (jwt_decode(refresh_token).exp <= getTimestampInSeconds()) {
        this.logout(true);
        return false;
      }

      const data = {
        refresh_token: refresh_token,
      };
      this.api.setHeaderContentType("application/json");
      const res = await this.api.post("/refresh", data);
      if (res.data.access_token) {
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("needToReload", "true");
        return true;
      }
      this.logout(true);
      return false;
    } catch (e) {
      this.logout(true);
      return false;
    }
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  async createUserCodeReinitPassword(username) {
    await this.loadAPI();
    const res = await this.api.post(
      `/utilisateur/reinitpassword?username=${encodeURI(username)}`,
    );
    if (res.status === 200) {
      return res.data;
    }
  }

  async validateUserCodeReinitPassword(data) {
    await this.loadAPI();
    const res = await this.api.post(
      "/utilisateur/validatereinitpassword",
      data,
    );
    if (res.status === 200) {
      return res.data;
    }
  }

  getQueryParam(item) {
    return new URLSearchParams(window.location.search).get(item);
  }

  logout(doRedirectUri = true) {
    const redirect_uri = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    this.clearLocalStorage();

    window.location.reload();
    window.location.replace(
      doRedirectUri
        ? `/auth/login?redirect_uri=${redirect_uri}&loggedout=true`
        : "/auth/login?loggedout=true",
    );
  }

  async createFormulaireUtilisateur(formulaire) {
    try {
      await this.loadAPI();
      const res = await this.api.post("/utilisateur/formulaire", formulaire);
      return res;
    } catch (e) {
      return e.response;
    }
  }

  async loadAPI() {
    if (localStorage.getItem("user") !== null && (await this.checkToken())) {
      this.api.addHeaderAuthentication();
    } else {
      this.api.deleteHeaderAuthentication();
    }

    this.api.createAPI();
    localStorage.setItem("needToReload", "false");
  }

  async getProfilsUserFromApi() {
    try {
      const res = await this.api.get("/profil/utilisateur");

      return res;
    } catch (e) {
      createNotification("error", "Erreur", e.response.data.detail);
    }
  }

  async getDroitsCurrentUserFromApi() {
    try {
      const res = await this.api.get("/profil/droits/utilisateur");

      return res;
    } catch (e) {
      createNotification("error", "Erreur", e.response.data.detail);
    }
  }

  getCurrentToken() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getCurrentDroits() {
    return JSON.parse(localStorage.getItem("droits"));
  }

  hasDroit(codetypedroit, modalite) {
    const droits = this.getCurrentDroits();
    if (droits == null) {
      return false;
    }
    let res = false;
    droits.forEach((droit) => {
      if (
        droit.codetypedroit === codetypedroit &&
        droit.iddroitmodalite >= modalite
      ) {
        res = true;
      }
    });
    return res;
  }

  hasProfil(codeprofil) {
    if (
      JSON.parse(localStorage.getItem("profils")) == null ||
      Object.keys(JSON.parse(localStorage.getItem("profils"))).length === 0
    ) {
      return false;
    }
    return JSON.parse(localStorage.getItem("profils")).some(
      (profil) => profil.codeprofil === codeprofil,
    );
  }

  hasOrganismeStockeur(idorganismestockeur) {
    if (JSON.parse(localStorage.getItem("profils")) == null) {
      return false;
    }
    return JSON.parse(localStorage.getItem("profils")).some(
      (profil) => profil.idorganismestockeur === idorganismestockeur,
    );
  }

  isOrganismeStockeur() {
    if (JSON.parse(localStorage.getItem("profils")) == null) {
      return false;
    }
    return JSON.parse(localStorage.getItem("profils")).some(
      (profil) => profil.idorganismestockeur != null,
    );
  }

  isAPGMB() {
    if (JSON.parse(localStorage.getItem("profils")) == null) {
      return false;
    }
    return JSON.parse(localStorage.getItem("profils")).some((profil) =>
      [
        droits.profils.ADMINISTRATEUR,
        droits.profils.RESPO_ADMIN,
        droits.profils.CONSEILLER,
      ].includes(profil.codeprofil),
    );
  }
}

export default new AuthService();
