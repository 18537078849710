import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import colors from "../../../config/colors";
import { dateFilterEditorProps } from "../../../config/utils";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "production", header: "Production" },
  { name: "abandon", header: "Abandon" },
];

export const columns = [
  {
    header: "Etat",
    name: "validationapgmb",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "Validé";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "Non validé";
    },
  },
  {
    header: "Numéro annexe",
    name: "numeroannexe",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de l'annexe",
    name: "dateannexe",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Changement d'adresse",
    name: "changementadresse",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background; // DANGER
        cellProps.style.color = colors.danger.color; // DANGER
        return "OUI";
      }
      cellProps.style.background = colors.success.background; // SUCCESS
      cellProps.style.color = colors.success.color; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Changement de statut",
    name: "changementstatut",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background; // DANGER
        cellProps.style.color = colors.danger.color; // DANGER
        return "OUI";
      }
      cellProps.style.background = colors.success.background; // SUCCESS
      cellProps.style.color = colors.success.color; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Perte de Label",
    name: "pertelabel",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background; // DANGER
        cellProps.style.color = colors.danger.color; // DANGER
        return "OUI";
      }
      cellProps.style.background = colors.success.background; // SUCCESS
      cellProps.style.color = colors.success.color; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Perte de récolte",
    name: "perterecolte",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.danger.background; // DANGER
        cellProps.style.color = colors.danger.color; // DANGER
        return "OUI";
      }
      cellProps.style.background = colors.success.background; // SUCCESS
      cellProps.style.color = colors.success.color; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Modification de surfaces",
    name: "modificationparcelles",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, data }) => {
      cellProps.style.border = "1px solid";
      if (
        data.modificationparcelles ||
        data.ajoutparcelles ||
        data.suppressionparcelles
      ) {
        cellProps.style.background = colors.danger.background; // DANGER
        cellProps.style.color = colors.danger.color; // DANGER
        return "OUI";
      }
      cellProps.style.background = colors.success.background; // SUCCESS
      cellProps.style.color = colors.success.color; // SUCCESS
      return "NON";
    },
  },
];

export const gridStyle = { minHeight: 300, color: "#ffffff" };

export const defaultFilterValue = [
  {
    name: "idetatvalidationadministrative",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "cotisation.0.paye", type: "select", value: [], operator: "inlist" },
  {
    name: "dateenvoifacturecotisation",
    type: "date",
    operator: "after",
    value: "",
  },
  { name: "datecontrat", type: "date", operator: "after", value: "" },
  {
    name: "nomorganismestockeur",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "libelletypeproduction",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "nbparcelles", type: "select", operator: "inlist", value: [] },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "surfacetotale", type: "float", operator: "contains", value: "" },
  { name: "abandon", type: "select", value: [], operator: "inlist" },
];
