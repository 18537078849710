import { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getQueryParam, hash, RenderIf } from "../../config/utils";
import AuthService from "../../config/AuthService";
import SpinLoadingAnimation from "../SpinLoadingAnimation";

export default function ResetPasswordCard(props) {
  const [loading, setLoading] = useState(false);
  const [
    showRepeatedNewPasswordErrorAlert,
    setShowRepeatedNewPasswordErrorAlert,
  ] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (event.target.password.value !== event.target.repeatedpassword.value) {
      setLoading(false);
      setShowRepeatedNewPasswordErrorAlert(true);
    } else {
      const form = {
        username: event.target.mail.value,
        password: hash(event.target.password.value),
        code: getQueryParam("code"),
        action: getQueryParam("action"),
      };
      await AuthService.validateUserCodeReinitPassword(form);
      props.onClickClose();
    }
  };

  const renderRepeatedNewPasswordErrorAlert = () => {
    return (
      <Alert
        color="danger"
        style={{ fontSize: 12 }}
        isOpen={showRepeatedNewPasswordErrorAlert}
        toggle={() =>
          setShowRepeatedNewPasswordErrorAlert(
            !showRepeatedNewPasswordErrorAlert,
          )
        }
      >
        Les mots de passe ne correspondent pas. Merci de saisir à nouveau votre
        nouveau mot de passe.
      </Alert>
    );
  };

  const renderForm = () => {
    return (
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="mail">Adresse mail</Label>
              <Input
                type="mail"
                name="mail"
                placeholder="Adresse mail"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="mail">Nouveau mot de passe</Label>
              <Input
                name="password"
                placeholder="Nouveau mot de passe"
                type="password"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="mail">Répétez le mot de passe</Label>
              <Input
                name="repeatedpassword"
                placeholder="Répétez le mot de passe"
                type="password"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{renderRepeatedNewPasswordErrorAlert()}</Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" type="submit">
              Valider
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <CardHeader
        className="card-header-login"
        style={styles.cardHeader}
        key="loginCardHeader"
      >
        <CardTitle tag="h1">Réinitialisez votre mot de passe</CardTitle>
        <p style={styles.alert}>
          Vous avez oublié votre mot de passe ? Merci de remplir ce formulaire.
        </p>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>{renderForm()}</RenderIf>
      </CardBody>
    </Card>
  );
}

const styles = {
  card: {
    textAlign: "center",
    padding: 5,
    flexGrow: 1,
    height: "43em",
    maxWidth: "62em",
    flexDirection: "column",
  },
  cardBody: {
    marginTop: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    lineHeight: 1.2,
    padding: "0rem 1rem",
  },
  alert: {
    fontSize: 12,
    width: "100%",
  },
  textField: {
    height: "1.5rem",
    padding: "0.75rem",
    borderRadius: 15,
    marginBottom: 20,
    fontSize: "1rem",
  },
  form: {
    display: "grid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  logo: {
    paddingTop: 20,
  },
  button: {
    borderStyle: "none",
    fontSize: "1.25rem",
    lineHeight: 1.5,
    padding: "0.5rem 1rem",
    display: "block",
    width: "96%",
    color: "#ffffff",
    backgroundColor: "#495057",
    borderColor: "#007bff",
    borderRadius: 15,
    marginTop: 20,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "96%",
    bottom: 5,
    color: "#ffffff",
    fontSize: 9,
  },
};
