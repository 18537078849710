import { useContext, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import CotisationController from "../config/apiUtils/CotisationController";
import useConstructor from "../config/hooks/useConstructor";
import { RenderIf } from "../config/utils";
import StoreContext from "../context/StoreContext";
import TableCotisation from "./cotisation/TableCotisation";

export default function Cotisation() {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [cotisations, setCotisations] = useState([]);

  useConstructor(async () => {
    setLoading(true);
    const tmp = await CotisationController.getCotisationByMillesime(
      store.millesime.idmillesime,
    );
    tmp.forEach((cotisation) => {
      cotisation.diffsurface = (
        cotisation.surfacefinale - cotisation.surfaceinitiale
      ).toFixed(3);
      cotisation.montantdiff = (
        (cotisation.surfacefinale - cotisation.surfaceinitiale) *
        cotisation.cotisationannuelle *
        1.2
      ).toFixed(2);
    });
    setCotisations(tmp);
    setLoading(false);
  });

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des cotisations</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button color="primary">
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <TableCotisation cotisations={cotisations} />
          </RenderIf>
        </Col>
      </Row>
    </>
  );
}
