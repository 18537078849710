const Constantes = {
  codesDroit: {
    GESTION_EXPLOITATION: "GESTION_EXPLOITATION",
    ACCES_EXPLOITATION: "ACCES_EXPLOITATION",
    GESTION_REF_PRODUIT: "GESTION_REF_PRODUIT",
    GESTION_REF_VARIETE: "GESTION_REF_VARIETE",
    GESTION_PLAN_PROD: "GESTION_PLAN_PROD",
    GESTION_UTILISATEUR: "GESTION_UTILISATEUR",
    EXPORT_DATA: "EXPORT_DATA",
    GESTION_PRODUCTEUR: "GESTION_PRODUCTEUR",
    GESTION_FORMATION: "GESTION_FORMATION",
    GESTION_CONTRAT: "GESTION_CONTRAT",
    GESTION_CONVENTION: "GESTION_CONVENTION",
    GESTION_REF_MATERIEL: "GESTION_REF_MATERIEL",
    GESTION_PARCELLE: "GESTION_PARCELLE",
    GESTION_CARTOGRAPHIE: "GESTION_CARTOGRAPHIE",
  },
  styles: {
    footerHeight: 65,
    marginBottom: 65,
  },
  etatsValidationAdministrative: {
    nonValide: 1,
    valideAPGMB: 2,
    valideOS: 3,
    valide: 4,
  },
};

export default Constantes;
