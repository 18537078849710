import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormUpdateVariete(props) {
  const store = useContext(StoreContext);
  const [variete, setVariete] = useState(
    store.referentiels.varietes.find(
      (variete) => variete.idrefvariete === props.idrefvariete,
    ),
  );
  const [libelle, setLibelle] = useState(variete.libelle);
  const [idreftypevariete, setIdreftypevariete] = useState(
    variete.idreftypevariete,
  );
  const [codevariete, setCodevariete] = useState(variete.codevariete);
  const [estapteigp, setEstapteigp] = useState(variete.estapteigp);
  const [estutilisee, setEstutilisee] = useState(variete.estutilisee);

  const onSubmit = (event) => {
    event.preventDefault();
    props.onClickUpdate({
      idreftypevariete: idreftypevariete,
      codevariete: codevariete,
      libelle: libelle,
      estapteigp: estapteigp,
      estutilisee: estutilisee,
    });
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const data = store.referentiels.varietes.find(
      (variete) => variete.idrefvariete === props.idrefvariete,
    );
    setVariete(data);
    setLibelle(data.libelle);
    setIdreftypevariete(data.idreftypevariete);
    setEstutilisee(data.estutilisee);
    setEstapteigp(data.estapteigp);
    setCodevariete(data.codevariete);
  }, [props.idrefvariete, store.referentiels.varietes]);

  const renderTypesVarieteOptions = () => {
    return store.referentiels.typesVarietes.map((type, key) => {
      return (
        <option key={key} value={type.idreftypevariete}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Variété : {variete.libelle}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="idreftypevariete">Type de variété</Label>
                <Input
                  name="idreftypevariete"
                  type="select"
                  className="select-input"
                  value={idreftypevariete}
                  onChange={(event) => setIdreftypevariete(event.target.value)}
                >
                  {renderTypesVarieteOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="codevariete">Code variété</Label>
                <Input
                  name="codevariete"
                  placeholder={"Code variété"}
                  type="text"
                  value={codevariete}
                  onChange={(event) => setCodevariete(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="libelle">Variété</Label>
                <Input
                  name="libelle"
                  placeholder={"Variété"}
                  type="text"
                  value={libelle}
                  onChange={(event) => setLibelle(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="estapteigp">Apte IGP</Label>
                <Input
                  name="estapteigp"
                  type="select"
                  className="select-input"
                  value={estapteigp}
                  onChange={(event) => setEstapteigp(event.target.value)}
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="estutilisee">Variété utilisée</Label>
                <Input
                  name="estutilisee"
                  type="select"
                  className="select-input"
                  value={estutilisee}
                  onChange={(event) => setEstutilisee(event.target.value)}
                >
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
