import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import {
  columns,
  defaultFilterValue,
  gridStyle,
} from "../../components/Grids/ListeUtilisateur/tableutilisateur";

export default function TableUtilisateurs(props) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des utilisateurs</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {props.loading && <SpinLoadingAnimation />}
        {!props.loading && (
          <Grid
            enableCellSelect={true}
            idProperty="idutilisateur"
            emptyText="Aucun utilisateur renseigné"
            columns={columns}
            dataSource={props.utilisateurs}
            defaultFilterValue={defaultFilterValue}
            style={gridStyle}
            onRowClick={props.onClickUtilisateur}
            pagination
            defaultLimit={20}
          />
        )}
      </CardBody>
    </Card>
  );
}
