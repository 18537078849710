import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import StoreContext from "../../../context/StoreContext";
import CloseButton from "../../../components/Buttons/CloseButton";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";

export default function FormUpdateMateriel(props) {
  const store = useContext(StoreContext);
  const [materiel, setMateriel] = useState(
    store.referentiels.materiels.find(
      (materiel) => materiel.idrefmateriel === props.idrefmateriel,
    ),
  );
  const [libelle, setLibelle] = useState(materiel.libellemateriel);
  const [idreftypemateriel, setIdreftypemateriel] = useState(
    materiel.idreftypemateriel,
  );

  const onSubmit = (event) => {
    props.onClickUpdate({
      idreftypemateriel: idreftypemateriel,
      libelle: libelle,
    });
    event.preventDefault();
    event.target.reset();
    props.onClickClose();
  };

  useEffect(() => {
    const mat = store.referentiels.materiels.find(
      (materiel) => materiel.idrefmateriel === props.idrefmateriel,
    );
    setMateriel(mat);
    setLibelle(mat.libellemateriel);
    setIdreftypemateriel(mat.idreftypemateriel);
  }, [props.idrefmateriel, store.referentiels.materiels]);

  const renderTypesMaterielOptions = () => {
    return store.referentiels.typesMateriels.map((type, key) => {
      return (
        <option key={key} value={type.idreftypemateriel}>
          {type.libelle}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Matériel : {materiel.libellemateriel}</CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="idreftypemateriel">Type de matériel</Label>
                <Input
                  name="idreftypemateriel"
                  type="select"
                  className="select-input"
                  value={idreftypemateriel}
                  onChange={(event) => setIdreftypemateriel(event.target.value)}
                >
                  {renderTypesMaterielOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="libelle">Matériel</Label>
                <Input
                  name="libelle"
                  placeholder={"Matériel"}
                  type="text"
                  value={libelle}
                  onChange={(event) => setLibelle(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <ValidateButton style={{ marginTop: 15 }} />
              <CancelButton
                style={{ marginTop: 15, marginLeft: 15 }}
                onClick={props.onClickClose}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
