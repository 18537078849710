import { useContext, useState } from "react";
import { Alert, Button, Col, Collapse, Row } from "reactstrap";
import FormationController from "../config/apiUtils/FormationController";
import ReferentielController from "../config/apiUtils/ReferentielController";
import useConstructor from "../config/hooks/useConstructor";
import { GoToTopPage } from "../config/utils";
import StoreContext from "../context/StoreContext";
import FormUpdateFormation from "./formation/FormUpdateFormation";
import TableFormation from "./formation/TableFormation";
import FormFormationProducteur from "./formation/FormFormationProducteur";
import { RenderIf } from "../config/utils";

export default function FormationsProducteur() {
  const store = useContext(StoreContext);

  const [showSuccessNewFromation, setShowSuccessNewFromation] = useState(false);
  const [showSuccessDeleteFormation, setShowSuccessDeleteFormation] =
    useState(false);
  const [showNewFormationForm, setShowNewFormationForm] = useState(false);
  const [showUpdateFormationForm, setShowUpdateFormationForm] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState({});

  const hideFormUpdateFormation = () => {
    setShowUpdateFormationForm(false);
  };

  const onClickSaveNewFormation = async (formation) => {
    const resFormation = await FormationController.createFormation(formation);

    if (resFormation.status === 200) {
      const resFormations = await FormationController.getFormations();
      setShowSuccessNewFromation(true);
      setShowSuccessDeleteFormation(false);
      store.formations.setFormationsMillesime(resFormations);
    }
  };

  const onClickUpdateFormation = async (idformationproducteur, formation) => {
    const resFormation = await FormationController.updateFormation(
      idformationproducteur,
      formation,
    );

    if (resFormation.status === 200) {
      const resFormations = await FormationController.getFormations();
      setShowSuccessNewFromation(false);
      setShowSuccessDeleteFormation(false);
      store.formations.setFormationsMillesime(resFormations);
    }
  };

  const onClickDeleteFormation = async (idformationproducteur) => {
    const resFormation = await FormationController.deleteFormation(
      idformationproducteur,
    );
    if (resFormation.status === 200) {
      const resFormations = await FormationController.getFormations();
      setShowSuccessNewFromation(false);
      setShowSuccessDeleteFormation(true);
      store.formations.setFormationsMillesime(resFormations);
    }
  };

  const onSelectFormation = (formation) => {
    GoToTopPage();
    setSelectedFormation(formation);
    setShowSuccessNewFromation(false);
    setShowSuccessDeleteFormation(false);
    setShowUpdateFormationForm(true);
  };

  useConstructor(async () => {
    const resFormations = await FormationController.getFormations();
    store.formations.setFormationsMillesime(resFormations);
    const resTypesFormation = await ReferentielController.getTypesFormations();
    store.referentiels.setTypesFormations(resTypesFormation);
  });

  const renderSuccessAlertNewFormations = () => {
    return <Alert color="success">Formation ajoutée avec succés.</Alert>;
  };

  const renderSuccessAlertDeleteFormation = () => {
    return (
      <Alert
        color="success"
        style={{ marginTop: 30 }}
        isOpen={showSuccessDeleteFormation}
        toggle={() => setShowSuccessDeleteFormation(false)}
      >
        Formation supprimée avec succés.
      </Alert>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Formations producteur</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            color="primary"
            onClick={() => {
              setShowSuccessNewFromation(false);
              setShowSuccessDeleteFormation(false);
              setShowNewFormationForm(true);
            }}
          >
            <i className="fa-solid fa-plus" />
          </Button>
        </Col>
      </Row>
      <RenderIf isTrue={showSuccessNewFromation}>
        <Row style={{ marginTop: 30 }}>
          <Col>{renderSuccessAlertNewFormations()}</Col>
        </Row>
      </RenderIf>
      {renderSuccessAlertDeleteFormation()}
      <Collapse isOpen={showNewFormationForm} mountOnEnter unmountOnExit>
        <Row style={{ marginTop: 30 }}>
          <Col md="12">
            <FormFormationProducteur
              producteurs={store.producteursMillesime}
              millesime={store.millesime.idmillesime}
              hideFormNewFormation={() => setShowNewFormationForm(false)}
              onClickSaveNewFormation={onClickSaveNewFormation}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={showUpdateFormationForm} mountOnEnter unmountOnExit>
        <Row style={{ marginTop: 30 }}>
          <Col md="12">
            <FormUpdateFormation
              producteurs={store.producteursMillesime}
              formation={selectedFormation}
              hideFormUpdateFormation={hideFormUpdateFormation}
              onClickUpdateFormation={onClickUpdateFormation}
              onClickDeleteFormation={onClickDeleteFormation}
            />
          </Col>
        </Row>
      </Collapse>
      <Row style={{ marginTop: 30 }}>
        <Col md="12">
          <Collapse
            isOpen={!showUpdateFormationForm && !showNewFormationForm}
            mountOnEnter
            unmountOnExit
          >
            <TableFormation
              millesime={store.millesime.idmillesime}
              onSelectFormation={onSelectFormation}
              formations={store.formations.formationsMillesime}
            />
          </Collapse>
        </Col>
      </Row>
    </>
  );
}
