import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Convention/convention";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf, createNotification, downloadFile } from "../../config/utils";
import EditionController from "../../config/apiUtils/EditionController";
import { useContext } from "react";
import StoreContext from "../../context/StoreContext";

export default function ConventionsExploitations(props) {
  const store = useContext(StoreContext);

  const onCellClick = async (event, cellProps) => {
    if (cellProps.name === "telecharger") {
      createNotification("info", "", "Préparation du document en cours...");
      const resDocument = await EditionController.getConvention(
        cellProps.data.idconvention,
      );
      downloadFile(
        resDocument,
        `convention_${cellProps.data.raisonsociale.replaceAll(" ", "-")}_${
          store.millesime.idmillesime
        }.pdf`,
        "application/octet-stream",
      );
      createNotification("success", "", "Convention générée avec succès.");
    } else {
      props.onClickUpdateConvention(cellProps.data);
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Conventions</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={props.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={props.conventions.length > 0 && !props.loading}>
            <Grid
              enableCellSelect={true}
              idProperty="idconvention"
              emptyText="Aucune convention renseigné"
              columns={columns}
              dataSource={props.conventions}
              defaultFilterValue={defaultFilterValue}
              style={gridStyle}
              onCellClick={onCellClick}
              pagination
              defaultLimit={50}
            />
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
