import { useContext, useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import ConventionController from "../config/apiUtils/ConventionController";
import useConstructor from "../config/hooks/useConstructor";
import ConventionsExploitations from "./convention/ConventionsExploitations";
import FormNewConvention from "./convention/FormNewConvention";
import FormUpdateConvention from "./convention/FormUpdateConvention";
import StoreContext from "../context/StoreContext";
import { createNotification, downloadFile } from "../config/utils";
import { useConfirmBrowserExit } from "../config/hooks/useConfirmBrowserExit";
import EditionController from "../config/apiUtils/EditionController";

export default function Convention() {
  const store = useContext(StoreContext);
  const confirmBrowserExit = useConfirmBrowserExit(false);
  const [showNewConventionForm, setShowNewConventionForm] = useState(false);
  const [showUpdateConventionForm, setShowUpdateConventionForm] =
    useState(false);
  const [conventionToUpdate, setConventionToUpdate] = useState({});
  const [conventions, setConventions] = useState([]);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    setConventions(
      await ConventionController.getConventionMillesime(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  const hideFormNewConvention = () => setShowNewConventionForm(false);

  const onClickSaveNewConvention = async (convention) => {
    const res = await ConventionController.insertConvention(convention);
    if (res.status === 200) {
      createNotification("success", "Succès", "Convention créée avec succès !");
      setLoading(true);
      setConventions(
        await ConventionController.getConventionMillesime(
          store.millesime.idmillesime,
        ),
      );
      setLoading(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création de la convention",
      );
    }
  };

  const onClickSaveUpdateConvention = async (convention) => {
    const res = await ConventionController.updateConvention(
      convention.idconvention,
      convention,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Convention modifiée avec succès !",
      );
      setLoading(true);
      setConventions(
        await ConventionController.getConventionMillesime(
          store.millesime.idmillesime,
        ),
      );
      setLoading(false);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de la convention...",
      );
    }
  };

  const onClickUpdateConvention = async (convention) => {
    setConventionToUpdate(convention);
    setShowUpdateConventionForm(true);
  };

  const downloadAllConventions = async () => {
    setLoading(true);
    createNotification(
      "info",
      "Préparation des documents en cours...",
      "L'extraction de tous les conventions peut prendre quelques minutes. En attendant la fin de l'extraction, merci de ne pas quitter la page tant que le chargement est en cours",
      20000,
    );
    confirmBrowserExit.setMessage(
      "L'extraction de tous les conventions est en cours. Souhaitez-vous vraiment quitter la page ?",
    );
    confirmBrowserExit.enable();
    const chunkSize = 100;
    let numChunk = 1;
    console.time("Execution time");
    for (let i = 0; i < conventions.length; i += chunkSize) {
      downloadFile(
        await EditionController.getConventions(
          conventions.slice(i, i + chunkSize).map((c) => c.idconvention),
        ),
        `conventions_${store.millesime.idmillesime}_${numChunk}.zip`,
        "application/octet-stream",
      );
      numChunk++;
    }
    console.timeEnd("Execution time");
    setLoading(false);
    createNotification("success", "", "Archive générée avec succès.");
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Consultation des conventions de {store.millesime.idmillesime}</h2>
        </Col>
        <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button
            color="primary"
            onClick={() => setShowNewConventionForm(true)}
          >
            <i className="fa-solid fa-plus" />
          </Button>
          <Button
            color="primary"
            style={{ marginRight: 10 }}
            onClick={downloadAllConventions}
          >
            Télécharger tout
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <Col md="12">
          <Collapse isOpen={showNewConventionForm} mountOnEnter unmountOnExit>
            <FormNewConvention
              onClickSaveNewConvention={onClickSaveNewConvention}
              hideFormNewConvention={hideFormNewConvention}
            />
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <Col md="12">
          <Collapse
            isOpen={showUpdateConventionForm}
            mountOnEnter
            unmountOnExit
          >
            <FormUpdateConvention
              convention={conventionToUpdate}
              onClickUpdateConvention={onClickSaveUpdateConvention}
              hideFormUpdateConvention={() =>
                setShowUpdateConventionForm(false)
              }
            />
          </Collapse>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <Col md="12">
          <ConventionsExploitations
            conventions={conventions}
            loading={loading}
            onClickUpdateConvention={onClickUpdateConvention}
          />
        </Col>
      </Row>
    </>
  );
}
