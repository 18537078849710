import { Col, Row } from "reactstrap";
import TableExploitationsActivesProduction from "./exploitationsactivesproduction/TableExploitationsActivesProduction";
import ActionsGroupees from "../ActionsGroupees";

export default function ExploitationsActivesProduction() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableExploitationsActivesProduction />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
