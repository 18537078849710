import Contrat from "../../views/Contrat";
import Convention from "../../views/Convention";
import Cotisation from "../../views/Cotisation";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";
import ValidationContrats from "../../views/ValidationContrats";

const ContratConventionRoutes = [
  {
    path: "/contrat",
    name: "Contrats",
    element: <Contrat />,
    restriction: droits.typesdroits.ACCES_CONTRAT,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/validation-contrats",
    name: "Validation des contrats",
    element: <ValidationContrats />,
    restriction: droits.typesdroits.ACCES_VALIDATION_CONTRAT,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/convention",
    name: "Conventions",
    element: <Convention />,
    restriction: droits.typesdroits.ACCES_CONVENTION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/cotisation",
    name: "Cotisations",
    element: <Cotisation />,
    restriction: droits.typesdroits.ACCES_COTISATION,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
];

export default ContratConventionRoutes;
