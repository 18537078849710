export const columns = [
  {
    header: "Millésime",
    name: "millesime",
    minWidth: 110,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de la demande",
    name: "datedemande",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date d'envoi",
    name: "dateenvoicertif",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Date de l'habilitation",
    name: "datehabilitation",
    minWidth: 140,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Habilité",
    name: "habilite",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "OUI";
      }
      cellProps.style.background = "#f9d7da"; // DANGER
      cellProps.style.color = "#842029"; // DANGER
      return "NON";
    },
  },
  {
    header: "Actif",
    name: "actif",
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "OUI";
      }
      cellProps.style.background = "#f9d7da"; // DANGER
      cellProps.style.color = "#842029"; // DANGER
      return "NON";
    },
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };
