import React, { Component, Fragment } from "react";
import GeoJSON from "ol/format/GeoJSON.js";
import StoreContext from "../../context/StoreContext";
import CartoController from "../../config/apiUtils/CartoController";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import { Button, Col, Row } from "reactstrap";
import {
  getQueryParam,
  deleteQueryParam,
  setQueryParam,
  RenderIf,
} from "../../config/utils";
import ControlPanelLayers from "./ControlPanelLayers";
import MapParcellaire from "./MapParcellaire";
import PanelRight from "./PanelRight";

export default class Parcellaire extends Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = {
      panelRightOpened: true,
      openedSection: getQueryParam("section") ?? "",
      loading: true,
      force: false,
    };

    this.height = "calc(100vh - 160px)";

    this.onMapClick = this.onMapClick.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidUpdate() {
    if (this.state.openedSection !== (getQueryParam("section") ?? "")) {
      this.setState({ openedSection: getQueryParam("section") ?? "" });
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  toggleSection(id) {
    /*if (this.state.openedSection === "contrat") {
      deleteQueryParam("contrat");
    }
    if (this.state.openedSection === "intentionsemis") {
      deleteQueryParam("intentionsemis");
    }*/

    if (this.state.openedSection === id) {
      deleteQueryParam("section");
    } else {
      setQueryParam("section", id);
    }
    this.setState({
      openedSection: this.state.openedSection === id ? "" : id,
    });
  }

  async loadData(reload = false) {
    this.setState({ loading: !reload });
    await this.context.carto.loadDataParcellaireCarto(() => this.forceUpdate());
    this.setState({ loading: false });
  }

  onMapClick(evt) {
    const me = this;

    if (this.context.carto.parcelleAssocieeSelecterRef.current != null) {
      this.context.carto.parcelleAssocieeSelecterRef.current.clearValue();
    }
    me.context.carto.getParcellaireLayer().clearSelection();

    this.context.carto.getParcellaireLayer().setSelectable(true, (evt) => {
      let hasClickedFeature = false;
      me.context.carto.mapRef.current.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          if (
            me.context.carto.getParcellaireLayer().isFeatureInLayer(feature)
          ) {
            hasClickedFeature = true;
            me.context.carto
              .getParcellaireLayer()
              .toggleSelectionFeature(feature);
            me.context.carto.parcelleAssocieeSelecterRef.current.setValue({
              value: feature.get("idparcelle"),
              label: me.context.carto.parcellaire.find(
                (parcelle) => parcelle.idparcelle == feature.get("idparcelle"),
              ).nomparcelle,
              fromCarto: true,
            });
          }
        },
      );
      if (!hasClickedFeature) {
        me.context.carto.getParcellaireLayer().clearSelection();
        if (me.context.carto.parcelleAssocieeSelecterRef.current != null) {
          me.context.carto.parcelleAssocieeSelecterRef.current.clearValue();
        }
      }
    });

    this.context.carto.layers.forEach((layer) => {
      if (layer.selectable) {
        layer.onSelectEvent(evt);
      }
    });
  }

  render() {
    return (
      <>
        <Row>
          <ControlPanelLayers
            height={this.height}
            loading={this.state.loading}
          />
          <Col
            md={
              this.state.panelRightOpened
                ? this.state.openedSection === ""
                  ? 9
                  : 6
                : 12
            }
            style={{
              paddingRight: this.state.panelRightOpened ? 0 : 10,
              zIndex: 0,
            }}
          >
            <MapParcellaire
              height={this.height}
              loading={this.state.loading}
              onMapClick={this.onMapClick}
            />
          </Col>
          <RenderIf isTrue={this.state.panelRightOpened}>
            <Col
              md={
                this.state.panelRightOpened
                  ? this.state.openedSection === ""
                    ? 3
                    : 6
                  : 0
              }
              style={{ paddingLeft: 0 }}
            >
              <PanelRight
                height={this.height}
                loading={this.state.loading}
                panelRightOpened={this.state.panelRightOpened}
                togglePanelRight={() =>
                  this.setState({
                    panelRightOpened: !this.state.panelRightOpened,
                  })
                }
                openedSection={this.state.openedSection}
                toggleSection={this.toggleSection}
                forceUpdate={() => this.forceUpdate()}
              />
            </Col>
          </RenderIf>
          <RenderIf isTrue={!this.state.panelRightOpened}>
            <div
              style={{
                position: "absolute",
                right: 0,
                width: 45,
                overflowX: "clip",
              }}
            >
              <Button
                color={"primary"}
                style={{
                  position: "relative",
                  top: 80,
                }}
                onClick={() =>
                  this.setState({
                    panelRightOpened: !this.state.panelRightOpened,
                  })
                }
              >
                <i className="fa-solid fa-arrow-left"></i>
              </Button>
            </div>
          </RenderIf>
        </Row>
      </>
    );
  }
}
