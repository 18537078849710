import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Progress,
  Row,
} from "reactstrap";
import FichierController from "../../config/apiUtils/FichierController";
import TableDocuments from "../../components/TableDocuments/TableDocuments";

export default function Documents(props) {
  const [tailleTotale, setTailleTotale] = useState(0);
  const [ratioGo] = useState(2);

  useEffect(() => {
    var taille = 0;
    props.documents.forEach((document) => {
      taille += parseFloat(document.taille);
    });
    taille = parseFloat(taille / 1024 / 1024 / 1024);
    setTailleTotale(taille);
  }, [props.documents]);

  const onClickDeleteDocument = async (iddocument) => {
    await FichierController.removeDocuments(iddocument);
    await props.loadDocuments();
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag="h5">Espace documentaire</CardTitle>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <div style={{ width: 200, marginLeft: 10 }}>
              <Progress value={(tailleTotale / ratioGo) * 100}>
                {((tailleTotale / ratioGo) * 100).toFixed(2)} %
              </Progress>
            </div>{" "}
            {tailleTotale.toFixed(2)} Go utilisés sur {ratioGo} Go
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        style={
          props.documents.length === 0 ? styles.cardEmpty : styles.cardNotEmpty
        }
      >
        {props.documents.length === 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            Pas de document
          </div>
        )}
        {props.documents.length > 0 && (
          <TableDocuments
            onClickDeleteDocument={onClickDeleteDocument}
            documents={props.documents}
          />
        )}
      </CardBody>
    </Card>
  );
}

const styles = {
  cardEmpty: {
    minHeight: 500,
    display: "flex",
    justifyContent: "center",
  },
  cardNotEmpty: {
    minHeight: 500,
  },
};
