import { useContext, useState } from "react";
import moment from "moment";
import StoreContext from "../../context/StoreContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import useConstructor from "../../config/hooks/useConstructor";
import ExploitationController from "../../config/apiUtils/ExploitationController";

export default function FormNewConvention(props) {
  const store = useContext(StoreContext);
  const [idexploitation, setIdexploitation] = useState(
    store.exploitation.informations.idexploitation,
  );

  useConstructor(async () => {
    const resExploitation = await ExploitationController.getExploitations();
    store.setExploitations(resExploitation);
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const conventionForm = {
      idexploitation: idexploitation,
      millesime: store.millesime.idmillesime,
      dateenvoi: event.target.dateenvoi.value,
    };
    await props.onClickSaveNewConvention(conventionForm);
    props.hideFormNewConvention();
    event.target.reset();
  };

  const renderExploitationOptions = () => {
    return store.exploitations.map((exploitation, key) => {
      return (
        <option key={key} value={exploitation.idexploitation}>
          {exploitation.raisonsociale}
        </option>
      );
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle convention</CardTitle>
        <CloseButton onClick={props.hideFormNewConvention} />
        <CardSubtitle>
          Pour ajouter une nouvelle convention au système de gestion, remplissez
          les informations suivantes. Tous les champs étoilés sont requis.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: 10 }}>
            <Col md="6">
              <FormGroup>
                <Label for="idexploitation">Exploitation *</Label>
                <Input
                  type="select"
                  className="select-input"
                  name="idexploitation"
                  disabled={
                    props.idexploitation !== null &&
                    props.idexploitation !== undefined
                  }
                  value={idexploitation}
                  onChange={(event) => setIdexploitation(event.target.value)}
                >
                  {renderExploitationOptions()}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="dateenvoi">Date d'envoi *</Label>
                <Input
                  type="date"
                  name="dateenvoi"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidateButton style={{ marginRight: 10 }} />
              <CancelButton
                onClick={(event) => {
                  event.preventDefault();
                  props.hideFormNewConvention();
                }}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
