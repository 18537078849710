import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import Grid from "../../components/Grid";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import PlanProductionController from "../../config/apiUtils/PlanProductionController";

const columns = [
  {
    header: "Organisme",
    name: "nomorganismestockeur",
    group: "organisme",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface printemps",
    name: "surfaceprintemps",
    group: "surfaces",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface automne",
    name: "surfaceautomne",
    group: "surfaces",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface totale",
    name: "surfacetotale",
    group: "surfaces",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

const groups = [
  { name: "organisme", header: "Organismes" },
  { name: "surfaces", header: "Surfaces" },
  { name: "quantite", header: "Quantités" },
];

export default function BilanParOganismeStockeur(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getVarieteColumnName = (idrefvariete) => {
    return "variete." + idrefvariete;
  };

  const loadGrid = () => {
    //Pour chaque organisme stockeur
    data.forEach((organisme) => {
      //Pour chaque variété, on vérifie si la variété est présente dans les colonnes du tableau
      organisme.varietes.forEach((variete) => {
        //Pas présente par défaut
        let varieteInColumns = false;
        columns.forEach((column) => {
          //Si on trouve la variété dans le tableau
          if (column.name === getVarieteColumnName(variete.idrefvariete)) {
            varieteInColumns = true;
          }
        });

        if (!varieteInColumns) {
          //On vérifie si toutes les valeurs de la colonne sont à 0 ou null => Si au moins une valeur, alors on affiche la colonne
          let columnEmpty = !data.some((org) =>
            org.varietes.some(
              (v) =>
                v.idrefvariete === variete.idrefvariete &&
                v.quantite !== null &&
                v.quantite !== 0,
            ),
          );

          if (!columnEmpty) {
            columns.push({
              header: variete.libelle,
              name: getVarieteColumnName(variete.idrefvariete),
              group: "quantite",
              cellStyle: { textAlign: "center" },
              minWidth: 170,
              defaultFlex: 1,
              suppressSizeToFit: true,
              sortable: true,
              filter: true,
              resizable: true,
            });
          }
        }

        // On met à plat les données pour l'affichage dans le tableau
        organisme[getVarieteColumnName(variete.idrefvariete)] =
          variete.quantite;
      });
    });
  };

  useConstructor(async () => {
    setLoading(true);
    setData(
      await PlanProductionController.getBilanOrganismeStockeur(
        store.millesime.idmillesime,
      ),
    );
    setLoading(false);
  });

  loadGrid();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Bilan Par Oganisme Stockeur</CardTitle>
        <CloseButton onClick={props.close} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <Grid
            enableCellSelect={true}
            idProperty="idplanproduction"
            emptyText="Le bilan est vide"
            columns={columns}
            groups={groups}
            dataSource={data}
            style={{ minHeight: 350, color: "#ffffff" }}
            pagination
            defaultLimit={20}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
