import { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import SpinLoadingAnimation from "../SpinLoadingAnimation";
import LoginForm from "./Login/LoginForm";
import AuthService from "../../config/AuthService";
import { createNotification, getQueryParam } from "../../config/utils";

export default function LoginCard(props) {
  document.title = "Connexion | Ecodim";
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isLoggedIn());
  const [showBadInformationLogin, setShowBadInformationLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const connect = async (user) => {
    setLoading(true);
    setShowBadInformationLogin(false);
    const res = await AuthService.login(user);
    if ((res !== null && res.success != false) || res.status === 200) {
      setIsLoggedIn(AuthService.isLoggedIn());
      window.location =
        getQueryParam("redirect_uri") +
          (getQueryParam("redirect_uri") !== null &&
          getQueryParam("redirect_uri").includes("?")
            ? "&"
            : "?") +
          "loggedin=true" ?? "/app/dashboard?loggedin=true";
    } else {
      if (res.message) {
        createNotification("error", "Erreur", res.message);
      } else {
        createNotification("error", "Erreur", "Une erreur s'est produite...");
      }
      setShowBadInformationLogin(true);
      setLoading(false);
    }
  };

  return (
    <Card style={styles.card} className="card-login" key="loginCard">
      <CardHeader
        className="card-header-login"
        style={styles.cardHeader}
        key="loginCardHeader"
      >
        <img
          alt="Logo Ecodim"
          src={require("../../assets/img/logo-crop.png")}
          style={styles.logo}
        />
        <CardTitle tag="h1">CAS</CardTitle>
      </CardHeader>
      <CardBody style={styles.cardBody} key="loginCardBody">
        {loading && <SpinLoadingAnimation />}
        <Row style={{ marginTop: 10 }}>
          {!isLoggedIn && !loading && (
            <LoginForm
              error={showBadInformationLogin}
              connect={(user) => connect(user)}
            />
          )}
        </Row>
        {!isLoggedIn && !loading && (
          <>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  style={{ borderRadius: "0.5rem" }}
                  outline
                  color="info"
                  block
                  onClick={props.onClickCreateAccount}
                >
                  Créer votre compte
                </Button>
              </Col>
            </Row>
            <Row md="4">
              <Col md="4">
                <a
                  style={{ cursor: "pointer", display: "flex" }}
                  onClick={props.onClickPasswordForgotten}
                >
                  Mot de passe oublié ?
                </a>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <CardFooter className="card-footer-login" style={styles.footer}>
          © Copyright 2023 APGMB. Tous droits réservés.
        </CardFooter>
      </Row>
    </Card>
  );
}

const styles = {
  card: {
    textAlign: "center",
    padding: 10,
    flexGrow: 1,
    height: "40em",
    maxWidth: "35em",
    borderRadius: 50,
    flexDirection: "column",
    boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
  },
  cardBody: {
    marginTop: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    lineHeight: 1.2,
    padding: "0rem 1rem",
    marginBottom: 30,
  },
  textField: {
    height: "1.5rem",
    padding: "0.75rem",
    borderRadius: 15,
    marginBottom: 20,
    fontSize: "1rem",
  },
  form: {
    display: "grid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  logo: {
    paddingTop: 20,
    width: 250,
    marginBottom: 20,
  },
  button: {
    borderStyle: "none",
    fontSize: "1.25rem",
    lineHeight: 1.5,
    padding: "0.5rem 1rem",
    display: "block",
    width: "96%",
    color: "#ffffff",
    backgroundColor: "#495057",
    borderColor: "#007bff",
    borderRadius: 15,
    marginTop: 20,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "90%",
    bottom: 5,
    color: "#ffffff",
    fontSize: 9,
  },
};
