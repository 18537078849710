import { Col, Row } from "reactstrap";
import TableConsignesRecolte from "./consignesrecolte/TableConsignesRecolte";
import ActionsGroupees from "../ActionsGroupees";

export default function ConsignesRecolte() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableConsignesRecolte />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
