import proj4 from "proj4";
import { register } from "ol/proj/proj4";

export const setProj4 = () => {
  proj4.defs(
    "EPSG:2154",
    "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  );
  proj4.defs(
    "EPSG:2169",
    "+proj=tmerc +lat_0=49.83333333333334 +lon_0=6.166666666666667 +k=1 +x_0=80000 +y_0=100000 +ellps=intl +towgs84=-189.681,18.3463,-42.7695,-0.33746,-3.09264,2.53861,0.4598 +units=m +axis=neu +no_defs",
  );
  register(proj4);
};

export class MapValueConverter {
  // Une échelle est un ratio sans unité = Resolution x PPI x IPM
  // 1- Resolution (OpenLayers) : Meters Per Pixel
  // 2- PPI : Pixels Per Inch = 72 (résolution moyenne (?) écran)
  // 3- IPM : Inches Per Meter = 39.37

  constructor(ppi = 72) {
    this.PPI = ppi;
    this.IPM = 39.37;
  }

  meter2pixel = (m) => {
    return Math.round(m * this.IPM * this.PPI);
  };
  pixel2meter = (px) => {
    return px / this.IPM / this.PPI;
  };

  resolution2Scale = (resolution) => {
    const round = (value, cap) => {
      const intValue = Math.round(value);
      const length = intValue.toString().length;
      const arrondi = length - cap;
      const factor = arrondi > 0 ? Math.pow(10, arrondi) : 1;
      return Math.round(intValue / factor) * factor;
    };

    return round(this.meter2pixel(resolution), 5);
  };
}

export default MapValueConverter;
