import { Table } from "reactstrap";
import Fichier from "./Fichier";
import { downloadFile } from "../../config/utils";
import FichierController from "../../config/apiUtils/FichierController";

export default function TableDocuments(props) {
  const renderFichiers = () => {
    return props.documents.map((fichier, key) => {
      return (
        <Fichier
          key={key}
          filename={fichier.nomdocument}
          filesize={fichier.taille}
          lastupdate="12/03/2022"
          libelletypedocument={fichier.libelletypedocument}
          iddocument={fichier.iddocument}
          onClickDownload={async () => {
            const res = await FichierController.downloadDocument(
              fichier.iddocument,
            );
            downloadFile(res, fichier.nomdocument, "application/octet-stream");
          }}
          onClickDeleteDocument={props.onClickDeleteDocument}
        />
      );
    });
  };

  return (
    <Table style={{ color: "#FFFFFF" }}>
      <thead>
        <tr>
          <th></th>
          <th>Type de document</th>
          <th>Nom du fichier</th>
          <th>Taille du fichier</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderFichiers()}</tbody>
    </Table>
  );
}
