export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "production", header: "Production" },
  { name: "abandon", header: "Abandon" },
];

export const columns = [
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 175,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Montant",
    name: "prixcotisation",
    type: "number",
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => (value * 1.2).toFixed(2) + " € TTC",
  },
  {
    header: "Payée",
    name: "paye",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "OUI";
      }
      cellProps.style.background = "#f9d7da"; // DANGER
      cellProps.style.color = "#842029"; // DANGER
      return "NON";
    },
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };
