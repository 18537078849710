import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import colors from "../../../config/colors";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "production", header: "Production" },
  { name: "abandon", header: "Abandon" },
];

export const columns = [
  {
    header: "Validé",
    name: "idetatvalidationadministrative",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 0, label: "Abandon" },
        { id: 1, label: "Non validé administrativement" },
        {
          id: 2,
          label:
            "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        },
        {
          id: 3,
          label:
            "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        },
        { id: 4, label: "Validé administrativement" },
        { id: 5, label: "Pré-validé, en attente paiement cotisation" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    resizable: true,
    render: ({ cellProps, data, value }) => {
      cellProps.style.border = "1px solid";
      if (data.valide === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
      } else {
        if (value === 0) {
          cellProps.style.background = "#b4b4b4"; // ABANDON
          cellProps.style.color = "#000000"; // ABANDON
        } else if (value === 1) {
          cellProps.style.background = "#f9d7da"; // DANGER
          cellProps.style.color = "#842029"; // DANGER
        } else {
          cellProps.style.background = colors.warning.background;
          cellProps.style.color = colors.warning.color;
        }
      }
      return data.libellevalidationadministrative;
    },
  },
  {
    header: "Numéro du contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value, data }) => {
      if (data.abandon === true) {
        cellProps.style.border = "1px solid";
        cellProps.style.background = "#b4b4b4"; // ABANDON
        cellProps.style.color = "#000000"; // ABANDON
      }
      return value;
    },
  },
  {
    header: "Date du contrat",
    name: "datecontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    cellStyle: { textAlign: "center" },
    group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Nombre de parcelles",
    name: "nbparcelles",
    type: "number",
    group: "production",
    cellStyle: { textAlign: "center" },
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface printemps (ha)",
    name: "surfaceprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => value + " Ha",
  },
  {
    header: "Surface automne (ha)",
    name: "surfaceautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => value + " Ha",
  },
  {
    header: "Surface totale (ha)",
    name: "surfacetotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => value + " Ha",
  },
  {
    header: "Abandonné",
    name: "abandon",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#f9d7da"; // DANGER
        cellProps.style.color = "#842029"; // DANGER
        return "OUI";
      }
      cellProps.style.background = "#d1e7dd"; // SUCCESS
      cellProps.style.color = "#0f5132"; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Commentaire abandon",
    name: "commentaireabandon",
    cellStyle: { textAlign: "center" },
    group: "abandon",
    minWidth: 250,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };
