import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import AuthService from "../../../config/AuthService";
import colors from "../../../config/colors";
import droits from "../../../config/CodesDroits";

export const columnsLivraison = [
  {
    header: "Silo",
    name: "nomsilo",
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Actif",
    name: "actif",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_LIVRAISON, 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: true, label: "OUI", nomId: "actif" },
        { id: false, label: "NON", nomId: "actif" },
      ],
      collapseOnSelect: true,
    },
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
];

export const columnsDefinitif = [
  {
    header: "Silo",
    name: "nomsilo",
    minWidth: 300,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Actif",
    name: "actif",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_DEFINITIFS, 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: true, label: "OUI", nomId: "actif" },
        { id: false, label: "NON", nomId: "actif" },
      ],
      collapseOnSelect: true,
    },
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
];

export const gridStyle = { minHeight: 500, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "nomsilo", type: "string", operator: "contains", value: "" },
  { name: "libelletypesilo", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  {
    name: "nomorganismestockeur",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "actif", type: "select", value: [], operator: "inlist" },
];
