import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import CiviliteController from "../../../config/apiUtils/CiviliteController";
import AuthService from "../../../config/AuthService";

export const columns = [
  {
    header: "Civilité",
    name: "civilite",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    suppressSizeToFit: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: "Monsieur", label: "Monsieur" },
        { id: "Madame", label: "Madame" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: AuthService.isLoggedIn()
        ? CiviliteController.getCivilites().then((civilites) =>
            civilites.map((civilite) => {
              return {
                id: civilite.idcivilite,
                label: civilite.libelle,
                nomId: "idcivilite",
              };
            }),
          )
        : [],
      collapseOnSelect: true,
    },
  },
  {
    header: "Nom",
    name: "nom",
    cellStyle: { textAlign: "center" },
    defaultFlex: 1,
    minWidth: 100,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    header: "Prénom",
    name: "prenom",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    header: "Raison sociale",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse mail",
    name: "mail",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    header: "Téléphone fixe",
    name: "telephonefixe",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
  {
    header: "Téléphone portable",
    name: "telephoneportable",
    group: "contact",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("ACCES_ADMINISTRATION", 10) &&
      AuthService.hasDroit("GESTION_PRODUCTEUR", 30),
  },
];

export const gridStyle = { minHeight: 800, color: "#ffffff" };

export const defaultFilterValue = [
  {
    name: "civilite",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "nom", type: "string", operator: "contains", value: "" },
  { name: "prenom", type: "string", operator: "contains", value: "" },
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "mail", type: "string", operator: "contains", value: "" },
  { name: "telephonefixe", type: "string", operator: "contains", value: "" },
  {
    name: "telephoneportable",
    type: "string",
    operator: "contains",
    value: "",
  },
];
