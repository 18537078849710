const commonBgColor = "#526b79";
const commonBgColorDisabled = "#3b505a";
const commonHoverColor = "#415864";
const commonWhite = "white";
const commonZIndex = 10;

export const selectStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? commonBgColorDisabled : commonBgColor,
    opacity: isDisabled ? 0.5 : 1,
    fill: commonWhite,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    "cursor": isDisabled ? "not-allowed" : "default",
    "backgroundColor": isSelected
      ? commonHoverColor
      : isFocused
      ? "#486271"
      : commonBgColor,
    "borderColor": commonBgColor,
    "zIndex": commonZIndex,
    ":active": {
      ...styles[":active"],
      zIndex: commonZIndex,
    },
    ":hover": {
      backgroundColor: commonHoverColor,
      zIndex: commonZIndex,
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    "color": commonWhite,
    ":hover": {
      color: commonWhite,
      backgroundColor: "#486271",
      borderRadius: 40,
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    "color": commonWhite,
    ":hover": {
      color: commonWhite,
      backgroundColor: "#486271",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: commonBgColor,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
    borderColor: commonBgColor,
    color: commonWhite,
  }),
  input: (styles) => ({
    ...styles,
    color: commonWhite,
  }),
  placeholder: (styles) => ({ ...styles, color: "rgba(255, 255, 255, 0.5)" }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? commonBgColorDisabled : commonBgColor,
    color: commonWhite,
    zIndex: commonZIndex,
  }),
};
