import { useContext, useState } from "react";
import { Card, CardBody, Col, Collapse, Input, Row } from "reactstrap";
import StoreContext from "../context/StoreContext";
import OrganismeStockeurController from "../config/apiUtils/OrganismeStockeurController";
import FicheOrganismeStockeur from "./organismestockeur/FicheOrganismeStockeur";
import TableSilo from "./organismestockeur/TableSilo";
import FormUpdateOrganismeStockeur from "./organismestockeur/FormUpdateOrganismeStockeur";
import useConstructor from "../config/hooks/useConstructor";
import {
  RenderIf,
  getQueryParam,
  setQueryParam,
  createNotification,
} from "../config/utils";
import FormUpdateSilo from "./organismestockeur/FormUpdateSilo";

export default function OrganismeStockeur() {
  const store = useContext(StoreContext);
  const [idorganismestockeurSelected, setIdorganismestockeurSelected] =
    useState("-1");
  const [idsiloSelected, setIdsiloSelected] = useState("-1");
  const [showFormUpdateSilo, setShowFormUpdateSilo] = useState(false);
  const [showFormUpdateOrganismeStockeur, setShowFormUpdateOrganismeStockeur] =
    useState(false);

  useConstructor(async () => {
    const resOrganismes =
      await OrganismeStockeurController.getOrganismesStockeur();
    store.setOrganismesStockeur(resOrganismes);

    if (getQueryParam("id")) {
      setIdorganismestockeurSelected(getQueryParam("id"));
    }
  });

  const renderOrganismesStockeurOption = () => {
    return store.organismesStockeur.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const handleShowFormUpdateOrganismeStockeur = () => {
    setShowFormUpdateOrganismeStockeur(true);
  };

  const validateUpdateOrganismeStockeur = async (organisme) => {
    const res = await OrganismeStockeurController.updateOrganismeStockeur(
      idorganismestockeurSelected,
      organisme,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Organisme stockeur modifié avec succès !",
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de l'organisme stockeur...",
      );
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Organismes stockeurs</h2>
        </Col>
        <Col md="3" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Input
            type="select"
            className="select-input"
            name="referentiel"
            value={idorganismestockeurSelected}
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            onChange={(event) => {
              setQueryParam("id", event.target.value);
              setIdorganismestockeurSelected(event.target.value);
            }}
          >
            <option value={-1}>--</option>
            {renderOrganismesStockeurOption()}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col>
          <RenderIf isTrue={idorganismestockeurSelected === "-1"}>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card>
                <CardBody>
                  Sélectionnez l'organisme stockeur sur lequel vous souhaitez
                  travailler.
                </CardBody>
              </Card>
            </Col>
          </RenderIf>
          <RenderIf isTrue={idorganismestockeurSelected !== "-1"}>
            <Collapse
              isOpen={!showFormUpdateOrganismeStockeur}
              mountOnEnter
              unmountOnExit
            >
              <FicheOrganismeStockeur
                idorganismestockeurSelected={idorganismestockeurSelected}
                onClickUpdate={handleShowFormUpdateOrganismeStockeur}
              />
            </Collapse>
            <Collapse
              isOpen={showFormUpdateOrganismeStockeur}
              mountOnEnter
              unmountOnExit
            >
              <FormUpdateOrganismeStockeur
                idorganismestockeurSelected={idorganismestockeurSelected}
                onClickValidate={validateUpdateOrganismeStockeur}
                hideFormUpdateOrganismeStockeur={() => {
                  setShowFormUpdateOrganismeStockeur(false);
                }}
              />
            </Collapse>
          </RenderIf>
        </Col>
      </Row>
      <Collapse
        isOpen={idsiloSelected !== "-1" && showFormUpdateSilo}
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginTop: 30 }}>
          <Col>
            <FormUpdateSilo
              onClickClose={() => {
                setShowFormUpdateSilo(false);
                setIdsiloSelected("-1");
              }}
              idsilo={idsiloSelected}
            />
          </Col>
        </Row>
      </Collapse>
      <Collapse
        isOpen={
          idorganismestockeurSelected !== "-1" &&
          idsiloSelected === "-1" &&
          !showFormUpdateSilo
        }
        mountOnEnter
        unmountOnExit
      >
        <Row style={{ marginTop: 30 }}>
          <Col>
            <TableSilo
              onClickSilo={(idsilo) => {
                setIdsiloSelected(idsilo);
                setShowFormUpdateSilo(true);
              }}
              idorganismestockeurSelected={idorganismestockeurSelected}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
