import { Card, CardBody, Col, Row } from "reactstrap";
import { useContext, useState } from "react";
import useConstructor from "../config/hooks/useConstructor";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../components/Grids/ChangeExploitation/changeexploitation";
import Grid from "../components/Grid";
import ExploitationController from "../config/apiUtils/ExploitationController";
import SpinLoadingAnimation from "../components/SpinLoadingAnimation";
import { useNavigate } from "react-router-dom";
import StoreContext from "../context/StoreContext";
import { createNotification } from "../config/utils";

export default function ChangeExploitation() {
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const [exploitations, setExploitations] = useState([]);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    const resExploitations = await ExploitationController.getExploitations();
    setExploitations(resExploitations);
    setLoading(false);
  });

  const onRowClick = async (rowIdx) => {
    setLoading(true);
    await ExploitationController.changeExploitation(rowIdx.data.idexploitation);
    await store.loadData();
    createNotification(
      "success",
      "Changement de dossier effectué",
      "Vous travaillez maintenant sur le dossier " + rowIdx.data.raisonsociale,
    );
    setTimeout(() => {
      navigate("/app/exploitation");
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Changer d'exploitation</h2>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {loading && <SpinLoadingAnimation />}
                  {exploitations !== null &&
                    exploitations !== [] &&
                    exploitations !== undefined &&
                    !loading && (
                      <Grid
                        onRowClick={onRowClick}
                        idProperty="idexploitation"
                        columns={columns}
                        emptyText="Aucune exploitation n'est disponible"
                        dataSource={exploitations}
                        style={gridStyle}
                        defaultFilterValue={defaultFilterValue}
                        pagination
                        defaultLimit={100}
                      />
                    )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
