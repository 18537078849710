import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import { RenderIf } from "../../config/utils";

export default function FicheProducteur(props) {
  const store = useContext(StoreContext);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Fiche Producteur</CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <RenderIf
                isTrue={store.utilisateur.hasDroits("GESTION_PRODUCTEUR", 30)}
              >
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={props.onClickUpdateProducteur}
                >
                  Modifier
                </Button>
              </RenderIf>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Table style={{ color: "#FFFFFF" }}>
                <tbody>
                  <tr>
                    <th scope="row">Civilité</th>
                    <td>{props.producteur.civilite}</td>
                  </tr>
                  <tr>
                    <th scope="row">NOM</th>
                    <td>{props.producteur.nom}</td>
                  </tr>
                  <tr>
                    <th scope="row">Prénom</th>
                    <td>{props.producteur.prenom}</td>
                  </tr>
                  <tr>
                    <th scope="row">Exploitation</th>
                    <td>{props.producteur.raisonsociale}</td>
                  </tr>
                  <tr>
                    <th scope="row">Adresse e-mail</th>
                    <td>
                      {props.producteur.mail && props.producteur.mail !== ""
                        ? props.producteur.mail
                        : "Non renseigné"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Téléphone fixe</th>
                    <td>
                      {props.producteur.telephonefixe &&
                      props.producteur.telephonefixe !== ""
                        ? props.producteur.telephonefixe
                        : "Non renseigné"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Téléphone mobile</th>
                    <td>
                      {props.producteur.telephoneportable &&
                      props.producteur.telephoneportable !== ""
                        ? props.producteur.telephoneportable
                        : "Non renseigné"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
