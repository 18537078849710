import { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import StoreContext from "../../../context/StoreContext";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import CommuneInput from "../../../components/CommuneInput";
import { colourStyles } from "../../../config/carto/utils";
import useKeyPress from "../../../config/hooks/useKeyPress";
import Keys from "../../../config/Keys";
import { useRecoilState } from "recoil";
import {
  culturesState,
  typesSolState,
} from "../../../config/atoms/referentiels";
import { createNotification, RenderIf } from "../../../config/utils";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";
import ExploitationController from "../../../config/apiUtils/ExploitationController";

export default function ModalUpdateParcelle(props) {
  const cultureSelectRef = useRef();
  const typeSolSelectRef = useRef();
  const context = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [cultures, setCultures] = useRecoilState(culturesState);
  const [typesSol, setTypesSol] = useRecoilState(typesSolState);

  useEffect(() => {
    const loadReferentiels = async () => {
      setLoading(true);
      setTypesSol(await ReferentielController.getTypesSol());
      setCultures(await ReferentielController.getCultures());
      setLoading(false);
    };
    loadReferentiels();
  }, []);

  useKeyPress(Keys.Escape, () => {
    props.close();
  });

  useEffect(() => {
    if (cultureSelectRef.current != null) {
      const cultureParcelle = cultures.find(
        (c) => c.idrefculture === props.parcelle.idrefculture,
      );
      cultureSelectRef.current.setValue({
        value: props.parcelle.idrefculture,
        label: `${cultureParcelle.codeculture} - ${cultureParcelle.libelle}`,
      });
    }
  }, [cultures]);

  useEffect(() => {
    if (typeSolSelectRef.current != null) {
      const typeSolParcelle = typesSol.find(
        (t) => t.idreftypesol === props.parcelle.idreftypesol,
      );
      typeSolSelectRef.current.setValue({
        value: props.parcelle.idreftypesol,
        label: typeSolParcelle.libelle,
      });
    }
  }, [typesSol]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (cultureSelectRef.current.getValue().length <= 0) {
      createNotification("error", "La culture est obligatoire");
      return;
    }
    if (typeSolSelectRef.current.getValue().length <= 0) {
      createNotification("error", "Le type de sol est obligatoire");
      return;
    }

    setLoading(true);
    const form = {
      surfacesaisie: event.target.surfacesaisie.value,
      numeroparcelle: event.target.numeroparcelle.value,
      nomparcelle: event.target.nomparcelle.value,
      idcommune: event.target.idcommune.value,
      informationparcelle: event.target.informationparcelle.value,
      idrefculture: cultureSelectRef.current.getValue()[0].value,
      idreftypesol: typeSolSelectRef.current.getValue()[0].value,
    };

    const res = await ExploitationController.updateParcelleExploitation(
      context.exploitation.informations.idexploitation,
      props.parcelle.idparcelle,
      form,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle " + form.nomparcelle + " modifiée avec succès",
      );
      context.carto.parcelleAssocieeSelecterRef.current.clearValue();
      context.carto.parcelleAssocieeSelecterRef.current.setValue({
        value: props.parcelle.idparcelle,
        label: form.nomparcelle,
      });
      props.close();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification de la parcelle...",
      );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size={"lg"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className="modal-header-form">
        Parcelle : {props.parcelle?.nomparcelle}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="modal-body-form">
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row style={{ marginBottom: 10 }}>
              <Col md={4}>
                <Label for="numeroparcelle">Numéro de la parcelle</Label>
                <Input
                  name="numeroparcelle"
                  type="number"
                  placeholder="Numéro de la parcelle"
                  required
                  defaultValue={props.parcelle?.numeroparcelle}
                />
              </Col>
              <Col md={8}>
                <Label for="nomparcelle">Nom de la parcelle</Label>
                <Input
                  name="nomparcelle"
                  type="text"
                  placeholder="Nom de la parcelle"
                  required
                  defaultValue={props.parcelle?.nomparcelle}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <CommuneInput
                  codepostal={props.parcelle?.codepostal}
                  idcommune={props.parcelle?.idcommune}
                  setIdCommune={() => {}}
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <Label for="surfacesaisie">Surface déclarée (Ha)</Label>
                <Input
                  name="surfacesaisie"
                  type="text"
                  placeholder="Surface déclarée"
                  defaultValue={props.parcelle.surfacesaisie}
                  required
                />
              </Col>
              <Col md={6}>
                <Label for="surfacecalculee">Surface calculée (Ha)</Label>
                <Input
                  name="surfacecalculee"
                  type="text"
                  placeholder="Surface calculée"
                  defaultValue={props.parcelle.surface}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <Label for="idrefculture">
                  {"Culture" +
                    (props.parcelle.libellecultureprecedente
                      ? ` - Culture précédente : ${props.parcelle.libellecultureprecedente}`
                      : "")}
                </Label>
                <Select
                  ref={cultureSelectRef}
                  className="select-single"
                  classNamePrefix="react-select"
                  placeholder="Culture..."
                  menuPortalTarget={document.body}
                  styles={colourStyles}
                  options={[
                    ...cultures.map((culture) => ({
                      value: culture.idrefculture,
                      label: `${culture.codeculture} - ${
                        culture.libelle +
                        (culture.libellegroupeculture
                          ? " (" + culture.libellegroupeculture + ")"
                          : "")
                      }`,
                    })),
                  ]}
                  defaultValue={
                    props.parcelle?.idrefculture == null
                      ? null
                      : {
                          value: props.parcelle?.idrefculture,
                          label: `${
                            cultures.find(
                              (c) =>
                                c.idrefculture === props.parcelle?.idrefculture,
                            )?.codeculture
                          } - ${
                            cultures.find(
                              (c) =>
                                c.idrefculture === props.parcelle?.idrefculture,
                            )?.libelle +
                            (cultures.find(
                              (c) =>
                                c.idrefculture === props.parcelle?.idrefculture,
                            )?.libellegroupeculture
                              ? " (" +
                                cultures.find(
                                  (c) =>
                                    c.idrefculture ===
                                    props.parcelle?.idrefculture,
                                )?.libellegroupeculture +
                                ")"
                              : "")
                          }`,
                        }
                  }
                  onChange={props.onChange}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <FormGroup>
                  <Label for="idreftypesol">Type de sol</Label>
                  <Select
                    ref={typeSolSelectRef}
                    className="select-single"
                    classNamePrefix="react-select"
                    placeholder="Type de sol..."
                    menuPortalTarget={document.body}
                    styles={colourStyles}
                    options={[
                      ...typesSol.map((type) => ({
                        value: type.idreftypesol,
                        label: type.libelle,
                      })),
                    ]}
                    defaultValue={
                      props.parcelle?.idreftypesol == null
                        ? null
                        : {
                            value: props.parcelle?.idreftypesol,
                            label: typesSol.find(
                              (t) =>
                                t.idreftypesol === props.parcelle?.idreftypesol,
                            )?.libelle,
                          }
                    }
                    onChange={props.onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <FormGroup>
                  <Label for="informationparcelle">
                    Informations sur la parcelle
                  </Label>
                  <Input
                    name={"informationparcelle"}
                    type={"textarea"}
                    placeholder={"Informations sur la parcelle..."}
                    defaultValue={props.parcelle?.informationparcelle}
                  />
                </FormGroup>
              </Col>
            </Row>
          </RenderIf>
        </ModalBody>
        <ModalFooter
          className="modal-footer-form"
          style={{ justifyContent: "left" }}
        >
          <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
          <CancelButton
            style={{ marginTop: 15 }}
            onClick={(event) => {
              event.preventDefault();
              props.close();
            }}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
