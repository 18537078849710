import { useContext, useState } from "react";
import { Collapse } from "reactstrap";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import StoreContext from "../../context/StoreContext";
import ListeMateriel from "./Materiel/ListeMateriel";
import FormNewMateriel from "./Materiel/FormNewMateriel";
import FormUpdateMateriel from "./Materiel/FormUpdateMateriel";
import { createNotification } from "../../config/utils";

export default function Materiel() {
  const store = useContext(StoreContext);
  const [showFormNewMateriel, setShowFormNewMateriel] = useState(false);
  const [showFormUpdateMateriel, setShowFormUpdateMateriel] = useState(false);
  const [idrefmaterielToUpdate, setIdrefmaterielToUpdate] = useState();

  const handleAddNewMateriel = async (materiel) => {
    const res = await ReferentielController.insertMateriel(materiel);
    if (res.status === 200) {
      createNotification("success", "Succès", "Matériel créé avec succès");
      store.referentiels.setMateriels([
        ...store.referentiels.materiels,
        res.data,
      ]);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la création du matériel",
      );
    }
  };

  const handleUpdateMateriel = async (materiel) => {
    const res = await ReferentielController.updateMateriel(
      idrefmaterielToUpdate,
      materiel,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Matériel modifié avec succès");
      store.referentiels.setMateriels(
        await ReferentielController.getMateriels(),
      );
    } else {
      createNotification(
        "error",
        "Erreur",
        "Une erreur s'est produite lors de la modification du matériel",
      );
    }
  };

  return (
    <>
      <Collapse
        isOpen={showFormNewMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormNewMateriel
          onClickAdd={handleAddNewMateriel}
          onClickClose={() => setShowFormNewMateriel(false)}
        />
      </Collapse>
      <Collapse
        isOpen={showFormUpdateMateriel}
        style={{ marginBottom: 30 }}
        mountOnEnter
        unmountOnExit
      >
        <FormUpdateMateriel
          onClickUpdate={handleUpdateMateriel}
          onClickClose={() => setShowFormUpdateMateriel(false)}
          idrefmateriel={idrefmaterielToUpdate}
        />
      </Collapse>
      <ListeMateriel
        showFormUpdateMateriel={(idrefmateriel) => {
          setIdrefmaterielToUpdate(idrefmateriel);
          setShowFormUpdateMateriel(true);
        }}
        onClickNewMateriel={() => setShowFormNewMateriel(true)}
      />
    </>
  );
}
