import { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import ValidateButton from "./Buttons/ValidateButton";
import TableAffectation from "./AffectationGrid/TableAffectation";

export default function AffectationGrid(props) {
  const [dataSourceGauche, setDataSourceGauche] = useState(
    props.dataSourceGauche,
  );
  const [dataSourceDroite, setDataSourceDroite] = useState(
    props.dataSourceDroite,
  );
  const [allData] = useState(
    props.dataSourceDroite.concat(props.dataSourceGauche),
  );

  const moveLeftToRight = (value) => {
    dataSourceDroite.unshift(
      dataSourceGauche.find((data) => data[props.idProperty] === value),
    );
    var gauche = dataSourceGauche;
    var exp = dataSourceGauche.find((data) => data[props.idProperty] === value);

    let index = dataSourceGauche.indexOf(exp);
    if (index !== -1) {
      dataSourceGauche.splice(index, 1);
      setDataSourceGauche(dataSourceGauche);
    }
    gauche = gauche.filter((data) => {
      return !dataSourceDroite.find((droite) => {
        return droite[props.idProperty] === data[props.idProperty];
      });
    });
    setDataSourceGauche(gauche);
  };

  const moveRightToLeft = (value) => {
    dataSourceGauche.unshift(
      dataSourceDroite.find((data) => data[props.idProperty] === value),
    );
    var droite = dataSourceDroite;
    var exp = dataSourceDroite.find((data) => data[props.idProperty] === value);

    let index = dataSourceDroite.indexOf(exp);
    if (index !== -1) {
      dataSourceDroite.splice(index, 1);
      setDataSourceDroite(dataSourceDroite);
    }
    droite = droite.filter((data) => {
      return !dataSourceGauche.find((gauche) => {
        return gauche[props.idProperty] === data[props.idProperty];
      });
    });
    setDataSourceDroite(droite);
  };

  const [columnsGauche] = useState([
    ...props.columns,
    {
      header: "",
      name: props.idProperty,
      minWidth: 40,
      defaultWidth: 40,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      resizable: true,
      render: () => {
        return (
          <i
            className="fa-solid fa-arrow-right"
            style={{ cursor: "pointer" }}
          ></i>
        );
      },
      onClick: (data) => moveLeftToRight(data[props.idProperty]),
    },
  ]);
  const [columnsDroite] = useState([
    {
      header: "",
      name: props.idProperty,
      minWidth: 40,
      defaultWidth: 40,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      resizable: true,
      render: () => {
        return (
          <i
            className="fa-solid fa-arrow-left"
            style={{ cursor: "pointer" }}
          ></i>
        );
      },
      onClick: (data) => moveRightToLeft(data[props.idProperty]),
    },
    ...props.columns,
  ]);

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col md="6" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <h5>{props.titreGauche}</h5>
            <Button
              color="primary"
              style={{ padding: 5, fontSize: 10, cursor: "pointer" }}
              onClick={() => {
                setDataSourceDroite(allData);
                setDataSourceGauche([]);
              }}
            >
              Tout ajouter <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </div>
          <div
            style={{
              overflow: "auto",
              fontSize: 12,
              maxHeight: 500,
              marginBottom: 10,
            }}
          >
            <TableAffectation
              columns={columnsGauche}
              style={props.style}
              idProperty={props.idProperty}
              emptyText={props.emptyText}
              dataSource={dataSourceGauche}
            />
          </div>
          {dataSourceGauche.length} éléments
        </Col>
        <Col md="6" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <Button
              color="primary"
              style={{ padding: 5, fontSize: 10, cursor: "pointer" }}
              onClick={() => {
                setDataSourceGauche(allData);
                setDataSourceDroite([]);
              }}
            >
              <i className="fa-solid fa-arrow-left"></i> Tout retirer
            </Button>
            <h5>{props.titreDroite}</h5>
          </div>
          <div
            style={{
              overflow: "auto",
              fontSize: 12,
              maxHeight: 500,
              marginBottom: 10,
            }}
          >
            <TableAffectation
              columns={columnsDroite}
              style={props.style}
              idProperty={props.idProperty}
              emptyText={props.emptyText}
              dataSource={dataSourceDroite}
            />
          </div>
          {dataSourceDroite.length} éléments
        </Col>
      </Row>
      <Row>
        <Col>
          <ValidateButton
            onClick={() =>
              props.onClickValider(dataSourceGauche, dataSourceDroite)
            }
          />
        </Col>
      </Row>
    </>
  );
}
