import { Col, Row } from "reactstrap";
import ActionsGroupees from "../ActionsGroupees";
import TableExploitationsActivesIntentionsContrats from "./exploitationsactivesintentionscontrats/TableExploitationsActivesIntentionsContrats";

export default function ExploitationsActivesIntentionsContrats() {
  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <TableExploitationsActivesIntentionsContrats />
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
