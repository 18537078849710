import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { Alert } from "reactstrap";
import colors from "../../../config/colors";
import { dateFilterEditorProps } from "../../../config/utils";

export const groups = [
  { name: "surfaces", header: "Surfaces" },
  { name: "exploitation", header: "Exploitation" },
  { name: "production", header: "Production" },
  { name: "abandon", header: "Abandon" },
];

export const columns = [
  {
    header: "Validé",
    name: "idetatvalidationadministrative",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: 1, label: "Non validé administrativement" },
        {
          id: 2,
          label:
            "Validé par l'APGMB, en attente de validation par l'Organisme Stockeur",
        },
        {
          id: 3,
          label:
            "Validé par l'Organisme Stockeur, en attente de validation par l'APGMB",
        },
        { id: 4, label: "Validé administrativement" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    resizable: true,
    render: ({ cellProps, data, value }) => {
      cellProps.style.border = "1px solid";
      if (data.valide === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
      } else {
        if (value === 1) {
          cellProps.style.background = "#f9d7da"; // DANGER
          cellProps.style.color = "#842029"; // DANGER
        } else {
          cellProps.style.background = colors.warning.background;
          cellProps.style.color = colors.warning.color;
        }
      }
      return data.libellevalidationadministrative;
    },
  },
  {
    header: "Date de saisie",
    name: "datesurfacepotentielle",
    cellStyle: { textAlign: "center" },
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
  },
  {
    header: "Exploitation",
    name: "exploitation",
    cellStyle: { textAlign: "center" },
    //group: "exploitation",
    minWidth: 250,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "codedc",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Année d'adhésion",
    name: "millesimeadhesion",
    cellStyle: { textAlign: "center" },
    //group: "exploitation",
    minWidth: 250,
    defaultVisible: false,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code INSEE",
    name: "codeinsee",
    cellStyle: { textAlign: "center" },
    //group: "exploitation",
    minWidth: 200,
    defaultVisible: true,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "commune",
    cellStyle: { textAlign: "center" },
    //group: "exploitation",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface printemps (ha)",
    name: "surfacepotentielleprintemps",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface automne (ha)",
    name: "surfacepotentielleautomne",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface totale (ha)",
    name: "surfacepotentielletotale",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "surfaces",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Tonnage estimé (t)",
    name: "tonnageestime",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Type de production",
    name: "libelletypeproduction",
    cellStyle: { textAlign: "center" },
    //group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Quantité de semence",
    name: "quantitesemence",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Variété",
    name: "libellevariete",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Rendement de référence moyen",
    name: "rendementmoyen",
    type: "number",
    cellStyle: { textAlign: "center" },
    //group: "production",
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    //group: "production",
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [].map((c) => {
        return { id: c, label: c };
      }),
    },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Abandonné",
    name: "abandon",
    cellStyle: { textAlign: "center" },
    //group: "abandon",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ value }) => {
      if (value === true) {
        return (
          <div style={{ display: "flex" }}>
            <Alert
              fade={false}
              color="danger"
              style={{
                height: 12,
                padding: "0rem 1.5rem 1.5rem 1.5rem",
                marginTop: "1rem",
              }}
            >
              OUI
            </Alert>
          </div>
        );
      }
      return (
        <div style={{ display: "flex" }}>
          <Alert
            fade={false}
            color="success"
            style={{
              height: 12,
              padding: "0rem 1.5rem 1.5rem 1.5rem",
              marginTop: "1rem",
            }}
          >
            NON
          </Alert>
        </div>
      );
    },
  },
  {
    header: "Commentaires",
    name: "commentaire",
    cellStyle: { textAlign: "center" },
    //group: "abandon",
    minWidth: 400,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  {
    name: "idetatvalidationadministrative",
    type: "select",
    operator: "inlist",
    value: [],
  },
  {
    name: "datesurfacepotentielle",
    type: "date",
    operator: "after",
    value: "",
  },
  { name: "exploitation", type: "string", operator: "contains", value: "" },
  {
    name: "codedc",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfacepotentielleprintemps",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfacepotentielleautomne",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfacepotentielletotale",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "tonnageestime", type: "float", operator: "contains", value: "" },
  { name: "rendementmoyen", type: "float", operator: "contains", value: "" },
  { name: "codeinsee", type: "string", operator: "contains", value: "" },
  { name: "commune", type: "string", operator: "contains", value: "" },
  {
    name: "libelletypeproduction",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  {
    name: "nomorganismestockeur",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "abandon", type: "select", value: [], operator: "inlist" },
  { name: "commentaire", type: "string", operator: "contains", value: "" },
];
