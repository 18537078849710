import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import useWindowDimensions from "../../config/hooks/useWindowDimensions";
import Keys from "../../config/Keys";
import useKeyPress from "../../config/hooks/useKeyPress";

export default function ControlPanel(props) {
  const store = useContext(StoreContext);
  const { height, width } = useWindowDimensions();
  const [ratio] = useState(1.2);
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  const toggleSidebarClass = props.isOpen
    ? "fa-solid fa-arrow-left"
    : "fa-solid fa-arrow-right";

  useKeyPress(Keys.Escape, () => {
    props.close();
  });

  return (
    <div
      className={sidebarClass}
      style={
        height / width <= ratio && !store.userDevice.isMobile
          ? { height: props.height }
          : {
              height: props.height,
              left: props.isOpen ? "0vw" : "-80vw",
              width: "80vw",
            }
      }
    >
      {props.children}
      <button
        onClick={props.toggleSidebar}
        className="sidebar-toggle"
        title={props.isOpen ? "Fermer" : "Déployer"}
      >
        <i
          className={toggleSidebarClass}
          style={{ color: "white", cursor: "pointer" }}
        ></i>
      </button>
      <div className="sidebar-button-container">
        {(props.buttons ?? []).map(
          (button, key) =>
            button.show && (
              <button
                key={key}
                onClick={button.onClick}
                title={button.tooltip}
                className="sidebar-button"
              >
                {button.icon}
              </button>
            ),
        )}
      </div>
    </div>
  );
}
