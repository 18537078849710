import { useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import CommuneInput from "../../components/CommuneInput";

export default function FormUpdateUtilisateur(props) {
  const store = useContext(StoreContext);

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = {
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      adresse: event.target.adresse.value,
      idcommune: event.target.idcommune.value,
      pays: "France",
      telephone: event.target.telephone.value,
      username: store.utilisateur.informations.username,
    };
    await props.onClickUpdateInfos(form);
    props.hideUpdateUtilisateurForm();
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag="h5">Modifier les informations</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <CloseButton onClick={props.hideUpdateUtilisateurForm} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nom">Nom</Label>
                <Input
                  type="text"
                  name="nom"
                  placeholder="Nom"
                  defaultValue={store.utilisateur.informations.nom}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="prenom">Prénom</Label>
                <Input
                  type="text"
                  name="prenom"
                  placeholder="Prénom"
                  defaultValue={store.utilisateur.informations.prenom}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="adresse">Adresse</Label>
            <Input
              type="text"
              name="adresse"
              placeholder="Adresse"
              defaultValue={store.utilisateur.informations.adresse}
            />
          </FormGroup>
          <FormGroup>
            <CommuneInput
              codepostal={store.utilisateur.informations.codepostal}
              idcommune={store.utilisateur.informations.idcommune}
              setIdCommune={() => {}}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mail">Mail</Label>
            <Input
              type="mail"
              name="mail"
              placeholder="Mail"
              defaultValue={store.utilisateur.informations.mail}
              disabled
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="telephone">Téléphone</Label>
            <Input
              type="text"
              name="telephone"
              placeholder="Téléphone"
              defaultValue={store.utilisateur.informations.telephone}
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <Button
                style={{ marginTop: 15, marginRight: 15 }}
                type="submit"
                color="primary"
              >
                Valider
              </Button>
              <Button
                style={{ marginTop: 15 }}
                color="danger"
                onClick={(event) => {
                  event.preventDefault();
                  props.hideUpdateUtilisateurForm();
                }}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
