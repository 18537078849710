import React, { Fragment } from "react";
import StoreContext from "../../context/StoreContext";
import CartoController from "../../config/apiUtils/CartoController";
import Layer from "../../config/carto/Layer";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import GeoJSON from "ol/format/GeoJSON";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import ControlPanel from "../../components/carto/ControlPanel";
import { NavLink } from "react-router-dom";
import layoutRoutes from "../../config/layoutRoutes";
import { createNotification, RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import MapWrapper from "../../components/carto/MapWrapper";
import AddButton from "../../components/Buttons/AddButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
import Icons from "../../config/Icons";
import CommuneController from "../../config/apiUtils/CommuneController";
import { selectStyles } from "../../config/styles";

export default class CartoAppariement extends React.Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = {
      showModalNewParcelle: false,
      showModalUpdateParcelle: false,
      showInfosParcelle: false,
      controlPanelOpen: false,
      parcelleAssocieeSelected: null,

      loading: true,
      force: false,

      parcelleSelected: null,
      parcellaire: [],
      layers: [],
      selected: [],
    };
    this.height = "calc(100vh - 160px)";

    this.onMapClick = this.onMapClick.bind(this);
    this.associerParcelle = this.associerParcelle.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    this.loadData();
  }

  async loadData() {
    this.setState({ loading: true });
    const tmpLayers = await CartoController.getLayersCatalogue(
      Layer.catalogue.APPARIEMENT_PARCELLES,
    );
    this.context.carto.setLayers(tmpLayers);
    const tmp = tmpLayers.map(
      (layer) =>
        new Layer(layer, this.context.carto.mapRef, () =>
          this.setState({ force: !this.state.force }),
        ),
    );
    this.context.referentiels.setCultures(
      await ReferentielController.getCultures(),
    );
    const parcellaire = await ParcelleController.getParcelles();

    const features = parcellaire
      .filter((parcelle) => parcelle.geometrie != null)
      .filter((parcelle) => parcelle.geometrie != null)
      .map((parcelle) => {
        const parcelleWithoutGeom = Object.assign({}, parcelle);
        delete parcelleWithoutGeom.geometrie;
        return {
          ...parcelle.geometrie,
          properties: {
            ...parcelle.geometrie.properties,
            ...parcelleWithoutGeom,
          },
        };
      })
      .map((geom) => {
        return new GeoJSON().readFeatures(geom)[0];
      });
    tmp
      .find(
        (couche) =>
          couche.getCodeCouche() ===
          Layer.codeCouche.APPARIEMENT_PARCELLES_ASSOCIEES,
      )
      .addFeatures(features);

    this.setState({
      layers: tmp,
      loading: false,
      parcellaire,
    });
  }

  async associerParcelle() {
    createNotification("info", "Association en cours...", "");
    const geojson = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:EPSG::2154",
        },
      },
      features: [
        JSON.parse(new GeoJSON().writeFeature(this.state.selected[0])),
      ],
    };

    const res = await ParcelleController.associerParcelle(
      this.state.parcelleSelected?.idparcelle,
      geojson,
    );
    if (res.status === 200) {
      createNotification(
        "success",
        "Parcelle " +
          this.state.parcelleSelected?.nomparcelle +
          " associée avec succès",
        "",
      );
      const tmp = [...this.state.parcellaire];
      const parcelleWithoutGeom = Object.assign({}, res.data);
      delete parcelleWithoutGeom.geometrie;
      tmp.find(
        (p) => p.idparcelle === this.state.parcelleSelected?.idparcelle,
      ).geometrie = {
        ...res.data.geometrie,
        properties: {
          ...res.data.geometrie.properties,
          ...parcelleWithoutGeom,
        },
      };
      this.getParcellaireLayer().addFeatures([
        new GeoJSON().readFeature({
          ...res.data.geometrie,
          properties: {
            ...res.data.geometrie.properties,
            ...parcelleWithoutGeom,
          },
        }),
      ]);
      this.setState({ parcellaire: tmp });
    }
  }

  handleDrop(droppedItem) {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    return;
    /*const itemBase = [
      ...this.state.layers.filter(
        (layer) => layer.getCodeCouche() !== droppedItem.draggableId,
      ),
    ];
    let updatedList = [
      ...itemBase.slice(0, itemBase.length - droppedItem.destination.index),
      this.state.layers.find(
        (layer) => layer.getCodeCouche() === droppedItem.draggableId,
      ),
      ...itemBase.slice(itemBase.length - droppedItem.destination.index),
    ];

    this.setState({ layers: updatedList });
    this.forceUpdate();
    return;*/
  }

  getParcellaireLayer() {
    return this.state.layers.find(
      (couche) =>
        couche.getCodeCouche() ===
        Layer.codeCouche.APPARIEMENT_PARCELLES_ASSOCIEES,
    );
  }

  getRpgLayer() {
    return this.state.layers.find(
      (couche) => couche.getCodeCouche() === Layer.codeCouche.RPG_ANONYME,
    );
  }

  onMapClick(evt) {
    const me = this;
    const hasClickedFeatures = [];
    this.getParcellaireLayer().setSelectable(true, (evt) => {
      let hasClickedFeature = false;
      me.context.carto.mapRef.current.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          if (me.getParcellaireLayer().isFeatureInLayer(feature)) {
            hasClickedFeature = true;
            me.getParcellaireLayer().toggleSelectionFeature(feature);
          }
        },
      );
      hasClickedFeatures.push(hasClickedFeature);
      if (!hasClickedFeature) {
        me.getParcellaireLayer().clearSelection();
      }
    });

    this.getRpgLayer().setSelectable(true, (evt) => {
      let hasClickedFeature = false;
      me.context.carto.mapRef.current.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          if (me.getRpgLayer().isFeatureInLayer(feature)) {
            hasClickedFeature = true;
            me.getRpgLayer().toggleSelectionFeature(feature);
          }
        },
      );
      hasClickedFeatures.push(hasClickedFeature);
      if (!hasClickedFeature) {
        me.getRpgLayer().clearSelection();
      }
    });

    this.state.layers.forEach((layer) => {
      if (layer.selectable) {
        layer.onSelectEvent(evt);
      }
    });

    const tmp = [];
    this.state.layers.forEach((layer) => {
      layer.selected.forEach((feature) => tmp.push(feature));
    });

    this.setState({ selected: tmp });
  }

  renderSelectedParcelles() {
    if (this.state.selected.length > 0) {
      return this.state.selected.map((feature, key) => {
        if (this.getRpgLayer().isFeatureInLayer(feature)) {
          return (
            <Fragment key={key}>
              Parcelle : {feature.getProperties()["id_parcel"]},{" "}
              {feature.getProperties()["surf_parc"]} Ha -{" "}
              {
                this.context.referentiels.cultures.find(
                  (c) => c.codeculture == feature.getProperties()["code_cultu"],
                ).libelle
              }
              <br />
            </Fragment>
          );
        }
      });
    }
  }

  renderButtonsLayer() {
    return (
      <DragDropContext onDragEnd={this.handleDrop}>
        <Droppable
          droppableId="buttons"
          ignoreContainerClipping={true}
          isCombineEnabled={true}
          type="COLUMN"
        >
          {(provided) => (
            <div
              className="characters"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {[...this.state.layers]
                .sort((layer1, layer2) => layer2.info.ordre - layer1.info.ordre)
                .map((layer, key) => {
                  return (
                    <Draggable
                      key={layer.getCodeCouche()}
                      draggableId={layer.getCodeCouche()}
                      index={key}
                      ignoreContainerClipping={true}
                      isCombineEnabled={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          key={layer.getCodeCouche()}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {layer.getButtonLayer()}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  renderControlPanel() {
    return (
      <ControlPanel
        isOpen={this.state.controlPanelOpen}
        close={() => this.setState({ controlPanelOpen: false })}
        toggleSidebar={() => {
          if (!this.state.controlPanelOpen) {
            //fondCartoSelectRef.current.focus();
          }
          this.setState({ controlPanelOpen: !this.state.controlPanelOpen });
        }}
        height={this.height}
        buttons={[
          {
            icon: <Icons.SelectionMapIcon style={{ height: 25 }} />,
            tooltip: "Zoomer sur la sélection",
            show: this.state.selected.length > 0,
            onClick: () => {
              this.context.carto.zoomMap(this.state.selected);
            },
          },
        ]}
      >
        <div
          style={{
            height: this.height,
            overflowX: "clip",
            overflowY: "auto",
            marginTop: 20,
            paddingBottom: 100,
          }}
        >
          <RenderIf isTrue={this.state.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!this.state.loading}>
            {this.renderButtonsLayer()}
          </RenderIf>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            width: "100%",
          }}
        >
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <NavLink
                style={{ textDecoration: "none", color: "#000000" }}
                to={`${layoutRoutes.userLayout}/parcelles`}
              >
                <Button color="warning">Tableau des parcelles</Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </ControlPanel>
    );
  }

  renderMap() {
    return (
      <Card
        className="card-carto"
        style={{
          height: this.height,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RenderIf isTrue={this.state.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!this.state.loading}>
          <MapWrapper
            showZoomControl
            showScaleline
            pageCarto
            onMapClick={this.onMapClick}
            layers={this.state.layers.map((layer) => layer.getLayer())}
          />
        </RenderIf>
      </Card>
    );
  }

  renderPanelRight() {
    return (
      <Card className="card-carto" style={{ height: this.height }}>
        <CardHeader className="card-header-carto">
          <Row style={{ textAlign: "left" }}>
            <Col>
              <CardTitle tag="h4">Appariement des parcelles</CardTitle>
            </Col>
            <Col
              md={1}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <AddButton
                id="button-add"
                onClick={() => {
                  //drawer.draw();
                  //setIsDrawing(true);
                }}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          style={{
            overflowX: "clip",
            overflowY: "auto",
            paddingBottom: 100,
          }}
        >
          <RenderIf isTrue={this.state.loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!this.state.loading}>
            <RenderIf isTrue={this.state.parcellaire.length == 0}>
              <Alert color="warning">
                Pas de parcelle enregistrée en{" "}
                {this.context.millesime.idmillesime}
              </Alert>
            </RenderIf>
            <RenderIf
              isTrue={
                this.state.parcellaire.filter((p) => p.geometrie == null)
                  .length > 0
              }
            >
              <h5>
                Parcelles non carto (
                {
                  this.state.parcellaire.filter((p) => p.geometrie == null)
                    .length
                }
                )
              </h5>
              <Row>
                <Col>
                  <Select
                    isClearable
                    className="select-single"
                    classNamePrefix="react-select"
                    isLoading={this.state.loading}
                    menuPortalTarget={document.body}
                    placeholder="Sélectionnez une parcelle..."
                    styles={selectStyles}
                    options={this.state.parcellaire
                      .filter((p) => p.geometrie == null)
                      .map((parcelle) => ({
                        value: parcelle.idparcelle,
                        label:
                          parcelle.nomparcelle +
                          " - " +
                          parcelle.codepostal +
                          " " +
                          parcelle.nomcommune,
                      }))}
                    onChange={async (selection) => {
                      if (selection) {
                        this.setState({
                          parcelleSelected: this.state.parcellaire.find(
                            (parcelle) =>
                              parcelle.idparcelle === selection.value,
                          ),
                        });
                      } else {
                        this.setState({ parcelleSelected: null });
                      }
                    }}
                  />
                </Col>
              </Row>
            </RenderIf>
            <RenderIf isTrue={this.state.parcelleSelected != null}>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <h5>{this.state.parcelleSelected?.nomparcelle}</h5>
                  {this.state.parcelleSelected?.codepostal} -{" "}
                  {this.state.parcelleSelected?.nomcommune}
                  <br />
                  Sol : {this.state.parcelleSelected?.libelletypesol}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color="info"
                    style={{ margin: 10 }}
                    onClick={async () => {
                      const geoJsonCommune =
                        await CommuneController.getGeometrieCommune(
                          this.state.parcelleSelected?.idcommune,
                        );
                      if (geoJsonCommune == null) {
                        createNotification(
                          "error",
                          "Géométrie de la commune inconnue, impossible de centrer",
                          "",
                        );
                        return;
                      }
                      this.context.carto.zoomMap(
                        new GeoJSON().readFeatures(geoJsonCommune),
                      );
                    }}
                  >
                    Centrer sur la commune
                  </Button>
                </Col>
              </Row>
            </RenderIf>
            <RenderIf isTrue={this.state.selected.length >= 1}>
              <hr />
              <Row>
                <Col>{this.renderSelectedParcelles()}</Col>
              </Row>
            </RenderIf>
            <RenderIf
              isTrue={
                this.state.selected.length === 1 &&
                this.state.parcelleSelected != null
              }
            >
              <Row>
                <Col>
                  <Button
                    color="warning"
                    style={{ margin: 10 }}
                    onClick={this.associerParcelle}
                  >
                    Associer
                  </Button>
                </Col>
              </Row>
            </RenderIf>
            <RenderIf
              isTrue={
                this.state.selected.length > 1 &&
                this.state.parcelleSelected != null
              }
            >
              <Alert color="danger" style={{ marginTop: 10 }}>
                Plusieurs parcelles sélectionnées, impossible d'associer
              </Alert>
            </RenderIf>
            <RenderIf
              isTrue={
                this.state.parcellaire.filter((p) => p.geometrie != null)
                  .length > 0
              }
            >
              <hr />
              <h5>
                Parcelles associées (
                {
                  this.state.parcellaire.filter((p) => p.geometrie != null)
                    .length
                }
                )
              </h5>
              <Row>
                <Col>
                  <Select
                    isClearable
                    className="select-single"
                    classNamePrefix="react-select"
                    isLoading={this.state.loading}
                    menuPortalTarget={document.body}
                    placeholder="Sélectionnez une parcelle..."
                    styles={selectStyles}
                    options={this.state.parcellaire
                      .filter((p) => p.geometrie != null)
                      .map((parcelle) => ({
                        value: parcelle.idparcelle,
                        label:
                          parcelle.nomparcelle +
                          " - " +
                          parcelle.codepostal +
                          " " +
                          parcelle.nomcommune,
                      }))}
                    onChange={async (selection) => {
                      if (selection) {
                        this.context.carto.zoomMap([
                          new GeoJSON().readFeature(
                            this.state.parcellaire.find(
                              (parcelle) =>
                                parcelle.idparcelle === selection.value,
                            ).geometrie,
                          ),
                        ]);
                      }
                    }}
                  />
                </Col>
              </Row>
            </RenderIf>
          </RenderIf>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <>
        <Row>
          {this.renderControlPanel()}
          <Col md="9" style={{ paddingRight: 0, zIndex: 0 }}>
            {this.renderMap()}
          </Col>
          <Col md="3" style={{ paddingLeft: 0 }}>
            {this.renderPanelRight()}
          </Col>
        </Row>
      </>
    );
  }
}
