import { useContext } from "react";
import moment from "moment";
import StoreContext from "../../context/StoreContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import { createNotification } from "../../config/utils";

export default function FormNewCertificationIgp(props) {
  const store = useContext(StoreContext);

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = {
      millesime: store.millesime.idmillesime,
      datedemande: event.target.datedemande.value,
      dateenvoicertif: event.target.dateenvoicertif.value,
      datehabilitation: event.target.datehabilitation.value,
      habilite: event.target.habilite.value,
      actif: event.target.actif.value,
    };

    const res = await ExploitationController.habilitationIgp(
      store.exploitation.informations.idexploitation,
      form,
    );

    if (res.status === 200) {
      await store.exploitation.loadDernierDossier();
      props.close();
      createNotification(
        "success",
        "Succès",
        "Habilitation créée pour l'année " + store.millesime.idmillesime,
      );
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>Nouvelle habilitation IGP</CardTitle>
        <CloseButton onClick={props.close} />
        <CardSubtitle>
          Ajout d'une nouvelle habilitation IGP à l'exploitation pour l'année de
          récolte {store.millesime.idmillesime}
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: 10 }}>
            <Col md="6">
              <FormGroup>
                <Label for="datedemande">Date de la demande</Label>
                <Input
                  type="date"
                  name="datedemande"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="dateenvoicertif">
                  Date d'envoi de la certification
                </Label>
                <Input
                  type="date"
                  name="dateenvoicertif"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FormGroup>
                <Label for="datehabilitation">Date de l'habilitation</Label>
                <Input
                  type="date"
                  name="datehabilitation"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="6">
              <FormGroup>
                <Label for="habilite">Habilité</Label>
                <Input type="select" className="select-input" name="habilite">
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="actif">Actif</Label>
                <Input type="select" className="select-input" name="actif">
                  <option value={true}>OUI</option>
                  <option value={false}>NON</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidateButton style={{ marginRight: 10 }} />
              <CancelButton
                onClick={(event) => {
                  event.preventDefault();
                  props.close();
                }}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
