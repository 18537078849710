import { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Collapse,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ContratController from "../../config/apiUtils/ContratController";
import ParcelleController from "../../config/apiUtils/ParcelleController";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import {
  createNotification,
  getQueryParam,
  GoToTopPage,
  RenderIf,
} from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import {
  columns,
  gridStyle,
  defaultFilterValue,
} from "../../components/Grids/Contrat/tableparcelleformcompleter";
import {
  columns as columnsParcelleContrat,
  gridStyle as gridStyleParcelleContrat,
  defaultFilterValue as defaultFilterValueParcelleContrat,
} from "../../components/Grids/Contrat/tableparcellerecapformcompleter";
import Grid from "../../components/Grid";

export default function FormCompleterContrat(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [parcelleSelected, setParcelleSelected] = useState(null);
  const [contrat, setContrat] = useState({});
  const [nbParcellesRestantes, setNbParcellsRestantes] = useState(0);

  useConstructor(async () => {
    GoToTopPage();
    setLoading(true);
    const tmp = await ContratController.getContratDetail(getQueryParam("id"));
    setContrat(tmp);
    setNbParcellsRestantes(tmp.nbparcelles - tmp.nbparcellesrenseignee);
    if (tmp.nbparcelles - tmp.nbparcellesrenseignee <= 0) {
      props.onClickClose();
    }
    const res = await ParcelleController.getParcelles();
    store.setParcelles(res);
    const typesSol = [];
    res.forEach((p) => {
      if (!typesSol.some((type) => type === p.libelletypesol)) {
        typesSol.push(p.libelletypesol);
      }
    });
    columns.forEach((column) => {
      if (column.name === "libelletypesol") {
        column.filterEditorProps = {
          multiple: true,
          wrapMultiple: false,
          dataSource: typesSol.map((c) => {
            return { id: c, label: c };
          }),
        };
      }
    });
    setLoading(false);
  });

  const onRowClick = (rowIdx) => {
    setParcelleSelected(rowIdx.data);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let sommeSurfaces =
      (event.target.surfaceengageeprintemps.value != ""
        ? event.target.surfaceengageeprintemps.value
        : 0) +
      (event.target.surfaceengageeautomne.value != ""
        ? event.target.surfaceengageeautomne.value
        : 0);

    if (sommeSurfaces == 0) {
      createNotification(
        "error",
        "Merci de renseigner une surface supérieure à 0 Ha",
        "",
      );
      return;
    }
    const form = {
      idparcelle: parcelleSelected.idparcelle,
      surfaceengageeprintemps:
        event.target.surfaceengageeprintemps.value != ""
          ? event.target.surfaceengageeprintemps.value
          : 0,
      surfaceengageeautomne:
        event.target.surfaceengageeautomne.value != ""
          ? event.target.surfaceengageeautomne.value
          : 0,
      commentaire: event.target.commentaire.value,
      millesime: store.millesime.idmillesime,
    };

    const res = await ContratController.completerContrat(
      contrat.idcontrat,
      form,
    );

    if (res.status === 200) {
      setParcelleSelected(null);
      await props.loadContrats();
      setNbParcellsRestantes(nbParcellesRestantes - 1);
      const tmp = await ContratController.getContratDetail(getQueryParam("id"));
      setContrat(tmp);
      setNbParcellsRestantes(tmp.nbparcelles - tmp.nbparcellesrenseignee);
      if (nbParcellesRestantes - 1 === 0) {
        // prettier-ignore
        createNotification(
          "success",
          "Contrat complété",
          "Votre contrat a bien été complété. Pour continuer, merci de signer le contrat en cliquant sur le bouton \"Signer le contrat\" présent dans la Fiche contrat",
          15000,
        );
        props.onClickClose();
      } else {
        createNotification("success", "Succès", "Contrat modifié avec succès");
      }
    } else {
      createNotification("error", "Erreur", "Une erreur s'est produite...");
    }
  };

  const renderNbParcellesRestantes = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col>
          Il reste {nbParcellesRestantes} parcelle
          {nbParcellesRestantes > 1 ? "s" : ""} à renseigner pour compléter le
          contrat {contrat.numerocontrat}
        </Col>
      </Row>
    );
  };

  const renderParcelleInformation = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope="row">Nom de la parcelle</th>
            <td>{parcelleSelected?.nomparcelle}</td>
          </tr>
          <tr>
            <th scope="row">Commune</th>
            <td>
              {parcelleSelected?.codepostal} {parcelleSelected?.nomcommune}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderInformationExploitation = () => {
    return (
      <Table style={{ color: "#FFFFFF" }}>
        <tbody>
          <tr>
            <th scope="row">Raison sociale</th>
            <td>{contrat.raisonsociale}</td>
          </tr>
          <tr>
            <th scope="row">Numéro SIRET</th>
            <td>{contrat.siret}</td>
          </tr>
          <tr>
            <th scope="row">Adresse</th>
            <td>
              {contrat.adresse}
              <br />
              {contrat.codepostal} {contrat.nomcommune}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Compléter le contrat : {contrat.numerocontrat}
              </CardTitle>
              <CardSubtitle tag={"h6"}>
                Ajouter une parcelle au contrat
              </CardSubtitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row>
              <Col md="5">
                {renderNbParcellesRestantes()}
                <h5>Informations exploitation</h5>
                {renderInformationExploitation()}
                <h5>Parcelle du contrat {contrat.numerocontrat}</h5>
                <Grid
                  idProperty="idparcelle"
                  emptyText="Aucune parcelle contractualisée"
                  columns={columnsParcelleContrat}
                  dataSource={contrat?.parcelles}
                  defaultFilterValue={defaultFilterValueParcelleContrat}
                  style={gridStyleParcelleContrat}
                  pagination
                  defaultLimit={100}
                />
              </Col>
              <Col md="7">
                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                  <h5>
                    Parcelles -{" "}
                    {parcelleSelected != null
                      ? parcelleSelected.nomparcelle
                      : "Sélectionnez une parcelle dans la liste pour compléter le contrat"}
                  </h5>
                  <Col>
                    <Collapse isOpen={parcelleSelected == null}>
                      <Grid
                        enableCellSelect={true}
                        idProperty="idparcelle"
                        emptyText="Aucune parcelle renseignée"
                        columns={columns}
                        dataSource={store.parcelles}
                        defaultFilterValue={defaultFilterValue}
                        style={gridStyle}
                        onRowClick={onRowClick}
                        pagination
                        defaultLimit={100}
                      />
                      <Row style={{ marginTop: 10 }}>
                        <Col>
                          Votre parcelle ne se trouve pas dans cette liste ?{" "}
                          <Button
                            color="primary"
                            onClick={props.onClickNewParcelle}
                          >
                            Ajoutez-la
                          </Button>
                        </Col>
                      </Row>
                    </Collapse>
                    <Collapse
                      isOpen={parcelleSelected != null}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Form onSubmit={onSubmit}>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md="6">{renderParcelleInformation()}</Col>
                          <Col md="6">
                            <Label for="commentaire">
                              Commentaires et remarques
                            </Label>
                            <Input
                              name="commentaire"
                              type="textarea"
                              placeholder="Commentaires et remarques..."
                            ></Input>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md="6">
                            <Label for="surfaceengageeprintemps">
                              Surface engagée pour le printemps
                            </Label>
                            <Input
                              name="surfaceengageeprintemps"
                              type="text"
                              placeholder="Surface engagée pour le printemps"
                              pattern="^\d{1,4}(?:\.\d{1,2})?$"
                              title="Merci de ne saisir que des chiffres"
                              required
                              defaultValue={0}
                            ></Input>
                          </Col>
                          <Col md="6">
                            <Label for="surfaceengageeautomne">
                              Surface engagée pour l'automne
                            </Label>
                            <Input
                              name="surfaceengageeautomne"
                              type="text"
                              placeholder="Surface engagée pour l'automne"
                              pattern="^\d{1,4}(?:\.\d{1,2})?$"
                              title="Merci de ne saisir que des chiffres"
                              required
                              defaultValue={0}
                            ></Input>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                          <Col>
                            <ValidateButton style={{ marginRight: 10 }} />
                            <CancelButton
                              style={{ marginRight: 10 }}
                              onClick={() => setParcelleSelected(null)}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
