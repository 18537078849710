import Section from "../Section";
import { Accordion, Alert, Col, Collapse, Row } from "reactstrap";
import { useContext, useState } from "react";
import StoreContext from "../../../../context/StoreContext";
import {
  deleteQueryParam,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../../../config/utils";
import ButtonCartoAction from "../../buttons/ButtonCartoAction";
import ModalNewIntentionSemis from "../../modal/ModalNewIntentionSemis";
import IntentionSemis from "./IntentionSemis";

export default function IntentionSemisSection() {
  const context = useContext(StoreContext);
  const [showModalNewIntention, setShowModalNewIntention] = useState(
    getQueryParam("newintention") === "true",
  );
  const [openedSection, setOpenedSection] = useState(
    getQueryParam("intentionsemis") ?? "",
  );

  const toggle = (id) => {
    if (openedSection === id) {
      deleteQueryParam("intentionsemis");
    } else {
      setQueryParam("intentionsemis", id);
    }
    setOpenedSection(openedSection === id ? "" : id);
  };

  return (
    <>
      <Collapse isOpen={showModalNewIntention} mountOnEnter unmountOnExit>
        {
          <ModalNewIntentionSemis
            isOpen={showModalNewIntention}
            toggle={() => {
              deleteQueryParam("newintention");
              setShowModalNewIntention(!showModalNewIntention);
            }}
          />
        }
      </Collapse>
      <Section id={"intentionsemis"} title={"Intention de semis"}>
        <Row>
          <RenderIf
            isTrue={
              context.surfacesPotentielles.length === 0 ||
              context.surfacesPotentielles.length ===
                context.surfacesPotentielles.filter(
                  (intention) => intention.abandon,
                ).length
            }
          >
            <ButtonCartoAction
              onClick={() => {
                setShowModalNewIntention(true);
                if (getQueryParam("newintention") === "true") {
                  deleteQueryParam("newintention");
                } else {
                  setQueryParam("newintention", "true");
                }
              }}
              libelle={"Nouvelle intention"}
            />
            <Row style={{ marginTop: 10 }}>
              <Col>
                <Alert fade={false} color={"danger"}>
                  Intention de semis non renseignée en{" "}
                  {context.millesime.idmillesime}
                </Alert>
              </Col>
            </Row>
          </RenderIf>
          <RenderIf
            isTrue={
              context.surfacesPotentielles.filter(
                (intention) => !intention.abandon,
              ).length > 0
            }
          >
            <Accordion
              className={"section-accordion-carto"}
              open={openedSection}
              toggle={toggle}
            >
              {context.surfacesPotentielles
                .filter((intention) => !intention.abandon)
                .map((intention, index) => (
                  <IntentionSemis intention={intention} key={index} />
                ))}
            </Accordion>
          </RenderIf>
        </Row>
      </Section>
    </>
  );
}
