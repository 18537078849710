import { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import ReferentielController from "../../../config/apiUtils/ReferentielController";
import StoreContext from "../../../context/StoreContext";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import CommuneInput from "../../../components/CommuneInput";
import {
  colourStyles,
  getGeoJsonFeature,
  getSurface,
} from "../../../config/carto/utils";
import useKeyPress from "../../../config/hooks/useKeyPress";
import Keys from "../../../config/Keys";
import { createNotification, RenderIf } from "../../../config/utils";
import GeoJSON from "ol/format/GeoJSON";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import ParcelleController from "../../../config/apiUtils/ParcelleController";
import SpinLoadingAnimation from "../../../components/SpinLoadingAnimation";

export default function ModalNewParcelle(props) {
  const [exploitations, setExploitations] = useState([]);
  const [cultures, setCultures] = useState([]);
  const [commune, setCommune] = useState();
  const [loading, setLoading] = useState(true);
  const cultureSelectRef = useRef();
  const typeSolSelectRef = useRef();
  const store = useContext(StoreContext);

  useEffect(() => {
    const loadReferentiels = async () => {
      setLoading(true);
      store.referentiels.setTypesSol(await ReferentielController.getTypesSol());
      setExploitations(
        await ExploitationController.getAllExploitations("", "", null),
      );
      setCultures(await ReferentielController.getCultures());
      setCommune(
        await ParcelleController.getCommuneParcelleByGeom(
          getGeoJsonFeature(props.feature),
        ),
      );
      setLoading(false);
    };
    loadReferentiels();
  }, []);

  useKeyPress(Keys.Escape, () => {
    props.toggle();
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    if (cultureSelectRef.current.getValue().length <= 0) {
      createNotification("error", "La culture est obligatoire");
      return;
    }
    if (typeSolSelectRef.current.getValue().length <= 0) {
      createNotification("error", "Le type de sol est obligatoire");
      return;
    }

    const newParcelle = {
      nomparcelle: event.target.nomparcelle.value,
      idrefculture: cultureSelectRef.current.getValue()[0].value,
      idreftypesol: typeSolSelectRef.current.getValue()[0].value,
      idcommune: event.target.idcommune.value,
      numeroparcelle: event.target.numeroparcelle.value,
      informationparcelle: event.target.informationparcelle.value,
      surface: event.target.surfacecalculee.value,
      surfacesaisie: event.target.surfacesaisie.value,
      geojson: getGeoJsonFeature(props.feature),
    };

    const res = await ExploitationController.insertParcelleExploitation(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
      newParcelle,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Parcelle " + newParcelle.nomparcelle + " créée avec succès",
      );
      props.addFeature(new GeoJSON().readFeature(res.data.geometrie));
      props.toggle();
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la création de la parcelle...",
      );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size={"lg"}
      centered
      scrollable={false}
      unmountOnClose
    >
      <ModalHeader className="modal-header-form">Nouvelle parcelle</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="modal-body-form">
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Row style={{ marginBottom: 10 }}>
              <Col md={4}>
                <Label for="numeroparcelle">Numéro de la parcelle</Label>
                <Input
                  name="numeroparcelle"
                  type="number"
                  placeholder="Numéro de la parcelle"
                  required
                />
              </Col>
              <Col md={8}>
                <Label for="nomparcelle">Nom de la parcelle</Label>
                <Input
                  name="nomparcelle"
                  type="text"
                  placeholder="Nom de la parcelle"
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <CommuneInput
                  disabled={loading}
                  loading={loading}
                  codepostal={commune?.codepostal ?? ""}
                  idcommune={commune?.idcommune}
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <Label for="surfacesaisie">Surface déclarée (Ha)</Label>
                <Input
                  name="surfacesaisie"
                  type="text"
                  placeholder="Surface déclarée"
                  defaultValue={getSurface(props.feature.getGeometry())}
                  required
                />
              </Col>
              <Col md={6}>
                <Label for="surfacecalculee">Surface calculée (Ha)</Label>
                <Input
                  name="surfacecalculee"
                  type="text"
                  placeholder="Surface calculée"
                  defaultValue={getSurface(props.feature.getGeometry())}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <Label for="idrefculture">Culture</Label>
                <Select
                  ref={cultureSelectRef}
                  className="select-single"
                  classNamePrefix="react-select"
                  placeholder="Culture..."
                  menuPortalTarget={document.body}
                  styles={colourStyles}
                  options={[
                    ...cultures.map((culture) => ({
                      value: culture.idrefculture,
                      label: `${culture.codeculture} - ${
                        culture.libelle +
                        (culture.libellegroupeculture
                          ? " (" + culture.libellegroupeculture + ")"
                          : "")
                      }`,
                    })),
                  ]}
                  onChange={props.onChange}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <FormGroup>
                  <Label for="idreftypesol">Type de sol</Label>
                  <Select
                    ref={typeSolSelectRef}
                    className="select-single"
                    classNamePrefix="react-select"
                    placeholder="Type de sol..."
                    menuPortalTarget={document.body}
                    styles={colourStyles}
                    options={[
                      ...store.referentiels.typesSol.map((type) => ({
                        value: type.idreftypesol,
                        label: type.libelle,
                      })),
                    ]}
                    onChange={props.onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <FormGroup>
                  <Label for="informationparcelle">
                    Informations sur la parcelle
                  </Label>
                  <Input
                    name={"informationparcelle"}
                    type={"textarea"}
                    placeholder={"Informations sur la parcelle..."}
                  />
                </FormGroup>
              </Col>
            </Row>
          </RenderIf>
        </ModalBody>
        <ModalFooter
          className="modal-footer-form"
          style={{ justifyContent: "left" }}
        >
          <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
          <CancelButton
            style={{ marginTop: 15 }}
            onClick={(event) => {
              event.preventDefault();
              props.toggle();
            }}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
}
