import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import colors from "../../../config/colors";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";

export const columns = [
  {
    header: "SIRET",
    name: "siret",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse",
    name: "adresse",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface du contrat (Ha)",
    name: "surfacefinale",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface jachère (Ha)",
    name: "surfacejachere",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Surface jachère conforme (4%)",
    name: "estconforme",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: null, label: "Non renseigné", nomId: "estconforme" },
        { id: true, label: "OUI", nomId: "estconforme" },
        { id: false, label: "NON", nomId: "estconforme" },
      ],
      collapseOnSelect: true,
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      } else if (value === false) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "NON";
      } else {
        cellProps.style.background = colors.warning.background;
        cellProps.style.color = colors.warning.color;
        return "Non renseigné";
      }
    },
  },
];

export const gridStyle = { minHeight: 500, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "siret", type: "float", operator: "contains", value: "" },
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "adresse", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  { name: "surfacefinale", type: "float", operator: "contains", value: "" },
  { name: "surfacejachere", type: "float", operator: "contains", value: "" },
  {
    name: "adresse",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "codepostal", type: "string", operator: "contains", value: "" },
  {
    name: "nomcommune",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "estconforme", type: "select", value: [], operator: "inlist" },
];
