import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import StoreContext from "../../../../context/StoreContext";
import Section from "../Section";
import { Row } from "reactstrap";
import {
  createNotification,
  deleteQueryParam,
  downloadFile,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../../../config/utils";
import ButtonCartoAction from "../../buttons/ButtonCartoAction";
import CompleterContrat from "./CompleterContrat";
import EditionController from "../../../../config/apiUtils/EditionController";
import AjouterAnnexe from "./AjouterAnnexe";
import ModalSignatureContrat from "../../modal/ModalSignatureContrat";

export default function Contrat({
  contrat,
  ind,
  onClickSelectParcelle,
  onClickZoomParcelle,
}) {
  const context = useContext(StoreContext);
  const navigate = useNavigate();
  const [showSignerContrat, setShowSignerContrat] = useState(
    getQueryParam("signercontrat") === "true" &&
      getQueryParam("contrat") === `contrat-${contrat.idcontrat}`,
  );
  const [showCompleterContrat, setShowCompleterContrat] = useState(
    getQueryParam("completercontrat") === "true" &&
      getQueryParam("contrat") === `contrat-${contrat.idcontrat}`,
  );
  const [showAjouterAnnexe, setShowAjouterAnnexe] = useState(
    getQueryParam("ajouterannexe") === "true" &&
      getQueryParam("contrat") === `contrat-${contrat.idcontrat}`,
  );
  const goToFicheContrat = (idcontrat) =>
    navigate(`/app/contrat?id=${idcontrat}`);

  const buttons = [
    [
      {
        libelle: "Fiche contrat",
        onClick: (contrat) => goToFicheContrat(contrat.idcontrat),
        isVisible: () => true,
      },
      {
        libelle: "Télécharger",
        onClick: async (contrat) => {
          createNotification("info", "", "Préparation du document en cours...");
          const resDocument = await EditionController.getContrat(
            contrat.idcontrat,
          );
          downloadFile(
            resDocument,
            `contrat_${contrat.millesime}-${contrat.numerocontrat}.pdf`,
            "application/octet-stream",
          );
          createNotification("success", "", "Contrat généré avec succès.");
        },
        isVisible: () => true,
      },
    ],
    [
      {
        libelle:
          getQueryParam("completercontrat") === "true"
            ? "Fermer"
            : "Compléter contrat",
        onClick: () => {
          if (showSignerContrat) {
            deleteQueryParam("signercontrat");
            setShowSignerContrat(false);
          }
          if (showAjouterAnnexe) {
            deleteQueryParam("ajouterannexe");
            setShowAjouterAnnexe(false);
          }
          if (showCompleterContrat) {
            deleteQueryParam("completercontrat");
          } else {
            setQueryParam("completercontrat", "true");
          }
          setShowCompleterContrat(!showCompleterContrat);
        },
        isVisible: (contrat) => {
          return contrat.nbparcelles != contrat.nbparcellesrenseignee;
        },
      },
      {
        libelle: "Signer contrat",
        onClick: () => {
          if (showSignerContrat) {
            deleteQueryParam("signercontrat");
            setShowSignerContrat(false);
          }
          if (showAjouterAnnexe) {
            deleteQueryParam("ajouterannexe");
            setShowAjouterAnnexe(false);
          }
          if (showSignerContrat) {
            deleteQueryParam("signercontrat");
          } else {
            setQueryParam("signercontrat", "true");
          }
          setShowSignerContrat(!showSignerContrat);
        },
        isVisible: (contrat) => {
          return (
            contrat.nbparcelles == contrat.nbparcellesrenseignee &&
            !contrat.signee
          );
        },
      },
      {
        libelle:
          getQueryParam("ajouterannexe") === "true"
            ? "Annuler"
            : "Ajouter une annexe",
        onClick: () => {
          if (showCompleterContrat) {
            deleteQueryParam("completercontrat");
            setShowCompleterContrat(false);
          }
          if (showSignerContrat) {
            deleteQueryParam("signercontrat");
            setShowSignerContrat(false);
          }

          if (showAjouterAnnexe) {
            deleteQueryParam("ajouterannexe");
          } else {
            setQueryParam("ajouterannexe", "true");
          }
          setShowAjouterAnnexe(!showAjouterAnnexe);
        },
        isVisible: () => !contrat.abandon,
      },
    ],
  ];

  return (
    <Section
      id={`contrat-${contrat.idcontrat}`}
      key={ind}
      title={`Contrat ${
        contrat.numerocontrat + (contrat.abandon ? " - Abandonné" : "")
      }`}
    >
      {buttons.map((ligne, i) => {
        return (
          <Row key={i}>
            {ligne.map((button, index) => {
              return (
                <RenderIf key={index} isTrue={button.isVisible(contrat)}>
                  <ButtonCartoAction
                    key={index}
                    libelle={button.libelle}
                    onClick={() => button.onClick(contrat)}
                  />
                </RenderIf>
              );
            })}
          </Row>
        );
      })}
      <RenderIf isTrue={showSignerContrat}>
        <ModalSignatureContrat
          isOpen={showSignerContrat}
          toggle={() => {
            deleteQueryParam("signercontrat");
            setShowSignerContrat(false);
          }}
          contrat={contrat}
        />
      </RenderIf>
      <RenderIf isTrue={showCompleterContrat}>
        <CompleterContrat
          idcontrat={contrat.idcontrat}
          close={() => {
            context.carto.getParcellaireLayer().clearSelection();
            deleteQueryParam("completercontrat");
            setShowCompleterContrat(!showCompleterContrat);
            setShowSignerContrat(true);
          }}
          onClickSelectParcelle={onClickSelectParcelle}
          onClickZoomParcelle={onClickZoomParcelle}
        />
      </RenderIf>
      <RenderIf isTrue={showAjouterAnnexe}>
        <AjouterAnnexe
          idcontrat={contrat.idcontrat}
          onClickSelectParcelle={onClickSelectParcelle}
          onClickZoomParcelle={onClickZoomParcelle}
          close={() => {
            context.carto.getParcellaireLayer().clearSelection();
            deleteQueryParam("ajouterannexe");
            setShowAjouterAnnexe(!showAjouterAnnexe);
          }}
        />
      </RenderIf>
    </Section>
  );
}
